import React from 'react';
import {connect} from 'react-redux';
import {Typography} from '@mui/material';

import {deleteResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

type Props = {
  id: string;
  disabled?: boolean;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
}

const title = 'Delete Proxy Configuration';

const DeleteProxyDialog = ({id, disabled = false, onClose, onSubmit, ...others}: Props) => {
  if (disabled) {
    return null;
  }

  return (
    <FormDialog
      title={title} submitLabel='Delete' disableSubmit={disabled} onClose={onClose} onSubmit={onSubmit} closeOnSuccess
      {...others}
    >
      {() => (
        <Typography>
          <strong>Are you sure you want to delete the '{id}' proxy configuration?</strong>
        </Typography>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
  onSubmit: (values: any) => dispatch(deleteResource('proxy', ownProps.id, values)),
});

export default connect(undefined, mapDispatchToProps)(DeleteProxyDialog);
