import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';

import {BPProperties, TargetItemDetails} from '@/types';

type Props = {
  id: String;
  itemDetails?: TargetItemDetails;
  fetchItemDetails?: Function;
}

const PartitionsField = ({fetchItemDetails, itemDetails}: Props) => {
  React.useEffect(() => {
    if (fetchItemDetails !== undefined) {
      fetchItemDetails();
    }
  }, []);

  let partitions = [] as string[];
  if (itemDetails && itemDetails.properties) {
    const prop = itemDetails.properties as BPProperties;
    partitions = prop.partitions ? prop.partitions : [];
  }
  if (isEmpty(partitions)) {
    return <span>--</span>;
  }

  return (
    <span>{partitions.sort((a, b) => a.localeCompare(b)).join(', ')}</span>
  );
};

const mapStateToProps = (state: Store, ownProps: Props) => {
  const itemDetails = state.resources['storage/' + ownProps.id + '/item'] || {};
  return {itemDetails: itemDetails.data};
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
  fetchItemDetails: () => dispatch(fetchResource('storage/' + ownProps.id + '/item')),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartitionsField);

