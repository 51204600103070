import React, {ChangeEvent} from 'react';
import {connect} from 'react-redux';
import arrayMutators from 'final-form-arrays';
import {Field} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';

import {
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MaterialTextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AddIcon from 'spectra-logic-ui/icons/Add';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';
import {createPatch, patchResource} from 'spectra-logic-ui/actions';
import {SpectraLogoColor} from 'spectra-logic-ui/colors';
import {Table, Tooltip} from 'spectra-logic-ui/components';
import {FormDialog, FormRenderProps} from 'spectra-logic-ui/components';
import Card from 'spectra-logic-ui/components/card';
import {Dispatch} from 'spectra-logic-ui';

import {ConfigureNetwork} from '@/help';
import {NetworkInterface, NetworkInterfacePatchFields} from '@/types';
import DialogDescription from '@/components/form/dialog_description';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';
import DNS from '@/network/form/nic_edit/dns';
import {InterfaceFormData} from '@/network/form/types';
import {DhcpDnsOption} from '@/network/form/nic_edit/enum';

type Props = {
  selectedInterface: NetworkInterface;
  onSubmit: (values: InterfaceFormData) => Promise<any>;
  onSuccess: () => any;
}

const useStyles = makeStyles({
  ipmode: {padding: '5px'},
  textField: {
    marginTop: 12,
  },
  root: {
    '& > *': {
      paddingTop: 0,
      paddingBottom: 5,
    },
  },
  typography: {
    marginLeft: '10px',
  },
  card: {
    marginBottom: 5,
  },
  checkbox: {
    marginLeft: 0,
  },
  mtu: {
    '& > *': {
      width: '30%',
    },
  },
  plus: {
    color: SpectraLogoColor.GREEN,
  },
  div: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  address: {
    width: '70%',
  },
});

const dialogTitle = 'Edit Network';

const ipv4DefaultTooltip = 'The IPv4 default gateway.';
const ipv6DefaultTooltip = 'The IPv6 default gateway.';
const dhcp4Tooltip = 'The IPv4 mode determines how the IPv4 configuration will be acquired.';
const auto6Tooltip = 'The IPv6 mode determines how the IPv6 configuration will be acquired. ' +
  'When set to automatic, the configuration will be acquired either via SLAAC or DHCPv6 depending on ' +
  'the router\'s configuration';
const prefixLengthTooltip = 'The prefix length for both IPv4 and IPv6 addresses. ' +
  'For IPv4 addresses, prefix lengths such as 24 are entered instead of netmasks such as 255.255.255.0.';

const Addresses = ({dhcp4, auto6, addresses, fields, meta: {error, submitFailed}}: any) => {
  const classes = useStyles();

  const renderRow = (address: string, index: number) => {
    if ((dhcp4 && addresses[index].includes('.')) || (auto6 && addresses[index].includes(':'))) {
      return null;
    }
    return (
      <TableRow key={address}>
        <Field name={address}>
          {({input, meta: {touched, error, invalid, submitError}}: any) => {
            const addrParts = input.value.split('/');
            const address = addrParts[0] || '';
            const prefix = addrParts[1] || '';

            const onAddrChange = (event: ChangeEvent<HTMLInputElement>) => {
              input.onChange(event.target.value + '/' + prefix);
            };
            const onPrefixChange = (event: ChangeEvent<HTMLInputElement>) => {
              input.onChange(address + '/' + event.target.value);
            };

            return (
              [<TableCell>
                <MaterialTextField
                  fullWidth onChange={onAddrChange} value={address} error={touched && invalid}
                  helperText={touched && invalid ? (error || submitError) : null} variant='standard'
                />
              </TableCell>,
              <TableCell>
                <MaterialTextField
                  fullWidth onChange={onPrefixChange} value={prefix} error={touched && invalid}
                  helperText={touched && invalid ? ' ' : null} variant='standard'
                />
              </TableCell>]
            );
          }}
        </Field>
        <TableCell>
          <IconButton onClick={() => fields.remove(index)} size='large'>
            <DeleteIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <div className={classes.div}>
        <Typography className={classes.typography}>Static Addresses</Typography>
        <IconButton
          className={classes.plus}
          onClick={() => {
            fields.push('');
          }}
          disabled={dhcp4 && auto6}
          size='large'>
          <AddIcon />
        </IconButton>
      </div>
      <Table>
        <TableHead>
          {submitFailed && error && <span>{error}</span>}
          <TableRow className={classes.root}>
            <TableCell className={classes.address}>IP Addresses</TableCell>
            <TableCell colSpan={2}>Prefix Length<Tooltip>{prefixLengthTooltip}</Tooltip></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map(renderRow)}
        </TableBody>
      </Table>
    </div>
  );
};

const NetworkDialog = ({selectedInterface, onSubmit, onSuccess, ...otherProps}: Props) => {
  const classes = useStyles();

  const {dhcp4, auto6, nameServers, searchDomains, dhcpDns, ...otherNicValues} = selectedInterface;
  const nameServersStr = (nameServers || []).join('\n');
  const searchDomainsStr = (searchDomains || []).join('\n');

  const initialValues = {
    dhcp4: dhcp4,
    dhcp4Mode: String(dhcp4 || false),
    auto6: auto6,
    auto6Mode: String(auto6 || false),
    nameServers: nameServersStr,
    searchDomains: searchDomainsStr,
    dhcpDnsOption: (dhcpDns ? DhcpDnsOption.CHOOSE_DHCP : DhcpDnsOption.CHOOSE_MANUAL),
    ...otherNicValues,
  } as InterfaceFormData;

  return (
    <FormDialog
      title={dialogTitle}
      submitLabel='Save'
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      initialValues={initialValues}
      getHelpLocation={() => ConfigureNetwork}
      mutators={{...arrayMutators}}
      {...otherProps}
    >
      {({form, values}: FormRenderProps) => (
        <>
          <DialogDescription>Configure your Network Settings</DialogDescription>
          <br/>
          <div>
            <Grid container>
              <Grid item className={classes.ipmode} xs={6}>
                <FormSingleSelect
                  name='dhcp4Mode'
                  label='IPv4 Mode'
                  options={[{key: 'true', text: 'DHCP'}, {key: 'false', text: 'Manual'}]}
                  onChange={(event) => {
                    form.mutators.setFormValue('dhcp4', event.target.value === 'true');
                    if (event.target.value === 'true' && values['auto6']) {
                      form.mutators.setFormValue('dhcpDnsOption', DhcpDnsOption.CHOOSE_DHCP);
                    } else if (event.target.value !== 'true' && !values['auto6']) {
                      form.mutators.setFormValue('dhcpDnsOption', DhcpDnsOption.CHOOSE_MANUAL);
                    }
                  }}
                  tooltip={dhcp4Tooltip}
                />
              </Grid>
              <Grid item className={classes.ipmode} xs={6}>
                <FormSingleSelect
                  name='auto6Mode'
                  label='IPv6 Mode'
                  options={[{key: 'true', text: 'Automatic'}, {key: 'false', text: 'Manual'}]}
                  onChange={(event) => {
                    form.mutators.setFormValue('auto6', event.target.value === 'true');
                    if (event.target.value === 'true' && values['dhcp4']) {
                      form.mutators.setFormValue('dhcpDnsOption', DhcpDnsOption.CHOOSE_DHCP);
                    } else if (event.target.value !== 'true' && !values['dhcp4']) {
                      form.mutators.setFormValue('dhcpDnsOption', DhcpDnsOption.CHOOSE_MANUAL);
                    }
                  }}
                  tooltip={auto6Tooltip}
                />
              </Grid>
            </Grid>
            <Card className={classes.card}>
              <FieldArray
                name='addresses'
                component={Addresses}
                dhcp4={values.dhcp4}
                auto6={values.auto6}
                addresses={values.addresses}
              />
            </Card>
            <TextField className={classes.textField} name='gateway4' label='IPv4 Default Gateway'
              disabled={values.dhcp4} tooltip={ipv4DefaultTooltip} />
            <TextField className={classes.textField} name='gateway6' label='IPv6 Default Gateway'
              disabled={values.auto6} tooltip={ipv6DefaultTooltip} />
            <TextField
              className={`${classes.textField} ${classes.mtu}`}
              name='mtu'
              label='MTU'
              parse={(val) => isNaN(parseInt(val, 10)) ? null : parseInt(val, 10)}
            />
          </div>
          <br/>
          <DNS dhcpDnsOption={values.dhcpDnsOption} auto6={values.auto6} dhcp4={values.dhcp4} />
        </>
      )}
    </FormDialog>
  );
};

let ipv4Redirect: any;
const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
  onSubmit: (values: InterfaceFormData) => {
    // Attempt to redirect to a new IP address that is sent down with
    // the request.  Ideally, we'd get the new IP from the response body,
    // via an `onSuccess` callback, but that won't work because we'll
    // never receive a response from the server (because it's IP address
    // is being changed).
    ipv4Redirect = setTimeout(() => {
      let newIpv4 = null;
      for (let i = 0; i < values.addresses.length; i++) {
        if (values.addresses[i].includes('.')) {
          const parts = values.addresses[i].split('/');
          newIpv4 = parts[0];
          break; // only get the first ipv4
        }
      }
      if (newIpv4) {
        window.location.replace(`${window.location.protocol}//${newIpv4}`);
      }
    }, 40000);

    const {addresses, dhcpDnsOption, gateway4, gateway6, nameServers, searchDomains, ...otherValues} = values;
    // Filter out addresses that were obtained via DHCP.
    const filterDynamicAddresses = (address: string) => {
      if ((values.dhcp4 && address.includes('.')) || (values.auto6 && address.includes(':'))) {
        return false;
      }
      return true;
    };
    const staticAddresses = addresses.filter(filterDynamicAddresses);
    const useDhcp = (dhcpDnsOption == DhcpDnsOption.CHOOSE_DHCP);
    let newNameServers = [] as string[];
    let newSearchDomains = [] as string[];
    if (!useDhcp) {
      if (nameServers) {
        newNameServers = nameServers.replace(/\n+$/, '').split('\n');
      }
      if (searchDomains) {
        newSearchDomains = searchDomains.replace(/\n+$/, '').split('\n');
      }
    }

    // Set addresses to a filtered version of the original addresses so `createPatch` can handle address correctly.
    const oldVals: any = ownProps.selectedInterface;
    if (oldVals.addresses) {
      oldVals.addresses = oldVals.addresses.filter(filterDynamicAddresses);
    }

    const newVals: any = {
      ...otherValues,
      addresses: staticAddresses,
      dhcpDns: useDhcp,
    };
    if (!values.dhcp4) {
      newVals.gateway4 = gateway4;
    }
    if (!values.auto6) {
      newVals.gateway6 = gateway6;
    }
    if (!useDhcp) {
      newVals.nameServers = newNameServers;
      newVals.searchDomains = newSearchDomains;
    }
    delete newVals['dhcp4Mode'];
    delete newVals['auto6Mode'];

    const cancelRedirect = () => clearTimeout(ipv4Redirect);
    const opts = {
      // We got a response, which means the user didn't change their IP address and
      // thus we don't need to redirect.  A successful network interface change that involved
      // an IP address change means we'll never get a response.
      onSuccess: cancelRedirect,
      // We got an error response but don't want to redirect if it's a validation error.
      onError: (error: any) => {
        if (error.code && error.message) {
          cancelRedirect();
        }
      },
    };

    const body = createPatch(NetworkInterfacePatchFields, oldVals, newVals);
    if (body.dhcp4 === false || body.auto6 === false) {
      // Switching from auto to static for either IPv4 or IPv6. Make sure static addresses are set.
      if (body.addresses === undefined) {
        body.addresses = staticAddresses;
      }
    }
    if (body.dhcp4 === false && body.gateway4 === undefined) {
      body.gateway4 = gateway4;
    }
    if (body.auto6 === false && body.gateway6 === undefined) {
      body.gateway6 = gateway6;
    }
    if (body.dhcpDns === false) {
      // Switching from DHCP DNS to manual DNS. Ensure name servers and search domains
      // are sent down in case they weren't changed.
      if (body.nameServers === undefined) {
        body.nameServers = newNameServers;
      }
      if (body.searchDomains === undefined) {
        body.searchDomains = newSearchDomains;
      }
    }
    return dispatch(patchResource('network/interfaces', ownProps.selectedInterface.name, body, opts));
  },
});

export default connect(null, mapDispatchToProps)(NetworkDialog);
