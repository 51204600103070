import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Table from 'spectra-logic-ui/components/table';

import {Acl, Bucket} from '@/types';

type Props = {
  bucket: Bucket;
}

const useStyles = makeStyles((theme) => ({
  acls: {
    marginTop: theme.spacing(2),
  },
  aclHeader: {
    fontWeight: 'bold',
    width: '25%',
  },
}));

const AclsDetails = ({bucket}: Props) => {
  const classes = useStyles();
  const acls = bucket.acls || [];
  return (
    <>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Block Public ACLs</Table.Cell>
            <Table.Cell>{bucket.blockPublicAcls ? 'Enabled' : 'Disabled'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Ignore Public ACLs</Table.Cell>
            <Table.Cell>{bucket.ignorePublicAcls ? 'Enabled' : 'Disabled'}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table className={classes.acls}>
        <Table.Header>
          <Table.Row>
            <Table.Cell className={classes.aclHeader}>AWS Canonical ID</Table.Cell>
            <Table.Cell className={classes.aclHeader}>Permissions</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {acls.map((acl, index) => (
            <Table.Row key={index}>
              <Table.Cell>{acl.id}</Table.Cell>
              <Table.Cell>{renderAcl(acl)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export const renderAcl = (acl: Acl) => {
  const acls = [];
  if (acl.read) {
    acls.push('Read');
  }
  if (acl.readACP) {
    acls.push('Read ACP');
  }
  if (acl.write) {
    acls.push('Write');
  }
  if (acl.writeACP) {
    acls.push('Write ACP');
  }
  return acls.join(', ');
};

export default AclsDetails;
