import React from 'react';

import {Grid} from '@mui/material';

import {OS} from '@/enum';
import {getOS} from '@/global';
import Interfaces from '@/network/interfaces';
import Proxy from '@/network/proxy';

const NetworkPage = () => {
  return (
    <Grid container>
      {getOS() !== OS.BP &&
        <Grid item xs={12}>
          <Interfaces />
        </Grid>
      }
      <Grid item xs={12}>
        <Proxy />
      </Grid>
    </Grid>
  );
};

export default NetworkPage;
