import React from 'react';

import {Divider, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SyncIcon from 'spectra-logic-ui/icons/SyncAlt';
import ExportIcon from 'spectra-logic-ui/icons/TrendingFlat';

const useStyles = makeStyles({
  cloudIcon: {
    width: 20,
    marginRight: 7,
  },
  divider: {
    margin: '10px 0',
  },
  example: {
    marginBottom: 10,
  },
  exampleHeader: {
    fontStyle: 'italic',
    marginBottom: 2,
  },
  exampleContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: 10,
    },
  },
  exampleItem: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ImportIcon = (props: any) => <ExportIcon style={{transform: 'scale(-1, 1)'}} {...props} />;

export const AwsStoreTooltip = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='body2'>
        Cloud buckets can be used as storage placements when defining lifecycle rules.
        Each object from the system bucket will be stored in the cloud bucket with a prefix added
        using the originating bucket's name.
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.example}>
        <div className={classes.exampleHeader}>
          <Typography variant='body2' display='inline'>
            <strong>Example: </strong>
          </Typography>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>system-bucket-1/file.txt</strong>
            </Typography>
          </div>
          <ExportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>cloud-bucket/system-bucket-1/file.txt</strong>
            </Typography>
          </div>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>system-bucket-2/file.txt</strong>
            </Typography>
          </div>
          <ExportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>cloud-bucket/system-bucket-2/file.txt</strong>
            </Typography>
          </div>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>system-bucket-3/file.txt</strong>
            </Typography>
          </div>
          <ExportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>cloud-bucket/system-bucket-3/file.txt</strong>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AwsLinkTooltip = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='body2'>
        When linking a cloud bucket with a system bucket, existing and new data can be discovered and copied between
        them. Lifecycles determine whether to "synchronize" or "import" this data.
        Synchronizing will ensure the data and subsequent changes between both buckets remain the same,
        while importing will persist data and subsequent changes from the cloud bucket data to the system bucket only.
        Importing can be used to move or copy data from a cloud bucket to a system bucket.
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.example}>
        <div className={classes.exampleHeader}>
          <Typography variant='body2' display='inline'><strong>Example 1: </strong></Typography>
          <Typography variant='body2' display='inline'>Synchronizing via lifecycle</Typography>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>System Bucket</strong>
            </Typography>
          </div>
          <SyncIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>Cloud Bucket</strong>
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.example}>
        <div className={classes.exampleHeader}>
          <Typography variant='body2' display='inline'><strong>Example 2: </strong></Typography>
          <Typography variant='body2' display='inline'>Importing via lifecycle</Typography>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>System Bucket</strong>
            </Typography>
          </div>
          <ImportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>Cloud Bucket</strong>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
