import React from 'react';
import {Field, useForm} from 'react-final-form';

import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {SelectOption} from 'spectra-logic-ui';
import {Tooltip} from 'spectra-logic-ui/components';

type Props = {
  name: string;
  target: string;
  label: string;
  options: SelectOption[];
  disabled?: Boolean;
  tooltip?: React.ReactNode;
  onChange?: (...args: any) => any;
}

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    width: '100%',
  },
  singleSelect: {
    display: 'flex',
    flex: '1 1 auto',
  },
  formControl: {
    width: '100%',
    minWidth: 100,
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
});

const FormForwardSelect = ({name, target, label, options, disabled, onChange, tooltip}: Props) => {
  const classes = useStyles();
  const form = useForm();
  const Component = ({input, meta: {touched, error, invalid}}: any) => {
    const wrappedOnChange = (event: any) => {
      if (onChange) {
        onChange(event);
      }
      input.onChange(event.target.value);
      form.mutators.setFormValue(target, event.target.value);
    };

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl} error={touched && invalid} variant='standard'>
          <InputLabel>{label}</InputLabel>
          <Select disabled={!!disabled} value={input.value} onChange={wrappedOnChange} className={classes.singleSelect}>
            {options.map((option) => (
              <MenuItem key={option.key} value={option.value || option.key}>{option.text}</MenuItem>
            ))}
          </Select>
          {touched && invalid && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        {tooltip &&
          <div className={classes.tooltip}>
            <Tooltip>{tooltip}</Tooltip>
          </div>
        }
      </div>
    );
  };
  return <Field name={name} label={label} options={options} component={Component} />;
};

export default FormForwardSelect;
