import {StorageClass} from '@/enum';
import {Pool} from '@/types';

// Does the given storage pool have an archival storage class.
export const isArchival = (storage: Pool) => {
  return isStorageClassArchival(storage.storageClass);
};

// Given a list of storage classes, is at least one of them archival.
export const isArchivalSupported = (storageClasses?: StorageClass[]) => {
  if (!storageClasses) {
    return false;
  }
  for (let i = 0; i < storageClasses.length; i++) {
    if (isStorageClassArchival(storageClasses[i])) {
      return true;
    }
  }
  return false;
};

// Is the given storage class archival. Note that StorageClass.GLACIER_IR is
// not archival because, despite having "glacier" in its name, does not
// require an explicit restore.
export const isStorageClassArchival = (sc?: StorageClass) => {
  return sc === StorageClass.GLACIER || sc === StorageClass.DEEPARCHIVE;
};
