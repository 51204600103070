import React from 'react';
import {Button, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import createStyles from '@mui/styles/createStyles';

type Props = {
  onSelect: Function;
  children: React.ReactElement[];
}

type ItemProps = {
  id: string;
  value: string;
  selected?: boolean;
  onClick?: Function;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
});

const useItemStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Selector = ({onSelect, children}: Props) => {
  const [selectedId, setSelectedId] = React.useState('-1');
  const classes = useStyles();

  // Reset selection to first selector item when items are added.
  // This is a bit of a hack to deal with the initial load,
  // and will cause problems for wanting to dynamically load selector items while maintaining state.
  React.useEffect(() => {
    if (children.length > 0 && children[0].props) {
      setSelectedId(children[0].props.id);
    }
  }, [React.Children.count(children)]);

  const createChildProps = (child: React.ReactElement) => {
    const defaultClickFunc = (id: string) => {
      setSelectedId(id);
      onSelect(id);
    };
    return {
      selected: child.props.id === selectedId,
      onClick: onSelect ? defaultClickFunc : setSelectedId,
    };
  };

  return (
    <div className={classes.root}>
      {React.Children.map(children, (child) => (
        React.cloneElement(child, createChildProps(child), null)),
      )}
    </div>
  );
};

Selector.Item = ({id, value, selected = false, onClick}: ItemProps) => {
  const classes = useItemStyles();
  return (
    <Button
      variant='contained' id={id} className={classes.button} color={selected ? 'primary' : 'secondary'}
      onClick={() => onClick && onClick(id)}
    >
      {value}
    </Button>
  );
};

export default Selector;
