import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {ApiStatus, Card, Table, Tooltip} from 'spectra-logic-ui/components';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';
import WorldIcon from 'spectra-logic-ui/icons/Public';

import {StorageUsed} from '@/types';
import CardHeader from '@/components/card_header';
import OnPremiseStorageCharts from '@/usage/on_premise_storage_charts';

type Props = {
  summary?: StorageUsed[];
  fetching?: boolean;
  error?: boolean;
  fetchSummary?: () => Promise<any>;
}

type CapacityRow = {
  usedByObjects?: number;
  available?: number;
  total?: number;
  totalOptional?: number;
  totalUsed?: number;
}

const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 'bold',
    width: '25%',
  },
});

const Sphere = ({summary = [], fetching = false, error = false, fetchSummary}: Props) => {
  const classes = useStyles();
  useEffect(() => {
    if (fetchSummary !== undefined) {
      fetchSummary();
    }
  }, []);
  type CapacityMap = {[key: string]: CapacityRow}
  const capacities: CapacityMap = {};
  summary.forEach((cap) => {
    if (cap.label !== undefined) {
      const capRow: CapacityRow = {};
      if (cap.data !== undefined) {
        capRow.usedByObjects = cap.data;
      }
      if (cap.total !== undefined) {
        capRow.total = cap.total;
      }
      if (cap.total !== undefined && cap.used !== undefined) {
        if (cap.optional !== undefined) {
          capRow.available = cap.optional + cap.total - cap.used;
        } else {
          capRow.available = cap.total - cap.used;
        }
      }
      if (cap.optional !== undefined) {
        capRow.totalOptional = cap.optional;
      }
      if (cap.used !== undefined) {
        capRow.totalUsed = cap.used;
      }
      capacities[cap.label] = capRow;
    }
  });

  return (
    <Card>
      <CardHeader icon={WorldIcon}>Sphere Endpoint Physical Capacity</CardHeader>
      <Card.Body>
        <ApiStatus isLoading={fetching} hasError={error} isEmpty={isEmpty(summary)}>
          <OnPremiseStorageCharts summary={summary} />
          <div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.Cell className={classes.tableHeader}>Storage</Table.Cell>
                  <Table.Cell className={classes.tableHeader}>Used</Table.Cell>
                  <Table.Cell className={classes.tableHeader}>
                    Optional
                    <Tooltip>
                      <span>Amount used by Vail for storing optional clones.</span>
                    </Tooltip>
                  </Table.Cell>
                  <Table.Cell className={classes.tableHeader}>
                    Available
                    <Tooltip>
                      <span>Available capacity does not account for capacity used by file system overhead.</span>
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              </Table.Header>
              <React.Fragment>
                <Table.Body>
                  {Object.keys(capacities).map((sc) => {
                    return (
                      <Table.Row key={'sphere_table_' + sc}>
                        <Table.Cell>{sc}</Table.Cell>
                        <Table.Cell>
                          {capacities[sc].usedByObjects !== undefined ?
                            numberToHumanSize(capacities[sc].usedByObjects) : <DataNA />}
                        </Table.Cell>
                        <Table.Cell>
                          {capacities[sc].totalOptional !== undefined ?
                            numberToHumanSize(capacities[sc].totalOptional) : <DataNA />}
                        </Table.Cell>
                        <Table.Cell>
                          {capacities[sc].available !== undefined ?
                            numberToHumanSize(capacities[sc].available) : <DataNA />}
                        </Table.Cell>
                      </Table.Row>
                    );
                  },
                  )}
                </Table.Body>
              </React.Fragment>
            </Table>
          </div>
        </ApiStatus>
      </Card.Body>
    </Card>
  );
};

const DataNA = () => (
  <span>Not Available <Tooltip>This data is not available immediately after the storage is created.</Tooltip></span>
);

const mapStateToProps = (state: Store) => {
  const sphereResource = state.resources['usage/sphere/summary'] || {};
  return {
    fetching: sphereResource.fetching,
    error: sphereResource.error,
    summary: sphereResource.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSummary: () => (dispatch(fetchResource('usage/sphere/summary'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sphere);
