import isEmpty from 'is-empty';

import {Lifecycle} from '@/types';
import {validateName, validateNumber, ValidationError} from '@/validate';
import {FormValues} from '@/lifecycle/form/types';

const maxNameLength = 255;

export const createParametersValidator = (lifecycles: Lifecycle[]) => {
  return ({name, uploads, description}: FormValues) => {
    const errors = {} as any;

    errors.name = validateName(name, {maxLength: maxNameLength});
    if (!errors.name && lifecycles.find((l) => name.trim() === l.name)) {
      errors.name = ValidationError.MUST_BE_UNIQUE;
    }

    errors.uploads = validateNumber(uploads);

    if (description && description.length > 255) {
      errors.description = 'Cannot be greater than 255 characters';
    }

    return errors;
  };
};

// validateRules validates the rules in the lifecycle form. Most of this validation
// is done server-side. The only parts that are validated here are things that can't
// be done server-side.
export const validateRules = ({rules}: FormValues) => {
  const ruleDestinations = {count: undefined, storage: undefined};
  const ruleSchedule = {days: undefined};
  const ruleError = {name: undefined, destinations: ruleDestinations, schedule: ruleSchedule};
  const errors = {rules: [] as typeof ruleError[]} as any;

  if (!isEmpty(rules)) {
    rules.forEach((rule, ruleIndex) => {
      errors.rules[ruleIndex] = Object.assign({}, ruleError);
      errors.rules[ruleIndex].destinations = Object.assign({}, ruleDestinations);
      errors.rules[ruleIndex].schedule = Object.assign({}, ruleSchedule);

      if (isEmpty(rule.schedule)) {
        if (rule.expiration) {
          errors.rules[ruleIndex].schedule.days = ValidationError.CANNOT_BE_BLANK;
        }
      } else {
        // rule.schedule.days is an int server-side. Thus, the server-side validations
        // won't see these kinds of invalid options a user might-have entered.
        const num = Number(rule.schedule.days);
        if (/\s/.test(rule.schedule.days)) {
          errors.rules[ruleIndex].schedule.days = ValidationError.MUST_NOT_CONTAIN_SPACES;
        } else if (/\./.test(rule.schedule.days)) {
          errors.rules[ruleIndex].schedule.days = ValidationError.CANNOT_CONTAIN_A_DECIMAL_POINT;
        } else if (isNaN(num)) {
          errors.rules[ruleIndex].schedule.days = ValidationError.MUST_BE_A_NUMBER;
        }
      }

      if (!isEmpty(rule.noncurrentVersions)) {
        // rule.noncurrentVersions is an int server-side. Thus, the server-side validations
        // won't see these kinds of invalid options a user might-have entered.
        const num = Number(rule.noncurrentVersions);
        if (rule.noncurrentVersions && /\s/.test(rule.noncurrentVersions)) {
          errors.rules[ruleIndex].noncurrentVersions = ValidationError.MUST_NOT_CONTAIN_SPACES;
        } else if (rule.noncurrentVersions && /\./.test(rule.noncurrentVersions)) {
          errors.rules[ruleIndex].noncurrentVersions = ValidationError.CANNOT_CONTAIN_A_DECIMAL_POINT;
        } else if (isNaN(num)) {
          errors.rules[ruleIndex].noncurrentVersions = ValidationError.MUST_BE_A_NUMBER;
        }
      }
    });
  }
  return errors;
};
