import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {updateResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {Proxy} from '@/types';
import {ProxyType} from '@/enum';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';
import {ProxyFormData} from '@/network/form/types';
import {validateProxy} from '@/network/form/validate';

export const ProxyTypes = [
  {key: ProxyType.GLOBAL, text: 'Global'},
];

const CreateProxyDialog = (props: any) => {
  const initialValues = {} as any;
  if (ProxyTypes.length === 1) {
    initialValues.id = ProxyTypes[0].key;
  }

  return (
    <FormDialog title='Create Proxy Configuration' initialValues={initialValues} validate={validateProxy} {...props}>
      {() => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormSingleSelect name='id' label='Select Proxy Type' options={ProxyTypes}
                disabled={ProxyTypes.length === 1} />
            </Grid>
            <Grid item xs={8}>
              <TextField name='hostname' label='Hostname' />
            </Grid>
            <Grid item xs={4}>
              <TextField name='port' label='Port' />
            </Grid>
            <Grid item xs={6}>
              <TextField name='username' label='Username' />
            </Grid>
            <Grid item xs={6}>
              <TextField name='password' label='Password' type='password' />
            </Grid>
          </Grid>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: ProxyFormData) => {
    const body: Proxy = {hostname: values.hostname, username: values.username, password: values.password};
    if (values.port !== undefined && values.port !== null && values.port !== '') {
      body.port = Number(values.port);
    }
    return dispatch(updateResource('proxy', values.id, body));
  },
});

export default connect(undefined, mapDispatchToProps)(CreateProxyDialog);
