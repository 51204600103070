import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {Color, SpectraLogoColor} from 'spectra-logic-ui/colors';
import {Tooltip} from 'spectra-logic-ui/components';

type TableRowProps = {
  name: string;
  value: string | number;
}

type TableProps = {
  children: React.ReactElement<TableRowProps> | React.ReactElement<TableRowProps>[];
}

type Props = {
  title?: string;
  tooltip?: React.ReactNode;
  headerColor?: Color | SpectraLogoColor;
  PopoverProps?: object;
  className?: string;
  children: React.ReactElement | React.ReactElement[];
}

const useStyles = (headerColor: Color | SpectraLogoColor = SpectraLogoColor.BLUE) => makeStyles({
  header: {
    background: headerColor,
    padding: '12px 15px 10px 15px',
  },
  title: {
    color: Color.WHITE,
  },
  tooltipIcon: {
    height: '18px !important',
    color: `${Color.WHITE} !important`,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  tableCell: {
    verticalAlign: 'top',
    overflowWrap: 'break-word',
  },
  tableCellName: {
    fontWeight: 'normal',
  },
  tableCellValue: {
    fontWeight: 'lighter',
  },
});

const NoteCard = ({title, tooltip, headerColor, PopoverProps = {}, className, children}: Props) => {
  const classes = useStyles(headerColor)();
  const headerContent = (
    <React.Fragment>
      {title}
      {tooltip &&
        <Tooltip className={classes.tooltipIcon} {...PopoverProps}>
          {tooltip}
        </Tooltip>
      }
    </React.Fragment>
  );

  return (
    <Card className={className}>
      {title &&
        <React.Fragment>
          <CardHeader
            subheader={headerContent} subheaderTypographyProps={{className: classes.title}}
            className={classes.header}
          />
          <Divider />
        </React.Fragment>
      }
      <CardContent className={classes.content}>
        {children}
      </CardContent>
    </Card>
  );
};

NoteCard.TableRow = ({name, value}: TableRowProps) => {
  const classes = useStyles()();
  return (
    <TableRow>
      <TableCell className={`${classes.tableCell} ${classes.tableCellName}`}>{name}</TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellValue}`}>{value || '--'}</TableCell>
    </TableRow>
  );
};

NoteCard.Table = ({children}: TableProps) => {
  const classes = useStyles()();
  return (
    <Table size='small' className={classes.table}>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
};

export default NoteCard;
