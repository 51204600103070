import {FormData} from '@/iam/users/form/types';

// Validate that when creating a new IAM user, name cannot
// be empty. This is done client-side because the name
// is part of the URL's path. Thus, having it be empty would
// result in a completely different request being sent to
// the server, which complicates server-side validation.
export default ({username}: FormData) => {
  const errors = {} as any;

  if (!username) {
    errors.username = 'Username must not be empty';
  }

  return errors;
};
