import {ValidationError} from '@/validate';
import {Pool} from '@/types';
import {FormOption} from '@/storage/form/delete/enum';

type FormValues = {
  confirmedName: string;
  confirmCheck: boolean;
  chosenStorage: string;
  formOption: FormOption;
}

export const validateOptions = ({chosenStorage, formOption}: FormValues) => {
  const errors = {} as any;
  if (!chosenStorage && formOption == 'choose_alternative') {
    errors.chosenStorage = ValidationError.CANNOT_BE_BLANK;
  }
  return errors;
};

export const validateAlt = ({confirmCheck}: FormValues) => {
  const errors = {} as any;
  if (!confirmCheck) {
    errors.confirmCheck = 'Must check to proceed';
  }
  return errors;
};

export const getValidator = (pool: Pool) => {
  return ({confirmedName}: FormValues) => {
    const errors = {} as any;
    if (!confirmedName) {
      errors.confirmedName = ValidationError.CANNOT_BE_BLANK;
    } else if (confirmedName !== pool.name) {
      errors.confirmedName = 'Must match the storage name';
    }
    return errors;
  };
};
