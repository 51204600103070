import React, {useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {ExpandableTable, StatusIcon} from 'spectra-logic-ui/components';

import {Pool} from '@/types';
import RegionEndpointField from '@/dashboard/details/region_field';

type Props = {
  pools: Pool[];
}

const useStyles = makeStyles({
  endpoint: {
    wordBreak: 'break-all',
  },
});

const nothingSelectedId = '-1';

const CloudDetails = ({pools}: Props) => {
  const [selectedId, setSelectedId] = React.useState(nothingSelectedId);
  const classes = useStyles();

  useEffect(() => {
    if (pools.length > 0 && selectedId === nothingSelectedId) {
      setSelectedId(pools[0].id);
    }
  }, [pools]);

  return (
    <ExpandableTable size='small'>
      <ExpandableTable.Header>
        <ExpandableTable.HeaderRow>
          <ExpandableTable.Cell>Name</ExpandableTable.Cell>
          <ExpandableTable.Cell>Region/Endpoint</ExpandableTable.Cell>
        </ExpandableTable.HeaderRow>
      </ExpandableTable.Header>
      <ExpandableTable.Body>
        {pools?.map((pool) => (
          <ExpandableTable.BodyRow
            key={pool.id} selected={pool.id === selectedId}
            onClick={() => setSelectedId(pool.id === selectedId ? nothingSelectedId : pool.id)}
          >
            <ExpandableTable.Cell>
              <span><StatusIcon status='ok' />&nbsp;{pool.name}</span>
            </ExpandableTable.Cell>
            <ExpandableTable.Cell className={classes.endpoint}>
              <RegionEndpointField id={pool.id}/>
            </ExpandableTable.Cell>
          </ExpandableTable.BodyRow>
        ))}
      </ExpandableTable.Body>
    </ExpandableTable>
  );
};

export default CloudDetails;
