import React, {useState} from 'react';
import {connect} from 'react-redux';

import {patchResource, updateResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {EditSphereAdmin} from '@/help';
import {Administrator} from '@/types';
import OptionsPage from '@/administrators/form/edit/options';
import {validatePassword} from '@/administrators/form/edit/validate';
import ChangeAttributesPage from '@/administrators/form/edit/attributes';
import ChangePasswordPage from '@/administrators/form/edit/password';
import {FormOption} from '@/administrators/form/edit/enum';
import {FormValues} from '@/administrators/form/edit/types';

type Props = {
  user: Administrator;
  onSubmit: (values: FormValues) => Promise<any>;
};

const EditAdministratorWizard = ({user, onSubmit, ...otherProps}: Props) => {
  const [values, setValues] = useState({} as any);
  const selectedOption = values['formOption'];

  return (
    <WizardFormDialog
      title='Edit Sphere Administrator' onSubmit={onSubmit} onValuesChange={setValues}
      getHelpLocation={() => EditSphereAdmin}
      initialValues={{formOption: FormOption.CHANGE_ATTRIBUTES, ...user}} {...otherProps}
    >
      <OptionsPage title='Options' user={user} />
      {selectedOption === FormOption.CHANGE_ATTRIBUTES &&
        <ChangeAttributesPage title='Change Attributes' />
      }
      {selectedOption === FormOption.CHANGE_PASSWORD &&
        <ChangePasswordPage title='Change Password' validate={validatePassword} />
      }
    </WizardFormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: FormValues) => {
    const {formOption, ...user} = values;
    let action = patchResource;
    let resourceUrl = 'users';
    let id = user.username;
    if (formOption === FormOption.CHANGE_PASSWORD) {
      action = updateResource;
      resourceUrl += `/${user.username}/password`;
      id = '';
    }
    delete user.passwordCheck;
    return dispatch(action(resourceUrl, id, user));
  },
});

export default connect(null, mapDispatchToProps)(EditAdministratorWizard);
