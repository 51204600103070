import React from 'react';
import {useForm, useFormState} from 'react-final-form';

import {IconButton, InputAdornment, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';
import AttentionIcon from 'spectra-logic-ui/icons/ErrorOutlineTwoTone';

import {Color} from 'spectra-logic-ui/colors';

import TextField from '@/components/form/text_field';
import FormSingleSelect from '@/components/form/single_select';
import NoteCard from '@/components/note_card';
import {AfterTip, IncludeTip, ExcludeTip, NCVsTip} from '@/lifecycle/form/pages/rules/transition_rule';
import isEmpty from 'is-empty';

type Props = {
  ruleField: string;
  index: number;
  onDelete?: () => void;
}

const useStyles = makeStyles({
  listItem: {
    listStyleType: 'none',
    zIndex: 99999,
    marginBottom: 15,
  },
  ruleInfo: {
    padding: 10,
  },
  deleteButton: {
    alignSelf: 'flex-end',
    padding: '0',
    margin: '0',
  },
  buttonSize: {
    padding: 5,
    margin: '0 -5px -5px 5px',
  },
  clonesSelect: {
    width: '100%',
  },
  expirationIcon: {
    width: 20,
    marginLeft: 3,
  },
  expirationText: {
    verticalAlign: 'super',
  },
});

const versionOptions = [
  {key: 'all', text: 'All'},
  {key: 'current', text: 'Latest'},
  {key: 'nonCurrent', text: 'Previous'},
];

const ExpirationRule = ({ruleField, index, onDelete}: Props) => {
  const classes = useStyles();
  const versionField = `${ruleField}.apply`;
  const applyAfterField = `${ruleField}.schedule.days`;
  const noncurrentVersionsToKeepField = `${ruleField}.noncurrentVersions`;
  const tooltip = (
    <React.Fragment>
      <strong>Warning: </strong>
      A deletion rule will delete the object at the specified time.
    </React.Fragment>
  );

  const form = useForm();
  const formState = useFormState();
  const ruleState = formState.values.rules[index];

  React.useEffect(() => {
    if (ruleState.apply === undefined) {
      form.mutators.setFormValue(versionField, versionOptions[0].key);
    }
  }, []);

  React.useEffect(() => {
    if (ruleState.apply !== 'nonCurrent' && !isEmpty(ruleState.noncurrentVersions)) {
      form.mutators.setFormValue(noncurrentVersionsToKeepField, '');
    }
  }, [ruleState.apply]);

  return (
    <li className={classes.listItem}>
      <NoteCard
        title='Deletion Rule' headerColor={Color.RED}
        tooltip={tooltip} PopoverProps={{icon: AttentionIcon}}
      >
        <Grid container spacing={2} className={classes.ruleInfo}>
          <Grid item xs={12}>
            <TextField label='Name' name={`${ruleField}.name`}/>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Apply After' name={applyAfterField}
              InputProps={{endAdornment: <InputAdornment position='start'>days</InputAdornment>}}
              tooltip={AfterTip}
              parse={(value) => {
                if (value === '') {
                  return undefined;
                }
                return value;
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSingleSelect name={versionField} label='Versioning' options={versionOptions}/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Noncurrent Versions to Keep' name={noncurrentVersionsToKeepField}
              InputProps={{endAdornment: <InputAdornment position='start'>count</InputAdornment>}}
              disabled={ruleState.apply !== 'nonCurrent'} tooltip={<NCVsTip />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Include' name={`${ruleField}.include`} tooltip={<IncludeTip />} />
          </Grid>
          <Grid item xs={11}>
            <TextField label='Exclude' name={`${ruleField}.exclude`} tooltip={<ExcludeTip />} />
          </Grid>
          <Grid item xs={1} className={classes.deleteButton}>
            <IconButton className={classes.buttonSize} onClick={onDelete} size='large'>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </NoteCard>
    </li>
  );
};

export default ExpirationRule;
