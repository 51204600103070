import React from 'react';

import {Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';

import {storageClassString} from '@/enum';
import {Object} from '@/buckets/objects/types';

type Props = {
  object: Object;
}

const PropertiesDetails = ({object}: Props) => {
  const sc = storageClassString(object.storageClass);
  const objectProps = [
    {key: 'Version', value: object.versionId === undefined ? '--' : object.versionId},
    {key: 'Size', value: object.isDelete ? '--' : numberToHumanSize(object.size)},
    {key: 'Owner', value: object.isDelete ? '--' : object.ownerName},
    {key: 'Storage class', value: sc === '' ? '--' : sc},
    {key: 'Last modified', value: dateTimeLong(object.lastModified)},
    {key: 'Restored until', value: object.storageClass === undefined ? '--' : emptyDateTime(object.restoredUntil)},
    {key: 'Legal hold', value: emptyLegalHold(object.legalHold)},
    {key: 'Retention mode', value: emptyRetentionMode(object.isCompliance)},
    {key: 'Retain until', value: emptyDateTime(object.retainUntil)},
  ];

  return (
    <Table>
      <Table.Body>
        {objectProps.map((kv) => (
          <Table.Row key={kv.key}>
            <Table.Cell>{kv.key}</Table.Cell>
            <Table.Cell>{kv.value}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const emptyDateTime = (datetime: string | undefined) => {
  let value = '--';
  if (datetime) {
    value = dateTimeLong(datetime);
  }
  return value;
};

const emptyLegalHold = (legalHold: boolean | undefined) => {
  let value = '--';
  if (legalHold !== undefined) {
    value = legalHold ? 'Enabled' : 'Disabled';
  }
  return value;
};

const emptyRetentionMode = (isCompliance: boolean | undefined) => {
  let value = '--';
  if (isCompliance !== undefined) {
    value = isCompliance ? 'Compliance' : 'Governance';
  }
  return value;
};

export default PropertiesDetails;
