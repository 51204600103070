import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {ApiStatus, Card} from 'spectra-logic-ui/components';
import WorldIcon from 'spectra-logic-ui/icons/Public';

import {StorageUsed} from '@/types';
import CardHeader from '@/components/card_header';
import StorageDisplay from '@/usage/storage_display';

type CloudCapacityProps = {
  summary: StorageUsed[];
}

type Props = {
  summary?: StorageUsed[];
  fetching?: boolean;
  error?: boolean;
  fetchSummary?: () => Promise<any>;
}

const CloudCapacity = ({summary}: CloudCapacityProps) => {
  return (
    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
      {summary.map((cap) => (
        <StorageDisplay key={cap.label}
          name={cap.label === undefined ? '' : cap.label} used={cap.used === undefined ? 0 : cap.used} />
      ))}
    </div>
  );
};

const Cloud = (
  {
    summary = [],
    fetching = false,
    error = false,
    fetchSummary,
  }: Props) => {
  useEffect(() => {
    if (fetchSummary !== undefined) {
      fetchSummary();
    }
  }, []);

  return (
    <Card>
      <CardHeader icon={WorldIcon}>Cloud Capacity</CardHeader>
      <Card.Body>
        <ApiStatus isLoading={fetching} hasError={error} isEmpty={isEmpty(summary)}>
          <div>
            <CloudCapacity summary={summary} />
          </div>
        </ApiStatus>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const cloudResource = state.resources['usage/cloud/summary'] || {};
  return {
    fetching: cloudResource.fetching,
    error: cloudResource.error,
    summary: cloudResource.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSummary: () => (dispatch(fetchResource('usage/cloud/summary'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cloud);
