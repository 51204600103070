import React from 'react';
import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Color} from 'spectra-logic-ui/colors';
import Card from 'spectra-logic-ui/components/card';
import WorldIcon from 'spectra-logic-ui/icons/Public';

import {Location} from '@/types';
import CardHeader from '@/components/card_header';
import AnimatedMap from '@/dashboard/system_view/animated_map';

type Props = {
  selectedLocationId?: string;
  onLocationSelect: (...args: any) => void;
  locations: Location[];
  error: boolean;
}

const useStyles = makeStyles({
  root: {
    height: 'calc(100% - 30px)',
    marginBottom: 0,
  },
  cardBody: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  errorText: {
    color: Color.ERROR,
    padding: '10px 35px 25px 35px',
  },
  content: {
    display: 'flex',
  },
  map: {
    width: '100%',
  },
});

const SystemView = (props: Props) => {
  const {
    selectedLocationId, onLocationSelect, locations = [],
    error = false,
  } = props;
  const classes = useStyles();

  const sortedLocations = locations
    .filter((l: Location) => l.id !== '0')
    .sort((a: Location, b: Location) => {
      const convertStatusToNum = (status: string | undefined) => {
        if (status === undefined) {
          return 0;
        }
        switch (status) {
        case 'error':
          return 3;
        case 'warning':
          return 2;
        case 'ok':
          return 1;
        default:
          return 0;
        }
      };
      return convertStatusToNum(a.status) - convertStatusToNum(b.status);
    });

  return (
    <Card className={classes.root}>
      <CardHeader icon={WorldIcon} isShort>System View</CardHeader>
      <Card.Body className={classes.cardBody}>
        {error ? <Typography className={classes.errorText}>Failed to load system view.</Typography> :
          <div className={classes.content}>
            <div className={classes.map}>
              <AnimatedMap
                locations={sortedLocations} selectedLocationId={selectedLocationId} onSelect={onLocationSelect}
              />
            </div>
          </div>
        }
      </Card.Body>
    </Card>
  );
};

export default SystemView;
