import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {AddIAMAccount} from '@/help';
import TextField from '@/components/form/text_field';
import {FormValues} from '@/accounts/form/types';

type Props = {
  onSubmit: (values: FormValues) => Promise<any>;
}

const CreateAccountDialog = ({onSubmit, ...otherProps}: Props) => (
  <FormDialog
    title='Add IAM Account' getHelpLocation={() => AddIAMAccount} onSubmit={onSubmit}
    {...otherProps}
  >
    {() => (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField name='roleArn' label='Role ARN' />
        </Grid>
        <Grid item xs={12}>
          <TextField name='externalId' label='External ID' helperText='Optional. Specifies who can assume the role.' />
        </Grid>
        <Grid item xs={12}>
          <TextField name='email' label='Email' />
        </Grid>
        <Grid item xs={12}>
          <TextField name='description' label='Description' />
        </Grid>
      </Grid>
    )}
  </FormDialog>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: FormValues) => dispatch(createResource('accounts', values)),
});

export default connect(undefined, mapDispatchToProps)(CreateAccountDialog);
