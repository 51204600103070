import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import isEmpty from 'is-empty';

import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {createResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {FormDialog} from 'spectra-logic-ui/components';
import {FileInput} from 'spectra-logic-ui/components/fields';
import {Dispatch} from 'spectra-logic-ui';

import {Pod} from '@/types';
import {ValidationError} from '@/validate';
import {onSuccess} from '@/updates/form/update_dialog';

type Props = {
  endpoint?: Pod;
  resource: string;
  onSuccess?: () => void;
}

const useStyles = makeStyles({
  description: {
    color: Color.RED,
  },
});

const formDataKey = 'file';

const validate = (values: any) => {
  const errors = {} as any;
  if (isEmpty(values[formDataKey])) {
    errors.file = ValidationError.CANNOT_BE_BLANK;
  }
  return errors;
};

const UploadDialogContent = ({endpoint, resource, onSuccess, ...others}: Props) => {
  const classes = useStyles();
  const [file, setFile] = useState<File>();
  const description = 'WARNING: Cannot be undone';
  // Ideally, we'd limit the input's accept to something more specific (e.g.
  // '-freebsd.archive' for BlackPearl), however, it must start with a period
  // if we want to limit the file based on its name.
  const accept = '.archive';

  const dispatch: Dispatch = useDispatch();
  const onSubmit = () => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    return dispatch(createResource(resource, formData));
  };

  // Add the file to the form data when the user selects a file.
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget?.files;
    if (files) {
      setFile(files[0]);
    }
  };

  const name = endpoint ? endpoint.name : 'this endpoint';

  return (
    <FormDialog
      title='Upload' submitLabel='Upload' onSuccess={onSuccess} onSubmit={onSubmit} validate={validate}
      {...others}
    >
      {() => (
        <>
          <Typography>
            <strong>Upload and initiate update for {name}?</strong>
          </Typography>
          <FileInput name={formDataKey} label='Update Archive' accept={accept} onChange={handleFileInputChange} />
          <Typography className={classes.description}>{description}</Typography>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSuccess: () => onSuccess(dispatch),
});

export default connect(undefined, mapDispatchToProps)(UploadDialogContent);
