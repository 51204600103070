import React from 'react';
import {connect} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';

import {Card, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Store} from 'spectra-logic-ui';
import {WizardPage} from 'spectra-logic-ui/components';

import {FormActionType, StorageClass} from '@/enum';
import {Pool, TargetItemDetails} from '@/types';
import Checkbox from '@/components/form/checkbox';
import DialogDescription from '@/components/form/dialog_description';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';
import {isArchivalSupported, isStorageClassArchival} from '@/storage/archive';
import {cloneRestoreTooltip, recoverableTooltip} from '@/storage/details/properties';
import ThresholdsCard from '@/storage/thresholdsCard';
import OptionalDataCard from '@/storage/optionalDataCard';
import {itemDetails} from '@/storage/form/store';

type ParametersCardProps = {
  action: FormActionType;
  storageClass: StorageClass;
  itemDetails?: TargetItemDetails;
  itemFetching: boolean;
}

type PageProps = {
  action: FormActionType;
  title: string;
  pool?: Pool;
  itemDetails?: TargetItemDetails;
  itemFetching?: boolean;
  fetchItemDetails?: Function;
  validate?: (values: any) => object;
}

const useStyles = makeStyles({
  root: {
    padding: 15,
    marginTop: 10,
  },
  grid: {
    '& > *': {
      marginBottom: 10,
    },
  },
});

const ParametersCard = ({action, storageClass, itemDetails, itemFetching}: ParametersCardProps) => {
  const classes = useStyles();
  const stgClasses = itemDetails && itemDetails.classes ? itemDetails.classes : [];
  const form = useForm();
  const disableCloneRestoreForNonArchival = (e: any) => {
    if (e && e.target && !isStorageClassArchival(e.target.value)) {
      form.mutators.setFormValue('cloneRestore', false);
    }
  };

  return (
    <Card className={classes.root}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={6}>
          <TextField name='name' label='Storage Name' />
        </Grid>
        {action === FormActionType.EDIT && stgClasses.length > 0 &&
          <Grid item xs={6}>
            <FormSingleSelect
              name='storageClass' label='Select Storage Class'
              options={stgClasses} disabled={stgClasses.length === 1 || itemFetching}
              onChange={disableCloneRestoreForNonArchival}
            />
          </Grid>
        }
        <Grid item xs={6}>
          <Checkbox
            name='recoverable' label='Third-party Recovery' tooltip={recoverableTooltip}
          />
        </Grid>
        {action === FormActionType.EDIT && isArchivalSupported(stgClasses) &&
          <Grid item xs={6}>
            <Checkbox
              name='cloneRestore' label='Restore To New Clone' tooltip={cloneRestoreTooltip}
              disabled={!isStorageClassArchival(storageClass)}
            />
          </Grid>
        }
      </Grid>
    </Card>
  );
};

const VirtualMachineParametersPage = ({action, title, fetchItemDetails, itemDetails,
  itemFetching = false, pool, ...otherProps}: PageProps) => {
  const formState = useFormState();
  const storageClass = formState.values.storageClass;

  React.useEffect(() => {
    if (fetchItemDetails && pool && pool.id) {
      fetchItemDetails();
    }
  }, []);

  return (
    <WizardPage
      title={title} {...otherProps}
      description={<DialogDescription>Configure your storage below.</DialogDescription>}
    >
      <ParametersCard
        action={action}
        storageClass={storageClass}
        itemDetails={itemDetails}
        itemFetching={itemFetching}
      />
      <ThresholdsCard />
      <OptionalDataCard />
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const itemState = state.resources[itemDetails] || {};
  return {
    itemDetails: itemState.data,
    itemFetching: itemState.fetching,
  };
};

export default connect(mapStateToProps)(VirtualMachineParametersPage);
