import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {SingleSelect, Tooltip} from 'spectra-logic-ui/components';
import {SelectOption} from 'spectra-logic-ui';

type Props = {
  name: string;
  label: string;
  options: SelectOption[] | string[];
  tooltip?: React.ReactNode;
  disabled?: boolean;
  noneOption?: boolean | string;
  className?: string;
  onChange?: (...args: any) => any;
  parse?: (value: any, name: string) => any;
}

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    width: '100%',
  },
  singleSelect: {
    display: 'flex',
    flex: '1 1 auto',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
});

const FormSingleSelect = ({name, label, options, disabled, tooltip, noneOption, className, onChange, parse}: Props) => {
  const styles = {width: '100%', minWidth: 100}; // Workaround for MUI Select not being targeted with className.
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SingleSelect
        name={name} label={label} options={options} disabled={disabled}
        className={`${classes.singleSelect} ${className}`} style={styles} noneOption={noneOption}
        onChange={onChange} parse={parse}
      />
      {tooltip &&
        <div className={classes.tooltip}>
          <Tooltip>{tooltip}</Tooltip>
        </div>
      }
    </div>
  );
};

export default FormSingleSelect;
