import React, {useEffect, useState} from 'react';
import {ComposableMap, Geographies, Geography, Marker, ZoomableGroup} from 'react-simple-maps';
import {Fab} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {applyOpacity, Color, SpectraLogoColor} from 'spectra-logic-ui/colors';
import AddIcon from 'spectra-logic-ui/icons/Add';
import RemoveIcon from 'spectra-logic-ui/icons/Remove';

import worldMap from '@/assets/world-50m.json';
import {Location} from '@/types';

type Props = {
  selectedLocationId?: string;
  locations: Location[];
  onSelect: Function;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
    marginTop: -49,
    cursor: 'pointer',
  },
  zoomButtons: {
    position: 'absolute',
    bottom: 20,
    right: 0,
    '& > *': {
      marginRight: 20,
    },
  },
  map: {
    width: '100%',
    marginBottom: -3,
    borderRadius: 6,
    maxHeight: '350px',
  },
});

const defaultColor = {
  fill: applyOpacity(SpectraLogoColor.BLUE, 10), stroke: '#D6D6DA', strokeWidth: 0.65, outline: 'none',
};
const hoverColor = {
  fill: applyOpacity(Color.BLUE_LIGHT, 25), stroke: '#D6D6DA', strokeWidth: 0.65, outline: 'none',
};

const colorStatus = (status: string | undefined) => {
  if (status === undefined) {
    status = 'unknown';
  }
  switch (status) {
  case 'ok':
    return Color.OK;
  case 'warning':
  case 'degraded':
    return Color.WARNING;
  case 'error':
  case 'unavailable':
    return Color.ERROR;
  case 'info':
  case 'notice':
    return Color.INFO;
  case 'unknown':
  default:
    return Color.UNKNOWN;
  }
};

const defaultCenter = [0, 0];
const defaultZoom = 1;
const cityClickZoom = 3;

const AnimatedMap = ({selectedLocationId, locations, onSelect}: Props) => {
  const [data, setData] = useState({center: defaultCenter, zoom: defaultZoom});
  const [cityMousedOver, setCityMousedOver] = useState('');

  useEffect(() => {
    const selectedId = selectedLocationId;
    const selectedCenter = locations.find((l) => l.id === selectedId);
    if (selectedCenter && typeof(selectedCenter.latitude) === 'number' &&
      typeof(selectedCenter.longitude) === 'number') {
      setData({center: [selectedCenter.longitude, selectedCenter.latitude], zoom: cityClickZoom});
    }
  }, [selectedLocationId]);

  const handleZoomIn = () => {
    setData({center: data.center, zoom: (data.zoom * 2)});
  };

  const handleZoomOut = () => {
    const newZoom = data.zoom / 2;
    if (newZoom >= defaultZoom) {
      setData({center: data.center, zoom: newZoom});
    }
  };

  const handleCityClick = (city: any) => {
    setData({center: city.coordinates, zoom: cityClickZoom});
    onSelect(city.id);
  };

  const handleMove = ({coordinates, zoom}: {coordinates: [number, number]; zoom: number}) => {
    setData({center: coordinates, zoom: zoom});
  };

  const classes = useStyles();
  const cities = locations
    .filter((s) => typeof(s.longitude) === 'number' && typeof(s.latitude) === 'number')
    .map((location) => ({
      id: location.id,
      name: location.name,
      status: location.status,
      color: colorStatus(location.status),
      coordinates: [location.longitude, location.latitude],
    } as any));

  const x = data.center[0];
  const y = data.center[1];

  const cityNameFontSize = (16 / data.zoom).toString() + 'px';

  return (
    <div className={classes.root}>
      <div className={classes.zoomButtons}>
        <Fab size='small' onClick={handleZoomIn}>
          <AddIcon />
        </Fab>
        <Fab size='small' onClick={handleZoomOut}>
          <RemoveIcon />
        </Fab>
      </div>
      <ComposableMap className={classes.map} projectionConfig={{}}>
        <ZoomableGroup center={[x, y]} zoom={data.zoom} onMoveEnd={handleMove}>
          <Geographies geography={worldMap}>
            {({geographies}) =>
              geographies.map((geography: any) => geography.id !== '010' && (
                <Geography
                  key={geography.rsmKey} geography={geography}
                  style={{default: defaultColor, hover: hoverColor, pressed: hoverColor}}
                />
              ))}
          </Geographies>
          {cities.map((city) => (
            <Marker key={city.id} coordinates={city.coordinates} onClick={() => handleCityClick(city)}
              onMouseEnter={() => setCityMousedOver(city.id)} onMouseLeave={() => setCityMousedOver('')}
            >
              {cityMousedOver === city.id &&
                <text textAnchor='middle' y={-14 / data.zoom} style={{fill: '#5f6268', fontSize: cityNameFontSize}}>
                  {city.name}
                </text>}
              <circle r={7 / data.zoom} fill={city.color} stroke='#5f6268' strokeWidth={2 / data.zoom} />
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default AnimatedMap;
