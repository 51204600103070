import React from 'react';
import {useForm, useFormState} from 'react-final-form';
import isEmpty from 'is-empty';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

import {Card, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Color} from 'spectra-logic-ui/colors';
import {WizardPage} from 'spectra-logic-ui/components';

import Checkbox from '@/components/form/checkbox';

type Props = {
  title: string;
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
  },
  noteText: {
    fontStyle: 'italic',
    marginBottom: 10,
  },
  error: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Color.WHITE,
    background: Color.ERROR,
    padding: '5px 10px',
  },
}));

const blockPublicPolicyTip = 'Setting this will block new bucket policies that grant ' +
  'public access to buckets and objects. This setting doesn\'t change any existing policies that allow ' +
  'public access to S3 resources.';
const restrictPublicBucketsTip = 'Setting this will ignore public and cross-account access for buckets ' +
  'with policies that grant public access to buckets and objects.';

const PolicyPage = ({title, ...otherProps}: Props) => {
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [error, setError] = React.useState('');
  const classes = useStyles();

  const formState = useFormState();
  const defaultPolicyString = `{
  "Version": "2012-10-17",
  "Id": "ExamplePolicy01",
  "Statement": [
    {
      "Sid": "ExampleStatement01",
      "Effect": "Allow",
      "Principal": {
        "AWS": "arn:aws:iam::123456789012:group/example-group"
      },
      "Action": [
        "s3:*"
      ],
      "Resource": [
        "arn:aws:s3:::${formState.values.name}",
        "arn:aws:s3:::${formState.values.name}/*"
      ]
    }
  ]
}`;
  const [policyString, setPolicyString] = React.useState(defaultPolicyString);

  // parsePolicy parses the policy JSON string. It returns one of the
  // following:
  //  false: It's invalid JSON
  //  null: It's an empty policy (e.g. the user is removing a policy).
  //        It's technically not valid JSON, however, it is a valid
  //        policy.
  //  everything else: The parsed JSON policy (e.g. a JSON object).
  const parsePolicy = (value: string) => {
    let policy: any = null;
    if (value !== '') {
      try {
        policy = JSON.parse(value);
      } catch {
        policy = false;
      }
    }
    return policy;
  };

  const form = useForm();
  const onChange = (value: string) => {
    setPolicyString(value);
    const policy = parsePolicy(value);
    setDisableSubmit(policy === false);
    if (policy !== false) {
      setError('');
      if (policy !== undefined && value !== defaultPolicyString) {
        form.mutators.setFormValue('policy', policy);
      }
    } else {
      setError('Badly formatted JSON');
    }
  };

  React.useEffect(() => {
    const policy = formState.values.policy;
    if (!isEmpty(policy)) {
      setPolicyString(JSON.stringify(policy, null, 2));
    } else {
      onChange(defaultPolicyString);
    }
  }, []);

  return (
    <WizardPage title={title} disableSubmit={disableSubmit} {...otherProps}>
      <Checkbox name='blockPublicPolicy' label='Block Public Policies' tooltip={blockPublicPolicyTip} />
      <Checkbox name='restrictPublicBuckets' label='Restrict Public Buckets' tooltip={restrictPublicBucketsTip} />
      <Card>
        {error && <Typography className={classes.error}>{error}</Typography>}
        <AceEditor
          value={policyString} mode='json' theme='github' placeholder='Create Policy' fontSize={12}
          wrapEnabled={true} showPrintMargin={false} height='305px' width='100%' onChange={onChange}
          setOptions={{tabSize: 2, useWorker: false}} editorProps={{$blockScrolling: true}}
        />
      </Card>
      <Typography variant='caption' className={classes.noteText}>
        For more info on configuring a policy, see the&nbsp;
        <a
          className={classes.link} target='_blank'
          href='https://docs.aws.amazon.com/AmazonS3/latest/dev/using-with-s3-actions.html'
        >
          AWS documentation
        </a>
      </Typography>
    </WizardPage>
  );
};

export default PolicyPage;
