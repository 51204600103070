import React from 'react';
import {connect} from 'react-redux';

import {patchCollection} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {System} from '@/system/types';
import DialogDescription from '@/components/form/dialog_description';
import TextField from '@/components/form/text_field';

type Props = {
  system: System;
  title?: string;
  onClose?: () => any;
  onSuccess?: () => void;
  onSubmit: (values: any) => Promise<any>;
}


const ActivationKeyDialog = ({system, title = 'Activation Key', onClose, onSubmit, ...others}: Props) => {
  const initialValues = {key: system.key} as System;
  return (
    <FormDialog
      title={title} submitLabel={!!system.key ? 'OK' : 'Save'} onClose={onClose} onSubmit={onSubmit}
      closeOnSuccess initialValues={initialValues}
      {...others}
    >
      {() => (
        <>
          <DialogDescription>{!system.key ? 'Set the sphere activation key' : ''}</DialogDescription>
          <br/>
          <div>
            <TextField name='key' label='Sphere Activation Key' disabled={!!system.key}/>
          </div>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const onSubmit = (values: System) => {
    const body = {'key': values.key};
    return dispatch(patchCollection('system', body));
  };
  return {onSubmit};
};

export default connect(undefined, mapDispatchToProps)(ActivationKeyDialog);
