import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {createPatch, patchResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {EditIAMAccount} from '@/help';
import {Account} from '@/types';
import TextField from '@/components/form/text_field';
import {FormValues} from '@/accounts/form/types';

type Props = {
  account: Account;
  onSubmit: (values: FormValues) => Promise<any>;
}

export const AccountPatchFields = [
  'email',
  'description',
  'roleArn',
  'externalId',
];

const EditAccountDialog = ({account, onSubmit, ...otherProps}: Props) => {
  // If the account has a roleArn, it was created by the user and can be edited.
  // An account without a roleArn means it's the default account and should never have a role.
  const cannotEditRole = !account.roleArn;

  return (
    <FormDialog
      title='Edit IAM Account' initialValues={account as FormValues} onSubmit={onSubmit}
      getHelpLocation={() => EditIAMAccount}
      {...otherProps}
    >
      {() => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField name='roleArn' label='Role ARN' disabled={cannotEditRole} />
          </Grid>
          <Grid item xs={12}>
            <TextField name='externalId'
              label='External ID' disabled={cannotEditRole} helperText='Optional. Specifies who can assume the role.'/>
          </Grid>
          <Grid item xs={12}>
            <TextField name='email' label='Email' />
          </Grid>
          <Grid item xs={12}>
            <TextField name='description' label='Description' />
          </Grid>
        </Grid>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {account}: Props) => ({
  onSubmit: (values: FormValues) => {
    const upd = createPatch(AccountPatchFields, account, values);
    return dispatch(patchResource('accounts', values.id, upd));
  },
});

export default connect(undefined, mapDispatchToProps)(EditAccountDialog);
