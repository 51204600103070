import React from 'react';

import {Button, Theme, Tooltip, useMediaQuery, useTheme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from 'spectra-logic-ui/icons/Add';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';
import EditIcon from 'spectra-logic-ui/icons/Edit';
import CheckIcon from 'spectra-logic-ui/icons/Check';

import {Color} from 'spectra-logic-ui/colors';
import {DialogLauncher, Toolbar} from 'spectra-logic-ui/components';

type ButtonColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

type DialogButtonProps = {
  dialog: any; // Short term fix until handling the Redux connect wrapper type is better understood
  icon: any;
  title: string;
  color?: ButtonColor;
  className?: string;
  onClick?: () => any;
  onClose?: () => any;
  onSuccess?: () => any;
  disabled?: boolean;
  children?: never;
  tooltip?: string;
};

type CustomDialogButtonProps = {
  dialog: any; // Short term fix until handling the Redux connect wrapper type is better understood
  title?: string;
  color?: ButtonColor;
  className?: string;
  onClick?: () => any;
  onClose?: () => any;
  onSuccess?: () => any;
  disabled?: boolean;
  children?: never;
  tooltip?: string;
};

type ButtonProps = {
  title: string;
  icon: any;
  onClick?: () => any;
  color?: ButtonColor;
  className?: string;
  disabled?: boolean;
  children?: never;
  tooltip?: string;
}

type Props = {
  children: React.ReactElement<DialogButtonProps | CustomDialogButtonProps> |
    React.ReactElement<DialogButtonProps | CustomDialogButtonProps>[];
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    borderRadius: 6,
  },
  button: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(1),
  },
  icon: {
    verticalAlign: 'top',
  },
  deleteButton: {
    backgroundColor: Color.RED,
    color: Color.WHITE,
    '&:hover': {
      backgroundColor: Color.RED_DARK,
      '& *': {
        color: Color.WHITE,
      },
    },
  },
}));

const ButtonToolbar = ({children, className}: Props) => {
  const classes = useStyles();
  let toolbarClasses = classes.toolbar;
  if (className) {
    toolbarClasses += ` ${className}`;
  }
  return <Toolbar className={toolbarClasses}>{children}</Toolbar>;
};

ButtonToolbar.Button = (props: ButtonProps) => {
  const {icon: Icon, title, onClick, color = 'secondary', className, tooltip, disabled = false} = props;
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const buttonProps = {
    variant: 'contained' as const,
    color,
    disabled,
    className: `${classes.button} ${className}`,
    onClick,
  };

  // Buttons are wrapped in a span so the tooltip is always available, even when the button is disabled.
  if (mdUp) {
    if (tooltip) {
      return (
        <Tooltip title={tooltip}>
          <span>
            <Button {...buttonProps}><div><Icon className={classes.icon} />&nbsp;{title}</div></Button>
          </span>
        </Tooltip>
      );
    } else {
      return <Button {...buttonProps}><div><Icon className={classes.icon} />&nbsp;{title}</div></Button>;
    }
  }

  return (
    <Tooltip title={tooltip ? `${title}: ${tooltip}` : title}>
      <span><Button {...buttonProps}><Icon /></Button></span>
    </Tooltip>
  );
};

ButtonToolbar.DialogButton = (props: DialogButtonProps) => {
  const {
    title, icon, dialog, className, color = 'secondary', disabled = false, onClick, onClose, onSuccess, tooltip,
  } = props;
  return (
    <DialogLauncher
      dialog={dialog} onClick={onClick} customClose={onClose} onSuccess={onSuccess}
      launcher={(props: any) => (
        <ButtonToolbar.Button
          title={title} icon={icon} variant='contained' color={color} disabled={disabled} className={className}
          tooltip={tooltip} {...props}
        />
      )}
    />
  );
};

ButtonToolbar.CreateDialogButton = ({title = 'Create', disabled = false, ...otherProps}: CustomDialogButtonProps) => (
  <ButtonToolbar.DialogButton
    title={title} color='primary' icon={AddIcon} disabled={disabled} {...otherProps}
  />
);

ButtonToolbar.EditDialogButton = ({title = 'Edit', disabled = false, ...otherProps}: CustomDialogButtonProps) => (
  <ButtonToolbar.DialogButton
    title={title} color='secondary' icon={EditIcon} disabled={disabled} {...otherProps}
  />
);

ButtonToolbar.DeleteDialogButton = (props: CustomDialogButtonProps) => {
  const {title = 'Delete', disabled = false, className, ...otherProps} = props;
  const classes = useStyles();
  return (
    <ButtonToolbar.DialogButton
      title={title} icon={DeleteIcon} disabled={disabled}
      className={`${className} ${classes.deleteButton}`}
      {...otherProps}
    />
  );
};

ButtonToolbar.VerifyDialogButton = ({title = 'Verify', disabled = false, ...otherProps}: CustomDialogButtonProps) => (
  <ButtonToolbar.DialogButton
    title={title} icon={CheckIcon} disabled={disabled} {...otherProps}
  />
);

export default ButtonToolbar;
