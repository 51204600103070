import {validateNumber} from '@/validate';
import {ProxyFormData} from '@/network/form/types';

const maxPortValue = 65535;

export const validateProxy = ({port}: ProxyFormData) => {
  const errors = {} as any;
  errors.port = validateNumber(port, {maxValue: maxPortValue});
  return errors;
};
