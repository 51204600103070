import React from 'react';
import {connect} from 'react-redux';

import {Dispatch} from 'spectra-logic-ui';
import {FormDialog} from 'spectra-logic-ui/components';
import {updateResource} from 'spectra-logic-ui/actions';

import {Pool} from '@/types';
import SingleSelect from '@/components/form/single_select';
import {Object} from '@/buckets/objects/types';
import validate from '@/buckets/objects/details/validate';

type Props = {
  bucketName: string;
  object: Object;
  pools: Pool[];
  onSubmit: (values: any) => Promise<any>;
}

export type FormData = {
  storage: string;
}

const CreateCloneDialog = ({pools, ...others}: Props) => {
  const {...formProps}: any = others;
  delete formProps.bucketName;
  delete formProps.object;

  const options = pools.map((pool) => (
    {key: pool.id, text: pool.name}
  ));

  return (
    <FormDialog title='Create Object Clone' validate={validate} {...formProps}>
      {() => (
        <SingleSelect name='storage' label='Select Storage' options={options} />
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {bucketName, object}: Props) => {
  const resource = `buckets/${bucketName}/clones`;
  const query = {
    object: object.key,
    version: object.versionId !== undefined ? object.versionId : '',
  };
  return {
    onSubmit: (values: FormData) => dispatch(updateResource(resource, values.storage, {}, {query: query})),
  };
};

export default connect(undefined, mapDispatchToProps)(CreateCloneDialog);
