import React, {useRef, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {FormApi} from 'final-form';

import {createResource, LOGIN_SUCCESS} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch, RequestOptions} from 'spectra-logic-ui';

import {Register} from '@/help';
import {Location, Token} from '@/types';
import {validate as validateResetPassword} from '@/login/reset_password/validate';
import CredentialsPage from '@/system/form/register/pages/credentials';
import SelectLocationPage, {newLocationID} from '@/system/form/register/pages/select_location';
import {validateCredentials, validateLocation} from '@/system/form/register/pages/validate';
import {FormValues} from '@/system/form/register/types';
import ResetPasswordPage from '@/system/form/register/pages/reset_password';
import ConfirmPage from '@/system/form/register/pages/confirm';
import {System} from '@/system/types';

type Props = {
  system: System;
  form?: string;
  getOnSubmit: (system: System, token: Token) => any;
};
const title = 'Register with Sphere';

const RegisterWizardContent = ({getOnSubmit, system, ...others}: Props) => {
  const [token, setToken] = useState({} as Token);
  const [passwordResetRequired, setPasswordResetRequired] = useState(false);
  const [values, setValues] = useState({} as FormValues);
  const formRef: React.MutableRefObject<FormApi> = useRef({} as any);

  const dispatch = useDispatch();

  const createToken = (values: FormValues) => {
    const {...body} = values;
    const tokenPath = `sphere/tokens`;
    return dispatch(createResource(tokenPath, body, {onSuccess: (res) => {
      setPasswordResetRequired(res && res.challengeName === 'NEW_PASSWORD_REQUIRED');
      setToken(res);
    }}));
  };

  const changePassword = ({newPassword}: FormValues) => {
    if (!token.challengeName) {
      // The user came back to this page after already resetting the password,
      // in which case we can't reset it again.
      return;
    }
    const body = {
      challengeName: token.challengeName,
      challengeResponses: {
        'USERNAME': token.challengeParameters.USER_ID_FOR_SRP,
        'NEW_PASSWORD': newPassword,
      },
      session: token.session,
    };
    const tokenPath = `sphere/tokens`;
    return dispatch(createResource(tokenPath, body, {onSuccess: (res) => setToken(res)}));
  };

  const createLocation = () => {
    const locationID = values.location;
    if (locationID !== newLocationID) {
      return;
    }
    const body = {
      name: values.locationName,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
    };
    const locationsPath = `sphere/locations`;
    const opts = {
      headers: {authorization: `Bearer ${token.token}`},
      onSuccess: (location: Location) => {
        // Update the location drop-down selector to select the newly created location.
        formRef.current.mutators.setFormValue('location', location.id);
      },
    } as RequestOptions;
    return dispatch(createResource(locationsPath, body, opts));
  };

  const onSubmit = getOnSubmit(system, token);
  return (
    <WizardFormDialog title={title} onSubmit={onSubmit} submitLabel='Register' onValuesChange={setValues}
      getHelpLocation={() => Register}
      formRef={formRef} {...others}>
      <CredentialsPage title='Enter Sphere Credentials' validate={validateCredentials} onNext={createToken} />
      {passwordResetRequired &&
        <ResetPasswordPage title='Reset Password' alreadyReset={!token.challengeName}
          validate={validateResetPassword} onNext={changePassword} />
      }
      <SelectLocationPage title='Select Location' validate={validateLocation} onNext={createLocation} />
      <ConfirmPage title='Confirm' />
    </WizardFormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const getOnSubmit = (system: System, token: Token) => (values: FormValues) => {
    const {location} = values;
    const headers = {authorization: `Bearer ${token.token}`};
    const onSuccess = (response: any) => {
      // Registration successfully completed.  The GUI needs
      // to be reloaded since the "are we registered or not" state
      // is bootstrapped.  The LOGIN_SUCCESS action is so the
      // user doesn't have to log in again.
      dispatch({type: LOGIN_SUCCESS, data: response.token});
      window.location.reload();
    };
    const options = {headers: headers, onSuccess: onSuccess};
    const locationPath = `sphere/endpoints`;
    return dispatch(createResource(locationPath, {location, type: system.type}, options as RequestOptions));
  };
  return {getOnSubmit};
};
export default connect(null, mapDispatchToProps)(RegisterWizardContent);
