import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Table from 'spectra-logic-ui/components/table';
import {commafy, numberToHumanSize} from 'spectra-logic-ui/helpers/number';
import {divide, toNumber} from 'lodash';

import {Metrics} from '@/types';

type Props = {
  id: string;
  metrics?: Metrics;
  error?: boolean;
  fetching?: boolean;
  fetchMetrics?: () => Promise<any>;
}

const MetricsDetails = ({id, fetchMetrics, metrics={}, error=false, fetching=false}: Props) => {
  useEffect(() => {
    if (fetchMetrics !== undefined) {
      fetchMetrics();
    }
  }, [id]);

  // Bucket metrics don't use Metrics.stored.
  return (
    <Table>
      <Table.Body isLoading={fetching} hasError={error}>
        <Table.Row>
          <Table.Cell>Number of objects</Table.Cell>
          <Table.Cell>{commafy(metrics.count)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Total size of all objects</Table.Cell>
          <Table.Cell>{numberToHumanSize(metrics.size)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Average object size</Table.Cell>
          <Table.Cell>{numberToHumanSize(divide(toNumber(metrics.size), toNumber(metrics.count)))}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const mapStateToProps = (state: Store, {id}: Props) => {
  const metricsResource = state.resources[`buckets/${id}/metrics`] || {};
  return {
    metrics: metricsResource.data,
    error: metricsResource.error,
    fetching: metricsResource.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {id}: Props) => ({
  fetchMetrics: () => dispatch(fetchResource(`buckets/${id}/metrics`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MetricsDetails);
