import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {deleteResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {Object} from '@/buckets/objects/types';

type Props = {
  id: string;
  bucketName: string;
  object: Object;
  disabled?: boolean;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  description: {
    color: Color.ERROR,
  },
});

const DeleteCloneDialog = ({disabled = false, onClose, onSubmit, ...others}: Props) => {
  const classes = useStyles();

  if (disabled) {
    return null;
  }
  return (
    <FormDialog
      title={'Delete Object Clone'} submitLabel='Delete' disableSubmit={disabled} onClose={onClose}
      onSubmit={onSubmit} closeOnSuccess {...others}
    >
      {() => (
        <Typography className={classes.description}>
          Are you sure you want to delete this clone of the object's data?
        </Typography>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {id, bucketName, object}: Props) => {
  const resource = `buckets/${bucketName}/clones`;
  const query = {
    object: object.key,
    version: object.versionId !== undefined ? object.versionId : '',
  };
  return {
    onSubmit: (values: any) => dispatch(deleteResource(resource, id, values, {query: query})),
  };
};

export default connect(undefined, mapDispatchToProps)(DeleteCloneDialog);
