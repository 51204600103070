import React from 'react';
import {connect} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';

import {CloudProperties, TargetItemDetails} from '@/types';

type Props = {
  id: String;
  itemDetails?: TargetItemDetails;
  fetchItemDetails?: Function;
}

const RegionEndpointField = ({fetchItemDetails, itemDetails}: Props) => {
  React.useEffect(() => {
    if (fetchItemDetails !== undefined) {
      fetchItemDetails();
    }
  }, []);

  if (itemDetails && itemDetails.properties) {
    const prop = itemDetails.properties as CloudProperties;
    if (prop.region) {
      return <span>{prop.region}</span>;
    }
    if (prop.endpoint) {
      return <span>{prop.endpoint}</span>;
    }
  }
  return <span>--</span>;
};

const mapStateToProps = (state: Store, ownProps: Props) => {
  const itemDetails = state.resources['storage/' + ownProps.id + '/item'] || {};
  return {itemDetails: itemDetails.data};
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
  fetchItemDetails: () => dispatch(fetchResource('storage/' + ownProps.id + '/item')),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionEndpointField);

