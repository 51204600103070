import {ValidationError} from '@/validate';
import {FormValues} from '@/login/forgot_password/form/confirm/types';

export const validate = ({confirmationCode, password, confirmPassword}: FormValues) => {
  const errors = {} as any;

  if (!confirmationCode) {
    errors.confirmationCode = ValidationError.CANNOT_BE_BLANK;
  } else if (confirmationCode !== confirmationCode.trim()) {
    errors.confirmationCode = ValidationError.CANNOT_CONTAIN_LEADING_OR_TRAILING_SPACES;
  }

  if (!password) {
    errors.password = ValidationError.CANNOT_BE_BLANK;
  } else if (password != confirmPassword) {
    const err = 'Passwords do not match';
    errors.password = err;
    errors.confirmPassword = err;
  }

  return errors;
};
