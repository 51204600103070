import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import AdminUserIcon from 'spectra-logic-ui/icons/Security';

import {Administrator} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import Paginator from '@/components/paginator';
import PaginatorFooter from '@/components/paginator_footer';
import CreateAdministratorDialog from '@/administrators/form/create';
import EditAdministratorWizard from '@/administrators/form/edit';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  users?: Administrator[];
  error?: boolean;
  fetching?: boolean;
  fetchUsers?: (marker: string) => Promise<any>;
  marker: string;
  maxKeys: number;
  nextMarker: string;
  isTruncated: boolean;
}

const useStyles = makeStyles({
  filler: {
    flexGrow: 1,
  },
});

const Administrators = ({users = [], error = false, fetching = false, fetchUsers,
  marker, maxKeys, nextMarker, isTruncated}: Props) => {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [paginatorFrom, setPaginatorFrom] = useState(1);
  const [prevMarkers, setPrevMarkers] = useState([] as string[]);
  const headerTooltip = 'Sphere Administrators have full access to create and change system settings. ' +
    'Note that the system relies on the AWS Cognito service to manage Sphere Administrators and, as a result, ' +
    'it is also possible to make Sphere Administrator changes via the AWS console.';

  useEffect(() => {
    if (fetchUsers !== undefined) {
      fetchUsers('');
    }
  }, []);

  useEffect(() => {
    if (selectedUserId && !users.find((user) => user.id === selectedUserId)) {
      setSelectedUserId('');
    }
  }, [users]);

  const onDelete = () => {
    setSelectedUserId('');
    if (fetchUsers !== undefined) {
      fetchUsers('');
    }
  };

  const nextPage = () => {
    if (!fetching) {
      if (users) {
        setPaginatorFrom(paginatorFrom+users.length);
      }
      if (marker) {
        prevMarkers.push(marker);
        setPrevMarkers(prevMarkers);
      }
      if (fetchUsers !== undefined) {
        fetchUsers(nextMarker);
      }
    }
  };

  const prevPage = () => {
    if (!fetching) {
      if (users) {
        setPaginatorFrom(paginatorFrom-maxKeys);
      }
      const prevMarker = prevMarkers.pop() || '';
      if (fetchUsers !== undefined) {
        fetchUsers(prevMarker);
      }
    }
  };

  const classes = useStyles();

  const selectedUser = users.find((u) => u.id === selectedUserId) || {} as Administrator;

  return (
    <Card>
      <CardHeader icon={AdminUserIcon} tooltip={headerTooltip}>
        Sphere Administrators
      </CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.CreateDialogButton dialog={CreateAdministratorDialog}/>
          <ButtonToolbar.EditDialogButton
            disabled={isEmpty(selectedUserId)}
            dialog={(props: any) => <EditAdministratorWizard user={selectedUser} {...props} />}
          />
          <ButtonToolbar.DeleteDialogButton
            disabled={isEmpty(selectedUserId)} onSuccess={onDelete}
            dialog={(props: any) => (
              <DeleteDialog
                id={selectedUserId} name={selectedUser.username}
                title='Delete Sphere Administrator' resource='users' {...props}
              />
            )}
          />
          <span className={classes.filler} />
          <Paginator
            prev={prevPage}
            from={paginatorFrom}
            to={users ? paginatorFrom + users.length - 1 : 0}
            next={nextPage}
            showNext={isTruncated}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Username</Table.Cell>
              <Table.Cell>Email Address</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            {users.map((user) => (
              <Table.Row
                key={user.id} selected={user.id === selectedUserId}
                onClick={() => setSelectedUserId(user.id)}
              >
                <Table.Cell>{user.username}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <PaginatorFooter
          prev={prevPage}
          from={paginatorFrom}
          to={users ? paginatorFrom+users.length-1 : 0}
          next={nextPage}
          showNext={isTruncated}
        />
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const usersState = state.resources.users || {};
  const users = usersState.data || [];
  return {
    users: users,
    error: usersState.error,
    fetching: usersState.fetching,
    marker: usersState.marker || '',
    maxKeys: usersState.maxKeys || 0,
    isTruncated: usersState.isTruncated || false,
    nextMarker: usersState.nextMarker || '',
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUsers: (marker: string) => {
    const query: {[name: string]: any} = {};
    if (marker) {
      query.marker = marker;
    }
    return dispatch(fetchResource('users', '', {query}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Administrators);
