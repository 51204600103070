import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {patchResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import TextField from '@/components/form/text_field';
import {AccessKey, IAMUser} from '@/iam/types';

type Props = {
  user: IAMUser;
  accessKey: AccessKey;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  description: {
    marginBottom: 15,
  },
  textField: {
    marginBottom: 15,
  },
});

const enableKeyForm = 'Enable Access Key';

const EnableKeyWarning = ({accessKeyId}: {accessKeyId: string}) => {
  const classes = useStyles();
  return (
    <Typography className={classes.description}>
      This will enable the <strong>{accessKeyId}</strong> access key so it can be used with Vail.
      Are you sure you want to proceed?
    </Typography>
  );
};

const EnableKeyDialog = ({accessKey, onSubmit, ...others}: Props) => {
  const classes = useStyles();
  return (
    <FormDialog title={enableKeyForm} submitLabel='Enable' onSubmit={onSubmit} {...others}>
      {() => (
        <>
          <EnableKeyWarning accessKeyId={accessKey.id} />
          <React.Fragment>
            {!accessKey.initialized &&
              <TextField className={classes.textField} name='secretAccessKey'
                label='Secret Access Key' type='password'/>
            }
          </React.Fragment>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {user, accessKey}: Props) => {
  const resource = `iam/users/${user.accountid}/${user.username}/keys`;
  return {
    onSubmit: (values: any) => {
      const body = {...values};
      if (!accessKey.initialized && !body.secretAccessKey) {
        // Let the server check for "cannot be blank" errors. Without this, leaving
        // the secret access key text field blank will result in it not getting sent
        // down at all, thus resulting in no error.
        //
        // This dialog is also used to enable a key that was previously enabled and subsequently
        // disabled. This action doesn't require the user type in the secret access key.
        // Thus, we only want to do this when the dialog is being used to enable the key for
        // the first time (i.e. when the user actually has to enter the secret).
        body.secretAccessKey = '';
      }
      if (accessKey.inactive) {
        body.inactive = false;
      }
      return dispatch(patchResource(resource, accessKey.id, body));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(EnableKeyDialog);
