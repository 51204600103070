import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import {Card, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource, resetResource} from 'spectra-logic-ui/actions';
import {Color, SpectraLogoColor} from 'spectra-logic-ui/colors';
import {Table, Tooltip} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';

import {storageClassString} from '@/enum';
import {Pool} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import {Object, CloneState} from '@/buckets/objects/types';
import CreateCloneDialog from '@/buckets/objects/details/create_clone_dialog';
import DeleteCloneDialog from '@/buckets/objects/details/delete_clone_dialog';
import VerifyCloneDialog from '@/buckets/objects/details/verify_index';

type Props = {
  bucketName: string;
  object: Object;
  clones?: CloneState;
  pools?: Pool[];
  fetching?: boolean;
  error?: boolean;
  fetchPools?: () => Promise<any>;
  fetchClones?: () => Promise<any>;
  fetchObjects?: Function;
  resetClones?: any;
}

const useStyles = makeStyles({
  tableCell: {
    color: Color.WHITE,
  },
  card: {
    margin: 20,
  },
  row: {
    background: SpectraLogoColor.BLUE,
    height: 47,
  },
  root: {
    height: '18px !important',
    color: `${Color.WHITE} !important`,
  },
});

const StorageDetails = (props: Props) => {
  const {fetching = false, error = false, fetchPools,
    bucketName, object, pools = [] as Pool[], clones = {} as CloneState, fetchClones, fetchObjects,
    resetClones} = props;
  const classes = useStyles();

  const [selectedCloneID, setSelectedCloneID] = React.useState('');

  React.useEffect(() => {
    if (fetchPools !== undefined) {
      fetchPools();
    }
    setSelectedCloneID('');
    if (fetchClones !== undefined) {
      fetchClones();
    }
    return () => {
      if (resetClones !== undefined) {
        resetClones();
      }
    };
  }, [object.key, object.versionId]);

  const poolNameMap = {} as any;
  const poolClassMap = {} as any;
  pools.forEach((pool) => {
    poolNameMap[pool.id] = pool.name;
    poolClassMap[pool.id] = storageClassString(pool.storageClass);
  });
  const mergedPools = clones.storage && JSON.parse(JSON.stringify(clones.storage));
  mergedPools?.forEach((pool:any, i: any) => {
    mergedPools[i]['name'] = poolNameMap[pool.id];
  });
  mergedPools?.sort((a:any, b:any) => (a.name > b.name) ? 1 : -1);

  const attributesToolTip =
    <div>
      <div>Archived: Data must be restored to access it.</div>
      <div>Restored: Archived data has been restored.</div>
    </div>;

  return (
    <div>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Lifecycle Status
              <Tooltip>
                {(!clones.scheduled && !clones.processing) && 'No lifecycle changes scheduled.' ||
                clones.restoring && 'Restore in progress.' ||
                clones.processing && 'Lifecycle changes in progress.' ||
                clones.scheduled && 'Lifecycle changes are scheduled.'}
              </Tooltip>
            </Table.Cell>
            <Table.Cell>
              {!clones.scheduled ? 'None' :
                clones.processing ? `Active since: ${dateTimeLong(clones.scheduled)}` :
                  `Scheduled at: ${dateTimeLong(clones.scheduled)}`}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Card className={classes.card}>
        <ButtonToolbar>
          <ButtonToolbar.CreateDialogButton dialog={(props: any) => (
            <CreateCloneDialog
              bucketName={bucketName}
              object={object}
              pools={pools || []}
              {...props}
              onSuccess={() => {
                if (fetchClones) {
                  fetchClones();
                }
              }}
            />
          )}/>
          <ButtonToolbar.VerifyDialogButton
            disabled={isEmpty(selectedCloneID)} dialog={(props: any) => (
              <VerifyCloneDialog
                id={selectedCloneID}
                bucketName={bucketName}
                object={object}
                {...props}
                onSuccess={() => {
                  if (fetchClones) {
                    fetchClones();
                  }
                  if (fetchObjects) {
                    // Need to refresh the properties tab and refetching the list of objects
                    // is the only way we have to do that at the moment.
                    fetchObjects();
                  }
                }}
              />
            )}
          />
          <ButtonToolbar.DeleteDialogButton
            disabled={isEmpty(selectedCloneID)} dialog={(props: any) => (
              <DeleteCloneDialog
                id={selectedCloneID}
                bucketName={bucketName}
                object={object}
                {...props}
                onSuccess={() => {
                  if (fetchClones) {
                    fetchClones();
                  }
                  if (fetchObjects) {
                    // Need to refresh the properties tab and refetching the list of objects
                    // is the only way we have to do that at the moment.
                    fetchObjects();
                  }
                }}
              />
            )}
          />
        </ButtonToolbar>
        <Table size='small'>
          <Table.Header >
            <Table.Row className={classes.row}>
              <Table.Cell className={classes.tableCell}>
                <Typography>Storage</Typography>
              </Table.Cell>
              <Table.Cell className={classes.tableCell}>
                <div style={{display: 'flex'}}>
                  <Typography>Attributes</Typography>
                  <Tooltip className={classes.root}>{attributesToolTip}</Tooltip>
                </div>
              </Table.Cell>
              <Table.Cell className={classes.tableCell}>
                <Typography>Storage Class</Typography>
              </Table.Cell>
              <Table.Cell className={classes.tableCell}>
                <Typography>Optional</Typography>
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body hasError={error} isLoading={fetching}>
            {(mergedPools || []).map((storageCopy: any) => {
              return (
                <Table.Row
                  key={storageCopy.id} selected={!isEmpty(selectedCloneID) && storageCopy.id === selectedCloneID}
                  onClick={() => setSelectedCloneID(storageCopy.id)}
                >
                  <Table.Cell>
                    {storageCopy.name ? (`${storageCopy.name} (${storageCopy.id})`) : storageCopy.id}
                  </Table.Cell>
                  <Table.Cell>
                    {storageCopy.archived ? 'archived' : ''}
                    {storageCopy.restored ? ' restored' : ''}
                  </Table.Cell>
                  <Table.Cell>{poolClassMap[storageCopy.id] || ''}</Table.Cell>
                  <Table.Cell>{storageCopy.optional ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: Store, {bucketName}: Props) => {
  const clonesUrl = `buckets/${bucketName}/clones`;
  const clonesResource = state.resources[clonesUrl] || {};
  const pools = state.resources.storage || {};
  return {
    clones: clonesResource.data,
    error: clonesResource.error,
    fetching: clonesResource.fetching,
    pools: pools.data || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {object, bucketName}: Props) => {
  const clonesUrl = `buckets/${bucketName}/clones`;
  const query = {
    object: object.key,
    version: object.versionId !== undefined ? object.versionId : '',
  };
  return {
    fetchClones: () => dispatch(fetchResource(clonesUrl, '', {query: query})),
    resetClones: () => dispatch(resetResource(clonesUrl)),
    fetchPools: () => dispatch(fetchResource('storage')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StorageDetails);
