import {FormValues} from '@/locations/form/create/types';
import {ValidationError, validateName} from '@/validate';

export default ({locationName, latitude, longitude}: FormValues) => {
  const errors = {locationName: validateName(locationName)} as any;

  if (latitude) {
    if (String(latitude) !== String(latitude).trim()) {
      errors.latitude = ValidationError.CANNOT_CONTAIN_LEADING_OR_TRAILING_SPACES;
    } else if (isNaN(Number(latitude))) {
      errors.latitude = ValidationError.MUST_BE_A_NUMBER;
    }
  }

  if (longitude) {
    if (String(longitude) !== String(longitude).trim()) {
      errors.longitude = ValidationError.CANNOT_CONTAIN_LEADING_OR_TRAILING_SPACES;
    } else if (isNaN(Number(longitude))) {
      errors.longitude = ValidationError.MUST_BE_A_NUMBER;
    }
  }

  return errors;
};
