export const accounts = '/accounts';
export const administrators = '/administrators';
export const buckets = '/buckets';
export const entitlements = '/entitlements';
export const forgotPassword = '/login/forgot_password';
export const hostname = '/hostname';
export const lifecycles = '/lifecycles';
export const locations = '/locations';
export const logs = '/logs';
export const messages = '/messages';
export const network = '/network';
export const performance = '/performance';
export const reports = '/reports';
export const settings = '/settings';
export const ssl = '/sslcertificate';
export const statistics = '/statistics';
export const storage = '/storage';
export const updates = '/updates';
export const usage = '/usage';
export const users = '/users';
