import React from 'react';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {parse, stringify} from 'query-string';
import isEmpty from 'is-empty';

import {Checkbox, FormControlLabel, TextField} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Card from 'spectra-logic-ui/components/card';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';
import {fetchResource} from 'spectra-logic-ui/actions';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import {Table, Toolbar} from 'spectra-logic-ui/components';
import {Dispatch, Store, RequestOptions} from 'spectra-logic-ui';

import {storageClassString} from '@/enum';
import {Bucket} from '@/types';
import Paginator from '@/components/paginator';
import PaginatorFooter from '@/components/paginator_footer';
import Breadcrumbs from '@/buckets/breadcrumbs';
import {DeleteMarkerIcon, FileIcon, FolderIcon, NotLatestIcon} from '@/buckets/icons';
import Details from '@/buckets/objects/details';
import {CommonPrefix, Object} from '@/buckets/objects/types';
import {parsePrefix} from '@/buckets/objects/prefix';

type Props = {
  bucket?: Bucket;
  bucketName: string;
  objects?: Object[];
  commonPrefixes?: CommonPrefix[];
  marker?: string;
  versionIDMarker?: string;
  maxKeys?: number;
  nextMarker?: string;
  nextVersionIDMarker?: string;
  isTruncated?: boolean;
  fetching?: boolean;
  error?: boolean;
  fetchBucket?: () => Promise<any>;
  getFetchObjects?: (prefix: string, showVersions: boolean) => Function;
}

type ObjectID = {
  key: string;
  versionId?: string;
}

const useStyles = makeStyles({
  filler: {
    flexGrow: 1,
  },
  search: {
    minWidth: '400px',
  },
});

const delimiter = '/';
const maxObjectsPerPage = '300';

const Objects = (props: Props) => {
  const {
    bucket, bucketName, objects = [], commonPrefixes = [],
    marker = '', maxKeys = 0, nextMarker = '', isTruncated = false, versionIDMarker = '', nextVersionIDMarker = '',
    fetching = false, error = false, fetchBucket, getFetchObjects,
  } = props;
  const parsedQuery = parse(location.search);
  const fullPrefix = (parsedQuery.prefix as string) || '';
  const showVersionsParam = parsedQuery.showVersions === 'true';

  const [selectedObjectID, setSelectedObjectID] = React.useState({} as ObjectID);
  const [selectedPrefix, setSelectedPrefix] = React.useState('');
  const [paginatorFrom, setPaginatorFrom] = React.useState(1);
  const [prevMarkers, setPrevMarkers] = React.useState([] as string[]);
  const [prevVersionIDMarkers, setPrevVersionIDMarkers] = React.useState([] as string[]);
  const objectsList = objects;
  const classes = useStyles();
  const navigate = useNavigate();

  const [searchTypingTimeout, setSearchTypingTimeout] = React.useState<number>();

  const [prefixWithoutSearch, searchFromPrefix] = parsePrefix(fullPrefix);
  const [search, setSearch] = React.useState('');
  const fetchObjects = getFetchObjects ? getFetchObjects(fullPrefix, showVersionsParam) : undefined;

  React.useEffect(() => {
    if (fetchBucket !== undefined && !bucket) {
      // Users will most likely come here from the bucket listing page,
      // in which case we'll already have the bucket and don't need to
      // fetch it again.
      fetchBucket();
    }
  });

  React.useEffect(() => {
    setPaginatorFrom(1);
    setPrevMarkers([]);
    setSearch(searchFromPrefix);
    if (fetchObjects !== undefined) {
      fetchObjects('', '');
    }
  }, [fullPrefix, showVersionsParam]);

  // Prevents persisted object selection when changing prefixes or showing versions.
  React.useEffect(() => {
    clearObject();
  }, [location.search]);

  // Ensures only one selection in the table is done at a time.
  React.useEffect(() => {
    if (!isEmpty(selectedObjectID)) {
      setSelectedPrefix('');
    }
  }, [selectedObjectID]);
  React.useEffect(() => {
    if (selectedPrefix !== '') {
      clearObject();
    }
  }, [selectedPrefix]);

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
    let prefixAndSearch: string;
    if (newSearch.startsWith('/')) {
      prefixAndSearch = newSearch.replace(/^\/+/, '');
    } else {
      prefixAndSearch = prefixWithoutSearch + newSearch;
    }
    clearTimeout(searchTypingTimeout);
    setSearchTypingTimeout(window.setTimeout(() => {
      navigate(createObjectsLink(prefixAndSearch, showVersionsParam));
    }, 500));
  };

  const createQueryParams = (prefix: string, showVersions: boolean) => {
    let params = {};
    if (prefix) params = {...params, prefix: prefix};
    if (showVersions) params = {...params, showVersions: showVersions};
    return `?${stringify(params)}`;
  };

  const createObjectsLink = (prefix = fullPrefix, showVersions = showVersionsParam) => {
    const queryParams = createQueryParams(prefix, showVersions);
    return `/buckets/${bucketName}/objects${queryParams}`;
  };

  const createName = (key: string, isDelete: boolean) => {
    if (!key) return key;
    const parts = key.split('/');
    const last = parts[parts.length - 1];
    let name = last === '' ? parts[parts.length - 2] : last;
    if (isDelete) {
      name += ' (Delete marker)';
    }
    return name;
  };

  const toggleShowVersions = () => {
    navigate(createObjectsLink(fullPrefix, !showVersionsParam));
  };

  const selectObject = (object: Object) => {
    setSelectedObjectID({key: object.key, versionId: object.versionId});
  };
  const clearObject = () => {
    setSelectedObjectID({} as Object);
  };

  const selectPrefix = (prefix: string) => {
    setSelectedPrefix(prefix);
  };

  const nextPage = () => {
    if (!fetching) {
      setPaginatorFrom(paginatorFrom+commonPrefixes.length+objectsList.length);
      if (marker) {
        prevMarkers.push(marker);
        setPrevMarkers(prevMarkers);
      }
      if (versionIDMarker) {
        prevVersionIDMarkers.push(versionIDMarker);
        setPrevVersionIDMarkers(prevVersionIDMarkers);
      }
      if (fetchObjects) {
        fetchObjects(nextMarker, nextVersionIDMarker);
      }
    }
  };

  const prevPage = () => {
    setPaginatorFrom(paginatorFrom-maxKeys);
    const prevMarker = prevMarkers.pop() || '';
    const prevVersionIDMarker = prevVersionIDMarkers.pop() || '';
    if (fetchObjects) {
      fetchObjects(prevMarker, prevVersionIDMarker);
    }
  };

  let selectedObject = null;
  if (selectedObjectID) {
    selectedObject = objects.find((o: Object) => {
      return o.key === selectedObjectID.key && o.versionId === selectedObjectID.versionId;
    });
  }

  // The only way the show-versions checkbox will be enabled and with a bucket that has versioning disabled
  // is if they manually specified it in the URL. In this case, we still should show it even though
  // it's meaningless.
  const showVersionsCheckbox = showVersionsParam ||
    (bucket && bucket.versioning && bucket.versioning !== '');

  return (
    <Card>
      <Breadcrumbs bucketName={bucketName} prefix={prefixWithoutSearch} showVersions={showVersionsParam} />
      <Card.Body>
        <Toolbar>
          <TextField
            className={classes.search}
            label='Find objects by prefix'
            variant='standard'
            value={search}
            onChange={(event) => {
              handleSearchChange(event.target.value);
            }}
          />
          {showVersionsCheckbox ? <FormControlLabel
            label='Show all versions' control={
              <Checkbox checked={showVersionsParam} style={{whiteSpace: 'nowrap'}} onChange={toggleShowVersions} />
            }
          /> : <></>}
          <span className={classes.filler} />
          <Paginator
            prev={prevPage}
            from={paginatorFrom}
            to={paginatorFrom+commonPrefixes.length+objectsList.length-1}
            next={nextPage}
            showNext={isTruncated}
          />
        </Toolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>Last Modified</Table.Cell>
              <Table.Cell>Size</Table.Cell>
              <Table.Cell>Storage Class</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            <React.Fragment>
              {commonPrefixes && commonPrefixes.map((cp) => (
                <Table.Row
                  key={cp.prefix} selected={cp.prefix === selectedPrefix}
                  onClick={(event) => event.target.tagName.toLowerCase() !== 'a' && selectPrefix(cp.prefix)}
                >
                  <Table.CellLink link={createObjectsLink(cp.prefix)}>
                    <React.Fragment><FolderIcon />{createName(cp.prefix, false)}</React.Fragment>
                  </Table.CellLink>
                  <Table.Cell>--</Table.Cell>
                  <Table.Cell>--</Table.Cell>
                  <Table.Cell>--</Table.Cell>
                </Table.Row>
              ))}
            </React.Fragment>
            <React.Fragment>
              {objectsList && objectsList.map((object) => (
                <Table.Row
                  key={`${object.key}-${object.versionId}`} onClick={() => selectObject(object)}
                  selected={object.key === selectedObjectID.key && object.versionId === selectedObjectID.versionId}
                >
                  <Table.Cell>
                    <React.Fragment>
                      {showVersionsParam && !object.isLatest && <NotLatestIcon />}
                      {object.isDelete ? <DeleteMarkerIcon /> : <FileIcon />}
                      {createName(object.key, !!object.isDelete)}
                    </React.Fragment>
                  </Table.Cell>
                  <Table.Cell>{dateTimeLong(object.lastModified)}</Table.Cell>
                  <Table.Cell>
                    {object.isDelete ? '--' :
                      <span title={object.size === undefined ? '0' : object.size.toString() + ' Bytes'}>
                        {numberToHumanSize(object.size)}
                      </span>
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {object.storageClass === undefined ? '--' : storageClassString(object.storageClass)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </React.Fragment>
          </Table.Body>
        </Table>
        <PaginatorFooter
          prev={prevPage}
          from={paginatorFrom}
          to={paginatorFrom+commonPrefixes.length+objectsList.length-1}
          next={nextPage}
          showNext={isTruncated}
        />
        {selectedObject &&
          <Details object={selectedObject} bucketName={bucketName} clearObject={clearObject}
            fetchObjects={fetchObjects} />
        }
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store, {bucketName}: Props) => {
  const buckets = state.resources.buckets || {};
  const bucket = buckets.data && buckets.data.find((b: Bucket) => b.name === bucketName);
  const objects = state.resources[`buckets/${bucketName}/objects`] || {};
  const commonPrefixes = objects.data && objects.data.commonPrefixes &&
    objects.data.commonPrefixes.filter((commonPrefix: CommonPrefix) => (
      commonPrefix.prefix && commonPrefix.prefix.replace('/', '') !== bucketName
    ));
  return {
    bucket: bucket,
    bucketName: bucketName,
    objects: objects.data && objects.data.contents,
    marker: objects.data && objects.data.marker || '',
    maxKeys: objects.data && objects.data.maxKeys || 0,
    isTruncated: objects.data && objects.data.isTruncated || false,
    nextMarker: objects.data && objects.data.nextMarker || '',
    versionIDMarker: objects.data && objects.data.versionIDMarker || '',
    nextVersionIDMarker: objects.data && objects.data.nextVersionIDMarker || '',
    commonPrefixes: commonPrefixes,
    error: objects.error,
    fetching: objects.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {bucketName}: Props) => {
  const fetchBucket = () => {
    // Use this style call instead of 'fetchResource(`buckets/${bucketName}`)' so the response
    // is stored in Redux the same way that a "get all buckets" response is stored. This
    // will prevent a slight flicker when the bucket is first loaded since most users
    // will be coming from the bucket-listing page. It will also still work if the
    // user came straight here (e.g. refreshed their browser).
    return dispatch(fetchResource('buckets', bucketName));
  };
  const getFetchObjects = (prefix: string, showVersions: boolean) => {
    return (marker: string, versionIDMarker: string) => {
      if (showVersions) {
        const options = {query: {delimiter, prefix: prefix, marker, 'max-keys': maxObjectsPerPage,
          'versions': 'true', 'version-id-marker': versionIDMarker}} as RequestOptions;
        return dispatch(fetchResource(`buckets/${bucketName}/objects`, '', options));
      } else {
        const options = {query: {delimiter, prefix: prefix, marker, 'max-keys': maxObjectsPerPage}} as RequestOptions;
        return dispatch(fetchResource(`buckets/${bucketName}/objects`, '', options));
      }
    };
  };
  return {fetchBucket, getFetchObjects};
};

const ConnectedObjects = connect(mapStateToProps, mapDispatchToProps)(Objects);

const ObjectsPage = () => {
  const params = useParams();
  const bucketName = params.bucket || '';
  return (
    <ConnectedObjects bucketName={bucketName} />
  );
};

export default ObjectsPage;
