import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import DeviceHubIcon from 'spectra-logic-ui/icons/DeviceHub';

import {Proxy} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import CreateProxyDialog, {ProxyTypes} from '@/network/form/proxy_create';
import EditProxyDialog from '@/network/form/proxy_edit';
import DeleteProxyDialog from '@/network/form/proxy_delete';

type Props = {
  proxies?: Proxy[];
  fetching?: boolean;
  error?: boolean;
  fetchProxies?: () => Promise<any>;
}

const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
});

const headers = ['Type', 'Hostname', 'Port', 'Username'];

const Proxy = ({proxies=[], fetching=false, error=false, fetchProxies}: Props) => {
  const classes = useStyles();
  const [selectedProxy, setSelectedProxy] = React.useState({} as Proxy);

  const clearProxy = () => {
    setSelectedProxy({} as Proxy);
  };

  React.useEffect(() => {
    if (fetchProxies !== undefined) {
      fetchProxies();
    }
  }, []);

  return (
    <Card>
      <CardHeader icon={DeviceHubIcon}>Proxy Servers</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.CreateDialogButton
            disabled={proxies.length >= ProxyTypes.length}
            dialog={(props: any) => <CreateProxyDialog {...props} />}
          />
          <ButtonToolbar.EditDialogButton
            disabled={isEmpty(selectedProxy)}
            dialog={(props: any) => <EditProxyDialog proxy={selectedProxy} {...props} />}
          />
          <ButtonToolbar.DeleteDialogButton
            onSuccess={clearProxy} disabled={isEmpty(selectedProxy)}
            dialog={(props: any) => <DeleteProxyDialog id={selectedProxy.id} {...props} />}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              {headers.map((hdr) => <Table.Cell className={classes.boldText} key={hdr}>{hdr}</Table.Cell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body hasError={error} isLoading={fetching} emptyMessage='No proxy servers are configured.'>
            {proxies.map((p) => (
              <Table.Row key={p.id} selected={p.id === selectedProxy.id} onClick={() => setSelectedProxy(p)}>
                <Table.Cell>{p.id}</Table.Cell>
                <Table.Cell>{p.hostname}</Table.Cell>
                <Table.Cell>{p.port}</Table.Cell>
                <Table.Cell>{p.username}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const proxyState = state.resources['proxy'] || {};
  return {
    proxies: proxyState.data || [],
    fetching: proxyState.fetching,
    error: proxyState.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProxies: () => dispatch(fetchResource('proxy')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Proxy);
