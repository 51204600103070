import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import makeStyles from '@mui/styles/makeStyles';

import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import {fetchResource} from 'spectra-logic-ui/actions';
import LocationIcon from 'spectra-logic-ui/icons/Map';
import {Dispatch, Store} from 'spectra-logic-ui';

import {Location} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import CreateLocationDialog from '@/locations/form/create';
import EditLocationDialog from '@/locations/form/edit';

type Props = {
  locations?: Location[];
  fetching?: boolean;
  error?: boolean;
  fetchLocations?: Function;
}

const useStyles = makeStyles({
  latlong: {'whiteSpace': 'nowrap'},
});

const Locations = ({locations = [], fetching = false, error = false, fetchLocations}: Props) => {
  const [selectedLocationId, setSelectedLocationId] = React.useState('');

  const clearLocation = () => {
    setSelectedLocationId('');
  };

  useEffect(() => {
    if (fetchLocations !== undefined) {
      fetchLocations();
    }
  }, []);

  const selectedLocation = locations.find((l) => l.id === selectedLocationId) || {} as Location;

  const classes = useStyles();

  return (
    <Card>
      <CardHeader icon={LocationIcon}>Locations</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.CreateDialogButton dialog={CreateLocationDialog} />
          <ButtonToolbar.EditDialogButton
            disabled={isEmpty(selectedLocationId)}
            dialog={(props: any) => <EditLocationDialog location={selectedLocation} {...props} />}
          />
          <ButtonToolbar.DeleteDialogButton
            disabled={isEmpty(selectedLocationId)} onSuccess={clearLocation}
            dialog={(props: any) => (
              <DeleteDialog
                id={selectedLocationId} name={selectedLocation.name} resource='locations' {...props}
              />
            )}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Location</Table.Cell>
              <Table.Cell className={classes.latlong}>Latitude</Table.Cell>
              <Table.Cell className={classes.latlong}>Longitude</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            {locations && locations
              .filter((location) => location.id !== '0')
              .map((location) => (
                <Table.Row
                  key={location.id} selected={location.id === selectedLocationId}
                  onClick={() => setSelectedLocationId(location.id)}
                >
                  <Table.Cell>{location.name}</Table.Cell>
                  <Table.Cell className={classes.latlong}>{location.latitude}</Table.Cell>
                  <Table.Cell className={classes.latlong}>{location.longitude}</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const locations = state.resources.locations || {};
  return {
    locations: locations.data || [],
    locationsError: locations.error,
    fetchingLocations: locations.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLocations: () => dispatch(fetchResource('locations')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
