import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {deleteResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

type Props = {
  id: string;
  name: string;
  resource: string;
  title?: string;
  description?: string;
  disabled?: boolean;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  description: {
    color: Color.ERROR,
  },
});

const DeleteDialog = (props: Props) => {
  const {
    name, title = 'Delete', description = 'WARNING: Cannot be undone.', disabled = false, onClose, onSubmit, ...others
  } = props;
  const classes = useStyles();

  if (disabled) {
    return null;
  }
  return (
    <FormDialog
      title={title} submitLabel='Delete' disableSubmit={disabled} onClose={onClose} onSubmit={onSubmit} closeOnSuccess
      {...others}
    >
      {() => (
        <>
          <Typography>
            <strong>Delete '{name}'?</strong>
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
  onSubmit: (values: any) => dispatch(deleteResource(ownProps.resource, ownProps.id, values)),
});

export default connect(undefined, mapDispatchToProps)(DeleteDialog);
