import React from 'react';

import {Grid} from '@mui/material';

import {WizardPage, TextField} from 'spectra-logic-ui/components';

const CredentialsPage = (props: any) => (
  <WizardPage {...props}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField name='username' label='Administrator Username' fullWidth={true} />
      </Grid>
      <Grid item xs={6}>
        <TextField type='password' name='password' label='Administrator Password' fullWidth={true} />
      </Grid>
    </Grid>
  </WizardPage>
);

export default CredentialsPage;
