import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {patchResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {AccessKey, IAMUser} from '@/iam/types';

type Props = {
  user: IAMUser;
  accessKey: AccessKey;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  description: {
    marginBottom: 15,
  },
});

const disableKeyForm = 'Disable Access Key';

const DisableKeyWarning = ({accessKeyId}: {accessKeyId: string}) => {
  const classes = useStyles();
  return (
    <Typography className={classes.description}>
      This will disable the <strong>{accessKeyId}</strong> access key so it can't be used with Vail.
      Are you sure you want to proceed?
    </Typography>
  );
};

const DisableKeyDialog = ({accessKey, onSubmit, ...others}: Props) => {
  return (
    <FormDialog title={disableKeyForm} submitLabel='Disable' onSubmit={onSubmit} {...others}>
      {() => (<DisableKeyWarning accessKeyId={accessKey.id} />)}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {user, accessKey}: Props) => {
  const resource = `iam/users/${user.accountid}/${user.username}/keys`;
  return {
    onSubmit: (values: any) => {
      const body = {...values};
      if (!accessKey.inactive) {
        body.inactive = true;
      }
      return dispatch(patchResource(resource, accessKey.id, body));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(DisableKeyDialog);
