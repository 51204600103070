import React from 'react';
import {connect} from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';

import {fetchResource} from 'spectra-logic-ui/actions';
import {TableCellProps} from 'spectra-logic-ui/components/table';
import ExpandableTable from 'spectra-logic-ui/components/table/expandable';
import {Dispatch, Store} from 'spectra-logic-ui';

import {Lifecycle, Pool} from '@/types';
import NoteCard from '@/components/note_card';

type Props = {
  lifecycle: Lifecycle;
  pools: Pool[];
  fetching: boolean;
  error: boolean;
  fetchPools: () => Promise<any>;
}

enum VersionType {
  LATEST = 'current',
  PREVIOUS = 'nonCurrent',
}

const useStyles = makeStyles({
  noteCards: {
    '& > *': {
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  nonNameCols: {
    width: '20%',
  },
});

const headers = ['Name', 'Type', 'Apply After'];

const Rules = ({lifecycle, pools, fetching, error, fetchPools}: Props) => {
  const rules = lifecycle.rules || [];
  const [selectedRuleIndex, setSelectedRuleIndex] = React.useState(-1);
  const classes = useStyles();

  React.useEffect(() => {
    if (fetchPools !== undefined) {
      fetchPools();
    }
  }, []);

  React.useEffect(() => {
    setSelectedRuleIndex(rules.length === 1 && rules[0].destinations ? 0 : -1);
  }, [rules]);

  return (
    <ExpandableTable>
      <ExpandableTable.Header>
        <ExpandableTable.HeaderRow>
          {headers.map((h, i) => {
            const extras = {} as TableCellProps;
            if (i > 0) {
              extras.className = classes.nonNameCols;
            }
            return <ExpandableTable.Cell key={h} {...extras}>{h}</ExpandableTable.Cell>;
          })}
        </ExpandableTable.HeaderRow>
      </ExpandableTable.Header>
      <ExpandableTable.Body isLoading={fetching} hasError={error} colSpan={headers.length}>
        {rules.map((rule, ruleIndex) => {
          const schedule = rule.schedule && (rule.schedule.days || rule.schedule.days == 0) ?
            `${rule.schedule.days} ${rule.schedule.days === 1 ? 'day' : 'days'}` :
            'N/A';
          const versioning = (() => {
            switch (rule.apply) {
            case VersionType.LATEST:
              return 'Latest';
            case VersionType.PREVIOUS:
              return 'Previous';
            default:
              return 'All';
            }
          })();
          let ruleType: string;
          if (rule.expiration) {
            ruleType = 'Deletion';
          } else {
            ruleType = 'Placement';
          }
          let deleteClones: string;
          if (rule.deletion) {
            deleteClones = 'Delete unselected storage';
          } else {
            deleteClones = 'Keep all existing storage';
          }
          let poolNames = null as any;
          let count = null as any;
          if (rule.destinations && rule.destinations.storage) {
            poolNames = rule.destinations.storage.map((storage: string) => {
              const foundPool = pools.find((pool) => pool.id == storage);
              return foundPool ? foundPool.name : null;
            });
            count = rule.destinations.count || 0;
            count = count === 0 ? 'All' : count;
          }
          return (
            <ExpandableTable.BodyRow
              key={ruleIndex} selected={ruleIndex === selectedRuleIndex}
              onClick={() => setSelectedRuleIndex(ruleIndex === selectedRuleIndex ? -1 : ruleIndex)}
              details={
                <>
                  <div className={classes.noteCards}>
                    {rule.destinations &&
                      <NoteCard title='Destinations'>
                        <NoteCard.Table>
                          <NoteCard.TableRow name='Count' value={count}/>
                          <NoteCard.TableRow name='Storage' value={poolNames ? poolNames.join(', ') : ''}/>
                          <NoteCard.TableRow name='Delete' value={deleteClones} />
                        </NoteCard.Table>
                      </NoteCard>
                    }
                    <NoteCard title='Filters'>
                      <NoteCard.Table>
                        <NoteCard.TableRow name='Versions' value={versioning} />
                        <NoteCard.TableRow name='Include' value={rule.include || ''} />
                        <NoteCard.TableRow name='Exclude' value={rule.exclude || ''} />
                      </NoteCard.Table>
                    </NoteCard>
                  </div>
                </>
              }
            >
              <ExpandableTable.Cell>{rule.name}</ExpandableTable.Cell>
              <ExpandableTable.Cell>{ruleType}</ExpandableTable.Cell>
              <ExpandableTable.Cell>{schedule}</ExpandableTable.Cell>
            </ExpandableTable.BodyRow>
          );
        })}
      </ExpandableTable.Body>
    </ExpandableTable>
  );
};

const mapStateToProps = (state: Store) => {
  const pools = state.resources.storage || {};
  return {
    pools: pools.data || [],
    error: pools.error,
    fetching: pools.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPools: () => dispatch(fetchResource('storage')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
