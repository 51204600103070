import React, {memo, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Field, Form, FormRenderProps} from 'react-final-form';
import {saveAs} from 'file-saver';
import {isEqual} from 'lodash';
import {Grid} from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import AssignmentIcon from 'spectra-logic-ui/icons/Assignment';
import Check from 'spectra-logic-ui/icons/Check';
import Save from 'spectra-logic-ui/icons/Save';
import {Dispatch, Store} from 'spectra-logic-ui';
import {changeUIState, fetchResource, patchCollection, patchResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {Button, Card, Popover, SlidePanel, StatusIcon, Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';

import {MessageSeverity} from '@/enum';
import CardHeader from '@/components/card_header';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';
import Paginator from '@/components/paginator';
import PaginatorFooter from '@/components/paginator_footer';
import {formatDate} from '@/reports/audits';
import PropertiesDetails from '@/messages/properties';
import {Message} from '@/messages/types';

type Props = {
  messages?: Message[];
  fetching?: boolean;
  error?: boolean;
  fetchMessages?: (filterState: object, marker: string) => void;
  updateMessages?: (read: boolean) => Promise<any>;
  updateMessage: (id: string, read: boolean) => Promise<any>;
  filterState: FilterState;
  marker: string;
  maxKeys: number;
  nextMarker: string;
  isTruncated: boolean;
}

type FilterState = {
  severity?: MessageSeverity;
  start?: Date;
  end?: Date;
  read?: string;
  search?: string;
}

const useStyles = makeStyles({
  filters: {
    margin: 10,
    padding: 15,
    background: Color.GRAY_LIGHT,
    borderRadius: 6,
  },
  form: {
    display: 'inline',
  },
  pagination: {
    margin: 10,
    padding: 5,
    background: Color.GRAY_LIGHT,
    borderRadius: 6,
  },
  bold: {
    'font-weight': 'bold !important',
  },
  largeItem: {
    flexGrow: 1,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  filler: {
    flexGrow: 1,
  },
});

const defaultInitialFilterState = {severity: MessageSeverity.INFO, read: 'all'};
const filterStateID = 'messagesFilter';
const maxObjectsPerPage = '300';
const messageSeverities = [
  {key: MessageSeverity.INFO, text: MessageSeverity.INFO},
  {key: MessageSeverity.OK, text: MessageSeverity.OK},
  {key: MessageSeverity.WARNING, text: MessageSeverity.WARNING},
  {key: MessageSeverity.ERROR, text: MessageSeverity.ERROR},
];
const messageReadStatus = [
  {key: 'all', text: 'All'},
  {key: 'true', text: 'Read'},
  {key: 'false', text: 'Unread'},
];

const Messages = (props: Props) => {
  const {messages = [], fetching = false, error = false, fetchMessages,
    updateMessages, updateMessage, filterState, marker, nextMarker, maxKeys, isTruncated} = props;
  const [typingTimeout, setTypingTimeout] = React.useState(0);
  const [selectedMessageID, setSelectedMessageID] = React.useState('');
  const [isOpenDetails, setOpenDetails] = React.useState(false);
  const openDetails = () => setOpenDetails(true);
  const [paginatorFrom, setPaginatorFrom] = React.useState(1);
  const [prevMarkers, setPrevMarkers] = React.useState([] as string[]);
  const [isStartValid, setIsStartValid] = React.useState(true);
  const [isEndValid, setIsEndValid] = React.useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const clearSelectedMessage = () => {
    setSelectedMessageID('');
    setOpenDetails(false);
  };

  const messagesMap: Record<string, Message> = {};
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    messagesMap[message.id] = message;
  }

  if (selectedMessageID && !messagesMap[selectedMessageID]) {
    clearSelectedMessage();
  }

  useEffect(() => {
    setPaginatorFrom(1);
    setPrevMarkers([]);
    if (fetchMessages !== undefined && isStartValid && isEndValid) {
      clearTimeout(typingTimeout);
      clearSelectedMessage();
      setTypingTimeout(window.setTimeout(() => {
        fetchMessages(filterState, '');
      }, 500));
    }
  }, [filterState, isStartValid, isEndValid]);

  const nextPage = () => {
    if (!fetching) {
      if (messages) {
        setPaginatorFrom(paginatorFrom+messages.length);
      }
      if (marker) {
        prevMarkers.push(marker);
        setPrevMarkers(prevMarkers);
      }
      if (fetchMessages !== undefined) {
        fetchMessages(filterState, nextMarker);
      }
    }
  };

  const prevPage = () => {
    if (!fetching) {
      if (messages) {
        setPaginatorFrom(paginatorFrom-maxKeys);
      }
      const prevMarker = prevMarkers.pop() || '';
      if (fetchMessages !== undefined) {
        fetchMessages(filterState, prevMarker);
      }
    }
  };

  // The DesktopDatePicker error handling nonsense is to work around an annoyance where
  // it treats a blank date as an error.
  return (
    <Card>
      <CardHeader icon={AssignmentIcon}>Messages</CardHeader>
      <Card.Body>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form className={classes.form} onSubmit={() => {}} initialValues={filterState}>
            {({handleSubmit, values}: FormRenderProps) => {
              useEffect(() => {
                dispatch(changeUIState(filterStateID, values));
              }, [values]);
              return (
                <form onSubmit={handleSubmit}>
                  <div className={classes.filters}>
                    <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                      <Grid item style={{minWidth: '16%'}}>
                        <FormSingleSelect name='severity' label='Minimum Severity' options={messageSeverities} />
                      </Grid>
                      <Grid item style={{minWidth: '13%'}}>
                        <Field
                          name='start'
                          render={({input}) => (
                            <DesktopDatePicker
                              inputFormat='MM/dd/yyyy'
                              label='Start Date'
                              maxDate={filterState.end}
                              renderInput={({error, inputProps, ...params}) => {
                                useEffect(() => {
                                  setIsStartValid(inputProps ? (inputProps.value ? !error : true) : true);
                                }, [inputProps, error]);
                                return <MuiTextField
                                  variant='standard' error={inputProps ? (inputProps.value ? error : false) : false}
                                  inputProps={inputProps} {...params}
                                />;
                              }}
                              {...input}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item style={{minWidth: '13%'}}>
                        <Field
                          name='end'
                          render={({input: {onChange, ...others}}) => (
                            <DesktopDatePicker
                              inputFormat='MM/dd/yyyy'
                              label='End Date'
                              minDate={filterState.start}
                              renderInput={({error, inputProps, ...params}) => {
                                useEffect(() => {
                                  setIsEndValid(inputProps ? (inputProps.value ? !error : true) : true);
                                }, [inputProps, error]);
                                return <MuiTextField
                                  variant='standard' error={inputProps ? (inputProps.value ? error : false) : false}
                                  inputProps={inputProps} {...params}
                                />;
                              }}
                              {...others}
                              onChange={(end) => {
                                if (end instanceof Date) {
                                  onChange(new Date(end.setUTCHours(23, 59, 59, 999)));
                                } else {
                                  onChange(end);
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item style={{minWidth: '13%'}}>
                        <FormSingleSelect
                          name='read' label='Read Status'
                          options={messageReadStatus}
                        />
                      </Grid>
                      <Grid item style={{minWidth: '12%'}}>
                        <TextField name='search' label='Search' />
                      </Grid>
                      <Grid item>
                        <Popover anchor={
                          <Button
                            onClick={() => downloadMessages(messages, filterState)}
                            disabled={messages?.length <= 0}>
                            <Save />
                          </Button>}
                        >
                          <div>Download filtered messages</div>
                        </Popover>
                      </Grid>
                      <Grid item>
                        <Popover anchor={
                          <Button onClick={ () => {
                            if (updateMessages != undefined) {
                              updateMessages(true);
                            }
                          }}>
                            <Check />
                          </Button>}
                        >
                          <div>Mark all as read</div>
                        </Popover>
                      </Grid>
                      <Grid item>
                        <span className={classes.filler} />
                        <Paginator
                          prev={prevPage}
                          from={paginatorFrom}
                          to={messages != null ? paginatorFrom+messages.length-1 : 0}
                          next={nextPage}
                          showNext={isTruncated}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </form>
              );
            }}
          </Form>
        </LocalizationProvider>
        <MessagesTable
          messages={messages} messagesMap={messagesMap} fetching={fetching} error={error}
          selectedMessageID={selectedMessageID} setSelectedMessageID={setSelectedMessageID}
          updateMessage={updateMessage} openDetails={openDetails}
        />
        <PaginatorFooter
          prev={prevPage}
          from={paginatorFrom}
          to={messages != null ? paginatorFrom+messages.length-1 : 0}
          next={nextPage}
          showNext={isTruncated}
        />
        {selectedMessageID !== '' && <SlidePanel
          title='Message Details' options={['Properties']} open={isOpenDetails} onClose={clearSelectedMessage}
        >
          <PropertiesDetails message={messagesMap[selectedMessageID]} />
        </SlidePanel>}
      </Card.Body>
    </Card>
  );
};

type MessagesTableProps = {
  messages: Message[];
  messagesMap: Record<string, Message>;
  fetching: boolean;
  error: boolean;
  selectedMessageID: string;
  setSelectedMessageID: (args: any) => void;
  updateMessage: (id: string, read: boolean) => Promise<any>;
  openDetails: (args: any) => void;
};

const MessagesTable = (
  {messages, messagesMap, fetching, error, selectedMessageID, setSelectedMessageID, updateMessage,
    openDetails}: MessagesTableProps,
) => {
  const classes = useStyles();

  const selectRow = (id: string) => {
    setSelectedMessageID(id);
    const message = messagesMap[id];
    if (message && !message.read) {
      updateMessage(message.id, true);
    }
  };

  return (
    <Table size='small'>
      <Table.Header>
        <Table.Row>
          <Table.Cell className={classes.nowrap}>Severity</Table.Cell>
          <Table.Cell>Description</Table.Cell>
          <Table.Cell className={classes.nowrap}>Time</Table.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body isLoading={fetching && selectedMessageID === ''} hasError={error}>
        {messages.map((message: any) => (
          <MessageRow
            key={message.id} message={message} openDetails={openDetails}
            selected={message.id === selectedMessageID} selectRow={() => selectRow(message.id)}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

type MessageRowProps = {
  message: Message;
  openDetails: (args: any) => void;
  selected: boolean;
  selectRow: (args: any) => void;
};

// rowPropsAreEqual is a React memoization function for the MessageRow component that
// determines if anything has changed that would require the component to be re-rendered.
// The only things that would affect this is the message's "read" property, and if
// the row was selected/unselected. We still compare the entire message just in case something
// else changed.
const rowPropsAreEqual = (prevProps: MessageRowProps, nextProps: MessageRowProps) => {
  return isEqual(prevProps.message, nextProps.message) && prevProps.selected === nextProps.selected;
};

// MessageRow represents a single row in the messages table. React.memo is used to
// increase the performance of the table by preventing re-rendering of all rows when
// a user selects a new one (which causes the parent table to be re-rendered).
const MessageRow = memo(({message, openDetails, selected, selectRow}: MessageRowProps) => {
  const classes = useStyles();
  const boldClass = message.read ? '' : classes.bold;
  let noWrapBoldClass = classes.nowrap;
  if (boldClass !== '') {
    noWrapBoldClass += ' ' + boldClass;
  }
  return (
    <Table.Row key={message.id} data-id={message.id} selected={selected} onClick={selectRow}>
      <Table.Cell className={noWrapBoldClass}><StatusIcon status={message.severity} /></Table.Cell>
      <Table.Cell className={boldClass}>{message.text}</Table.Cell>
      <Table.Cell className={noWrapBoldClass}>{dateTimeLong(message.time)}</Table.Cell>
      <Table.CellDetailsButton onClick={openDetails} />
    </Table.Row>
  );
}, rowPropsAreEqual);

const mapStateToProps = (state: Store) => {
  const messageResource = state.resources.messages || {};
  const filterState = state.ui[filterStateID] || defaultInitialFilterState;
  const messages = messageResource.data || [];
  return {
    messages: messages,
    error: messageResource.error,
    fetching: messageResource.fetching,
    filterState: filterState,
    marker: messageResource.marker || '',
    maxKeys: messageResource.maxKeys || 0,
    isTruncated: messageResource.isTruncated || false,
    nextMarker: messageResource.nextMarker || '',
  };
};

export const fetchMessages = (filterState?: FilterState, marker?: string) => {
  if (!filterState) {
    filterState = defaultInitialFilterState;
  }
  const query: {[name: string]: any} = {'max-keys': maxObjectsPerPage};
  if (marker) {
    query.marker = marker;
  }
  if (filterState.start) {
    query.start = filterState.start.toISOString();
  }
  if (filterState.end) {
    query.end = filterState.end.toISOString();
  }
  if (filterState.read !== 'all') {
    query.read = filterState.read === 'true';
  }
  if (filterState.search !== '') {
    query.search = filterState.search;
  }
  if (filterState.severity) {
    query.severity = filterState.severity;
  }
  return fetchResource('messages', '', {query});
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMessages: (filterState: FilterState, marker: string) => {
    dispatch(fetchMessages(filterState, marker));
  },
  updateMessages: (read: boolean) => {
    const query: {[name: string]: any} = {'max-keys': maxObjectsPerPage};
    return dispatch(patchCollection('messages', {read}, {query}));
  },
  updateMessage: (id: string, read: boolean) => {
    return dispatch(patchResource('messages', id, {read}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

// handleMessagesPubsubEvent is a pub/sub handler for the messages channel. It
// fetches the messages using the same UI filter state that this page uses.
// This prevents the displayed messages from changing if a pub/sub event comes in
// while on the page.
export const handleMessagesPubsubEvent = (event: any, dispatch: Dispatch, state: Store) => {
  const filterState = state.ui[filterStateID];
  dispatch(fetchMessages(filterState));
};

// downloadMessages downloads the messages as a JSON file. Windows line-endings
// are used if the browser's user agent indicates it's running on Windows.
const downloadMessages = (messages: Message[], filterState: FilterState) => {
  let data = JSON.stringify(messages, null, '\t');
  if (window.navigator && window.navigator.userAgent &&
    window.navigator.userAgent.indexOf('Win') !== -1) {
    data = data.replace(/\n/g, '\r\n');
  }
  let jsonFilename = 'messages.json';
  if (filterState.start && !filterState.end) {
    jsonFilename = `messages from ${formatDate(filterState.start)}.json`;
  } else if (!filterState.start && filterState.end) {
    jsonFilename = `messages to ${formatDate(filterState.end)}.json`;
  } else if (filterState.start && filterState.end) {
    jsonFilename = `messages from ${formatDate(filterState.start)} to ${formatDate(filterState.end)}.json`;
  }
  saveAs(new Blob([data], {type: 'json'}), jsonFilename);
};
