import React from 'react';

import {WizardPage} from 'spectra-logic-ui/components';

import {Administrator} from '@/types';
import DialogDescription from '@/components/form/dialog_description';
import Radio from '@/components/radio';
import {FormOption} from '@/administrators/form/edit/enum';

type Props = {
  title: string;
  user: Administrator;
}

const OptionsPage = ({title, user, ...otherProps}: Props) => (
  <WizardPage
    title={title} {...otherProps}
    description={
      <DialogDescription>Select how you would like to edit <strong>{user.username}</strong>.</DialogDescription>
    }
  >
    <Radio>
      <Radio.Button name='formOption' label='Modify existing attributes' value={FormOption.CHANGE_ATTRIBUTES} />
      <Radio.Button name='formOption' label='Set new password' value={FormOption.CHANGE_PASSWORD} />
    </Radio>
  </WizardPage>
);

export default OptionsPage;
