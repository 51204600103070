import React from 'react';
import {connect} from 'react-redux';
import {NavigateFunction} from 'react-router-dom';
import {Form, FormRenderProps} from 'react-final-form';

import {Button} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {updateResource} from 'spectra-logic-ui/actions';
import {loginStyles} from 'spectra-logic-ui/components/login';
import {Dispatch} from 'spectra-logic-ui';

import TextField from '@/components/form/text_field';
import {FormValues} from '@/login/forgot_password/form/confirm/types';
import {validate} from '@/login/forgot_password/form/confirm/validate';

type Props = {
  navigate: NavigateFunction;
  username: string;
  onSubmit?: (...args: any) => Promise<any> | void;
}

const useStyles = makeStyles(loginStyles);

const ConfirmForgotPasswordForm = ({onSubmit}: Props) => {
  const codeRef = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();

  React.useEffect(() => {
    if (codeRef.current !== null) {
      codeRef.current.focus();
    }
  }, []);

  return (
    <Form
      onSubmit={(values) => {
        if (onSubmit) return onSubmit(values);
      }}
      validate={validate}
    >
      {({error, submitting, handleSubmit, submitError}: FormRenderProps) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          {(error || submitError) && !submitting && <div className='error'>{error || submitError}</div>}
          <TextField name='confirmationCode' label='Confirmation Code' className={classes.field} inputRef={codeRef} />
          <TextField name='password' label='New Password' type='password' className={classes.field} />
          <TextField name='confirmPassword' label='Confirm New Password' type='password' className={classes.field} />
          <Button type='submit' className={classes.button} fullWidth disabled={submitting}>Submit</Button>
        </form>
      )}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {navigate, username}: Props) => {
  const onSuccess = () => navigate !== undefined && navigate('/login');

  return {
    onSubmit: (values: FormValues) => {
      const {confirmationCode, password} = values;
      return dispatch(
        updateResource(`users/${username}/forgot_password`,
          '', {confirmationCode, password}, {onSuccess}),
      );
    },
  };
};

export default connect(undefined, mapDispatchToProps)(ConfirmForgotPasswordForm);
