// This is the main entry point for the node's client application.
import 'core-js/stable';
import React from 'react';

import AccountIcon from 'spectra-logic-ui/icons/AccountCircle';
import AdminUserIcon from 'spectra-logic-ui/icons/Security';
import ArchiveIcon from 'spectra-logic-ui/icons/Archive';
import AssignmentIcon from 'spectra-logic-ui/icons/Assignment';
import ComputerIcon from 'spectra-logic-ui/icons/Computer';
import DeviceHubIcon from 'spectra-logic-ui/icons/DeviceHub';
import FolderIcon from 'spectra-logic-ui/icons/Folder';
import HomeIcon from 'spectra-logic-ui/icons/Home';
import KeyIcon from 'spectra-logic-ui/icons/Key';
import LifecycleIcon from 'spectra-logic-ui/icons/Autorenew';
import LocationIcon from 'spectra-logic-ui/icons/Map';
import LockIcon from 'spectra-logic-ui/icons/Lock';
import PerformanceIcon from 'spectra-logic-ui/icons/Timeline';
import PieChartIcon from 'spectra-logic-ui/icons/PieChart';
import StorageIcon from 'spectra-logic-ui/icons/Storage';
import PeopleIcon from 'spectra-logic-ui/icons/People';
import UpdateIcon from 'spectra-logic-ui/icons/Sync';
import SettingsIcon from 'spectra-logic-ui/icons/Build';

import App from 'spectra-logic-ui/app';
import SettingsMenu from 'spectra-logic-ui/app/header/settings_menu';
import HelpButton from 'spectra-logic-ui/components/help_button';
import NotificationsButton from 'spectra-logic-ui/components/notifications_button';

import {OS} from '@/enum';
import * as paths from '@/paths';
import {setSingle} from '@/single';
import Accounts from '@/accounts';
import Administrators from '@/administrators';
import S3 from '@/buckets';
import Bucket from '@/buckets/objects';
import Capacity from '@/usage';
import CloudDashboard from '@/dashboard/index_cloud';
import Entitlements from '@/entitlements';
import NodeDashboard from '@/dashboard/index_node';
import {getHelpLocation} from '@/help';
import Hostname from '@/hostname';
import Title from '@/layout/title';
import Login from '@/login';
import Lifecycles from '@/lifecycle';
import Locations from '@/locations';
import ForgotPassword from '@/login/forgot_password';
import Logs from '@/logs';
import Messages, {fetchMessages, handleMessagesPubsubEvent} from '@/messages';
import Network from '@/network';
import Performance from '@/performance';
import Reports from '@/reports';
import {handleAuditsPubsubEvent} from '@/reports/audits';
import SSLCertificate from '@/sslcertificate';
import Storage from '@/storage';
import Updates from '@/updates';
import IAM from '@/iam';
import Settings from '@/settings';

const {
  authEnabled = true, os, name, single, ...initialState
} = window.SPECTRA_LOGIC_VAIL_INITIAL_STATE || {};

setSingle(single);

// Array of Material-UI <ListItem /> properties
const nav = [{text: 'Dashboard', icon: HomeIcon, path: '/'}];

const dashboard = authEnabled ? CloudDashboard : NodeDashboard;

// pathPrefix must match defaults.MgmtBasePath.
const pathPrefix = '/sl';

const copyrightLink = pathPrefix + '/docs/90990168_A_OpenSourceAcknowledgements.pdf';

// Array of react-router <Route /> properties
const routes = [
  {path: '/', component: dashboard},
  {path: paths.network, component: Network},
];
if (os !== OS.BP) {
  routes.push({path: paths.hostname, component: Hostname} as any);
  routes.push({path: paths.ssl, component: SSLCertificate} as any);
}
routes.push({path: paths.entitlements, component: Entitlements} as any);
routes.push({path: paths.logs, component: Logs} as any);

const settingsMenu = [];
if (authEnabled) {
  nav.push({text: 'Storage', icon: StorageIcon, path: paths.storage});
  nav.push({text: 'Lifecycles', icon: LifecycleIcon, path: paths.lifecycles});
  nav.push({text: 'Buckets', icon: FolderIcon, path: paths.buckets});
  nav.push({text: 'Capacity', icon: PieChartIcon, path: paths.usage});
  nav.push({text: 'Performance', icon: PerformanceIcon, path: paths.performance});
  nav.push({text: 'Reports', icon: AssignmentIcon, path: paths.reports});

  routes.push({path: paths.buckets, component: S3});
  routes.push({path: paths.buckets+'/:bucket', component: S3});
  // List objects in the given bucket.  prefix and delimiter query
  // parameters are used to list objects under a certain subfolder.  This is
  // also used to display object metadata.
  routes.push({path: paths.buckets+'/:bucket/objects', component: Bucket} as any);
  routes.push({path: paths.usage, component: Capacity} as any);
  routes.push({path: paths.lifecycles, component: Lifecycles} as any);
  if (!single) {
    routes.push({path: paths.locations, component: Locations} as any);
    // Resetting a password in single mode requires a support call, like BP.
    routes.push({path: paths.forgotPassword, component: ForgotPassword, public: true} as any);
  }
  routes.push({path: paths.performance, component: Performance} as any);
  routes.push({path: paths.performance+'/:endpoint', component: Performance} as any);
  routes.push({path: paths.performance+'/:endpoint/:type', component: Performance} as any);
  routes.push({path: paths.performance+'/:endpoint/:type/:table', component: Performance} as any);
  routes.push({path: paths.reports, component: Reports} as any);
  routes.push({path: paths.messages, component: Messages} as any);
  routes.push({path: paths.storage, component: Storage} as any);
  routes.push({path: paths.updates, component: Updates} as any);
  routes.push({path: paths.users, component: IAM} as any);
  if (!single) {
    // Use the BlackPearl mgmt server for users in single mode
    routes.push({path: paths.administrators, component: Administrators} as any);
  }
  routes.push({path: paths.accounts, component: Accounts} as any);
  routes.push({path: paths.settings, component: Settings} as any);

  if (!single) {
    // Use the BlackPearl mgmt server for users in single mode
    settingsMenu.push({text: 'Administrators', icon: AdminUserIcon, path: paths.administrators});
  }
  settingsMenu.push({text: 'IAM Accounts', icon: AccountIcon, path: paths.accounts});
  settingsMenu.push({text: 'IAM Users & Groups', icon: PeopleIcon, path: paths.users});
  if (!single) {
    settingsMenu.push({text: 'Locations', icon: LocationIcon, path: paths.locations});
  }
  settingsMenu.push({text: 'Software Updates', icon: UpdateIcon, path: paths.updates});
}
settingsMenu.push({text: 'Logs', icon: ArchiveIcon, path: paths.logs});
if (authEnabled) {
  settingsMenu.push({text: 'Global Settings', icon: SettingsIcon, path: paths.settings});
}

settingsMenu.push({text: 'Network', icon: DeviceHubIcon, path: paths.network});

if (os !== OS.BP) {
  settingsMenu.push({text: 'Hostname', icon: ComputerIcon, path: paths.hostname});
  settingsMenu.push({text: 'SSL Certificate', icon: LockIcon, path: paths.ssl});
}
settingsMenu.push({text: 'Entitlements', icon: KeyIcon, path: paths.entitlements});

const additionalHeader = (
  <div style={{display: 'inline'}}>
    {authEnabled && <NotificationsButton
      route={paths.messages}
      getCount={(state: any) => (state.resources.messages || {}).UnreadCount}
      getStatus={(state: any) => (state.resources.messages || {}).maxUnreadSeverity}
      tooltip='View messages'
      fetchCount={() => fetchMessages()} />}
    {settingsMenu.length > 0 && <SettingsMenu settings={settingsMenu} tooltip='Configure settings' />}
    <HelpButton getHelpLocation={getHelpLocation} />
  </div>
);

const app = new App({
  title: <Title bootstrappedName={name} single={single} />,
  subTitle: '',
  nav, routes, loginComponent: Login, initialState, authEnabled, additionalHeader,
  copyrightLink: copyrightLink,
  basePath: pathPrefix,
  pubsubEnabled: true,
});
app.registerPubsubHandler(`${pathPrefix}/api/messages`, handleMessagesPubsubEvent);
app.registerPubsubHandler(`${pathPrefix}/api/reports/audit`, handleAuditsPubsubEvent);
app.run();
