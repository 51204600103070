import React from 'react';
import {connect} from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';

import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import {fetchResource} from 'spectra-logic-ui/actions';
import ComputerIcon from 'spectra-logic-ui/icons/Computer';
import {Dispatch, Store} from 'spectra-logic-ui';

import {NetworkInterface} from '@/types';
import CardHeader from '@/components/card_header';
import ButtonToolbar from '@/components/button_toolbar';
import HostnameDialog from '@/hostname/edit';
import {System} from '@/system/types';

type Props = {
  interfaces?: NetworkInterface[];
  fetching?: boolean;
  error?: boolean;
  fetchSystem?: () => Promise<any>;
  system: System;
}

const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
});

const headers = ['Name', 'Value'];

const HostnamePage = ({fetching = false, error = false, fetchSystem, system}: Props) => {
  React.useEffect(() => {
    if (fetchSystem !== undefined) {
      fetchSystem();
    }
  }, []);
  const classes = useStyles();
  const renderRows = () => (
    <Table.Row>
      <Table.Cell>Hostname</Table.Cell>
      <Table.Cell>{system.name}</Table.Cell>
    </Table.Row>
  );
  return (
    <Card>
      <CardHeader icon={ComputerIcon}>Hostname</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.EditDialogButton dialog={(props: any) => <HostnameDialog system={system} {...props} />} />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              {headers.map((hdr) => <Table.Cell className={classes.boldText} key={hdr}>{hdr}</Table.Cell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body hasError={error} isLoading={fetching}>
            {renderRows()}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const systemResource = state.resources.system || {};
  const system = systemResource.data || {};
  return {
    system: system,
    fetching: systemResource.fetching,
    error: systemResource.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSystem: () => dispatch(fetchResource('system')),
});

export default connect(mapStateToProps, mapDispatchToProps)(HostnamePage);
