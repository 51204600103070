import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import makeStyles from '@mui/styles/makeStyles';

import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import {fetchResource} from 'spectra-logic-ui/actions';
import DeviceHubIcon from 'spectra-logic-ui/icons/DeviceHub';
import {Dispatch, Store} from 'spectra-logic-ui';

import {NetworkInterface} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import NetworkDialog from '@/network/form/nic_edit';

type Props = {
  interfaces: NetworkInterface[];
  fetching: boolean;
  error: boolean;
  className?: string;
  fetchInterfaces?: () => Promise<any>;
  readonly?: boolean;
}
const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
});

const headers = ['Name', 'IP Addresses', 'MTU', 'Default Gateway', 'Name Servers', 'Search Domains'];

const NetworkPage = ({interfaces = [], fetching, error, fetchInterfaces, readonly, className}: Props) => {
  const TableType = readonly ? Table : Table;
  const classes = useStyles();
  const [selectedInterfaceName, selectInterfaceName] = React.useState('');

  useEffect(() => {
    if (fetchInterfaces !== undefined) {
      fetchInterfaces();
    }
  }, []);

  const clearInterface = () => {
    selectInterfaceName('');
  };

  const selectedInterface = interfaces.find((i) => i.name === selectedInterfaceName) || {} as NetworkInterface;

  const renderRows = () => (
    interfaces.map((nic: NetworkInterface) => (
      <TableType.Row key={nic.name} selected={nic.name === selectedInterfaceName}
        onClick={(event) => !readonly && event.target.tagName.toLowerCase()!== 'a' && selectInterfaceName(nic.name)}>
        <TableType.Cell style={{width: '10%'}}>{nic.name}</TableType.Cell>
        <TableType.Cell style={{width: '25%'}}>
          {nic.addresses && nic.addresses.map((addr) => <p key={addr}>{addr}</p>)}</TableType.Cell>
        <TableType.Cell style={{width: '9%'}}>{nic.mtu}</TableType.Cell>
        <TableType.Cell style={{width: '20%'}}>
          {nic.gateway4 ? <p>{nic.gateway4}</p> : ''}
          {nic.gateway6 ? <p>{nic.gateway6}</p> : ''}
        </TableType.Cell>
        <Table.Cell style={{width: '18%'}}>
          {nic.nameServers && nic.nameServers.map((item) => <p>{item}</p>)}
        </Table.Cell>
        <Table.Cell style={{width: '18%'}}>
          {nic.searchDomains && nic.searchDomains.map((item) => <p>{item}</p>)}
        </Table.Cell>
      </TableType.Row>
    ))
  );
  return (
    <Card className={className}>
      <CardHeader icon={DeviceHubIcon} isShort={readonly}>Network Interfaces</CardHeader>
      <Card.Body>
        {!readonly && <ButtonToolbar><ButtonToolbar.EditDialogButton disabled={isEmpty(selectedInterfaceName)}
          dialog={(props: any) => <NetworkDialog selectedInterface={selectedInterface}
            onSuccess={clearInterface} {...props} />
          }
        /></ButtonToolbar>}
        <TableType>
          <TableType.Header>
            <TableType.Row>
              {headers.map((hdr) => <TableType.Cell className={classes.boldText} key={hdr}>{hdr}
              </TableType.Cell>)}
            </TableType.Row>
          </TableType.Header>
          <TableType.Body hasError={error} isLoading={fetching}>
            {renderRows()}
          </TableType.Body>
        </TableType>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const interfacesState = state.resources['network/interfaces'] || {};
  return {
    interfaces: interfacesState.data || [],
    fetching: interfacesState.fetching,
    error: interfacesState.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchInterfaces: () => dispatch(fetchResource('network/interfaces')),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkPage);
