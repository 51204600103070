import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import ComputerIcon from 'spectra-logic-ui/icons/Computer';
import HomeIcon from 'spectra-logic-ui/icons/Home';

import CardHeader from '@/components/card_header';
import NetworkInterfaces from '@/network/interfaces';
import SystemComponent from '@/system';
import {System} from '@/system/types';

type Props = {
  fetching?: boolean;
  error?: boolean;
  fetchSystem?: () => Promise<any>;
  system: System;
}

const useStyles = makeStyles({
  grid: {
    '& > *': {
      margin: '4px 0px 0px 0px',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  tableCell: {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  card: {
    margin: '6px 0px 10px 0px',
  },
});

const Dashboard = ({fetchSystem, fetching=false, error=false, system}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (fetchSystem !== undefined) {
      fetchSystem();
    }
  }, []);

  return (
    <Card>
      <CardHeader icon={HomeIcon}>Dashboard</CardHeader>
      <SystemComponent endpoints={[]} system={system} fetching={fetching} error={error} />
      <Card.Body>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} >
            <NetworkInterfaces className={classes.card} readonly={true} />
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader icon={ComputerIcon} isShort>Hostname</CardHeader>
              <Card.Body>
                <Grid container spacing={2} className={classes.grid}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell className={`${classes.tableCell} ${classes.boldText}`}>Hostname</Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body hasError={error} isLoading={fetching}>
                      <Table.Row>
                        <Table.Cell className={classes.tableCell}>{system.name}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid>
              </Card.Body>
            </Card>
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const systemResource = state.resources.system || {};
  const system = systemResource.data || {};
  return {
    system: system,
    fetching: systemResource.fetching,
    error: systemResource.error,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSystem: () => dispatch(fetchResource('system')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
