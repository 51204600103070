import React from 'react';
import {connect} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource, updateResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';

import FormSingleSelect from '@/components/form/single_select';
import {IAMGroup, IAMUser} from '@/iam/types';
import validate from '@/iam/groups/form/validate';
import {FormData} from '@/iam/groups/form/types';

type Props = {
  user: IAMUser;
  onSubmit: (values: any) => Promise<any>;
  userGroups: IAMGroup[];
  allGroups?: IAMGroup[];
  error?: boolean;
  fetching?: boolean;
  fetchAllGroups?: () => Promise<any>;
}

const AddUserToGroupDialog = (
  {userGroups, allGroups = [], error = false, fetching = false, fetchAllGroups, ...others}: Props,
) => {
  React.useEffect(() => {
    if (fetchAllGroups !== undefined) {
      fetchAllGroups();
    }
  }, []);

  const userGroupsSet = new Set(userGroups.map((g) => g.name));
  const options: any = [];
  if (!error && !fetching) {
    allGroups.forEach((group) => {
      if (!userGroupsSet.has(group.name)) {
        options.push({key: group.name, text: group.name});
      }
    });
  }

  const disableSubmit = error || fetching || options.length === 0;

  let errMsg = '';
  if (error) {
    errMsg = 'There was an error fetching groups.';
  } else if (!fetching && options.length === 0) {
    errMsg = 'There are no available groups.';
  }

  return (
    <FormDialog title='Add IAM User to Group' validate={validate} {...others} disableSubmit={disableSubmit}>
      {() => (
        <>
          {errMsg ?
            <p className='error'>{errMsg}</p> :
            <FormSingleSelect name='name' label='Select Group' disabled={error || fetching} options={options} />
          }
        </>
      )}
    </FormDialog>
  );
};

const mapStateToProps = (state: Store, {user}: Props) => {
  const allGroupsState = state.resources[`iam/groups/${user.accountid}`] || {};
  const allGroups = allGroupsState.data || [];
  return {
    allGroups: allGroups,
    error: allGroupsState.error,
    fetching: allGroupsState.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {user}: Props) => {
  const resource = `iam/users/${user.accountid}/${user.username}/groups`;
  return {
    fetchAllGroups: () => dispatch(fetchResource(`iam/groups/${user.accountid}`)),
    onSubmit: ({name}: FormData) => dispatch(updateResource(resource, name, {})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToGroupDialog);
