import React from 'react';
import {connect} from 'react-redux';
import {useFormState} from 'react-final-form';
import {find} from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import {Store} from 'spectra-logic-ui';
import {WizardPage} from 'spectra-logic-ui/components';

import {TargetType} from '@/enum';
import {TargetItem} from '@/types';
import NoteCard from '@/components/note_card';
import DialogDescription from '@/components/form/dialog_description';
import {isStorageClassArchival} from '@/storage/archive';
import {items} from '@/storage/form/store';

type PageProps = {
  title: string;
  targetItems: TargetItem[];
}

const useStyles = makeStyles({
  errorCard: {
    marginTop: 10,
  },
  content: {
    '& > *': {
      marginTop: 15,
    },
  },
});

const BlackPearlConfirmPage = (props: PageProps) => {
  const {title, targetItems = [], ...otherProps} = props;
  const classes = useStyles();

  const formState = useFormState();
  const name = formState.values.name;
  const item = formState.values.item;
  const target = formState.values.target;
  const storageClass = formState.values.storageClass;
  const cloneRestore = formState.values.cloneRestore;
  const hasRecoverable = formState.values.recoverable !== undefined;
  const recoverable = formState.values.recoverable;
  const cautionThreshold = formState.values.cautionThreshold;
  const warningThreshold = formState.values.warningThreshold;
  const selectedItem = find(targetItems, {id: item});
  const itemName = selectedItem && selectedItem.name ? selectedItem.name : item;
  const itemLabel = target === TargetType.BPPOLICY ? 'Data Policy' : 'BlackPearl Bucket';
  return (
    <WizardPage
      title={title} {...otherProps}
      description={<DialogDescription>Verify your configuration is correct.</DialogDescription>}
    >
      <div className={classes.content}>
        <NoteCard title='Parameters'>
          <NoteCard.Table>
            <React.Fragment>
              <NoteCard.TableRow name='Storage Name' value={name}/>
              <NoteCard.TableRow name={itemLabel} value={itemName}/>
              {target === TargetType.BPLINK &&
               <NoteCard.TableRow name='Link to Bucket' value={formState.values.link}/>}
              <NoteCard.TableRow name='Storage Class' value={storageClass}/>
              {isStorageClassArchival(storageClass) &&
                <NoteCard.TableRow name='Restore To New Clone' value={cloneRestore ? 'Enabled' : 'Disabled'}/>}
              {hasRecoverable &&
                <NoteCard.TableRow name='Third-party Recovery' value={recoverable ? 'Enabled' : 'Disabled'}/>}
              {target !== TargetType.BPLINK &&
                <NoteCard.TableRow name='Caution Threshold'
                  value={cautionThreshold ? cautionThreshold + '%' : '--'}/>}
              {target !== TargetType.BPLINK &&
                <NoteCard.TableRow name='Warning Threshold'
                  value={warningThreshold ? warningThreshold + '%' : '--'}/>}
            </React.Fragment>
          </NoteCard.Table>
        </NoteCard>
      </div>
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const targetItemsResource = state.resources[items] || {};
  return {targetItems: targetItemsResource.data};
};

export default connect(mapStateToProps)(BlackPearlConfirmPage);
