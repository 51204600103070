import React from 'react';
import {connect} from 'react-redux';
import {Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {numberToHumanSize, commafy} from 'spectra-logic-ui/helpers/number';
import {fetchResource} from 'spectra-logic-ui/actions';
import {ApiStatus} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {isSingle} from '@/single';
import {Pod, Location} from '@/types';

export interface Status {
  totalManaged: number;
}

type Props = {
  summary?: Status;
  locations?: Location[];
  endpoints?: Pod[];
  fetching?: boolean;
  error?: boolean;
  fetchSummary?: () => Promise<any>;
}

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    paddingBottom: 5,
  },
  grid: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  gridHeader: {
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    textAlign: 'right',
    marginRight: 10,
  },
  gridData: {
    wordBreak: 'break-all',
  },
});

const Status = (props: Props) => {
  const {
    summary = {} as Status, locations = [], endpoints = [],
    fetching = false, error = false, fetchSummary,
  } = props;
  const classes = useStyles();
  const locationsNum = locations.filter((location) => location.id !== '0').length;
  const endpointsNum = endpoints.filter((endpoint) => endpoint.id !== '0').length;

  React.useEffect(() => {
    if (fetchSummary !== undefined) {
      fetchSummary();
    }
  }, []);

  return (
    <ApiStatus isLoading={fetching} hasError={error}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={12}>
          <Grid container className={classes.grid}>
            <Grid item xs={6} className={classes.gridHeader}>Total Managed:</Grid>
            <Grid item xs={5}>
              <div className={classes.gridData}>{numberToHumanSize(summary.totalManaged)}</div>
            </Grid>
          </Grid>
        </Grid>
        {!isSingle() && <Grid item xs={12} sm={4} lg={12}>
          <Grid container className={classes.grid}>
            <Grid item xs={6} className={classes.gridHeader}>Locations:</Grid>
            <Grid item xs={5}>
              <div className={classes.gridData}>{commafy(locationsNum)}</div>
            </Grid>
            <Grid item xs={6} className={classes.gridHeader}>Endpoints:</Grid>
            <Grid item xs={5}>
              <div className={classes.gridData}>{commafy(endpointsNum)}</div>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </ApiStatus>
  );
};

const mapStateToProps = (state: Store) => {
  const summaryResource = state.resources.summary || {};
  const locationsResource = state.resources.locations || {};
  const endpointsResource = state.resources.endpoints || {};

  return {
    summary: summaryResource.data,
    locations: locationsResource.data,
    endpoints: endpointsResource.data,
    fetching: summaryResource.fetching || locationsResource.fetching || endpointsResource.fetching,
    error: summaryResource.error || locationsResource.error || endpointsResource.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSummary: () => dispatch(fetchResource('summary')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
