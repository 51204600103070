import React from 'react';
import {connect} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';

import {Grid, IconButton, InputAdornment} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';

import {Store} from 'spectra-logic-ui';

import {Pool} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import FormMultiSelect from '@/components/form/multi_select';
import TextField from '@/components/form/text_field';
import NoteCard from '@/components/note_card';
import Checkbox from '@/components/form/checkbox';

type Props = {
  ruleField: string;
  index: number;
  onDelete?: () => void;
  storage?: Pool[];
}

const useStyles = makeStyles({
  listItem: {
    listStyleType: 'none',
    zIndex: 99999,
    marginBottom: 15,
  },
  ruleInfo: {
    padding: 10,
  },
  deleteButton: {
    alignSelf: 'flex-end',
    padding: '0',
    margin: '0',
  },
  buttonSize: {
    padding: 5,
    margin: '0 -5px -5px 5px',
  },
  storageSelect: {
    width: '100%',
  },
});

const versionOptions = [
  {key: 'all', text: 'All'},
  {key: 'current', text: 'Latest'},
  {key: 'nonCurrent', text: 'Previous'},
];

const DestinationsTip = () =>(
  <p>
    Select the destinations to store clones of the object data, in order of
    preference.
    <br/><br/>
    Select a destination count to have the system choose the required number
    of clones from the selected storage destinations. Use "All" to indicate
    every selected storage destination should have a clone of the object data.
    <br/><br/>
    Check the delete option to have the rule delete clone data from any
    storage not included in the selected storage destinations. Any new required
    clones will be created before the deletions.
  </p>
);

export const AfterTip = `
The number of days before the rule applies during daily processing. A value of
0 applies the rule on the next daily processing, which will be between 0 and 24
hours later. If left blank, the rule applies immediately, without waiting for
daily processing.
`;

export const IncludeTip = () => (
  <p>
      An optional regular expression that is applied to all objects. Only object
      names that match this regular expression are included in the rule. If left
      blank, all objects will be included.
    <br/><br/>
    <strong>Examples</strong><br/>
      Prefix: archive/images/production/:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>^archive/images/production/</i>
    <br/>
      Objects ending in .txt:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>\.txt$</i>
  </p>
);

export const ExcludeTip = () => (
  <span>
    An optional regular expression that is applied to all objects. All object
    names that match this regular expression are excluded from the rule. If
    left blank, no objects are excluded.
    <br/><br/>
    <strong>Examples</strong><br/>
    Prefix: archive/images/production/:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>^archive/images/production/</i>
    <br/>
    Objects ending in .txt:<br/>&nbsp;&nbsp;&nbsp;&nbsp;<i>\.txt$</i>
  </span>
);

export const NCVsTip = () => (
  <p>
      The number of non-current versions that should be kept and not expired.
      After this limit is reached, any excess non-current versions will be allowed to
      expire based on the "After" value that is set (starting with the oldest non-current
      versions). If for example this value is set to "1", then the most recent version of
      an object (the current version) will be kept along with the single most recent
      previous version of that object (the newest non-current version).
  </p>
);

const TransitionRule = ({ruleField, index, storage = [], onDelete}: Props) => {
  const classes = useStyles();
  const versionField = `${ruleField}.apply`;
  const applyAfterField = `${ruleField}.schedule.days`;
  const clonesField = `${ruleField}.destinations.count`;
  const storageField = `${ruleField}.destinations.storage`;
  const formState = useFormState();
  const deletionField = `${ruleField}.deletion`;
  const storageOptions = storage.filter((s) => (
    !s.readOnly
  )).map((s) => ({key: s.id, text: s.name}));
  const clonesOptions = [{key: '0', text: 'All'}];
  // No point displaying "All" and "1" when there's only one storage option
  // to select from.
  if (storageOptions.length > 1) {
    for (let i = 1; i < (storageOptions.length + 1); i++) {
      clonesOptions.push({key: i.toString(), text: i.toString()});
    }
  }

  const form = useForm();
  React.useEffect(() => {
    const rule = formState.values.rules[index];
    if (rule.apply === undefined) {
      form.mutators.setFormValue(versionField, versionOptions[0].key);
    }
    if (rule.destinations === undefined || rule.destinations.count == undefined) {
      form.mutators.setFormValue(clonesField, clonesOptions[0].key);
    }
  }, []);

  return (
    <li className={classes.listItem}>
      <NoteCard title='Placement Rule'>
        <Grid container spacing={2} className={classes.ruleInfo}>
          <Grid item xs={12}>
            <TextField label='Name' name={`${ruleField}.name`} />
          </Grid>
          <Grid item xs={12}>
            <FormMultiSelect className={classes.storageSelect}
              name={storageField}
              label='Select Destination Storage' options={storageOptions}
              sortable
              tooltip={<DestinationsTip />}
            />
          </Grid>
          <Grid item xs={9}>
            <Checkbox
              name={deletionField} label='Delete clones not on selected destination storage'
            />
          </Grid>
          <Grid item xs={3}>
            <FormSingleSelect name={clonesField} label='Destination Count'
              options={clonesOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label='Apply After' name={applyAfterField}
              InputProps={{endAdornment: <InputAdornment position='start'>days</InputAdornment>}}
              tooltip={AfterTip}
              parse={(value) => {
                if (value === '') {
                  return undefined;
                }
                return value;
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSingleSelect name={versionField} label='Versioning' options={versionOptions} />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Include' name={`${ruleField}.include`} tooltip={<IncludeTip />} />
          </Grid>
          <Grid item xs={11}>
            <TextField label='Exclude' name={`${ruleField}.exclude`} tooltip={<ExcludeTip />} />
          </Grid>
          <Grid item xs={1} className={classes.deleteButton}>
            <IconButton className={classes.buttonSize} onClick={onDelete} size='large'>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </NoteCard>
    </li>
  );
};

const mapStateToProps = (state: Store) => {
  const storageResource = state.resources.storage || {};
  return {
    storage: storageResource.data,
  };
};

export default connect(mapStateToProps)(TransitionRule);
