import React from 'react';
import {connect} from 'react-redux';

import {patchCollection} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {System} from '@/system/types';
import DialogDescription from '@/components/form/dialog_description';
import TextField from '@/components/form/text_field';

type Props = {
  title?: string;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
  form?: string;
  system: System;
}

const HostnameDialog = ({system, title = 'Edit Hostname', onClose, onSubmit, ...others}: Props) => {
  const initialValues = {name: system.name} as System;
  return (

    <FormDialog
      title={title} submitLabel='Save' onClose={onClose} onSubmit={onSubmit} closeOnSuccess
      initialValues={initialValues} {...others}
    >
      {() => (
        <>
          <DialogDescription>Configure your Hostname Settings</DialogDescription>
          <br/>
          <div>
            <TextField name='name' label='Hostname'/>
          </div>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const onSubmit = (values: System) => {
    const body = {'name': values.name};
    return dispatch(patchCollection('system', body));
  };
  return {onSubmit};
};

export default connect(undefined, mapDispatchToProps)(HostnameDialog);
