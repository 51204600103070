import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import {Checkbox, WizardPage} from 'spectra-logic-ui/components';
import {Store} from 'spectra-logic-ui';

import {Pool} from '@/types';
import {useFormState} from 'react-final-form';

type Props = {
  title: string;
  name: string;
  validate: (values: any) => object;
  chosenStorage?: string;
  pools?: Pool[];
}

const confirmAlt = ({title, name, pools = [], validate, ...otherProps}: Props) => {
  const formState = useFormState();
  const chosenStorage = formState.values.chosenStorage;

  const pool = pools.find((p) => p.id === chosenStorage) || {} as Pool;
  return (
    <WizardPage title={title} validate={validate} {...otherProps} description={
      <Typography>
      Are you sure you want to permanently delete '{name}'?
      </Typography>
    }>
      <Typography variant='subtitle1'>
        If any object has its only data clone on '{name}', a clone will be
        created on '{pool.name}' before the storage is deleted.
      </Typography>
      <Checkbox name='confirmCheck' label={`Yes, I want to permanently delete '${name}'.`} />
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const poolsResource = state.resources.storage || {};
  return {pools: poolsResource.data};
};
export default connect(mapStateToProps)(confirmAlt);
