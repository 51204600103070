import React from 'react';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {applyOpacity, Color, SpectraLogoColor} from 'spectra-logic-ui/colors';
import {Tooltip} from 'spectra-logic-ui/components';

type Props = {
  children?: string;
  tooltip?: string | JSX.Element;
  isShort?: boolean;
  icon?: any;
}
const boxShadow = `0 12px 20px -10px ${applyOpacity(SpectraLogoColor.BLUE, 28)}, ` +
  `0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${applyOpacity(SpectraLogoColor.BLUE, 20)}`;

const useStyles = (isShort: boolean) => makeStyles({
  root: {
    width: isShort ? 'fit-content' : '',
    color: Color.WHITE,
    background: `linear-gradient(60deg, ${SpectraLogoColor.BLUE}, ${SpectraLogoColor.BLUE})`,
    boxShadow: boxShadow,
    margin: '0 15px',
    position: 'relative',
    borderRadius: '3px',
    marginTop: '-20px',
    padding: '15px',
    zIndex: 100,
  },
  title: {
    display: 'inline',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  icon: {
    display: 'inline',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'bottom',
  },
  tooltipIcon: {
    height: '18px !important',
    color: `${Color.WHITE} !important`,
  },
});

const CardHeader = (props: Props) => {
  const {icon: Icon, tooltip = '', isShort = false, children} = props;
  const classes = useStyles(isShort)();
  return (
    <div className={classes.root}>
      {Icon && <Icon className={classes.icon} />}
      <Typography className={classes.title}>{children}</Typography>
      {tooltip !== '' &&
        <Tooltip
          className={classes.tooltipIcon}
          transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
        >
          {tooltip}
        </Tooltip>
      }
    </div>
  );
};

export default CardHeader;
