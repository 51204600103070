import React from 'react';

import {Location, LocationCapacityMap} from '@/types';
import Selector from '@/components/selector';

type Props = {
  locations: Location[];
  capacityMap?: LocationCapacityMap;
  cloudLocations?: Location[];
  onSelect: Function;
}

const createLocationItems = (locations: Location[]) => {
  return locations
    .filter((location) => location.id !== '0')
    .map((l) => (
      <Selector.Item key={l.id} id={l.id} value={l.name}/>
    ));
};

const createCloudLocationItems = (locations: Location[]) => {
  return locations.map(((l) => <Selector.Item key={l.id} id={l.id} value={l.name}/>));
};

const LocationSelector = (props: Props) => {
  const {locations = [], cloudLocations = [], onSelect} = props;

  if (locations.length === 0 && cloudLocations.length === 0) {
    return null;
  }
  const locationSelectorItems = createLocationItems(locations);
  const cloudSelectorItems = createCloudLocationItems(cloudLocations);
  const selectorItems = locationSelectorItems.concat(cloudSelectorItems);
  return <Selector onSelect={onSelect}>{selectorItems}</Selector>;
};

export default LocationSelector;
