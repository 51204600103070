import React from 'react';
import {Divider, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {StorageUsed} from '@/types';
import StorageChart from '@/components/storage_chart';

type Props = {
  summary: StorageUsed[];
}

const useStyles = makeStyles({
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const OnPremiseStorageCharts = ({summary}: Props) => {
  if (summary == null) {
    return null;
  }

  const summaryToShow: StorageUsed[] = [];
  summary.forEach((cap) => {
    if (cap.label !== undefined && cap.used !== undefined && cap.total !== undefined) {
      summaryToShow.push(cap);
    }
  });
  if (summaryToShow.length === 0) {
    return null;
  }

  const classes = useStyles();

  return (
    <div>
      <Grid container>
        {summaryToShow.map((cap) => (
          <Grid item key={cap.label} xs={6} sm={3} md={3} lg={2}>
            <StorageChart heading={cap.label === undefined ? '' : cap.label}
              optional={cap.optional || 0} used={cap.used || 0} total={cap.total || 0} />
          </Grid>
        ))}
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};

export default OnPremiseStorageCharts;
