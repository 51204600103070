import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';

import {AuditLog} from '@/reports/audits/types';

type Props = {
  log: AuditLog;
}

const useStyles = makeStyles({
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
  tableRow: {
    '& > *': {
      overflowWrap: 'break-word',
    },
  },
});

const Properties = ({log}: Props) => {
  const classes = useStyles();
  const requestData = (
    <pre className={classes.preWrap}>
      {JSON.stringify(log.request?.data, null, 2)}
    </pre>
  );

  const userProps = [
    {key: 'Description', value: log.message},
    {key: 'User', value: log.username},
    {key: 'Request Path', value: log.request?.path},
    {key: 'Request Method', value: log.request?.method},
    {key: 'Request Data', value: requestData},
    {key: 'Request Timestamp', value: dateTimeLong(log.request?.time)},
    {key: 'Resource ID', value: log.resource?.id},
    {key: 'Resource Name', value: log.resource?.name},
    {key: 'Host IP', value: log.hostIP},
    {key: 'Client IP', value: log.clientIP},
    {key: 'Server', value: log.server},
  ];

  return (
    <Table size='small'>
      <Table.Body>
        {userProps.map((kv) => (
          <Table.Row key={kv.key} className={classes.tableRow}>
            <Table.Cell>{kv.key}</Table.Cell>
            <Table.Cell>{kv.value}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default Properties;
