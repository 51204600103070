import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {TextField as SLTextField, Tooltip} from 'spectra-logic-ui/components';

type Props = {
  name: string;
  label: string;
  type?: string;
  helperText?: string;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  className?: string;
  defaultValue?: any;
  parse?: (value: any, name: string) => any;
  widthPercent?: number;
  inputRef?: any;
  InputProps?: object;
  multiline?: boolean;
  rows?: string | number;
  maxRows?: string | number;
  variant?: 'filled' | 'outlined' | 'standard';
  margin?: 'dense' | 'none' | 'normal';
}

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    width: '100%',
  },
  textField: {
    display: 'flex',
    flex: '1 1 auto',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginLeft: 2,
  },
});

const TextField = (props: Props) => {
  const {name, label, type, disabled, maxRows, rows, variant='standard',
    multiline, tooltip, className, parse, inputRef, InputProps, helperText} = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SLTextField
        name={name}
        label={label}
        rows={rows}
        maxRows={maxRows}
        disabled={disabled}
        type={type}
        parse={parse}
        inputRef={inputRef}
        InputProps={{autoComplete: 'off', ...InputProps}}
        fullWidth
        helperText={helperText}
        multiline={multiline}
        variant={variant}
        className={`${classes.textField} ${className}`}
      />
      {tooltip &&
        <div className={classes.tooltip}>
          <Tooltip>{tooltip}</Tooltip>
        </div>
      }
    </div>
  );
};

export default TextField;
