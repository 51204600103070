import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import {MenuItem, Select} from '@mui/material';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import AccountIcon from 'spectra-logic-ui/icons/AccountCircle';
import PeopleIcon from 'spectra-logic-ui/icons/People';

import {Account} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import {IAMGroup} from '@/iam/types';
import CreateIamGroupDialog from '@/iam/groups/form/create';
import {useIAMStyles} from '@/iam/users';

type Props = {
  accounts?: Account[];
  selectedGroup?: IAMGroup;
  openDrawer: (...args: any) => any;
  closeDrawer: (...args: any) => any;
  fetchAccounts?: () => Promise<any>;
  fetchGroups?: (account: string) => Promise<any>;
  selectGroup: (...args: any) => any;
}

const IAMGroups = ({accounts = [], selectedGroup = {} as IAMGroup, openDrawer, closeDrawer, fetchAccounts, fetchGroups,
  selectGroup}: Props) => {
  const [selectedAccount, setSelectedAccount] = React.useState<string>();
  const classes = useIAMStyles();

  React.useEffect(() => {
    if (fetchAccounts !== undefined) {
      fetchAccounts();
    }
  }, []);

  React.useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setSelectedAccount(accounts[0].id);
      if (fetchGroups) {
        fetchGroups(accounts[0].id);
      }
    }
  }, [accounts]);

  const selectAccount = (event: any) => {
    setSelectedAccount(event.target.value);
    if (fetchGroups) {
      fetchGroups(event.target.value);
    }
  };

  const onCreate = () => {
    if (fetchGroups && selectedAccount) {
      fetchGroups(selectedAccount);
    }
  };

  const onDelete = () => {
    closeDrawer();
    selectGroup({} as IAMGroup);
    if (fetchGroups && selectedAccount) {
      fetchGroups(selectedAccount);
    }
  };

  return (
    <Card>
      <CardHeader
        icon={PeopleIcon} tooltip='All AWS accounts must have the correct role permissions enabled to list IAM groups.'
      >
        IAM Groups
      </CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.CreateDialogButton
            disabled={isEmpty(selectedAccount)} onSuccess={onCreate}
            dialog={(props: any) => (
              <CreateIamGroupDialog account={selectedAccount} {...props} />
            )}
          />
          <ButtonToolbar.DeleteDialogButton
            disabled={isEmpty(selectedGroup)} onSuccess={onDelete}
            dialog={(props: any) => (
              <DeleteDialog
                id={selectedGroup.name} name={selectedGroup.name}
                title='Delete IAM Group'
                resource={`iam/groups/${selectedAccount || ''}`} {...props}
              />
            )}
          />
          <span className={classes.accountSelect} title='IAM Account'>
            <AccountIcon className={classes.icon} color='secondary' />
            {
              selectedAccount ?
                <Select
                  disabled={accounts.length < 2}
                  value={selectedAccount}
                  variant='standard'
                  onChange={(event: any) => selectAccount(event)}>
                  {accounts.map((account: Account) => (
                    <MenuItem id={account.id} key={account.id} value={account.id}>
                      {account.username || account.id}
                    </MenuItem>
                  ))}
                </Select> :
                <Select disabled displayEmpty value={''}>
                  <MenuItem value='' disabled>Loading</MenuItem>
                </Select>
            }
          </span>
        </ButtonToolbar>
        <IAMGroupsTable
          account={selectedAccount || ''} selectedGroup={selectedGroup} selectGroup={selectGroup}
          openDrawer={openDrawer}
        />
      </Card.Body>
    </Card>
  );
};

const mapStateToIAMUsersProps = (state: Store) => {
  const accountsResource = state.resources.accounts || {};
  return {
    accounts: accountsResource.data,
  };
};

const mapDispatchToIAMUsersProps = (dispatch: Dispatch) => ({
  fetchAccounts: () => dispatch(fetchResource('accounts')),
  fetchGroups: (account: string) => dispatch(fetchResource(`iam/groups/${account}`)),
});

export default connect(mapStateToIAMUsersProps, mapDispatchToIAMUsersProps)(IAMGroups);

type TableProps = {
  account: string;
  groups?: IAMGroup[];
  error?: boolean;
  fetching?: boolean;
  openDrawer: (...args: any) => any;
  selectedGroup: IAMGroup;
  selectGroup: (...args: any) => any;
}

const RawIAMGroupsTable = (props: TableProps) => {
  const {groups = [], error = false, fetching = false, openDrawer, selectedGroup, selectGroup} = props;

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Cell>Name</Table.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body isLoading={fetching} hasError={error}>
        {groups && groups.map((group) => (
          <Table.Row
            key={group.accountid+'-'+group.name}
            selected={group.name === selectedGroup.name}
            onClick={() => selectGroup(group)}
          >
            <Table.Cell>{group.name}</Table.Cell>
            <Table.CellDetailsButton
              onClick={() => {
                selectGroup(group);
                openDrawer();
              }}
            />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const mapStateToTableProps = (state: Store, {account}: TableProps) => {
  if (!account) {
    // Still loading accounts
    return {groups: [], error: false, fetching: true};
  }

  const iamGroupsState = state.resources[`iam/groups/${account}`] || {};
  const iamGroups = iamGroupsState.data || [];
  return {
    groups: iamGroups,
    error: iamGroupsState.error,
    fetching: iamGroupsState.fetching,
  };
};

const IAMGroupsTable = connect(mapStateToTableProps)(RawIAMGroupsTable);
