import React from 'react';
import {connect} from 'react-redux';

import {Dialog} from '@mui/material';

import {updateResource} from 'spectra-logic-ui/actions';
import {DialogHeader} from 'spectra-logic-ui/components/form_dialog';
import {Dispatch} from 'spectra-logic-ui';

import VerifyCloneForm from '@/buckets/objects/details/verify_form';
import {Object, VerificationResult} from '@/buckets/objects/types';

type Props = {
  id: string;
  bucketName: string;
  object: Object;
  disabled?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => Promise<any>;
}

const VerifyCloneDialog = ({open, disabled = false, onClose, onSubmit}: Props) => {
  const [verificationResult, setVerificationResult] = React.useState({} as VerificationResult);
  const wrappedSubmit = (values: any) => {
    return new Promise((resolve, reject) => {
      if (onSubmit === undefined) {
        return null;
      }
      onSubmit(values).then((response: any) => {
        resolve(response);
        response.data.submitted = true;
        setVerificationResult(response.data);
      }, (error) => reject(error));
    });
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogHeader onClose={onClose}>{verificationResult.submitted ?
        'Verification Complete' : 'Verify Clone'}</DialogHeader>
      <VerifyCloneForm disabled={disabled} verificationResult={verificationResult} onClose={onClose}
        onSubmit={wrappedSubmit} />
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {id, bucketName, object}: Props) => {
  const resource = `buckets/${bucketName}/clones/${id}`;
  const body = {'full': true, 'alert': false};
  const query = {
    object: object.key,
    version: object.versionId !== undefined ? object.versionId : '',
  };
  return {
    onSubmit: () => dispatch(updateResource(resource, 'verify', body, {query: query})),
  };
};

export default connect(null, mapDispatchToProps)(VerifyCloneDialog);
