import {ValidationError} from '@/validate';
import {FormValues} from '@/login/software_token_mfa/types';

export const validate = ({softwareTokenMfaCode}: FormValues) => {
  const errors = {} as any;

  if (!softwareTokenMfaCode) {
    errors.softwareTokenMfaCode = ValidationError.CANNOT_BE_BLANK;
  }

  return errors;
};
