import {ValidationError} from '@/validate';
import {FormValues} from '@/administrators/form/edit/types';

export const validatePassword = ({password, passwordCheck}: FormValues) => {
  const errors = {} as any;

  if (!password) {
    errors.password = ValidationError.CANNOT_BE_BLANK;
  } else if (password.length < 6) {
    errors.password = 'Must contain at least 6 characters';
  }

  if (!passwordCheck) {
    errors.passwordCheck = ValidationError.CANNOT_BE_BLANK;
  } else if (passwordCheck !== password) {
    errors.passwordCheck = 'Passwords do not match';
  }
  return errors;
};
