import React from 'react';
import {useNavigate} from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import {loginStyles} from 'spectra-logic-ui/components/login';

import ForgotPasswordForm from '@/login/forgot_password/form';
import ConfirmForgotPasswordForm from '@/login/forgot_password/form/confirm';

const useStyles = makeStyles(loginStyles);

const ForgotPasswordPage = () => {
  const [username, setUsername] = React.useState('');
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.page}>
      {username === '' ?
        <ForgotPasswordForm setUsername={setUsername} /> :
        <ConfirmForgotPasswordForm navigate={navigate} username={username} />
      }
    </div>
  );
};

export default ForgotPasswordPage;
