import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import {Grid} from '@mui/material';

import {createPatch, patchResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {Proxy} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';
import {ProxyTypes} from '@/network/form/proxy_create';
import {ProxyFormData} from '@/network/form/types';
import {validateProxy} from '@/network/form/validate';

type Props = {
  proxy: Proxy;
  onSubmit: (body: ProxyFormData) => Promise<any>;
}

export const ProxyPatchFields = [
  'hostname',
  'port',
  'username',
  'password',
];

const EditProxyDialog = ({proxy, ...others}: Props) => {
  return (
    <FormDialog title='Edit Proxy Configuration' initialValues={proxy} validate={validateProxy} {...others}>
      {() => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormSingleSelect name='id' label='Select Proxy Type' options={ProxyTypes} disabled={true} />
            </Grid>
            <Grid item xs={8}>
              <TextField name='hostname' label='Hostname' />
            </Grid>
            <Grid item xs={4}>
              <TextField name='port' label='Port' />
            </Grid>
            <Grid item xs={6}>
              <TextField name='username' label='Username' />
            </Grid>
            <Grid item xs={6}>
              <TextField name='password' label='Password' type='password' />
            </Grid>
          </Grid>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {proxy}: Props) => ({
  onSubmit: (values: ProxyFormData) => {
    const body: Proxy = {hostname: values.hostname, username: values.username, password: values.password};
    if (isEmpty(values.username) && isEmpty(values.password)) {
      // The password field isn't populated on edit because it's not sent in the REST API.
      // Leaving it blank would result in the server not changing it (as opposed to deleting it).
      // If a user is trying to remove the username, they'd also have to remove the password
      // but our GUI doesn't provide an explicit way to do that (because you can't leave the username
      // while just deleting the password). Thus, explicitly send a blank password so the server deletes
      // both the username and password.
      body.password = '';
    }
    if (values.port !== undefined && values.port !== null && values.port !== '') {
      body.port = Number(values.port);
    }
    const upd = createPatch(ProxyPatchFields, proxy, body);
    return dispatch(patchResource('proxy', values.id, upd));
  },
});

export default connect(undefined, mapDispatchToProps)(EditProxyDialog);
