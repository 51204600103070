import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Table from 'spectra-logic-ui/components/table';

import {storageClassString, TargetType} from '@/enum';
import {Pool} from '@/types';
import {StorageRowId} from '@/storage/types';

type Props = {
  pools: Pool[];
  selectedRowId: StorageRowId;
  selectRowId: Function;
  setOpenDetails: Function;
}

const useStyles = makeStyles({
  row: {},
  name: {
    paddingLeft: '50px',
  },
  nowrap: {'whiteSpace': 'nowrap'},
});

export const createTypeDisplayName = (pool: Pool) => {
  let name = '--';
  switch (pool.target) {
  case TargetType.BLACKPEARL:
  case TargetType.BPLINK:
  case TargetType.BPPOLICY:
    if (pool.archival) {
      name = 'Tape';
    } else {
      name = 'Disk Pool';
    }
    break;
  case TargetType.S3:
    name = 'Amazon S3';
    break;
  case TargetType.S3OTHER:
    name = 'Other S3';
    break;
  case TargetType.AZURE:
    name = 'Azure';
    break;
  case TargetType.GOOGLE:
    name = 'Google';
    break;
  case TargetType.VOLUME:
    name = 'Volume';
    break;
  }

  if (name !== '--' && pool.link) {
    name += ' (Linked)';
  }

  return name;
};

const StorageRows = ({pools = [], selectRowId, selectedRowId, setOpenDetails}: Props) => {
  const classes = useStyles();

  const statusName = (status?: string) => {
    let poolStatus = status ? status.replace(/^\w/, (c) => c.toUpperCase()) : '';
    if (poolStatus === 'Ok') {
      poolStatus = 'OK';
    }
    return poolStatus;
  };

  const sortedPools = pools.slice();
  sortedPools.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <React.Fragment>
      {sortedPools.map((pool) => (
        <Table.Row className={classes.row}
          key={pool.id} selected={selectedRowId.poolId === pool.id}
          onClick={() => selectRowId({poolId: pool.id})}
        >
          <Table.Cell className={classes.name}>
            {pool.name}
          </Table.Cell>
          <Table.Cell className={classes.nowrap}>{createTypeDisplayName(pool)}</Table.Cell>
          <Table.Cell className={classes.nowrap}>
            {storageClassString(pool.storageClass)}
          </Table.Cell>
          <Table.Cell className={classes.nowrap}>{statusName(pool.status)}</Table.Cell>
          <Table.CellDetailsButton onClick={() => setOpenDetails()} />
        </Table.Row>
      ))}
    </React.Fragment>
  );
};

export default StorageRows;
