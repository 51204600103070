import React from 'react';

import {Typography, Card} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {Color} from 'spectra-logic-ui/colors';
import {TextField, WizardPage} from 'spectra-logic-ui/components';

type Props = {
  title: string;
  name: string;
  validate: (values: any) => object;
}

const useStyles = makeStyles({
  warningText: {
    color: Color.ERROR,
    fontWeight: 'bold',
  },
  underlinedText: {
    textDecoration: 'underline',
  },
  confirmationCard: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
});

export default ({title, name, validate, ...otherProps}: Props) => {
  const classes = useStyles();
  return (
    <WizardPage title={title} validate={validate} {...otherProps} description={
      <Typography>
        Are you sure you want to permanently delete '{name}'?
      </Typography>
    }>
      <Typography variant='subtitle1' className={classes.warningText}>
        <span className={classes.underlinedText}>ALL DATA</span> on storage '{name}' will be deleted.
        If there are objects unique to this storage, this action results in data loss.
      </Typography>
      <Card className={classes.confirmationCard} raised>
        <Typography gutterBottom>
          Enter the storage name below to verify your intent to permanently delete '{name}'.
        </Typography>
        <TextField name='confirmedName' label='Confirmed Storage Name' fullWidth />
      </Card>
    </WizardPage>
  );
};
