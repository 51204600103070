import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Field, Form, FormRenderProps} from 'react-final-form';
import isEmpty from 'is-empty';
import {Grid} from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';

import {Dispatch, RequestOptions, Store} from 'spectra-logic-ui';
import {changeUIState, fetchResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {SlidePanel, Table, Toolbar} from 'spectra-logic-ui/components';
import Card from 'spectra-logic-ui/components/card';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import AssignmentIcon from 'spectra-logic-ui/icons/Assignment';

import CardHeader from '@/components/card_header';
import TextField from '@/components/form/text_field';
import Paginator from '@/components/paginator';
import PaginatorFooter from '@/components/paginator_footer';
import PropertiesDetails from '@/reports/audits/properties';
import {AuditLog} from '@/reports/audits/types';

type Props = {
  auditLogs?: AuditLog[];
  fetching?: boolean;
  error?: boolean;
  fetchAuditLogs?: (filterState: FilterState, marker: string) => void;
  filterState: FilterState;
  marker: string;
  maxKeys: number;
  nextMarker: string;
  isTruncated: boolean;
}

type FilterState = {
  username?: string;
  start?: Date;
  end?: Date;
}

const useStyles = makeStyles({
  filters: {
    margin: 10,
    padding: 15,
    background: Color.GRAY_LIGHT,
    borderRadius: 6,
  },
  form: {
    display: 'inline',
  },
  filler: {
    flexGrow: 1,
  },
  nowrap: {'whiteSpace': 'nowrap'},
});

export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
};

const filterStateID = 'auditsFilter';
const maxObjectsPerPage = '300';

const Audits = (props: Props) => {
  const {auditLogs = [], fetching = false, error = false, fetchAuditLogs, marker,
    maxKeys, nextMarker, isTruncated, filterState} = props;
  const [typingTimeout, setTypingTimeout] = React.useState(0);
  const [selectedAuditLogId, setSelectedAuditLogId] = React.useState('');
  const [paginatorFrom, setPaginatorFrom] = React.useState(1);
  const [prevMarkers, setPrevMarkers] = React.useState([] as string[]);
  const [isStartValid, setIsStartValid] = React.useState(true);
  const [isEndValid, setIsEndValid] = React.useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const clearSelectedAuditLog = () => setSelectedAuditLogId('');

  useEffect(() => {
    setPaginatorFrom(1);
    setPrevMarkers([]);
    if (fetchAuditLogs !== undefined && isStartValid && isEndValid) {
      clearTimeout(typingTimeout);
      setTypingTimeout(window.setTimeout(() => {
        fetchAuditLogs(filterState, '');
      }, 500));
    }
  }, [filterState, isStartValid, isEndValid]);

  const selectedAuditLog = auditLogs.find((l) => l.id === selectedAuditLogId) || {} as AuditLog;

  const nextPage = () => {
    if (!fetching) {
      if (auditLogs) {
        setPaginatorFrom(paginatorFrom+auditLogs.length);
      }
      if (marker) {
        prevMarkers.push(marker);
        setPrevMarkers(prevMarkers);
      }
      if (fetchAuditLogs !== undefined) {
        fetchAuditLogs(filterState, nextMarker);
      }
    }
  };

  const prevPage = () => {
    if (!fetching) {
      if (auditLogs) {
        setPaginatorFrom(paginatorFrom-maxKeys);
      }
      const prevMarker = prevMarkers.pop() || '';
      if (fetchAuditLogs !== undefined) {
        fetchAuditLogs(filterState, prevMarker);
      }
    }
  };

  // The DesktopDatePicker error handling nonsense is to work around an annoyance where
  // it treats a blank date as an error.
  return (
    <Card>
      <CardHeader icon={AssignmentIcon}>Audit Log</CardHeader>
      <Card.Body>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form className={classes.form} onSubmit={() => {}}>
            {({handleSubmit, values}: FormRenderProps) => {
              useEffect(() => {
                dispatch(changeUIState(filterStateID, values));
              }, [values]);
              return (
                <form onSubmit={handleSubmit}>
                  <Toolbar variant='dense' className={classes.filters}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextField name='username' label='User Name' />
                      </Grid>
                      <Grid item>
                        <Field
                          name='start'
                          render={({input}) => (
                            <DesktopDatePicker
                              inputFormat='MM/dd/yyyy'
                              label='Start Date'
                              maxDate={filterState.end}
                              renderInput={({error, inputProps, ...params}) => {
                                useEffect(() => {
                                  setIsStartValid(inputProps ? (inputProps.value ? !error : true) : true);
                                }, [inputProps, error]);
                                return <MuiTextField
                                  variant='standard' error={inputProps ? (inputProps.value ? error : false) : false}
                                  inputProps={inputProps} {...params}
                                />;
                              }}
                              {...input}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name='end'
                          render={({input: {onChange, ...others}}) => (
                            <DesktopDatePicker
                              inputFormat='MM/dd/yyyy'
                              label='End Date'
                              minDate={filterState.start}
                              renderInput={({error, inputProps, ...params}) => {
                                useEffect(() => {
                                  setIsEndValid(inputProps ? (inputProps.value ? !error : true) : true);
                                }, [inputProps, error]);
                                return <MuiTextField
                                  variant='standard' error={inputProps ? (inputProps.value ? error : false) : false}
                                  inputProps={inputProps} {...params}
                                />;
                              }}
                              {...others}
                              onChange={(end) => {
                                if (end instanceof Date) {
                                  onChange(new Date(end.setUTCHours(23, 59, 59, 999)));
                                } else {
                                  onChange(end);
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <span className={classes.filler}/>
                    <Paginator
                      prev={prevPage}
                      from={paginatorFrom}
                      to={auditLogs != null ? paginatorFrom + auditLogs.length - 1 : 0}
                      next={nextPage}
                      showNext={isTruncated}
                    />
                  </Toolbar>
                </form>
              );
            }}
          </Form>
        </LocalizationProvider>
        <Table size='small'>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell className={classes.nowrap}>User</Table.Cell>
              <Table.Cell className={classes.nowrap}>Time</Table.Cell>
              <Table.Cell> </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            {auditLogs?.map((log) => (
              <Table.Row key={log.id}>
                <Table.Cell>{log.message}</Table.Cell>
                <Table.Cell className={classes.nowrap}>{log.username}</Table.Cell>
                <Table.Cell className={classes.nowrap}>{dateTimeLong(log.request?.time)}</Table.Cell>
                <Table.CellDetailsButton onClick={() => setSelectedAuditLogId(log.id)} />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <PaginatorFooter
          prev={prevPage}
          from={paginatorFrom}
          to={auditLogs ? paginatorFrom+auditLogs.length-1 : 0}
          next={nextPage}
          showNext={isTruncated}
        />
        <SlidePanel
          title='Audit Log' options={['Properties']} open={!isEmpty(selectedAuditLogId)} onClose={clearSelectedAuditLog}
        >
          <PropertiesDetails log={selectedAuditLog} />
        </SlidePanel>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const auditResource = state.resources['reports/audit'] || {};
  const filterState = state.ui[filterStateID] || {};
  const auditLogs = auditResource.data || [];
  return {
    auditLogs: auditLogs,
    error: auditResource.error,
    fetching: auditResource.fetching,
    filterState: filterState,
    marker: auditResource.marker || '',
    maxKeys: auditResource.maxKeys || 0,
    isTruncated: auditResource.isTruncated || false,
    nextMarker: auditResource.nextMarker || '',
  };
};

const fetchAuditLogs = (filterState: FilterState, marker?: string) => {
  const query: {[name: string]: any} = {'max-keys': maxObjectsPerPage};
  if (marker) {
    query.marker = marker;
  }
  if (filterState.username) {
    query.username = filterState.username;
  }
  if (filterState.start) {
    query.start = filterState.start.toISOString();
  }
  if (filterState.end) {
    query.end = filterState.end.toISOString();
  }
  const options = {query} as RequestOptions;
  return fetchResource('reports/audit', '', options);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAuditLogs: (filterState: FilterState, marker: string) => {
    dispatch(fetchAuditLogs(filterState, marker));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Audits);

// handleAuditsPubsubEvent is a pub/sub handler for the messages channel. It
// fetches the audits using the same UI filter state that this page uses.
// This prevents the displayed audits from changing if a pub/sub event comes in
// while on the page.
export const handleAuditsPubsubEvent = (event: any, dispatch: Dispatch, state: Store) => {
  const filterState = state.ui[filterStateID] || {};
  dispatch(fetchAuditLogs(filterState));
};
