import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import makeStyles from '@mui/styles/makeStyles';
import CachedIcon from 'spectra-logic-ui/icons/Cached';
import PeopleIcon from 'spectra-logic-ui/icons/People';
import AccountIcon from 'spectra-logic-ui/icons/AccountCircle';
import {MenuItem, Select} from '@mui/material';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';

import {Account} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import {IAMUser} from '@/iam/types';
import ClearIamUserCacheDialog from '@/iam/users/form/clear';
import CreateIamUserDialog from '@/iam/users/form/create';

type Props = {
  accounts?: Account[];
  selectedUser?: IAMUser;
  fetchAccounts?: () => Promise<any>;
  fetchUsers?: (account: string) => Promise<any>;
  openDrawer: (...args: any) => any;
  closeDrawer: (...args: any) => any;
  selectUser: (...args: any) => any;
}

export const useIAMStyles = makeStyles({
  icon: {
    marginRight: '5px',
    verticalAlign: 'middle',
  },
  accountSelect: {
    textAlign: 'right',
    width: '100%',
    whiteSpace: 'nowrap',
  },
});

const IAMUsers = ({accounts = [], selectedUser = {} as IAMUser, fetchAccounts, fetchUsers, openDrawer, closeDrawer,
  selectUser}: Props) => {
  const [selectedAccount, setSelectedAccount] = React.useState<string>();
  const classes = useIAMStyles();

  React.useEffect(() => {
    if (fetchAccounts !== undefined) {
      fetchAccounts();
    }
  }, []);

  React.useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setSelectedAccount(accounts[0].id);
      if (fetchUsers) {
        fetchUsers(accounts[0].id);
      }
    }
  }, [accounts]);

  const selectAccount = (event: any) => {
    setSelectedAccount(event.target.value);
    if (fetchUsers) {
      fetchUsers(event.target.value);
    }
  };

  const onClearCache = () => {
    closeDrawer();
    selectUser({} as IAMUser);
    if (fetchUsers && selectedAccount) {
      fetchUsers(selectedAccount);
    }
  };

  const onCreate = () => {
    if (fetchUsers && selectedAccount) {
      fetchUsers(selectedAccount);
    }
  };

  const onDelete = () => {
    closeDrawer();
    selectUser({} as IAMUser);
    if (fetchUsers && selectedAccount) {
      fetchUsers(selectedAccount);
    }
  };

  return (
    <Card>
      <CardHeader
        icon={PeopleIcon} tooltip='All AWS accounts must have the correct role permissions enabled to list IAM users.'
      >
        IAM Users
      </CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.DialogButton
            icon={CachedIcon} title='Clear Cache' color='primary'
            dialog={ClearIamUserCacheDialog} onSuccess={onClearCache}
          />
          <ButtonToolbar.CreateDialogButton
            disabled={isEmpty(selectedAccount)} onSuccess={onCreate}
            dialog={(props: any) => (
              <CreateIamUserDialog account={selectedAccount} {...props} />
            )}
          />
          <ButtonToolbar.DeleteDialogButton
            disabled={isEmpty(selectedUser)} onSuccess={onDelete}
            dialog={(props: any) => (
              <DeleteDialog
                id={selectedUser.username} name={selectedUser.username}
                title='Delete IAM User'
                resource={`iam/users/${selectedAccount || ''}`} {...props}
              />
            )}
          />
          <span className={classes.accountSelect} title='IAM Account'>
            <AccountIcon className={classes.icon} color='secondary' />
            {
              selectedAccount ?
                <Select
                  disabled={accounts.length < 2}
                  value={selectedAccount}
                  variant='standard'
                  onChange={(event: any) => selectAccount(event)}>
                  {accounts.map((account: Account) => (
                    <MenuItem id={account.id} key={account.id} value={account.id}>
                      {account.username || account.id}
                    </MenuItem>
                  ))}
                </Select> :
                <Select disabled displayEmpty value={''}>
                  <MenuItem value='' disabled>Loading</MenuItem>
                </Select>
            }
          </span>
        </ButtonToolbar>
        <IAMUsersTable
          account={selectedAccount || ''} selectedUser={selectedUser} selectUser={selectUser} openDrawer={openDrawer}
        />
      </Card.Body>
    </Card>
  );
};

const mapStateToIAMUsersProps = (state: Store) => {
  const accountsResource = state.resources.accounts || {};
  return {
    accounts: accountsResource.data,
  };
};

const mapDispatchToIAMUsersProps = (dispatch: Dispatch) => ({
  fetchAccounts: () => dispatch(fetchResource('accounts')),
  fetchUsers: (account: string) => dispatch(fetchResource(`iam/users/${account}`)),
});

export default connect(mapStateToIAMUsersProps, mapDispatchToIAMUsersProps)(IAMUsers);

type TableProps = {
  account: string;
  users?: IAMUser[];
  error?: boolean;
  fetching?: boolean;
  selectedUser: IAMUser;
  openDrawer: (...args: any) => any;
  selectUser: (...args: any) => any;
}

const RawIAMUsersTable = (
  {users = [], error = false, fetching = false, selectedUser, selectUser, openDrawer}: TableProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.Cell>Username</Table.Cell>
          <Table.Cell> </Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body isLoading={fetching} hasError={error}>
        {users && users.map((user) => (
          <Table.Row
            key={user.accountid+'-'+user.username}
            selected={user.username === selectedUser.username}
            onClick={() => selectUser(user)}
          >
            <Table.Cell>{user.username}</Table.Cell>
            <Table.CellDetailsButton
              onClick={() => {
                selectUser(user);
                openDrawer();
              }}
            />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const mapStateToTableProps = (state: Store, {account}: TableProps) => {
  if (!account) {
    // Still loading accounts
    return {users: [], error: false, fetching: true};
  }

  const iamUsersState = state.resources[`iam/users/${account}`] || {};
  const iamUsers = iamUsersState.data || [];
  return {
    users: iamUsers,
    error: iamUsersState.error,
    fetching: iamUsersState.fetching,
  };
};

const IAMUsersTable = connect(mapStateToTableProps)(RawIAMUsersTable);
