import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Article from 'spectra-logic-ui/icons/ArticleOutlined';
import Clear from 'spectra-logic-ui/icons/ClearOutlined';
import Folder from 'spectra-logic-ui/icons/FolderOutlined';
import SubdirectoryRight from 'spectra-logic-ui/icons/SubdirectoryRight';

const useStyles = makeStyles({
  root: {
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
  },
  padding: {
    paddingRight: '5px',
  },
});

export function DeleteMarkerIcon() {
  const classes = useStyles();
  return <Clear className={classes.root + ' ' + classes.padding} />;
}

export function FileIcon() {
  const classes = useStyles();
  return <Article className={classes.root + ' ' + classes.padding} />;
}

export function FolderIcon() {
  const classes = useStyles();
  return <Folder className={classes.root + ' ' + classes.padding} />;
}

export function NotLatestIcon() {
  const classes = useStyles();
  return <SubdirectoryRight className={classes.root} />;
}
