import React, {memo, useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Card, SlidePanel, Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import FolderIcon from 'spectra-logic-ui/icons/Folder';
import UpdateAltIcon from 'spectra-logic-ui/icons/SystemUpdateAlt';

import {TargetType} from '@/enum';
import {Account, Bucket, Lifecycle, Pool} from '@/types';
import CardHeader from '@/components/card_header';
import ButtonToolbar from '@/components/button_toolbar';
import DeleteDialog from '@/components/delete_dialog';
import AclsDetails from '@/buckets/details/acls';
import PolicyDetails from '@/buckets/details/policy';
import PropertiesDetails from '@/buckets/details/properties';
import MetricsDetails from '@/buckets/details/metrics';
import CreateBucketWizard from '@/buckets/form/create';
import EditBucketWizard from '@/buckets/form/edit';
import ScanBucketDialog from '@/buckets/form/scan';

type Props = {
  error?: boolean;
  fetching?: boolean;
  buckets?: Bucket[];
  accounts?: Account[];
  lifecycles?: Lifecycle[];
  pools?: Pool[];
  fetchBuckets?: () => Promise<any>;
  fetchAccounts?: () => Promise<any>;
  fetchLifecycles?: () => Promise<any>;
  fetchPools?: () => Promise<any>;
}

type PoolsMap = Record<Pool['id'], Pool>;
type LifecyclesMap = Record<Lifecycle['id'], Lifecycle['name']>;
type AccountsMap = Record<Account['canonicalId'], Account>;

const Buckets = (props: Props) => {
  const {error = false, fetching = false, buckets = [], accounts = [], pools = [],
    lifecycles = [], fetchBuckets, fetchAccounts, fetchLifecycles, fetchPools} = props;
  const [selectedBucketName, setSelectedBucketName] = React.useState('');
  const [isOpenDetails, setOpenDetails] = React.useState(false);

  const closeDetails = () => setOpenDetails(false);
  const clearBucket = () => {
    setSelectedBucketName('');
    setOpenDetails(false);
  };

  useEffect(() => {
    if (fetchBuckets !== undefined) {
      fetchBuckets();
    }
    if (fetchAccounts !== undefined) {
      fetchAccounts();
    }
    if (fetchPools !== undefined) {
      fetchPools();
    }
    if (fetchLifecycles !== undefined) {
      fetchLifecycles();
    }
  }, []);

  // Unselect the bucket if it has been deleted.
  useEffect(() => {
    if (selectedBucketName && !buckets.find((b) => b.name === selectedBucketName)) {
      clearBucket();
    }
  }, [buckets]);

  const selectedBucket = buckets.find((b) => b.name === selectedBucketName) || {} as Bucket;

  const poolsMap = pools.reduce((map: PoolsMap, p: Pool) => {
    map[p.id] = p;
    return map;
  }, {});

  const lifecyclesMap = lifecycles.reduce((map: LifecyclesMap, l: Lifecycle) => {
    map[l.id] = l.name;
    return map;
  }, {});

  const accountsMap = accounts.reduce((map: AccountsMap, a: Account) => {
    map[a.canonicalId] = a;
    return map;
  }, {});

  let scanBucket = '';
  let scanDescription = '';
  if (selectedBucketName) {
    scanBucket = selectedBucketName;
    const linkID = selectedBucket.linkedStorage || '';
    if (linkID !== '') {
      scanDescription = 'Scan the entire '+linkedPoolType(linkID, poolsMap)+
        ' bucket "'+linkedPoolBucket(linkID, poolsMap)+'" for new and deleted objects?';
    }
  }

  return (
    <Card>
      <CardHeader icon={FolderIcon}>Buckets</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.CreateDialogButton
            dialog={(props: any) => <CreateBucketWizard accounts={accounts} {...props} />}
            onClick={clearBucket}
          />
          <ButtonToolbar.EditDialogButton
            disabled={isEmpty(selectedBucketName)}
            dialog={(props: any) => (
              <EditBucketWizard bucket={selectedBucket} accounts={accounts} {...props} />
            )}
            onClick={closeDetails} onSuccess={clearBucket}
          />
          <ButtonToolbar.DeleteDialogButton
            onClick={closeDetails} onSuccess={clearBucket} disabled={isEmpty(selectedBucketName)}
            dialog={(props: any) => (
              <DeleteDialog
                id={selectedBucketName} name={selectedBucketName} resource='buckets' {...props}
              />
            )}
          />
          <ButtonToolbar.DialogButton
            icon={UpdateAltIcon} title='Scan' color='primary'
            disabled={isEmpty(selectedBucketName) || isEmpty(selectedBucket.linkedStorage)}
            dialog={(props: any) => (
              <ScanBucketDialog bucket={scanBucket} description={scanDescription} {...props}
              />
            )}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>Owner</Table.Cell>
              <Table.Cell>Linked Storage</Table.Cell>
              <Table.Cell>Date Created</Table.Cell>
              <Table.Cell>Lifecycle</Table.Cell>
              <Table.Cell> </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            {buckets && buckets.map((bucket) => (
              <BucketRow
                key={bucket.name} bucket={bucket} owner={owner(bucket.owner, accountsMap)}
                linkedStorage={linkedPoolName(bucket.linkedStorage || '', poolsMap)}
                lifecycle={bucket.lifecycle ? (lifecyclesMap[bucket.lifecycle] || bucket.lifecycle) : ''}
                selected={bucket.name === selectedBucketName}
                setOpenDetails={setOpenDetails} setSelectedBucketName={setSelectedBucketName}
              />
            ))}
          </Table.Body>
        </Table>
        <SlidePanel
          title={selectedBucketName} options={['Properties', 'Usage', 'ACLs', 'Policy']}
          open={isOpenDetails} onClose={closeDetails}
        >
          <PropertiesDetails bucket={selectedBucket} lifecycles={lifecycles} accounts={accounts}
            linkedPool={linkedPoolName(selectedBucket.linkedStorage || '', poolsMap)}/>
          <MetricsDetails id={selectedBucket.name} />
          <AclsDetails bucket={selectedBucket} />
          <PolicyDetails bucket={selectedBucket} />
        </SlidePanel>
      </Card.Body>
    </Card>
  );
};

const BucketRow = memo((
  {bucket, owner, linkedStorage, lifecycle, selected, setOpenDetails, setSelectedBucketName}: any,
) => {
  return (
    <Table.Row
      selected={selected}
      onClick={(event: any) => event.target.tagName.toLowerCase() !== 'a' && setSelectedBucketName(bucket.name)}
    >
      <Table.CellLink link={`/buckets/${bucket.name}/objects`}>
        <React.Fragment>{bucket.name}</React.Fragment>
      </Table.CellLink>
      <Table.Cell>{owner}</Table.Cell>
      <Table.Cell>{linkedStorage}</Table.Cell>
      <Table.Cell>{dateTimeLong(bucket.created)}</Table.Cell>
      <Table.Cell>{lifecycle}</Table.Cell>
      <Table.CellDetailsButton onClick={() => setOpenDetails(true)} />
    </Table.Row>
  );
});

const owner = (owner: string, accountsMap: AccountsMap) => {
  let name = owner;
  if (accountsMap[owner]) {
    name = accountsMap[owner].username || accountsMap[owner].id;
  }
  return name;
};

const linkedPoolName = (id: string, poolsMap: PoolsMap) => {
  let name = '--';
  if (id) {
    name = id;
    if (poolsMap[id]) {
      name = poolsMap[id].name;
    }
  }
  return name;
};

const linkedPoolBucket = (id: string, poolsMap: PoolsMap) => {
  let bucket = '--';
  if (id) {
    if (poolsMap[id]) {
      bucket = poolsMap[id].item || '--';
    }
  }
  return bucket;
};

const linkedPoolType = (id: string, poolsMap: PoolsMap) => {
  let poolType = 'cloud';
  if (id) {
    if (poolsMap[id] && poolsMap[id].target == TargetType.BLACKPEARL) {
      if (poolsMap[id].archival) {
        poolType = 'Tape';
      } else {
        poolType = 'Disk Pool';
      }
    }
  }
  return poolType;
};

const mapStateToProps = (state: Store) => {
  const bucketsResource = state.resources.buckets || {};
  const accountsResource = state.resources.accounts || {};
  const lifecyclesResource = state.resources.lifecycles || {};
  const poolsResource = state.resources.storage || {};
  return {
    buckets: bucketsResource.data,
    accounts: accountsResource.data,
    lifecycles: lifecyclesResource.data,
    error: bucketsResource.error,
    fetching: bucketsResource.fetching,
    pools: poolsResource.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchBuckets: () => dispatch(fetchResource('buckets')),
  fetchAccounts: () => dispatch(fetchResource('accounts')),
  fetchLifecycles: () => dispatch(fetchResource('lifecycles')),
  fetchPools: () => dispatch(fetchResource('storage')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Buckets);
