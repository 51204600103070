import React from 'react';
import {connect} from 'react-redux';
import {Dialog} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {createResource} from 'spectra-logic-ui/actions';
import {DialogHeader} from 'spectra-logic-ui/components/form_dialog';
import {Dispatch} from 'spectra-logic-ui';

import {AccessKey, IAMUser} from '@/iam/types';
import CreateKeyForm from '@/iam/users/details/form/create/form';

type Props = {
  user: IAMUser;
  form: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  dialog: {
    maxWidth: '700px',
  },
});

const CreateKeyDialog = ({form, open, onClose, onSubmit}: Props) => {
  const classes = useStyles();

  const [createdKey, setCreatedKey] = React.useState({} as AccessKey);

  // Wrap onClose so clicking outside the dialog won't close it.
  const wrappedOnClose = (event: {}, reason: any) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const wrappedSubmit = (values: any) => {
    return new Promise((resolve, reject) => {
      if (onSubmit === undefined) {
        return null;
      }
      onSubmit(values).then((response: any) => {
        resolve(response);
        setCreatedKey(response.data);
      }, (error) => reject(error));
    });
  };

  return (
    <Dialog fullWidth PaperProps={{className: classes.dialog}} onClose={wrappedOnClose} open={open}>
      <DialogHeader onClose={onClose}>{(createdKey && createdKey.id) ? 'Access Key Created' : form}</DialogHeader>
      <CreateKeyForm createdKey={createdKey} onClose={onClose} onSubmit={wrappedSubmit} />
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {user}: Props) => {
  const resource = `iam/users/${user.accountid}/${user.username}/keys`;
  return {
    onSubmit: () => (
      dispatch(createResource(resource))
    ),
  };
};

export default connect(null, mapDispatchToProps)(CreateKeyDialog);
