import React from 'react';
import {Form} from 'react-final-form';
import isEmpty from 'is-empty';
import {Button, DialogActions, DialogContent, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContentCopy from 'spectra-logic-ui/icons/ContentCopy';

import NoteCard from '@/components/note_card';
import {AccessKey} from '@/iam/types';

type Props = {
  createdKey: AccessKey;
  onClose: () => void;
  submitting?: boolean;
  onSubmit: (values: any) => Promise<any>;
  error?: string;
}

const useStyles = makeStyles({
  description: {
    marginBottom: 10,
  },
  table: {
    tableLayout: 'auto',
  },
});

const useAccessKeyRowStyles = makeStyles({
  name: {
    verticalAlign: 'middle',
    overflowWrap: 'break-word',
    fontWeight: 'normal',
  },
  value: {
    verticalAlign: 'middle',
    overflowWrap: 'break-word',
    fontWeight: 'lighter',
  },
  icon: {
    cursor: 'pointer',
    marginRight: '5px',
    verticalAlign: 'middle',
  },
  text: {
    verticalAlign: 'middle',
  },
});

// AccessKeyRow is a component that displays an access key id or secret access key along with
// an icon that copies the value to the clipboard when clicked.
const AccessKeyRow = ({name, value}: {name: string; value: string}) => {
  const classes = useAccessKeyRowStyles();
  return (
    <TableRow>
      <TableCell className={`${classes.name}`}>{name}</TableCell>
      <TableCell className={`${classes.value}`}>
        <ContentCopy className={classes.icon} onClick={() => navigator.clipboard.writeText(value)} />
        <span className={classes.text}>{value}</span>
      </TableCell>
    </TableRow>
  );
};

const CreateKeyForm = ({createdKey, onClose, onSubmit}: Props) => {
  const classes = useStyles();
  return (
    <Form onSubmit={onSubmit}>
      {({error, handleSubmit, submitting, submitError}) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {(error || submitError) && !submitting && <div className='error'>{error || submitError}</div>}
            {(error || submitError || isEmpty(createdKey)) &&
              <Typography>Are you sure you want to create an access key?</Typography>
            }
            {!error && !submitError && !isEmpty(createdKey) &&
              <div>
                <Typography className={classes.description}>
                  This is the <strong>only</strong> time this secret access key can be viewed.
                  You cannot recover it.
                </Typography>
                <NoteCard title='Created Access Key'>
                  <Table size='small' className={classes.table}>
                    <TableBody>
                      <AccessKeyRow name='Access Key ID' value={createdKey.id} />
                      <AccessKeyRow name='Secret Access Key' value={createdKey.secretAccessKey || ''} />
                    </TableBody>
                  </Table>
                </NoteCard>
              </div>
            }
          </DialogContent>
          <DialogActions>
            {isEmpty(createdKey) && <Button color='primary' disabled={submitting} type='submit'>Submit</Button>}
            {!isEmpty(createdKey) && <Button color='primary' onClick={onClose}>Close</Button>}
          </DialogActions>
        </form>
      )}
    </Form>
  );
};

export default CreateKeyForm;
