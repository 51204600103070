import {ValidationError} from '@/validate';
import validateNewLocation from '@/locations/form/create/validate';
import {FormValues} from '@/system/form/register/types';
import {newLocationID} from '@/system/form/register/pages/select_location';

export const validateCredentials = ({username, password}: FormValues) => {
  const errors = {} as any;
  if (!username) {
    errors.username = ValidationError.CANNOT_BE_BLANK;
  }
  if (!password) {
    errors.password = ValidationError.CANNOT_BE_BLANK;
  }
  return errors;
};

export const validateLocation = ({location, locationName, latitude, longitude}: FormValues) => {
  const errors = {} as any;
  if (!location) {
    errors.location = ValidationError.CANNOT_BE_BLANK;
  } else if (location === newLocationID) {
    const newLocationErrors = validateNewLocation({
      locationName, latitude, longitude,
    });
    errors.locationName = newLocationErrors.locationName;
    errors.latitude = newLocationErrors.latitude;
    errors.longitude = newLocationErrors.longitude;
  }
  return errors;
};
