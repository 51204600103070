import {ValidationError} from '@/validate';
import {FormValues} from '@/login/reset_password/types';

export const validate = ({newPassword, confirmNewPassword}: FormValues) => {
  const errors = {} as any;

  if (!newPassword) {
    errors.newPassword = ValidationError.CANNOT_BE_BLANK;
  } else if (newPassword != confirmNewPassword) {
    const err = 'Passwords do not match';
    errors.newPassword = err;
    errors.confirmNewPassword = err;
  }

  return errors;
};
