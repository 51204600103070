import React from 'react';
import {connect} from 'react-redux';

import {Dispatch} from 'spectra-logic-ui';
import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';

type Props = {
  onSubmit: (values: any) => Promise<any>;
}

const CreateLogSet = ({onSubmit, ...others}: Props) => {
  return (
    <FormDialog title='Create Log set' submitLabel='Create' onSubmit={onSubmit} {...others}>
      {() => <p>Are you sure you want to create a log set?</p>}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: () => dispatch(createResource('logs')),
});

export default connect(null, mapDispatchToProps)(CreateLogSet);
