import React from 'react';
import isEmpty from 'is-empty';
import makeStyles from '@mui/styles/makeStyles';

import {Color} from 'spectra-logic-ui/colors';
import Table, {TableHeaderProps} from 'spectra-logic-ui/components/table';

import {Location, Pool} from '@/types';
import PodRow from '@/storage/pod_row';
import StorageRows from '@/storage/storage_rows';
import {StorageRowId} from '@/storage/types';

type Props = {
  selectedRowId: StorageRowId;
  selectRowId: Function;
  setOpenDetails: Function;
  closeDetails: Function;
  index: number;
  location?: Location;
  pools: Pool[];
  pods: any[];
}

const useStyles = makeStyles({
  tableHeader: {
    '& > * > *': {
      color: Color.GRAY_DARK,
    },
  },
  locationPadding: {
    paddingTop: '30px',
  },
  locationName: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  nowrap: {'whiteSpace': 'nowrap'},
});

// OnSiteStorageTableLocation will be used multiple times within the same
// Table and having multiple <thead> elements within a <table> is not
// valid.  Thus, the "header" of each location section is a <tbody> instead of a
// <thead> but styled to look like our other table headers.
const OnSiteHeader = ({children, ...props}: TableHeaderProps) => {
  const classes = useStyles();
  return (
    <Table.Body className={classes.tableHeader} {...props}>{children}</Table.Body>
  );
};

const OnSiteStorageTableLocation = (props: Props) => {
  const {selectedRowId, selectRowId, setOpenDetails, closeDetails, index, location, pools, pods} = props;

  if (isEmpty(pods)) {
    return null;
  }

  const classes = useStyles();
  let locationClasses = classes.locationName;
  if (index > 0) {
    locationClasses += ' ' + classes.locationPadding;
  }

  const poolsByPodID = {} as any;
  pools.forEach((p: Pool) => {
    if (!poolsByPodID[p.endpoint]) {
      poolsByPodID[p.endpoint] = [];
    }
    poolsByPodID[p.endpoint].push(p);
  });

  const sortedPods = pods.slice();
  sortedPods.sort((a, b) => {
    const nameA = a.pod.name.toUpperCase();
    const nameB = b.pod.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <React.Fragment>
      <OnSiteHeader>
        {location ?
          <Table.Row>
            <Table.Cell className={locationClasses} colSpan={5}>
              {location.name}
            </Table.Cell>
          </Table.Row> : <></>}
        <Table.Row>
          <Table.Cell>Name</Table.Cell>
          <Table.Cell className={classes.nowrap}>Type</Table.Cell>
          <Table.Cell className={classes.nowrap}>Storage Class</Table.Cell>
          <Table.Cell className={classes.nowrap}>Status</Table.Cell>
          <Table.Cell className={classes.nowrap}>&nbsp;</Table.Cell>
        </Table.Row>
      </OnSiteHeader>
      <Table.Body>
        {sortedPods.map((metaPod) => (
          <React.Fragment key={metaPod.pod.id}>
            <PodRow pod={metaPod.pod} type={metaPod.type} selectRowId={selectRowId} selectedRowId={selectedRowId}
              closeDetails={closeDetails} />
            <StorageRows pools={poolsByPodID[metaPod.pod.id.split('.')[0]]}
              selectRowId={selectRowId} selectedRowId={selectedRowId} setOpenDetails={ () => setOpenDetails(true)}/>
          </React.Fragment>
        ))}
      </Table.Body>
    </React.Fragment>
  );
};

export default OnSiteStorageTableLocation;
