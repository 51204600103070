import React from 'react';
import {connect} from 'react-redux';
import {useFormState} from 'react-final-form';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {WizardPage} from 'spectra-logic-ui/components';
import {Store} from 'spectra-logic-ui';

import DialogDescription from '@/components/form/dialog_description';
import NoteCard from '@/components/note_card';
import {Location} from '@/types';

type PageProps = {
  title: string;
  locations?: Location[];
}

const useStyles = makeStyles({
  errorCard: {
    marginTop: 10,
  },
  content: {
    '& > *': {
      marginTop: 15,
    },
  },
});

const ConfirmPage = ({title, locations = [], ...others}: PageProps) => {
  const classes = useStyles();
  const formState = useFormState();
  const locationID = formState.values.location;

  let locationValue = locationID;
  const location = locations.find((l) => l.id === locationID) || {} as Location;
  if (location.name) {
    locationValue = location.name;
  }

  return (
    <WizardPage
      title={title} {...others}
      description={<DialogDescription>Verify your registration information is correct.</DialogDescription>}
    >
      <div className={classes.content}>
        {formState.submitErrors && formState.submitErrors.version &&
          <div className='error'>{formState.submitErrors.version}</div>}
        <NoteCard title='Parameters'>
          <NoteCard.Table>
            <NoteCard.TableRow name='Location' value={locationValue} />
          </NoteCard.Table>
        </NoteCard>
        <Typography>
          Note: It may take several minutes for this system to appear in the Sphere UI once registration is complete.
        </Typography>
      </div>
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const locationsPath = `sphere/locations`;
  const locationsResource = state.resources[locationsPath] || {};
  return {locations: locationsResource.data};
};

export default connect(mapStateToProps)(ConfirmPage);
