import React from 'react';
import {Field} from 'react-final-form';

import {FormControl, FormControlLabel, Radio as MuiRadio, RadioGroup} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {Tooltip} from 'spectra-logic-ui/components';

type ButtonProps = {
  name: string;
  label: string;
  value: unknown;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  TooltipProps?: object;
}

type Props = {
  children: any;
  className?: string;
  defaultValue?: any;
  row?: boolean;
  onChange?: (...args: any) => void;
}

const useStyles = makeStyles({
  formControlLabel: {
    marginRight: 2,
  },
  span: {
    marginRight: 20,
  },
});

const Radio = ({children, className, defaultValue, onChange, row}: Props) => (
  <div>
    <FormControl>
      <RadioGroup className={className} defaultValue={defaultValue} onChange={onChange} row={row}>
        {children}
      </RadioGroup>
    </FormControl>
  </div>
);

Radio.Button = ({name, disabled, label, value, tooltip='', TooltipProps = {}}: ButtonProps) => {
  const classes = useStyles();
  return (
    <span className={classes.span}>
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Field name={name} type='radio' value={value} disabled={disabled}>
            {({input: {checked, onChange, value}}: any) => (
              <MuiRadio checked={checked} onChange={onChange} value={value} disabled={disabled} />
            )}
          </Field>
        }
        label={label}
        value={value}
      />
      {tooltip && <Tooltip {...TooltipProps}>{tooltip}</Tooltip>}
    </span>
  );
};

export default Radio;
