import React from 'react';
import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Color} from 'spectra-logic-ui/colors';
import {Table} from 'spectra-logic-ui/components';

import {Bucket} from '@/types';

type Props = {
  bucket: Bucket;
}

const useStyles = makeStyles({
  root: {
    padding: 5,
  },
  notFoundText: {
    color: Color.GRAY_DARK,
  },
});

const PolicyDetails = ({bucket}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Block Public Policy</Table.Cell>
            <Table.Cell>{bucket.blockPublicPolicy ? 'Enabled' : 'Disabled'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Restrict Public Buckets</Table.Cell>
            <Table.Cell>{bucket.restrictPublicBuckets ? 'Enabled' : 'Disabled'}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {bucket.policy ?
        <pre>{JSON.stringify(bucket.policy, null, 2)}</pre> :
        <Typography className={classes.notFoundText}>No policy has been defined for this bucket.</Typography>
      }
    </div>
  );
};

export default PolicyDetails;
