// parsePrefix parses the given prefix and returns a tuple containing
// everything upto and including the last "/", and everything
// after the last "/".
//
// Examples:
//    parsePrefix('') => ['', '']
//    parsePrefix('foo') => ['', 'foo']
//    parsePrefix('foo/bar') => ['foo/', 'bar']
//    parsePrefix('foo/bar/') => ['foo/bar/', '']
export const parsePrefix = (prefix: string) => {
  const splitPrefix = prefix.split('/');
  if (splitPrefix.length === 1) {
    // The prefix doesn't include a '/' and thus it's entirely a search string.
    return ['', prefix];
  }
  const search = splitPrefix[splitPrefix.length-1];
  const prefixWithoutSearch = splitPrefix.slice(0, splitPrefix.length-1).join('/') + '/';
  return [prefixWithoutSearch, search];
};
