export enum BucketForm {
  CREATE = 'Create Bucket',
  EDIT = 'Edit Bucket',
}

export enum BucketVersioning {
  ENABLED = 'Enabled',
  SUSPENDED = 'Suspended',
  DISABLED = '',
}
