import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';

import {MenuItem} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {createResource, fetchResource} from 'spectra-logic-ui/actions';
import {WizardPage} from 'spectra-logic-ui/components';
import {Dispatch, Store, RequestOptions} from 'spectra-logic-ui';

import {Location} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import {AddLocationFormContent} from '@/locations/form/create';

type Props = {
  locations?: Location[];
  fetching?: boolean;
  error?: boolean;
  submitLabel?: string;
  title: string;
  token?: string;
  fetchLocations?: (token: string) => Promise<any>;
  onNext?: any;
  validate?: any;
};
const useStyles = makeStyles({
  fields: {
    width: '300px',
  },
});

export const newLocationID = 'new';

// Select a location for this VM to join.
const SelectLocationPage = (props: Props) => {
  const {
    locations = [],
    error = false,
    fetching = true,
    token = '',
    title,
    fetchLocations,
    ...others
  } = props;

  const classes = useStyles();

  const options = locations
    .filter((location) => location.id !== '0')
    .map((location) => ({key: location.id, text: location.name})) as any;
  options.push(<MenuItem key='new' value={newLocationID}><em>New Location</em></MenuItem>);

  React.useEffect(() => {
    if (fetchLocations) fetchLocations(token);
  }, []);

  const formState = useFormState();
  const locationID = formState.values.location;
  const locationName = formState.values.locationName;
  const locationLatitude = formState.values.latitude;
  const locationLongitude = formState.values.longitutde;

  const form = useForm();
  const dispatch = useDispatch();
  const createLocation = (token: string, body: any) => {
    const locationsPath = `sphere/locations`;
    const opts = {
      headers: {authorization: `Bearer ${token}`},
      onSuccess: (location: Location) => {
        // Update the location drop-down selector to select the newly created location.
        form.mutators.setFormValue('location', location.id);
      },
    } as RequestOptions;
    return dispatch(createResource(locationsPath, body, opts));
  };

  const onNext = {} as any;
  if (locationID == newLocationID && createLocation) {
    const body = {
      name: locationName,
      latitude: parseFloat(locationLatitude),
      longitude: parseFloat(locationLongitude),
    };
    onNext.onNext = () => createLocation(token, body);
  }

  return (
    <WizardPage title={title} {...others}>
      {fetching && <p>Fetching locations</p>}
      {error && !fetching && <p className='error'>Failed to fetch locations</p>}
      {!error && !fetching && (
        <div>
          <FormSingleSelect label='Select a Location' className={classes.fields} name='location' options={options} />
          {locationID == newLocationID && <><br/><br/><AddLocationFormContent /></>}
        </div>
      )}
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const tokenPath = `sphere/tokens`;
  const tokens = state.resources[tokenPath] || {};
  const firstToken = tokens.data && Array.isArray(tokens.data) && tokens.data.length > 0 ? tokens.data[0] : {};

  const locationPath = `sphere/locations`;
  const locations = state.resources[locationPath] || {};

  return {
    locations: locations.data,
    fetching: locations.fetching,
    error: locations.error,
    token: firstToken.token,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLocations: (token: string) => {
    const locationPath = `sphere/locations`;
    const headers = {authorization: `Bearer ${token}`};
    return dispatch(fetchResource(locationPath, '', {headers: headers} as RequestOptions));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocationPage);
