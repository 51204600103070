import React from 'react';

import {Card, Grid} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import DialogDescription from '@/components/form/dialog_description';
import TextField from '@/components/form/text_field';

type Props = {
}

const useStyles = makeStyles({
  detailsCard: {
    marginTop: 10,
    padding: 15,
  },
  grid: {
    '& > *': {
      marginBottom: 10,
    },
  },
});

// This form is also used in the registration wizard. The name field is actually
// locationName to ensure it doesn't collide with any other fields in that wizard.
const Confirmation = ({}: Props) => {
  const classes = useStyles();
  return (
    <div>
      <DialogDescription
        footnote={
          <React.Fragment>
            <strong>Note:</strong> Latitude and Longitude values are used for the System View map on the dashboard.
          </React.Fragment>
        }
      >
        Please confirm the details below. If necessary, you may edit any pre-populated fields or
        execute another search.
      </DialogDescription>
      <Card className={classes.detailsCard}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12}>
            <TextField name='locationName' label='Name' />
          </Grid>
          <Grid item xs={6}>
            <TextField name='latitude' label='Latitude' />
          </Grid>
          <Grid item xs={6}>
            <TextField name='longitude' label='Longitude' />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Confirmation;
