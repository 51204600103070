import React from 'react';

import {Divider, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExportIcon from 'spectra-logic-ui/icons/TrendingFlat';

const useStyles = makeStyles({
  bpIcon: {
    width: 20,
    marginRight: 7,
  },
  divider: {
    margin: '10px 0',
  },
  example: {
    marginBottom: 10,
  },
  exampleHeader: {
    fontStyle: 'italic',
    marginBottom: 2,
  },
  exampleContent: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: 10,
    },
  },
  exampleItem: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ImportIcon = (props: any) => <ExportIcon style={{transform: 'scale(-1, 1)'}} {...props} />;

export const BpStoreTooltip = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='body2'>
        A BlackPearl bucket can be used to store clones of object data. Data is
        optimized for storage on tape or disk pool, and should never be accessed
        or modified through BlackPearl.
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.example}>
        <div className={classes.exampleHeader}>
          <Typography variant='body2' display='inline'>
            <strong>Example: </strong>
          </Typography>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>system-bucket-1/file.txt</strong>
            </Typography>
          </div>
          <ExportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>BlackPearl-bucket/system-bucket-1/file.txt</strong>
            </Typography>
          </div>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>system-bucket-2/file.txt</strong>
            </Typography>
          </div>
          <ExportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>BlackPearl-bucket/system-bucket-2/file.txt</strong>
            </Typography>
          </div>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>system-bucket-3/file.txt</strong>
            </Typography>
          </div>
          <ExportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>BlackPearl-bucket/system-bucket-3/file.txt</strong>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BpLinkTooltip = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant='body2'>
        Linking a BlackPearl bucket enables discovery of objects written there.
        Discovered objects are treated as if they had been written directly to the
        linked system bucket. Lifecycles determine whether to automatically create
        clones of discovered objects in other storage.
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.example}>
        <div className={classes.exampleHeader}>
          <Typography variant='body2' display='inline'><strong>Example: </strong></Typography>
          <Typography variant='body2' display='inline'>Creating clones via lifecycle</Typography>
        </div>
        <div className={classes.exampleContent}>
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>System Bucket</strong>
            </Typography>
          </div>
          <ImportIcon />
          <div className={classes.exampleItem}>
            <Typography variant='body2' display='inline'>
              <strong>BlackPearl Bucket</strong>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
