import React from 'react';
import {connect} from 'react-redux';

import {Dispatch} from 'spectra-logic-ui';
import {createPatch, patchResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';

import {Location, LocationPatchFields} from '@/types';
import {AddLocationFormContent} from '@/locations/form/create';
import {FormValues} from '@/locations/form/create/types';
import validate from '@/locations/form/create/validate';

type Props = {
  location: Location;
  onSubmit: (values: FormValues) => Promise<any>;
}

const title = 'Edit Location';

const EditLocationDialog = ({location, ...others}: Props) => {
  const initialValues:FormValues = {
    locationName: location.name,
    latitude: (location.latitude || '').toString(),
    longitude: (location.longitude || '').toString(),
  };
  return (
    <FormDialog title={title} validate={validate} initialValues={initialValues} {...others}>
      {() => <AddLocationFormContent />}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {location}: Props) => ({
  onSubmit: (values: FormValues) => {
    const props = {
      name: values.locationName,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
    };
    const body = createPatch(LocationPatchFields, location, props);
    return dispatch(patchResource('locations', location.id, body));
  },
});

export default connect(undefined, mapDispatchToProps)(EditLocationDialog);
