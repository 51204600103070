import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import AccountIcon from 'spectra-logic-ui/icons/AccountCircle';

import {Account} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import DeleteDialog from '@/components/delete_dialog';
import CreateAccountDialog from '@/accounts/form/add';
import EditAccountDialog from '@/accounts/form/edit';

type Props = {
  accounts?: Account[];
  error?: boolean;
  fetching?: boolean;
  fetchAccounts?: () => Promise<any>;
}

const Accounts = ({accounts = [], error = false, fetching = false, fetchAccounts}: Props) => {
  const [selectedAccountId, setSelectedAccountId] = React.useState('');

  useEffect(() => {
    if (fetchAccounts !== undefined) {
      fetchAccounts();
    }
  }, []);

  useEffect(() => {
    if (selectedAccountId && !accounts.find((account) => account.id === selectedAccountId)) {
      setSelectedAccountId('');
    }
  }, [accounts]);

  const onDelete = () => {
    setSelectedAccountId('');
  };

  const selectedAccount = accounts.find((a) => a.id === selectedAccountId) || {};

  return (
    <Card>
      <CardHeader icon={AccountIcon}>
        IAM Accounts
      </CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.CreateDialogButton title='Add' dialog={CreateAccountDialog} />
          <ButtonToolbar.EditDialogButton
            disabled={isEmpty(selectedAccount)}
            dialog={(props: any) => <EditAccountDialog account={selectedAccount} {...props} />}
          />
          <ButtonToolbar.DeleteDialogButton
            disabled={isEmpty(selectedAccount)} onSuccess={onDelete}
            dialog={(props: any) => (
              <DeleteDialog
                id={selectedAccountId} name={selectedAccountId}
                title='Delete IAM Account' resource='accounts' {...props}
              />
            )}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>ID</Table.Cell>
              <Table.Cell>Username</Table.Cell>
              <Table.Cell>Canonical ID</Table.Cell>
              <Table.Cell>Description</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            {accounts.map((account) => (
              <Table.Row
                key={account.id} selected={account.id === selectedAccountId}
                onClick={() => setSelectedAccountId(account.id)}
              >
                <Table.Cell>{account.id}</Table.Cell>
                <Table.Cell>{account.username}</Table.Cell>
                <Table.Cell>{account.canonicalId}</Table.Cell>
                <Table.Cell>{account.description}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const accountsResource = state.resources.accounts || {};
  return {
    accounts: accountsResource.data,
    accountsError: accountsResource.error,
    accountsFetching: accountsResource.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccounts: () => dispatch(fetchResource('accounts')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
