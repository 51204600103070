import React from 'react';

import {Grid, InputAdornment} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {WizardPage, TextField as OutlinedTextField} from 'spectra-logic-ui/components';

import {FormActionType} from '@/enum';
import Checkbox from '@/components/form/checkbox';
import DialogDescription from '@/components/form/dialog_description';
import TextField from '@/components/form/text_field';

type Props = {
  title: string;
  action: string;
  validate: (formValues: any) => object;
}

const useStyles = makeStyles({
  checkbox: {
    marginTop: 10,
  },
  textField: {
    marginTop: 10,
  },
});

const uploadsExpirationTooltip = 'This will abort multipart uploads if they don\'t complete within ' +
  'the number of days provided. When aborting a multipart upload, it deletes all parts associated with it, ' +
  'which prevents having remaining incomplete uploads stored.';
const markerExpirationTooltip = 'This will remove delete markers when they become the only remaining version of an ' +
  'object. A delete marker keeps track of deletions for versioned objects so that S3 can indicate the object is not ' +
  'present. If a lifecycle causes a previous version to be removed, and the current version is a delete marker that ' +
  'is the only remaining version, the delete marker will be removed along with the previous version.';
const afterPutTooltip = 'This will perform the initial data placement as if the lifecycle were not present. '+
  'Any clones required by the lifecycle rules will be created as a secondary operation.';
const ignoreClassTooltip = 'This will ignore any storage class specified in a put-object or multipart upload '+
  'operation. The default initial storage placement will always be used.';

const ParametersPage = ({title, action, ...props}: Props) => {
  const classes = useStyles();
  const description = action == FormActionType.EDIT ? 'Edit lifecycle parameters.' : 'Configure your new lifecycle.';
  return (
    <WizardPage
      title={title} {...props}
      description={<DialogDescription>{description}</DialogDescription>}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField name='name' label='Name'/>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='uploads' label='Multipart Upload Expiration' tooltip={uploadsExpirationTooltip}
            InputProps={{endAdornment: <InputAdornment position='end'>days</InputAdornment>}}
          />
        </Grid>
      </Grid>
      <div className={classes.checkbox}>
        <Checkbox name='markers' label='Delete Marker Expiration' tooltip={markerExpirationTooltip}/>
      </div>
      <div className={classes.checkbox}>
        <Checkbox name='afterPut' label='Force Initial Copy' tooltip={afterPutTooltip}/>
      </div>
      <div className={classes.checkbox}>
        <Checkbox name='ignoreClass' label='Ignore Requested Storage Class' tooltip={ignoreClassTooltip}/>
      </div>
      <OutlinedTextField
        name='description'
        className={classes.textField}
        label='Description'
        multiline
        rows={4}
        fullWidth
        variant='outlined'
      />
    </WizardPage>
  );
};

export default ParametersPage;
