import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {MultiSelect, Tooltip} from 'spectra-logic-ui/components';
import {SelectOption} from 'spectra-logic-ui';

type Props = {
  name: string;
  label: string;
  options: SelectOption[] | string[];
  tooltip?: React.ReactNode;
  sortable?: boolean;
  className?: string;
  onChange?: (...args: any) => any;
}

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    width: '100%',
  },
  multiSelect: {
    display: 'flex',
    flex: '1 1 auto',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
});

const FormMultiSelect = ({name, label, options, sortable, tooltip, className, onChange}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MultiSelect
        name={name} label={label} options={options} sortable={sortable}
        className={`${classes.multiSelect} ${className}`}
        onChange={onChange}
      />
      {tooltip &&
        <div className={classes.tooltip}>
          <Tooltip>{tooltip}</Tooltip>
        </div>
      }
    </div>
  );
};

export default FormMultiSelect;
