import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Grid} from '@mui/material';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {SlidePanel} from 'spectra-logic-ui/components';

import {Pool} from '@/types';
import CloudStorage from '@/storage/cloud_storage';
import OnSiteStorage from '@/storage/on_site_storage';
import PropertiesDetails from '@/storage/details/properties';
import MetricsDetails from '@/storage/details/metrics';
import {StorageRowId} from '@/storage/types';

type Props = {
  pools: Pool[];
  fetchPools: Function;
}

const Storage = ({pools, fetchPools}: Props) => {
  const [selectedRowId, selectRowId] = React.useState({} as StorageRowId);
  const [isOpenDetails, setOpenDetails] = React.useState(false);
  const closeDetails = () => setOpenDetails(false);
  const clearSelectedRow = () => {
    setOpenDetails(false);
    selectRowId({} as StorageRowId);
  };

  useEffect(() => {
    fetchPools();
  }, []);

  const selectedPool = pools.find((pool) => pool.id === selectedRowId.poolId);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <OnSiteStorage
            pools={pools} selectedPool={selectedPool} selectedRowId={selectedRowId} selectRowId={selectRowId}
            clearSelectedRow={clearSelectedRow} setOpenDetails={setOpenDetails} closeDetails={closeDetails} />
        </Grid>
        <Grid item xs={12}>
          <CloudStorage
            pools={pools} selectedPool={selectedPool} selectedRowId={selectedRowId} selectRowId={selectRowId}
            clearSelectedRow={clearSelectedRow} setOpenDetails={setOpenDetails} />
        </Grid>
      </Grid>
      <SlidePanel
        title={(selectedPool || {}).name || ''} options={['Properties', 'Usage']}
        open={isOpenDetails} onClose={closeDetails}
      >
        <PropertiesDetails storage={selectedPool || {} as Pool} />
        <MetricsDetails id={(selectedPool || {} as Pool).id} />
      </SlidePanel>
    </React.Fragment>
  );
};

const mapStateToProps = (state: Store) => {
  const pools = state.resources.storage || {};
  return {pools: pools.data || []};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPools: () => dispatch(fetchResource('storage')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Storage);
