import React from 'react';
import Grid from '@mui/material/Grid';

import Cloud from '@/usage/cloud';
import Location from '@/usage/location';
import Sphere from '@/usage/sphere';

const Capacity = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Sphere />
    </Grid>
    <Grid item xs={12}>
      <Location />
    </Grid>
    <Grid item xs={12}>
      <Cloud />
    </Grid>
  </Grid>
);

export default Capacity;
