import React, {useState} from 'react';
import {connect} from 'react-redux';
import arrayMutators from 'final-form-arrays';

import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {patchResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {Account, Bucket} from '@/types';
import {BucketOwnershipControls, DefaultRetentionModes, DefaultRetentionTimeUnits, FormActionType} from '@/enum';
import {createParametersValidator, validateACLs, validateRetention} from '@/buckets/form/pages/validate';
import ConfirmPage from '@/buckets/form/pages/confirm';
import LifecyclePage from '@/buckets/form/pages/lifecycle';
import ParametersPage from '@/buckets/form/pages/parameters';
import PolicyPage from '@/buckets/form/pages/permissions/policy';
import AclsPage from '@/buckets/form/pages/permissions/acls';
import {BucketForm} from '@/buckets/form/enum';
import {FormValues} from '@/buckets/form/types';
import RetentionPage from '@/buckets/form/pages/retention';

type WizardProps = {
  bucket: Bucket;
  accounts: Account[];
  buckets?: Bucket[];
  onSubmit: (values: FormValues) => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  bucketDlg: {
    minWidth: theme.spacing(120),
  },
}));

const EditBucketWizard = ({bucket, buckets = [], accounts = [], onSubmit, ...otherProps}: WizardProps) => {
  const classes = useStyles();
  const [values, setValues] = useState({} as FormValues);

  const initialValues = {...bucket} as FormValues;
  if (bucket.locking && bucket.defaultRetention !== undefined) {
    initialValues.useDefaultRetention = true;
    initialValues.mode = DefaultRetentionModes.GOVERNANCE;
    if (bucket.defaultRetention.compliance) {
      initialValues.mode = DefaultRetentionModes.COMPLIANCE;
    }
    if (bucket.defaultRetention.days !== undefined) {
      initialValues.timeUnits = DefaultRetentionTimeUnits.DAYS;
      initialValues.time = bucket.defaultRetention.days;
    } else if (bucket.defaultRetention.years !== undefined) {
      initialValues.timeUnits = DefaultRetentionTimeUnits.YEARS;
      initialValues.time = bucket.defaultRetention.years;
    }
  }

  const validateParameters = createParametersValidator(buckets?.filter((l) => l.name !== bucket.name));

  return (
    <WizardFormDialog
      title={BucketForm.EDIT} initialValues={initialValues} className={classes.bucketDlg}
      width={820} onSubmit={onSubmit} mutators={{...arrayMutators}} onValuesChange={setValues} {...otherProps}
    >
      <ParametersPage
        title='Parameters' action={FormActionType.EDIT} bucket={bucket} accounts={accounts}
        validate={validateParameters}
      />
      {values.locking && <RetentionPage title='Retention' validate={validateRetention} />}
      <PolicyPage title='Policy' />
      {values.control !== BucketOwnershipControls.BUCKET_OWNER_ENFORCED &&
        <AclsPage title='Access Control Lists' validate={validateACLs} />}
      <LifecyclePage title='Lifecycle' />
      <ConfirmPage title='Confirm' accounts={accounts} />
    </WizardFormDialog>
  );
};

const mapStateToProps = (state: Store) => {
  return {buckets: (state.resources.buckets || {}).data};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: FormValues) => {
    const {...body} = values;
    if (body.control === BucketOwnershipControls.BUCKET_OWNER_ENFORCED) {
      body.acls = [];
    }
    return dispatch(patchResource('buckets', values.name, body));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBucketWizard);
