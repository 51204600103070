import React from 'react';

import {Grid} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {TextField as OutlinedTextField} from 'spectra-logic-ui/components';

import Radio from '@/components/radio';
import {DhcpDnsOption} from '@/network/form/nic_edit/enum';

type Props = {
  dhcpDnsOption: DhcpDnsOption;
  dhcp4: boolean;
  auto6: boolean;
}

const useStyles = makeStyles({
  radioButton: {
    marginBottom: 5,
  },
  textField: {
    marginTop: 10,
  },
});

const DNS = ({dhcp4, auto6, dhcpDnsOption}: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Radio className={classes.radioButton} row>
        <Radio.Button
          name='dhcpDnsOption' label='DHCP' value={DhcpDnsOption.CHOOSE_DHCP} disabled={!dhcp4 && !auto6}
        />
        <Radio.Button
          name='dhcpDnsOption' label='Manual' value={DhcpDnsOption.CHOOSE_MANUAL} disabled={!dhcp4 && !auto6}
        />
      </Radio>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <OutlinedTextField
            name='nameServers'
            fullWidth
            className={classes.textField}
            label='Name Servers'
            multiline
            rows={4}
            variant='outlined'
            disabled={dhcpDnsOption === DhcpDnsOption.CHOOSE_DHCP}
          />
        </Grid>
        <Grid item xs={6}>
          <OutlinedTextField
            name='searchDomains'
            fullWidth
            className={classes.textField}
            label='Search Domains'
            multiline
            rows={4}
            variant='outlined'
            disabled={dhcpDnsOption === DhcpDnsOption.CHOOSE_DHCP}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DNS;
