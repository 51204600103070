import React from 'react';
import {Card, Grid, InputAdornment} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import TextField from '@/components/form/text_field';

const useStyles = makeStyles({
  root: {
    padding: 15,
    marginTop: 10,
  },
  grid: {
    '& > *': {
      marginBottom: 10,
    },
  },
});

const OptionalDataCard = () => {
  const classes = useStyles();

  const OptionalDataToolTip = 'Set the percentage of storage ' +
  'available for optional object data clones. Optional clones use spare ' +
  'storage to speed up data access. They are deleted as necessary to keep ' +
  'space used below this percentage. If this field is left blank, no ' +
  'optional clones will be stored, and object access times will not be ' +
  'tracked.';
  return (
    <Card className={classes.root}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={6}>
          <TextField
            parse={(value) => {
              const number = Number(value);
              if (!value && value !== '0') {
                return null;
              } else if (isNaN(number)) {
                return value;
              } else {
                return number;
              }
            }}
            name='optionalData'
            label='Optional Data'
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
            tooltip={OptionalDataToolTip} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default OptionalDataCard;
