import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {compareVersions} from 'compare-versions';

import {Dispatch, Store} from 'spectra-logic-ui';
import {ADD_BANNER, REMOVE_BANNER, fetchResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {Card, Loading, Spinner, Table} from 'spectra-logic-ui/components';
import {statusColor} from 'spectra-logic-ui/components/status_icon';
import CheckCircleIcon from 'spectra-logic-ui/icons/CheckCircle';
import UpdateAltIcon from 'spectra-logic-ui/icons/SystemUpdateAlt';
import UpdateIcon from 'spectra-logic-ui/icons/Sync';
import WarningIcon from 'spectra-logic-ui/icons/Warning';

import {NodeStatus, EndpointTypes, SphereStatus, UpdateStatusUpdate} from '@/enum';
import {Pod, EndpointUpdateVersion, UpdateStatus} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import UpdateDialog from '@/updates/form/update_dialog';
import UploadDialog from '@/updates/form/upload_dialog';

type PodTableRowProps = {
  pod: Pod;
  type: string;
  updateStatus?: UpdateStatus;
  availableVersion?: string;
  selected?: boolean;
  setSelectedPodId: (id: string) => void;
  endpointVersionsFetching: boolean;
}

type Props = {
  endpoints: Pod[];
  endpointVersions: EndpointUpdateVersion[];
  endpointVersionsError: boolean;
  endpointVersionsFetching: boolean;
  updateStatuses: Record<string, UpdateStatus>;
  fetching: boolean;
  fetchPods: () => void;
  fetchUpdateStatus: (id: string) => void;
  addNoLicenseServerBanner: () => void;
  removeNoLicenseServerBanner: () => void;
}

const noLicenseServerMessage = 'The license server could not be contacted for update information';

const podStatus = (status?: NodeStatus | SphereStatus, updateStatus?: any) => {
  if (status === NodeStatus.updating) {
    return <span><Spinner/>Updating{updateStatusDetails(updateStatus)}</span>;
  } else if (status === NodeStatus.unavailable) {
    return 'Unavailable';
  } else if (status === NodeStatus.degraded) {
    return 'Degraded';
  } else if (status === SphereStatus.deleting) {
    return 'Deleting';
  } else if (status === NodeStatus.ok) {
    return 'OK';
  }
};

export const updateStatusDetails = (updateStatus?: UpdateStatus) => {
  let details = '';
  if (updateStatus && updateStatus.update) {
    switch (updateStatus.update) {
    case UpdateStatusUpdate.PROGRESS:
      const progress = [];
      if (updateStatus.value) {
        progress.push(`${updateStatus.value}% complete`);
      }
      if (updateStatus.detail) {
        progress.push(updateStatus.detail);
      }
      details = progress.join(': ');
      break;
    case UpdateStatusUpdate.ERROR:
      details = updateStatus.detail || 'Error';
      break;
    case UpdateStatusUpdate.ACTION:
      details = 'Action Required';
      break;
    }
  }
  if (details !== '') {
    details = ` - ${details}`;
  }
  return details;
};

const PodTableRow = ({
  pod, selected, setSelectedPodId, type, updateStatus, availableVersion, endpointVersionsFetching, ...otherProps
}: PodTableRowProps) => {
  if (pod === undefined || pod === null) {
    return null;
  }
  const hasUpdate = pod.version && availableVersion &&
    compareVersions(pod.version, availableVersion) < 0;
  const RowIcon = () => hasUpdate ?
    <WarningIcon style={{height: 18, color: Color.WARNING, verticalAlign: 'bottom'}} /> :
    <CheckCircleIcon style={{height: 18, color: Color.OK, verticalAlign: 'bottom'}} />;

  let renderedAvailableVersion: any = '--';
  if (endpointVersionsFetching) {
    renderedAvailableVersion = <Loading />;
  } else if (availableVersion) {
    renderedAvailableVersion = availableVersion;
  }

  const onClick = () => setSelectedPodId(pod.id);

  let podStatusColor = statusColor('ok');
  if (pod.status == NodeStatus.updating && updateStatus && updateStatus.update == UpdateStatusUpdate.ERROR) {
    podStatusColor = statusColor('error');
  }

  return (
    <Table.Row selected={selected} onClick={onClick} {...otherProps}>
      <Table.Cell>{pod.name}</Table.Cell>
      <Table.Cell>{type}</Table.Cell>
      <Table.Cell>
        <div style={{color: podStatusColor}}>
          {podStatus(pod.status, updateStatus)}
        </div>
      </Table.Cell>
      <Table.Cell>
        {pod.version ? <span>{pod.version && <RowIcon/>}&nbsp;{pod.version}</span> : '--'}
      </Table.Cell>
      <Table.Cell>{renderedAvailableVersion}</Table.Cell>
    </Table.Row>
  );
};

const Updates = ({
  endpoints = [], endpointVersions=[], updateStatuses={}, endpointVersionsError=false,
  endpointVersionsFetching=false, fetching=false, fetchPods, fetchUpdateStatus,
  addNoLicenseServerBanner, removeNoLicenseServerBanner,
}: Props) => {
  const [selectedPodId, setSelectedPodId] = React.useState('');
  const updateUrl = `endpoints/${selectedPodId}/update`;
  const uploadUrl = `${updateUrl}/upload`;

  useEffect(() => {
    for (let i = 0; i < endpoints.length; i++) {
      const ep = endpoints[i];
      if (ep.status === NodeStatus.updating) {
        fetchUpdateStatus(ep.id);
      }
    }
  }, [endpoints]);

  const clearPodId = () => {
    setSelectedPodId('');
  };
  useEffect(() => {
    fetchPods();
  }, []);

  useEffect(() => {
    if (endpointVersionsError) {
      addNoLicenseServerBanner();
    } else {
      removeNoLicenseServerBanner();
    }
    // Only show the banner while on the software updates page.
    return removeNoLicenseServerBanner;
  }, [endpointVersionsError]);

  const availableVersionsMap = {} as any;
  for (let i = 0; i < endpointVersions.length; i++) {
    availableVersionsMap[endpointVersions[i].id] = endpointVersions[i].version;
  }

  const selectedPod = endpoints.find((ep) => ep.id === selectedPodId) || {} as Pod;
  const uploadEnabled = selectedPod.status !== NodeStatus.updating;
  const updateEnabled = uploadEnabled && availableVersionsMap[selectedPodId];

  return (
    <Card>
      <CardHeader icon={UpdateIcon}>Software Updates</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.DialogButton
            icon={UpdateAltIcon} title='Update' color='primary' disabled={!updateEnabled} onSuccess={clearPodId}
            dialog={(props: any) => (
              <UpdateDialog name={selectedPod.name} resource={updateUrl} {...props} />
            )}
          />
          <ButtonToolbar.DialogButton
            icon={UpdateAltIcon} title='Upload' disabled={!uploadEnabled} onSuccess={clearPodId}
            dialog={(props: any) => <UploadDialog endpoint={selectedPod} resource={uploadUrl} {...props} />}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>Current Version</Table.Cell>
              <Table.Cell>Available Version</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching}>
            <React.Fragment>
              {endpoints.filter((ep) => ep.version).map((ep) => (
                <PodTableRow
                  key={ep.id} pod={ep} selected={ep.id === selectedPodId}
                  type={EndpointTypes[ep.type]}
                  updateStatus={updateStatuses[ep.id]}
                  availableVersion={availableVersionsMap[ep.id]}
                  setSelectedPodId={setSelectedPodId}
                  endpointVersionsFetching={endpointVersionsFetching}
                />
              ))}
            </React.Fragment>
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const endpoints = state.resources.endpoints || {};
  const endpointsData: Pod[] = endpoints.data || [];
  const endpointVersions = state.resources['updates/endpoints'] || {};
  const updateStatuses: Record<string, UpdateStatus> = {};
  for (let i = 0; i < endpointsData.length; i++) {
    const ep = endpointsData[i];
    if (ep.status === NodeStatus.updating) {
      updateStatuses[ep.id] = (state.resources[`endpoints/${ep.id}/update`] || {}).data || {};
    }
  }
  return {
    endpoints: endpointsData,
    endpointVersions: endpointVersions.data,
    updateStatuses: updateStatuses,
    fetching: endpoints.fetching,
    endpointVersionsError: endpointVersions.error,
    endpointVersionsFetching: endpointVersions.fetching,
  };
};

const addNoLicenseServerBannerAction = {
  type: ADD_BANNER, key: 'noLicenseServer', severity: 'info', message: noLicenseServerMessage,
};

const removeNoLicenseServerBannerAction = {type: REMOVE_BANNER, key: 'noLicenseServer'};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPods: () => {
    dispatch(fetchResource('endpoints'));
    dispatch(fetchResource('updates/endpoints'));
  },
  fetchUpdateStatus: (id: string) => {
    dispatch(fetchResource(`endpoints/${id}/update`));
  },
  addNoLicenseServerBanner: () => {
    dispatch(addNoLicenseServerBannerAction);
  },
  removeNoLicenseServerBanner: () => {
    dispatch(removeNoLicenseServerBannerAction);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Updates);
