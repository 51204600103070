import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Table from 'spectra-logic-ui/components/table';

import {IAMUser, IAMGroup} from '@/iam/types';
import ButtonToolbar from '@/components/button_toolbar';
import AddUserToGroupDialog from '@/iam/users/details/form/add';
import RemoveUserFromGroupDialog from '@/iam/users/details/form/remove';

type Props = {
  user: IAMUser;
  fetchUserGroups?: () => Promise<any>;
  groups?: IAMGroup[];
  error?: boolean;
  fetching?: boolean;
}

const IAMUserGroups = ({user, fetchUserGroups, groups = [], error = false, fetching = false}: Props) => {
  const [selectedGroupName, setSelectedGroupName] = useState('');

  useEffect(() => {
    if (fetchUserGroups !== undefined) {
      fetchUserGroups();
    }
    setSelectedGroupName('');
  }, [user]);

  const selectedGroup = groups.find((group: IAMGroup) => group.name === selectedGroupName) || {};

  return (
    <>
      <ButtonToolbar>
        <ButtonToolbar.CreateDialogButton
          title='Add'
          dialog={(props: any) => <AddUserToGroupDialog user={user} userGroups={groups} {...props} />}
          disabled={error || fetching}
        />
        <ButtonToolbar.DeleteDialogButton
          title='Remove'
          disabled={isEmpty(selectedGroupName)}
          onSuccess={() => setSelectedGroupName('')}
          dialog={(props: any) => <RemoveUserFromGroupDialog user={user} group={selectedGroup} {...props} />}
        />
      </ButtonToolbar>
      <Table>
        <Table.Header>
          <Table.Row><Table.Cell>Name</Table.Cell></Table.Row>
        </Table.Header>
        <Table.Body isLoading={fetching} hasError={error}>
          {groups.map((group: IAMGroup) => (
            <Table.Row
              key={group.name} selected={!isEmpty(selectedGroupName) && group.name === selectedGroupName}
              onClick={() => setSelectedGroupName(group.name)}
            >
              <Table.Cell>{group.name}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

const mapStateToProps = (state: Store, {user}: Props) => {
  const url = `iam/users/${user.accountid}/${user.username}/groups`;
  const iamUserGroupsState = state.resources[url] || {};
  const iamUserGroups = iamUserGroupsState.data || [];
  return {
    groups: iamUserGroups,
    error: iamUserGroupsState.error,
    fetching: iamUserGroupsState.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {user}: Props) => {
  const url = `iam/users/${user.accountid}/${user.username}/groups`;
  return {fetchUserGroups: () => dispatch(fetchResource(url))};
};

export default connect(mapStateToProps, mapDispatchToProps)(IAMUserGroups);
