import React from 'react';

import {SpectraLogoColor} from 'spectra-logic-ui/colors';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';

type Props = {
  name: string;
  used: number;
}

const StorageDisplay = ({name = 'N/A', used}: Props) => (
  <div style={{display: 'flex', flexDirection: 'column', width: '165px'}}>
    <div style={{fontSize: '0.8em', margin: '0 auto', width: '100%', textAlign: 'center'}}>
      {name}
    </div>
    <div style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
      <h1 style={{color: SpectraLogoColor.BLUE}}>{numberToHumanSize(used)}</h1>
    </div>
    <div style={{width: '100%', textAlign: 'center', fontSize: '0.8em'}}>
      used
    </div>
  </div>
);

export default StorageDisplay;
