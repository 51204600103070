import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {ExpandableTable, StatusIcon} from 'spectra-logic-ui/components';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';

import {EndpointType} from '@/enum';
import {Pod, Location, Pool, StorageUsed} from '@/types';
import {isSingle} from '@/single';
import VmIcon from '@/assets/images/icons/vm.png';
import BpIcon from '@/assets/images/icons/bp.png';
import {System} from '@/system/types';
import BlackPearlDetails from '@/dashboard/details/black_pearl_details';
import VmDetails, {Capacity} from '@/dashboard/details/vm_details';

type Props = {
  location: Location;
  endpoints: Pod[];
  entities: StorageUsed[];
  pools: Pool[];
  system: System;
}

const useStyles = makeStyles(() => ({
  podIcon: {
    height: 20,
    width: 20,
    marginRight: 2,
    verticalAlign: 'bottom',
  },
  cloudlessDetails: {
    paddingTop: '10px',
  },
}));


const nothingSelectedId = '-1';

const OnSiteDetails = ({location, endpoints, entities, pools, system}: Props) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState(nothingSelectedId);
  const locationEndpoints = endpoints.filter((ep) => ep.location === location.id);

  useEffect(() => {
    // Default to selecting this node's endpoint, assuming we're currently looking at its location.
    if (locationEndpoints.length > 0) {
      const myEndpointId = system.id ? system.id.split('.')[0] : '-1';
      const myEndpoint = locationEndpoints.find((ep) => ep.id == myEndpointId);
      if (myEndpoint) {
        setSelectedId(myEndpointId);
      } else {
        setSelectedId(locationEndpoints[0].id);
      }
    }
  }, [endpoints, location, system]);

  const capacities: {[key: string]: {total: number; capacities: Capacity[]}} = {};
  const poolMap: {[key: string]: string} = {};
  pools.forEach((pool) => {
    poolMap[pool.id] = pool.name;
  });
  entities.forEach((entity) => {
    const pod = entity.endpoint == undefined ? '' : entity.endpoint;
    const epId = pod; // endpoints are Pods
    if (!capacities[epId]) {
      capacities[epId] = {total: 0, capacities: []};
    }
    (Array.isArray(entity.storage) ? entity.storage : []).forEach((storage) => {
      const entry = {name: poolMap[storage.id],
        used: entity.used === undefined ? 0 : entity.used,
        optional: storage.optional === undefined ? 0 : storage.optional,
        total: entity.total};
      capacities[epId].capacities.push(entry);
    });
    capacities[epId].total = capacities[epId].total + (entity.total || 0);
  });

  if (isSingle() && locationEndpoints.length === 1) {
    const ep = locationEndpoints[0];
    if (ep.type === EndpointType.BP) {
      return <BlackPearlDetails className={classes.cloudlessDetails} endpoint={ep} />;
    }
    return <VmDetails className={classes.cloudlessDetails} capacities={capacities[ep.id]?.capacities || []} />;
  }

  return (
    <ExpandableTable size='small'>
      <ExpandableTable.Header>
        <ExpandableTable.HeaderRow>
          <ExpandableTable.Cell>Name</ExpandableTable.Cell>
          <ExpandableTable.Cell>Type</ExpandableTable.Cell>
          <ExpandableTable.Cell>Capacity</ExpandableTable.Cell>
        </ExpandableTable.HeaderRow>
      </ExpandableTable.Header>
      <ExpandableTable.Body>
        <React.Fragment>
          {locationEndpoints
            .map((ep) => (
              <ExpandableTable.BodyRow
                key={ep.id} selected={ep.id === selectedId}
                onClick={() => setSelectedId(ep.id === selectedId ? nothingSelectedId : ep.id)}
                details={ep.type === EndpointType.BP ? <BlackPearlDetails endpoint={ep} /> :
                  <VmDetails capacities={capacities[ep.id]?.capacities || []} />}
              >
                <ExpandableTable.Cell>
                  <StatusIcon status={ep.status} /> {ep.name}
                </ExpandableTable.Cell>
                <ExpandableTable.Cell>
                  <img src={ep.type === EndpointType.BP ? BpIcon : VmIcon} className={classes.podIcon} />
                  {ep.type === EndpointType.BP ? 'BlackPearl' : 'Virtual Machine'}
                </ExpandableTable.Cell>
                <ExpandableTable.Cell>
                  {capacities[ep.id] ? numberToHumanSize(capacities[ep.id].total) : ''}
                </ExpandableTable.Cell>
              </ExpandableTable.BodyRow>
            ))
          }
        </React.Fragment>
      </ExpandableTable.Body>
    </ExpandableTable>
  );
};

export default OnSiteDetails;
