import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Form, FormRenderProps} from 'react-final-form';
import {FormApi, MutableState, Tools} from 'final-form';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Card, Loading} from 'spectra-logic-ui/components';
import WorldIcon from 'spectra-logic-ui/icons/Public';
import PerformanceGraph from 'spectra-logic-ui/performance';

import {PerformanceTable, Pod} from '@/types';
import CardHeader from '@/components/card_header';
import SingleSelect from '@/components/form/single_select';
import {System} from '@/system/types';

type Props = {
  endpoints: Pod[];
  system?: System;
  fetchEndpoints: Function;
  fetchSystem: Function;
  fetchTables: Function;
  getTableState: Function;
}

const useStyles = makeStyles({
  selection: {width: '100%'},
});

// The performance page renders a single performance chart based on the selected endpoint, table type,
// and table. The endpoint, table type, and table state are stored in the URL path so users can refresh
// the page without losing their selection. It also allows users to bookmark a specific performance chart.
//
// A complete performance chart path is "/performance/ENDPOINT/TABLE-TYPE/TABLE". This component will detect
// partial URLs (e.g. "/performance", "/performance/ENDPOINT", "/performance/ENDPOINT/TABLE-TYPE") and
// redirect as appropriate. If endpoint is missing (i.e. "/performance"), it will redirect to the
// current system's endpoint. If table type is missing (i.e. "/performance/ENDPOINT"), it will redirect
// to the first table type in the list of tables for that endpoint.
const Performance = ({endpoints, system, fetchEndpoints, fetchSystem, fetchTables, getTableState}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const endpoint = params.endpoint || '';
  const tableType = params.type || '';
  const table = params.table || '';

  const tableState = getTableState(endpoint);
  const tables: PerformanceTable[] = tableState.data || [];
  const tableMap: {[key: string]: PerformanceTable} = {};
  for (let i= 0; i < tables.length; i++) {
    tableMap[tables[i].type] = tables[i];
  }
  const fetchingTables = tableState.fetching;

  useEffect(() => {
    fetchEndpoints();
    if (endpoint) {
      fetchTables(endpoint);
    } else if (system && system.id) {
      // The URL is just "/performance" without even specifying an endpoint ID.
      // Redirect to "/performance/X". The next useEffect will take care of
      // redirecting to a complete URL.
      const myEndpointId = system.id.split('.')[0];
      navigate(`/performance/${myEndpointId}/`);
    } else {
      fetchSystem();
    }
  }, [endpoint, system]);

  useEffect(() => {
    // We could combine all these if statements into one, big if statement but that makes the code
    // very difficult to read.
    if (endpoint && tables[0] && tables[0].tables && tables[0].tables.length > 0) {
      const defaultTableForEndpoint = `/performance/${endpoint}/${tables[0].type}/${tables[0].tables[0]}`;
      if (!tableType || !table) {
        // The URL is either "/performance/X" or "/performance/X/Y". Redirect to a complete
        // URL that specified endpoint, table type, and table.
        navigate(defaultTableForEndpoint);
      } else if (table && !tableMap[tableType]) {
        // Handle the situation where a user selects a different endpoint.
        // If the selected table type is not valid for the newly selected endpoint,
        // redirect to the first table type in the list of tables for the new endpoint.
        // This also runs on initial load but our intention is to do nothing in that case.
        navigate(defaultTableForEndpoint);
      }
    }
  }, [endpoint, tableType, tables]);

  useEffect(() => {
    // Handle the situation where a user selects a different table type.
    // If the selected table is not valid for the newly selected table type,
    // which it most likely won't be (unless we use the same table name in different table types),
    // redirect to the first table in the list of tables for the new table type.
    // This also runs on initial load but our intention is to do nothing in that case.
    if (tableType && table && tableMap[tableType] && !tableMap[tableType].tables.includes(table)) {
      navigate(`/performance/${endpoint}/${tableType}/${tableMap[tableType].tables[0]}`);
    }
  }, [tableType]);

  const onSiteEndpoints = endpoints.filter((e) => e.id !== '0');
  const initialValues: Record<string, any> = {};

  const endpointOptions = onSiteEndpoints.map((pod) => (
    {key: pod.id, value: pod.id, text: `${pod.name} (${pod.url})`}
  ));
  if (endpointOptions.find((o) => o.key === endpoint)) {
    initialValues['endpoint'] = endpoint;
  }

  const typeOptions = tables.map((table) => (
    {key: table.type, value: table.type, text: table.title}
  ));
  if (typeOptions.find((o) => o.key === tableType)) {
    initialValues['type'] = tableType;
  }

  const tableTypeInfo = tableMap[tableType] || {} as PerformanceTable;
  const tableOptions = (tableTypeInfo.tables || []).map((table: any) => (
    {key: table, value: table, text: table}
  ));
  if (tableOptions.find((o) => o.key === table)) {
    initialValues['table'] = table;
  }

  let title = '';
  let description = '';
  if (tableType && tableMap[tableType]) {
    title = tableMap[tableType].title;
    if (tableMap[tableType].tables.length > 1) {
      title = title + ': ' + table;
    }
    description = tableMap[tableType].description;
  }

  const changeEndpoint = (newEndpoint: string, form: FormApi) => {
    navigate(`/performance/${newEndpoint}/${tableType}/${table}`);
    // Reset type/table to prevent material UI warnings.
    form.mutators.setFormValue('type', '');
    form.mutators.setFormValue('table', '');
  };

  const changeTableType = (newTableType: string, form: FormApi) => {
    navigate(`/performance/${endpoint}/${newTableType}/${table}`);
    // Reset table to prevent material UI warnings.
    form.mutators.setFormValue('table', '');
  };

  const changeTable = (newTable: string) => {
    navigate(`/performance/${endpoint}/${tableType}/${newTable}`);
  };

  const mutators = {
    setFormValue: ([key, value]: any, state: MutableState<any>, {changeValue}: Tools<any>) => {
      changeValue(state, key, () => value);
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Form initialValues={initialValues} mutators={mutators} onSubmit={() => {}}>
          {({form}: FormRenderProps) => (
            <form className={classes.selection}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={4} md={4}>
                  <SingleSelect
                    name='endpoint' label='Endpoint'
                    onChange={(event) => changeEndpoint(event.target.value, form)}
                    options={endpointOptions}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <SingleSelect
                    name='type' label='Table Type'
                    onChange={(event) => changeTableType(event.target.value, form)}
                    options={typeOptions}
                  />
                </Grid>
                {tableOptions && tableOptions.length > 1 && <Grid item xs={4} sm={4} md={4}>
                  <SingleSelect
                    name='table' label='Name'
                    onChange={(event) => changeTable(event.target.value)}
                    options={tableOptions}
                  />
                </Grid>}
              </Grid>
            </form>
          )}
        </Form>
      </Grid>
      <Grid item xs={10} sm={10} md={10}>
        {fetchingTables && <Loading />}
        {!fetchingTables && endpoint && tableType && table &&
          <Card>
            <CardHeader icon={WorldIcon} tooltip={description}>{title}</CardHeader>
            <Card.Body>
              <PerformanceGraph
                requestHandler='performance'
                prefix={endpoint}
                tableType={tableType}
                tableName={table}
              />
            </Card.Body>
          </Card>}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: Store) => {
  const endpointsState = state.resources.endpoints || {};
  const systemState = state.resources.system || {};
  const getTableState = (endpoint: string) => {
    return endpoint ? (state.resources[`performance/${endpoint}`] || {}) : {};
  };

  return {
    endpoints: endpointsState.data || [],
    system: systemState.data,
    getTableState: getTableState,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchEndpoints: () => dispatch(fetchResource('endpoints')),
  fetchSystem: () => dispatch(fetchResource('system')),
  fetchTables: (endpoint: string) => dispatch(fetchResource(`performance/${endpoint}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Performance);
