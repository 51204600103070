import React from 'react';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type Props = {
  children: React.ReactNode;
  footnote?: React.ReactNode;
}

const useStyles = makeStyles({
  description: {
    lineHeight: 'normal',
  },
  footnote: {
    lineHeight: 'normal',
    display: 'block',
    fontStyle: 'italic',
    marginTop: 5,
  },
});

const DialogDescription = ({children, footnote}: Props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant='subtitle1' className={classes.description}>{children}</Typography>
      {footnote && <Typography variant='caption' className={classes.footnote}>{footnote}</Typography>}
    </React.Fragment>
  );
};

export default DialogDescription;
