import React from 'react';
import {connect} from 'react-redux';
import {Grid, Divider, InputAdornment} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch} from 'spectra-logic-ui';
import {createPatch, patchCollection} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';

import {isSingle} from '@/single';
import {System, SystemPatchFields} from '@/system/types';
import Checkbox from '@/components/form/checkbox';
import TextField from '@/components/form/text_field';
import DialogDescription from '@/components/form/dialog_description';

type Props = {
  system: System;
  onSubmit: (values: FormValues) => Promise<any>;
}

export type FormValues = {
  monitor: boolean;
  nightly: string;
  namespace?: string;
  awsRegion?: string;
  awsKey?: string;
  awsSecret?: string;
}

const useStyles = makeStyles({
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  grid: {
    marginTop: '5px',
  },
});

const monitorTooltip = 'Automatically send diagnostic information to Spectra Logic.';
const nightlyTooltip = 'The time when all systems perform nightly processing. '+
    'Changing this value will not change actions already scheduled. '+
    'All endpoints will restart to recognize the new value.';

const EditSettingsDialog = ({system, ...otherProps}: Props) => {
  const initialValues = {
    monitor: system.monitor,
    nightly: system.nightly,
    namespace: system.namespace,
    awsRegion: system.awsRegion,
  } as FormValues;

  const classes = useStyles();

  return (
    <FormDialog title='Edit Global Settings' initialValues={initialValues} {...otherProps}>
      {() => (
        <>
          <Checkbox name='monitor' label='Enable Diagnostic Monitor' tooltip={monitorTooltip} />
          <TextField name='nightly' label='Nightly Processing'
            InputProps={{endAdornment: <InputAdornment position='start'>UTC</InputAdornment>}}
            tooltip={nightlyTooltip}/>
          {isSingle() && <>
            <Divider className={classes.divider} />
            <DialogDescription>Configure AWS Infrastructure</DialogDescription>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={12}><TextField name='namespace' label='Namespace' /></Grid>
              <Grid item xs={12}><TextField name='awsRegion' label='AWS Region' /></Grid>
              <Grid item xs={12}><TextField name='awsKey' label='AWS Access Key ID' /></Grid>
              <Grid item xs={12}><TextField name='awsSecret' label='AWS Secret Access Key' /></Grid>
            </Grid>
          </>}
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {system}: Props) => ({
  onSubmit: (values: FormValues) => {
    const upd = createPatch(SystemPatchFields, system, values);

    return dispatch(patchCollection('system', upd));
  },
});

export default connect(undefined, mapDispatchToProps)(EditSettingsDialog);
