import {Pool} from '@/types';
import {TargetType} from '@/enum';

// Does the given storage pool have a cloud storage type.
export const isCloud = (storage: Pool) => {
  return isCloudStorage(storage.target);
};

// Is the given storage type cloud storage.
export const isCloudStorage = (st?: TargetType) => {
  return st === TargetType.S3 || st === TargetType.S3OTHER || st === TargetType.AZURE || st === TargetType.GOOGLE;
};
