import React from 'react';
import {connect} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';

import makeStyles from '@mui/styles/makeStyles';

import {WizardPage} from 'spectra-logic-ui/components';
import {Store} from 'spectra-logic-ui';

import {Pool} from '@/types';
import DialogDescription from '@/components/form/dialog_description';
import FormSingleSelect from '@/components/form/single_select';
import Radio from '@/components/radio';
import {FormOption} from '@/storage/form/delete/enum';

type Props = {
  title: string;
  pool: Pool;
  validate: (values: any) => object;
  formValue?: string;
  pools?: Pool[];
}

const useStyles = makeStyles({
  singleSelect: {
    width: '60%',
    marginLeft: 50,
    marginBottom: 30,
  },
});

const OptionsPage = ({title, pool, pools = [], validate, ...otherProps}: Props) => {
  const classes = useStyles();
  const filteredPools = pools.filter((p) => {
    return p.id !== pool.id && p.link === undefined && !p.readOnly;
  });
  const tooltipText = 'If the only clone of an object\'s data is on ' +
  'the storage about to be deleted, data will be copied to the alternative storage.  ' +
  'Be sure to choose alternative storage with adequate available space.';
  const deleteTip = 'If the only clone of an object\'s data is on ' +
  'the storage about to be deleted, the object will be removed. Specify alternative '+
  'storage to ensure your objects are preserved.';
  const form = useForm();
  const formState = useFormState();
  const formOption = formState.values.formOption;

  return (
    <WizardPage
      title={title} validate={validate} {...otherProps}
      description={<DialogDescription>Delete '{pool.name}'?</DialogDescription>}
    >
      <Radio onChange={() => form.mutators.setFormValue('chosenStorage', undefined)}>
        <Radio.Button
          name='formOption'
          label='Choose Alternative Storage'
          tooltip={tooltipText}
          value={FormOption.CHOOSE_ALTERNATIVE}
        />
        <div className={classes.singleSelect}>
          <FormSingleSelect disabled={formOption !== 'choose_alternative'}
            label='Select Storage'
            name='chosenStorage'
            options={filteredPools.map((p) => (
              {key: p.id, text: `${p.name} (${p.id})`}
            ))}
          />
        </div>
        <Radio.Button name='formOption' label='Delete Objects' value={FormOption.CHOOSE_DELETE} tooltip={deleteTip}/>
      </Radio>
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const poolsResource = state.resources.storage || {};
  return {
    pools: poolsResource.data || [],
  };
};

export default connect(mapStateToProps)(OptionsPage);
