const top = 'https://support.spectralogic.com/vail/vailonlinehelp.htm';

export const AddBlackPearlStorage = top+'#B-Installation-Topics/CreateStorage-BlackPearl.htm';
export const AddCloudStorage = top+'#B-Installation-Topics/CreateStorage-Cloud.htm';
export const CreateLifecycle = top+'#B-Installation-Topics/CreateLifecycle.htm';
export const CreateVailBucket = top+'#B-Installation-Topics/CreateVailBucket.htm';
export const ConfigureNetwork = top+'#L-VailVMNode-Topics/VailNode-ConfigureNetwork.htm';
export const Register = top+'#L-VailVMNode-Topics/VailNode-RegisterSphere.htm';
export const Activate = Register;
export const CreateSphereAdministrator = top+'#cshid=ConfigureSphereAdmin';
export const EditSphereAdmin = top+'#cshid=EditSphereAdmin';
export const AddIAMAccount = top+'#cshid=AddIAMAccount';
export const EditIAMAccount = top+'#cshid=EditIAMAccount';

export const getHelpLocation = (): string => {
  return top;
};
