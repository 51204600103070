import React from 'react';

import {Grid, Typography} from '@mui/material';

import {Color} from 'spectra-logic-ui/colors';

import StorageChart from '@/components/storage_chart';

export type Capacity = {
  name?: string;
  used?: number;
  optional?: number;
  total?: number;
}

type Props = {
  capacities: Capacity[];
  className?: string;
}

const VmDetails = ({capacities, className=''}: Props) => {
  return (
    <Grid container className={className}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} style={{borderBottom: 'solid 1px darkgray'}}>
            <Typography variant='caption' style={{display: 'block', textAlign: 'center', color: Color.GRAY_DARK}}>
                  Capacity
            </Typography>
          </Grid>
          {capacities && capacities.map((pool) => (
            <Grid key={pool.name} item xs={6} sm={3} md={3} lg={2}>
              <StorageChart heading={pool.name || ''} optional={pool.optional || 0} used={pool.used || 0}
                total={pool.total || 0} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VmDetails;

