import React from 'react';
import {useFormState} from 'react-final-form';

import makeStyles from '@mui/styles/makeStyles';

import {WizardPage} from 'spectra-logic-ui/components';

import {FormActionType} from '@/enum';
import DialogDescription from '@/components/form/dialog_description';
import NoteCard from '@/components/note_card';
import {isStorageClassArchival} from '@/storage/archive';

type PageProps = {
  action: FormActionType;
  title: string;
}

const useStyles = makeStyles({
  errorCard: {
    marginTop: 10,
  },
  content: {
    '& > *': {
      marginTop: 15,
    },
  },
});

const VirtualMachineConfirmPage = ({action, title, ...otherProps}: PageProps) => {
  const classes = useStyles();

  const formState = useFormState();
  const name = formState.values.name;
  const storageClass = formState.values.storageClass;
  const cloneRestore = formState.values.cloneRestore;
  const hasRecoverable = formState.values.recoverable !== undefined;
  const recoverable = formState.values.recoverable;
  const cautionThreshold = formState.values.cautionThreshold;
  const warningThreshold = formState.values.warningThreshold;
  const optionalData = formState.values.optionalData;

  return (
    <WizardPage
      title={title} {...otherProps}
      description={<DialogDescription>Verify your configuration is correct.</DialogDescription>}
    >
      <div className={classes.content}>
        <NoteCard title='Parameters'>
          <NoteCard.Table>
            <React.Fragment>
              <NoteCard.TableRow name='Storage Name' value={name} />
              {action === FormActionType.EDIT && <NoteCard.TableRow name='Storage Class' value={storageClass} />}
              {hasRecoverable &&
                <NoteCard.TableRow name='Third-party Recovery' value={recoverable ? 'Enabled' : 'Disabled'} />}
              {action === FormActionType.EDIT && isStorageClassArchival(storageClass) &&
                <NoteCard.TableRow name='Restore To New Clone' value={cloneRestore ? 'Enabled' : 'Disabled'} />}
              <NoteCard.TableRow name='Caution Threshold' value={cautionThreshold ? cautionThreshold + '%' : '--'} />
              <NoteCard.TableRow name='Warning Threshold' value={warningThreshold ? warningThreshold + '%' : '--'} />
              <NoteCard.TableRow name='Optional Data' value={optionalData ? optionalData + '%' : '--'} />
            </React.Fragment>
          </NoteCard.Table>
        </NoteCard>
      </div>
    </WizardPage>
  );
};

export default VirtualMachineConfirmPage;
