import React from 'react';
import {useFormState} from 'react-final-form';

import {Card, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {WizardPage} from 'spectra-logic-ui/components';

import {FormActionType, TargetType} from '@/enum';
import DialogDescription from '@/components/form/dialog_description';
import TextField from '@/components/form/text_field';
import {TextField as OutlinedTextField} from 'spectra-logic-ui/components';

type AuthorizationCardProps = {
  action: FormActionType;
}

type PageProps = {
  action: FormActionType;
  title: string;
  validate: (formValues: any) => object;
  onNext?: any;
}

const useStyles = makeStyles({
  root: {
    padding: 15,
    marginTop: 15,
  },
  arnRadioButton: {
    marginRight: 5,
    verticalAlign: 'sub',
  },
  boldText: {
    fontWeight: 'bold',
  },
});

const Authorization = ({action}: AuthorizationCardProps) => {
  const formState = useFormState();
  const target = formState.values.target;
  if (target === undefined) {
    return (
      <div />
    );
  }

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {target === TargetType.S3 &&
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField name='region' label='Region' disabled={action !== FormActionType.CREATE} />
          </Grid>
          <React.Fragment>
            <Grid item xs={6}>
              <TextField name='accessKey' label='AWS Access Key ID' />
            </Grid>
            <Grid item xs={6}>
              <TextField name='secretKey' label='AWS Secret Access Key' />
            </Grid>
          </React.Fragment>
          <React.Fragment>
            <Grid item xs={6}>
              <TextField name='arn' label='AWS IAM Role ARN'
                tooltip={<span><strong>Example:</strong> arn:aws:iam:123456789012:role/S3Access</span>} />
            </Grid>
            <Grid item xs={6}>
              <TextField name='externalid' label='AWS IAM Role External ID'
                helperText='Optional. Specifies who can assume the role.' />
            </Grid>
          </React.Fragment>
        </Grid>
      }
      {target !== TargetType.S3 && target !== TargetType.GOOGLE &&
        <Grid container spacing={2}>
          {target === TargetType.S3OTHER &&
            <Grid item xs={6}>
              <TextField name='url' label='Data Path Endpoint'/>
            </Grid>
          }
          <Grid item xs={6}>
            {action === FormActionType.CREATE && target === TargetType.AZURE &&
              <TextField name='account' label='Storage Account'/>
            }
            {action === FormActionType.EDIT && target === TargetType.AZURE &&
              <TextField name='secret' label='Shared Secret' />
            }
            {target !== TargetType.AZURE &&
              <TextField name='accessKey' label='Access Key'/>
            }
          </Grid>
          <Grid item xs={6}>
            {action === FormActionType.CREATE && target === TargetType.AZURE &&
              <TextField name='secret' label='Shared Secret' />
            }
            {target !== TargetType.AZURE &&
              <TextField name='secretKey' label='Secret Key' />
            }
          </Grid>
        </Grid>
      }
      {target === TargetType.GOOGLE &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedTextField
              name='credentials'
              label='Google Cloud Platform JSON Credentials'
              fullWidth
              multiline
              rows={4}
              variant='outlined'
            />
          </Grid>
        </Grid>
      }
    </Card>
  );
};

const CloudAuthorizationPage = ({action, title, ...otherProps}: PageProps) => (
  <WizardPage
    title={title} {...otherProps}
    description={<DialogDescription>Enter your authorization data below.</DialogDescription>}
  >
    <Authorization action={action} />
  </WizardPage>
);

export default CloudAuthorizationPage;
