import React from 'react';
import {useForm, useFormState} from 'react-final-form';

import {Checkbox as MuiCheckbox, FormControlLabel} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Color} from 'spectra-logic-ui/colors';
import {Tooltip, WizardPage} from 'spectra-logic-ui/components';

import {DefaultRetentionModes, DefaultRetentionTimeUnits} from '@/enum';
import {DefaultRetention} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';

type PageProps = {
  title: string;
  validate: (formValues: any) => object;
}

const useStyles = makeStyles({
  select: {
    maxWidth: '300px',
  },
  error: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Color.WHITE,
    background: Color.ERROR,
    padding: '5px 10px',
  },
  container: {
    display: 'inline-flex',
  },
  checkBox: {
    marginRight: '0px',
  },
});

export const modeTranslations = {
  [DefaultRetentionModes.COMPLIANCE]: 'Compliance',
  [DefaultRetentionModes.GOVERNANCE]: 'Governance',
};

export const timeUnitTranslations = {
  [DefaultRetentionTimeUnits.DAYS]: 'Days',
  [DefaultRetentionTimeUnits.YEARS]: 'Years',
};

const RetentionPage = ({title, ...otherProps}: PageProps) => {
  const classes = useStyles();

  const formState = useFormState();
  const useDefaultRetention = formState.values.useDefaultRetention || false;
  const defaultRetention: DefaultRetention = formState.values.defaultRetention;
  const mode = formState.values.mode;
  const timeUnits = formState.values.timeUnits;
  const time: number = formState.values.time;

  let newRet: DefaultRetention = {};
  if (useDefaultRetention) {
    if (mode !== undefined) {
      newRet.compliance = mode === DefaultRetentionModes.COMPLIANCE;
    }
    if (time !== undefined) {
      switch (timeUnits) {
      case DefaultRetentionTimeUnits.DAYS: {
        newRet.days = time;
        newRet.years = undefined;
        break;
      } case DefaultRetentionTimeUnits.YEARS: {
        newRet.days = undefined;
        newRet.years = time;
        break;
      }
      }
    }
  }

  const modeOptions = [{
    key: DefaultRetentionModes.COMPLIANCE,
    text: modeTranslations[DefaultRetentionModes.COMPLIANCE],
  }, {
    key: DefaultRetentionModes.GOVERNANCE,
    text: modeTranslations[DefaultRetentionModes.GOVERNANCE],
  }];

  const timeUnitOptions = [{
    key: DefaultRetentionTimeUnits.DAYS,
    text: timeUnitTranslations[DefaultRetentionTimeUnits.DAYS],
  }, {
    key: DefaultRetentionTimeUnits.YEARS,
    text: timeUnitTranslations[DefaultRetentionTimeUnits.YEARS],
  }];

  let timeUnitLabel = 'Number of ';
  timeUnitLabel += timeUnits === DefaultRetentionTimeUnits.DAYS ?
    timeUnitTranslations[DefaultRetentionTimeUnits.DAYS] : timeUnitTranslations[DefaultRetentionTimeUnits.YEARS];

  const form = useForm();

  const handleChange = () => {
    const value = !useDefaultRetention;
    form.mutators.setFormValue('useDefaultRetention', value);
  };

  React.useEffect(() => {
    if (!useDefaultRetention) {
      newRet = {};
      form.mutators.setFormValue('defaultRetention', newRet);
    } else if (defaultRetention === undefined || defaultRetention.compliance !== newRet.compliance ||
        defaultRetention.days !== newRet.days || defaultRetention.years !== newRet.years) {
      if (newRet.compliance !== undefined && (newRet.days !== undefined || newRet.years !== undefined)) {
        form.mutators.setFormValue('defaultRetention', newRet);
      }
    }
  }, [useDefaultRetention, mode, timeUnits, time]);

  return (
    <WizardPage title={title} {...otherProps}>
      <div className={classes.container}>
        <FormControlLabel
          label='Use Default Retention' className={classes.checkBox}
          control={
            <MuiCheckbox
              checked={useDefaultRetention} onChange={handleChange}
            />
          } />
        <div>
          <Tooltip>
            Enable this to add default retention settings for objects put to the bucket. When putting an object to a
            bucket with default retention settings, not specifying retention settings for the object will cause the
            object to inherit the defaults; otherwise, specifying retention settings will override the defaults.
          </Tooltip>
        </div>
      </div>
      <FormSingleSelect className={classes.select} name='mode' label='Retention Mode' options={modeOptions}
        disabled={!useDefaultRetention} />
      <FormSingleSelect className={classes.select} name='timeUnits' label='Unit of Time' options={timeUnitOptions}
        disabled={!useDefaultRetention} />
      <TextField name='time' label={timeUnitLabel} disabled={!useDefaultRetention} parse={(value) => {
        const number = Number(value);
        if (!value || isNaN(number)) {
          return value;
        } else {
          return Math.trunc(number);
        }
      }}/>
    </WizardPage>
  );
};

export default RetentionPage;
