import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {Table} from 'spectra-logic-ui/components';

import {IAMGroup} from '@/iam/types';

type Props = {
  group: IAMGroup;
}

const useStyles = makeStyles({
  tableRow: {
    '& > *': {
      overflowWrap: 'break-word',
    },
  },
  key: {
    width: '30%',
  },
  value: {
    width: '70%',
  },
});

const Properties = ({group}: Props) => {
  const {name, accountid} = group;
  const classes = useStyles();
  const arn = accountid ? `arn:aws:iam::${accountid}:group/${name}` : '';
  const groupProps = [
    {key: 'Name', value: name},
    {key: 'IAM Account ID', value: accountid},
    {key: 'ARN', value: arn},
  ];

  return (
    <Table>
      <Table.Body>
        {groupProps.map((kv) => (
          <Table.Row key={kv.key} className={classes.tableRow}>
            <Table.Cell className={classes.key}>{kv.key}</Table.Cell>
            <Table.Cell className={classes.value}>{kv.value}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default Properties;
