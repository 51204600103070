import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {WizardPage} from 'spectra-logic-ui/components';

import TextField from '@/components/form/text_field';

type Props = {
  title: string;
  validate: (...args: any) => any;
};

const useStyles = makeStyles({
  textField: {
    width: '50%',
  },
});

const ChangePasswordPage = ({title, ...props}: Props) => {
  const classes = useStyles();
  return (
    <WizardPage title={title} {...props}>
      <div className={classes.textField}>
        <TextField name='password' label='New Password' type='password' />
        <TextField name='passwordCheck' label='Confirm New Password' type='password' />
      </div>
    </WizardPage>
  );
};

export default ChangePasswordPage;
