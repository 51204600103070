import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import {Checkbox as SLCheckbox, Tooltip} from 'spectra-logic-ui/components';

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  onChange?: (...args: any) => any;
}

const useStyles = makeStyles({
  root: {
    display: 'inline',
  },
  checkbox: {
    marginRight: 0,
    marginLeft: 0,
  },
  tooltip: {
    marginLeft: 2,
  },
});

const Checkbox = ({name, label, disabled, tooltip, labelPlacement='end', onChange}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SLCheckbox name={name} label={label} disabled={disabled} labelPlacement={labelPlacement}
        className={tooltip ? classes.checkbox : undefined} onChange={onChange} />
      {tooltip &&
        <span className={classes.tooltip}>
          <Tooltip>{tooltip}</Tooltip>
        </span>
      }
    </div>
  );
};

export default Checkbox;
