import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';
import {Card, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, SelectOption, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {WizardPage} from 'spectra-logic-ui/components';

import {FormActionType, StorageClass, TargetType} from '@/enum';
import {Bucket, Pool, TargetItem, TargetItemDetails} from '@/types';
import Checkbox from '@/components/form/checkbox';
import DialogDescription from '@/components/form/dialog_description';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';
import {isArchivalSupported, isStorageClassArchival} from '@/storage/archive';
import {cloneRestoreTooltip, recoverableTooltip} from '@/storage/details/properties';
import ThresholdsCard from '@/storage/thresholdsCard';
import {items, itemDetails} from '@/storage/form/store';
import {BpLinkTooltip} from '@/storage/form/pages/blackpearl/tooltips';

type ParametersCardProps = {
  action: FormActionType;
  target?: TargetType;
  buckets: Bucket[];
  bucketsFetching: boolean;
  targetItems: TargetItem[];
  storageClass: StorageClass;
  itemDetails?: TargetItemDetails;
  itemFetching: boolean;
  error?: string;
  link?: string;
  pool?: Pool;
}

type PageProps = {
  title: string;
  action: FormActionType;
  endpoint: string;
  buckets?: Bucket[];
  bucketsFetching?: boolean;
  targetItems?: TargetItem[];
  fetchBuckets?: Function;
  fetchDS3Buckets?: Function;
  fetchItemDetails?: Function;
  pool?: Pool;
  itemDetails?: TargetItemDetails;
  itemFetching?: boolean;
  itemErrorMessage?: string;
  validate?: (values: any) => object;
}

const useStyles = makeStyles({
  root: {
    padding: 15,
    marginTop: 10,
  },
  grid: {
    '& > *': {
      marginBottom: 10,
    },
  },
});

const ParametersCard = ({buckets = [], targetItems = [], action, bucketsFetching, target,
  pool, storageClass, itemDetails, itemFetching, error=''}: ParametersCardProps) => {
  const classes = useStyles();
  let bpBucketOptions;
  if (action === FormActionType.EDIT && pool && pool.item) {
    bpBucketOptions = [{key: pool.item, text: pool.item}];
  } else {
    bpBucketOptions = targetItems.map((b) => ({key: b.id, text: b.name ? b.name : b.id})) as SelectOption[];
  }

  let bucketOptions = [] as SelectOption[];
  if (target === TargetType.BPLINK) {
    if (action === FormActionType.EDIT && pool && pool.link) {
      bucketOptions = [{key: pool.link, text: pool.link}] as SelectOption[];
    } else {
      bucketOptions = buckets.filter((b) => !b.linkedStorage).map((b) => {
        return {key: b.name, text: b.name};
      }) as SelectOption[];
    }
  }
  const stgClasses = itemDetails && itemDetails.classes ? itemDetails.classes : [];

  const form = useForm();
  const disableCloneRestoreForNonArchival = (e: any) => {
    if (e && e.target && !isStorageClassArchival(e.target.value)) {
      form.mutators.setFormValue('cloneRestore', false);
    }
  };

  const clearStorageClass = () => {
    form.mutators.setFormValue('storageClass', undefined);
  };

  const sLabel = target === TargetType.BPPOLICY ? 'BlackPearl Data Policy' : 'BlackPearl Bucket';
  const selectorLabel = action === FormActionType.EDIT ? sLabel : 'Select ' + sLabel;
  const bucketTooltip = 'Select a bucket to use as a source of imported data.';
  const policyTooltip = 'Select a data policy for this new storage';
  const itemTooltip = target === TargetType.BPPOLICY ? policyTooltip : bucketTooltip;
  return (
    <Card className={classes.root}>
      {error && <p className='error'>{error}</p>}
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={6}>
          <FormSingleSelect
            name='item' label={selectorLabel} tooltip={itemTooltip}
            options={bpBucketOptions} disabled={action === FormActionType.EDIT}
            onChange={clearStorageClass}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField name='name' label='Storage Name' />
        </Grid>
        <Grid item xs={6}>
          <FormSingleSelect
            name='storageClass' label='Select Storage Class'
            options={stgClasses} disabled={stgClasses.length === 0 || itemFetching}
            onChange={disableCloneRestoreForNonArchival}
          />
        </Grid>
        <Grid item xs={6}>
          {target === TargetType.BPLINK &&
          <FormSingleSelect
            name='link' label='Select Bucket to Link' tooltip={<BpLinkTooltip />}
            options={bucketOptions} disabled={bucketsFetching || action === FormActionType.EDIT}
          />}
        </Grid>
        {target !== TargetType.BPLINK && <Grid item xs={6}>
          <Checkbox name='recoverable' label='Third-party Recovery' tooltip={recoverableTooltip} />
        </Grid>}
        {isArchivalSupported(stgClasses) &&
          <Grid item xs={6}>
            <Checkbox
              name='cloneRestore' label='Restore To New Clone' tooltip={cloneRestoreTooltip}
              disabled={!isStorageClassArchival(storageClass)}
            />
          </Grid>
        }
      </Grid>
    </Card>
  );
};


const BlackPearlParametersPage = (props: PageProps) => {
  const {title, action, buckets = [], targetItems = [], fetchBuckets,
    bucketsFetching = false, pool, fetchItemDetails,
    itemDetails, itemFetching=false, itemErrorMessage, ...otherProps} = props;

  const form = useForm();
  const formState = useFormState();
  const item = formState.values.item;
  const target = formState.values.target;
  const link = formState.values.link;
  const storageClass = formState.values.storageClass;
  const stgClasses = itemDetails && itemDetails.classes ? itemDetails.classes : [];

  useEffect(() => {
    if (fetchBuckets !== undefined) {
      fetchBuckets();
    }
    if (fetchItemDetails && pool && pool.id) {
      fetchItemDetails(formState.values);
    }
  }, []);

  if (action === FormActionType.CREATE && fetchItemDetails) {
    useEffect(() => {
      if (item) {
        fetchItemDetails(formState.values);
      }
    }, [item]);

    useEffect(() => {
      if (storageClass && !stgClasses.includes(storageClass)) {
        form.mutators.setFormValue('storageClass', undefined);
      }
    }, [itemDetails]);
  }

  useEffect(() => {
    if (target === TargetType.BPLINK) {
      form.mutators.setFormValue('recoverable', undefined);
    }
  }, [target]);

  return (
    <WizardPage
      title={title} {...otherProps}
      description={<DialogDescription>Configure your BlackPearl storage below.</DialogDescription>}
    >
      <ParametersCard
        buckets={buckets}
        bucketsFetching={bucketsFetching}
        targetItems={targetItems}
        target={target}
        action={action}
        pool={pool}
        storageClass={storageClass}
        itemDetails={itemDetails}
        itemFetching={itemFetching}
        error={itemErrorMessage}
        link={link}
      />
      {target !== TargetType.BPLINK && <ThresholdsCard />}
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const bucketsResource = state.resources['buckets'] || {};
  const targetItemsResource = state.resources[items] || {};
  const itemState = state.resources[itemDetails] || {};
  return {
    buckets: bucketsResource.data,
    bucketsFetching: bucketsResource.fetching,
    targetItems: targetItemsResource.data,
    itemDetails: itemState.data,
    itemFetching: itemState.fetching,
    itemErrorMessage: itemState.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {endpoint, pool = {} as Pool}: PageProps) => ({
  fetchBuckets: () => dispatch(fetchResource('buckets')),
  fetchItemDetails: (values: any) => {
    const opts = {storeName: itemDetails};
    if (pool && pool.id) {
      return dispatch(fetchResource(`storage/${pool.id}/item`, '', opts));
    } else {
      return dispatch(fetchResource(`targets/${endpoint}/${values.target}/${values.item}`, '', opts));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlackPearlParametersPage);
