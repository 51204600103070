import React, {useState} from 'react';
import {connect} from 'react-redux';

import {patchResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {Pool} from '@/types';
import ConfirmAltPage from '@/storage/form/delete/confirmAlt';
import ConfirmPage from '@/storage/form/delete/confirm';
import {FormOption} from '@/storage/form/delete/enum';
import OptionsPage from '@/storage/form/delete/options';
import {getValidator, validateOptions, validateAlt} from '@/storage/form/delete/validate';

type Props = {
  formOption: FormOption;
  chosenStorage?: string;
  pool: Pool;
  onSuccess?: () => void;
  getOnSubmit(formOption: string, chosenStorage: string): any;
  form?: string;
}
const title = 'Delete Storage';
const DeleteStorageWizard = ({pool, onSuccess, getOnSubmit, ...otherProps}: Props) => {
  const initialValues = {formOption: FormOption.CHOOSE_ALTERNATIVE};
  const [values, setValues] = useState({} as any);

  const formOption = values.formOption;
  const chosenStorage = values.chosenStorage;

  return (
    <WizardFormDialog
      title={title}
      action='delete'
      submitLabel='Delete'
      onSuccess={onSuccess}
      onSubmit={getOnSubmit(formOption, chosenStorage)}
      closeOnSuccess
      initialValues={initialValues} onValuesChange={setValues} {...otherProps}
    >
      <OptionsPage title='Delete Options' pool={pool} validate={validateOptions} />
      {formOption === FormOption.CHOOSE_ALTERNATIVE &&
        <ConfirmAltPage title='Confirm Alternative' name={pool.name} validate={validateAlt} />}
      {formOption === FormOption.CHOOSE_DELETE &&
        <ConfirmPage title='Confirm Delete' name={pool.name} validate={getValidator(pool)} />}
    </WizardFormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => {
  const getOnSubmit = (formOption: string, chosenStorage: string) => () => {
    if (formOption === FormOption.CHOOSE_ALTERNATIVE) {
      // copy storage to given alternative storage and delete current storage
      const body = {status: 'deleting', alternate: chosenStorage};
      return dispatch(patchResource('storage', ownProps.pool.id, body, {onSuccess: ownProps.onSuccess}));
    } else {
      // delete current storage
      const body = {status: 'deleting'};
      return dispatch(patchResource('storage', ownProps.pool.id, body, {onSuccess: ownProps.onSuccess}));
    }
  };
  return {getOnSubmit};
};

export default connect(null, mapDispatchToProps)(DeleteStorageWizard);
