import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {Pod} from '@/types';

type Props = {
  endpoint: Pod;
  className?: string;
}

const useStyles = makeStyles({
  dashboard: {
    border: 'none',
    height: '1000px',
    width: '100%',
  },
});

const BlackPearlDetails = ({endpoint, className=''}: Props) => {
  if (!endpoint.managementURL) {
    return <div className={`error ${className}`}>Unable to get BlackPearl details</div>;
  }

  const classes = useStyles();
  const src = `${endpoint.managementURL}/app/embed`;
  return <iframe src={src} className={`${classes.dashboard} ${className}`}></iframe>;
};

export default BlackPearlDetails;
