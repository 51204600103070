import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AccountTreeIcon from 'spectra-logic-ui/icons/AccountTree';

import {Color} from 'spectra-logic-ui/colors';
import Table from 'spectra-logic-ui/components/table';

import {Pod} from '@/types';
import {StorageRowId} from '@/storage/types';

type Props = {
  pod: Pod;
  type: string;
  selectedRowId: StorageRowId;
  selectRowId: Function;
  closeDetails: any;
}

const useStyles = makeStyles({
  row: {
    backgroundColor: Color.GRAY_LIGHTER,
    cursor: 'auto',
  },
  selectableRow: {
    backgroundColor: Color.GRAY_LIGHTER,
    cursor: 'pointer',
  },
  cell: {fontWeight: 'bold'},
  link: {'&:hover': {textDecoration: 'underline'}},
  endpointIcon: {
    verticalAlign: 'middle',
    marginRight: 5,
  },
});

const PodRow = ({pod, type, selectRowId, selectedRowId, closeDetails}: Props) => {
  const classes = useStyles();

  let podStatus = pod.status ? pod.status.replace(/^\w/, (c) => c.toUpperCase()) : '';
  if (podStatus === 'Ok') {
    podStatus = 'OK';
  }

  const onClick = () => {
    closeDetails();
    selectRowId({endpointId: pod.id});
  };

  const rowClassName = (type === 'BlackPearl' || type === 'VM') ? classes.selectableRow : classes.row;

  return (
    <Table.Row key={pod.id} className={rowClassName}
      selected={selectedRowId.endpointId === pod.id}
      onClick={onClick}
    >
      <Table.Cell className={classes.cell}>
        <span title='Endpoint'>
          <AccountTreeIcon className={classes.endpointIcon}></AccountTreeIcon>
        </span>{pod.name}
        <span style={{marginLeft: 4}}>
          (<a className={classes.link} target='_blank' href={pod.url}>{pod.url}</a>)
        </span>
      </Table.Cell>
      <Table.Cell className={classes.cell}>{type}</Table.Cell>
      <Table.Cell className={classes.cell}>&nbsp;</Table.Cell>
      <Table.Cell className={classes.cell}>{podStatus}</Table.Cell>
      <Table.Cell className={classes.cell}>&nbsp;</Table.Cell>
    </Table.Row>
  );
};

export default PodRow;
