import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';

import {Lifecycle} from '@/types';

type Props = {
  lifecycle: Lifecycle;
}

const useStyles = makeStyles({
  tableRow: {
    '& > *': {
      overflowWrap: 'break-word',
    },
  },
});

export default ({lifecycle}: Props) => {
  const classes = useStyles();
  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Description</Table.Cell>
          <Table.Cell>{lifecycle.description}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Upload Expiration</Table.Cell>
          <Table.Cell>{lifecycle.uploads}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Marker Expiration</Table.Cell>
          <Table.Cell>{lifecycle.markers ? 'Enabled' : 'Disabled'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Force Initial Copy</Table.Cell>
          <Table.Cell>{lifecycle.afterPut ? 'Enabled' : 'Disabled'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Ignore Storage Class</Table.Cell>
          <Table.Cell>{lifecycle.ignoreClass ? 'Enabled' : 'Disabled'}</Table.Cell>
        </Table.Row>
        <Table.Row className={classes.tableRow}>
          <Table.Cell>Modified</Table.Cell>
          <Table.Cell>{dateTimeLong(lifecycle.modified)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
