import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {createResource, fetchResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

type Props = {
  name: string;
  resource: string;
  description?: string;
  onSuccess?: () => void;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  description: {
    color: Color.RED,
  },
});

const UpdateDialogContent = (props: Props) => {
  const {name, description = 'WARNING: Cannot be undone', onSuccess, onSubmit, ...others} = props;
  const classes = useStyles();
  return (
    <FormDialog title='Update' submitLabel='Update' onSuccess={onSuccess} onSubmit={onSubmit} {...others}>
      {() => (
        <>
          <Typography>
            <strong>Update {name}?</strong>
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </>
      )}
    </FormDialog>
  );
};

const fetchPods = (dispatch: Dispatch) => {
  dispatch(fetchResource('endpoints'));
  dispatch(fetchResource('updates/endpoints'));
};

export const onSuccess = (dispatch: Dispatch) => {
  return setTimeout(() => fetchPods(dispatch), 5000);
};

const mapDispatchToProps = (dispatch: Dispatch, {resource = ''}) => ({
  onSubmit: () => dispatch(createResource(resource)),
  onSuccess: () => onSuccess(dispatch),
});

export default connect(undefined, mapDispatchToProps)(UpdateDialogContent);
