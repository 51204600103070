import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import {startCase} from 'lodash';

import ArchiveIcon from 'spectra-logic-ui/icons/Archive';
import DownloadIcon from 'spectra-logic-ui/icons/GetApp';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {getToken} from 'spectra-logic-ui/app/auth';
import {getBasePath} from 'spectra-logic-ui/app/base_path';
import {Card, Loading, Table} from 'spectra-logic-ui/components';
import {TableRowProps} from 'spectra-logic-ui/components/table';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';

import {LogSet} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import CreateLogSet from '@/logs/form/create_logset';
import DeleteLogSet from '@/logs/form/delete_logset';

type Props = {
  logs: LogSet[];
  error: boolean;
  fetching: boolean;
  fetchLogs: () => void;
}

const refreshInterval = 5000;

const Logs = ({logs = [], error = false, fetching = false, fetchLogs}: Props) => {
  const [selectedLogId, setSelectedLogId] = React.useState('');
  const clearSelectedLog = () => setSelectedLogId('');
  const selectedLog = logs.find((log) => log.id === selectedLogId) || {};

  useEffect(() => {
    fetchLogs();
  }, []);

  const logCreating = !!logs.find((log) => log.creating);
  useEffect(() => {
    // A log set is currently being created. Poll for updates until it's finished.
    // A better solution is to always rely on pub/sub. While pub/sub works fine for
    // this on Linux, it doesn't on FreeBSD because the mgmt server doesn't send pub/sub
    // events to Vail.
    if (logCreating) {
      const id = setInterval(fetchLogs, refreshInterval);
      return () => clearInterval(id);
    }
  }, [logs]);

  return (
    <Card>
      <CardHeader icon={ArchiveIcon}>Logs</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.DialogButton
            icon={ArchiveIcon} title='Create' color='primary' disabled={logCreating}
            dialog={(props: any) => <CreateLogSet {...props} />}
          />
          <ButtonToolbar.Button title='Download' icon={DownloadIcon}
            onClick={() => startLogSetDownload(selectedLogId)}
            disabled={selectedLogId === ''}
          />
          <ButtonToolbar.DeleteDialogButton
            onSuccess={clearSelectedLog} disabled={isEmpty(selectedLogId)}
            dialog={(props: any) => (
              <DeleteLogSet log={selectedLog} {...props} />
            )}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>Created</Table.Cell>
              <Table.Cell>Size</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            {logs.map((log) => {
              const rowProps: TableRowProps = {};
              if (!log.creating) {
                // Only fully created logs can be selected.
                rowProps.onClick = () => setSelectedLogId(log.id);
                rowProps.selected = selectedLogId === log.id;
              }
              return (
                <Table.Row key={log.id} {...rowProps}>
                  <Table.Cell>{log.creating ? <Loading text='Creating' /> : startCase(log.type)}</Table.Cell>
                  <Table.Cell>{dateTimeLong(log.created)}</Table.Cell>
                  <Table.Cell>{log.creating ? '--' : numberToHumanSize(log.size)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const startLogSetDownload = (id: string) => {
  const options = {
    method: 'GET',
    headers: new Headers({'Authorization': `Bearer ${getToken()}`}),
  };
  const basePath = getBasePath();
  fetch(basePath+'/api/logs/'+id+'/url', options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`Failed to fetch log set download URL. ${response.status} (${response.statusText})`);
      }
    })
    .then((json) => window.open(json.url, '_blank'))
    .catch((e) => console.error(e));
};

const mapStateToProps = (state: Store) => {
  const logsResource = state.resources.logs || {};
  const logs = logsResource.data || [];
  return {
    logs: logs,
    error: logs.error,
    fetching: logs.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLogs: () => dispatch(fetchResource('logs')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
