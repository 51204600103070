// Validate that when creating a new clone, storage must be
// selected. This is done client-side because the selected
// storage's ID is part of the URL's path. Thus, having it
// be empty would result in a completely different request
// being sent to the server, which complicates server-side
// validation.
export default ({storage}: {storage: string}) => {
  const errors = {} as any;

  if (!storage) {
    errors.storage = 'Storage must be selected';
  }

  return errors;
};
