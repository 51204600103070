import React from 'react';

import {WizardPage} from 'spectra-logic-ui/components';

import {Pool} from '@/types';
import DialogDescription from '@/components/form/dialog_description';
import Radio from '@/components/radio';
import {EditOption} from '@/storage/form/enum';

type Props = {
  title: string;
  pool: Pool;
}

const OptionsPage = ({title, pool, ...otherProps}: Props) => (
  <WizardPage
    title={title} {...otherProps}
    description={
      <DialogDescription>Select how you would like to edit <strong>{pool.name}</strong>.</DialogDescription>
    }
  >
    <Radio>
      <Radio.Button name='editOption' label='Modify parameters' value={EditOption.CHANGE_PARAMS} />
      <Radio.Button name='editOption' label='Modify authorization' value={EditOption.CHANGE_AUTH} />
    </Radio>
  </WizardPage>
);

export default OptionsPage;
