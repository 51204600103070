import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';

import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Dispatch, Store} from 'spectra-logic-ui';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import LockIcon from 'spectra-logic-ui/icons/Lock';

import {SSLCertificate} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import SSLCertificateDialog from '@/sslcertificate/edit';

type Props = {
  fetching?: boolean;
  error?: boolean;
  fetchSSLCertificate?: () => Promise<any>;
  sslCert: SSLCertificate;
}
const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
});
const headers = ['Issuer', 'Subject', 'Not Before', 'Not After'];

const SSLCertificatePage = ({fetching = false, error = false, fetchSSLCertificate, sslCert}: Props) => {
  const classes = useStyles();
  useEffect(() => {
    if (fetchSSLCertificate !== undefined) {
      fetchSSLCertificate();
    }
  }, []);
  const renderRows = () => (
    <Table.Row>
      <Table.Cell >{sslCert.issuer}</Table.Cell>
      <Table.Cell >{sslCert.subject}</Table.Cell>
      <Table.Cell >{dateTimeLong(sslCert.notBefore)}</Table.Cell>
      <Table.Cell >{dateTimeLong(sslCert.notAfter)}</Table.Cell>
    </Table.Row>
  );
  return (
    <Card>
      <CardHeader icon={LockIcon}>SSL Certificate</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.EditDialogButton
            dialog={(props: any) => <SSLCertificateDialog certificate={sslCert} {...props} />}
          />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              {headers.map((hdr) => <Table.Cell className={classes.boldText} key={hdr}>{hdr}</Table.Cell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body hasError={error} isLoading={fetching}>
            {sslCert && renderRows()}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const sslcertResource = state.resources.certificate || {};
  return {
    sslCert: sslcertResource.data,
    fetching: sslcertResource.fetching,
    error: sslcertResource.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSSLCertificate: () => dispatch(fetchResource('certificate')),
});

export default connect(mapStateToProps, mapDispatchToProps)(SSLCertificatePage);
