// Define a form component that's used whenever a user is asked to supply
// a multi-factor authentication code after logging in.
import React from 'react';
import {connect} from 'react-redux';
import {Form, FormRenderProps} from 'react-final-form';
import {Location, NavigateFunction} from 'react-router-dom';

import {Button} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {login as loginAction} from 'spectra-logic-ui/actions';
import {loginStyles} from 'spectra-logic-ui/components/login';
import {Dispatch} from 'spectra-logic-ui';

import TextField from '@/components/form/text_field';
import {FormValues} from '@/login/software_token_mfa/types';
import {validate} from '@/login/software_token_mfa/validate';

type Props = {
  location?: Location;
  navigate?: NavigateFunction;
  session?: string;
  challengeName?: string;
  challengeParameters?: {
    [name: string]: string;
  };
  onSubmit?: (...args: any) => void;
}

const useStyles = makeStyles(loginStyles);

const SoftwareTokenMfaForm = ({onSubmit}: Props) => {
  const softwareTokenMfaCode = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();

  React.useEffect(() => {
    if (softwareTokenMfaCode.current !== null) {
      softwareTokenMfaCode.current.focus();
    }
  }, []);

  return (
    <Form
      onSubmit={(values) => {
        if (onSubmit) return onSubmit(values);
      }}
      validate={validate}
    >
      {({error, submitting, handleSubmit, submitError}: FormRenderProps) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <p>Please enter the code from your Multi-Factor Authentication device</p>
          {(error || submitError) && !submitting && <div className='error'>{error || submitError}</div>}
          <TextField
            name='softwareTokenMfaCode' label='Multi-Factor Authentication Code' className={classes.field}
            inputRef={softwareTokenMfaCode}
          />
          <Button type='submit' className={classes.button} fullWidth disabled={submitting}>
            Submit
          </Button>
        </form>
      )}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => {
  const {location, navigate, session = '', challengeName = '', challengeParameters = {}} = ownProps;
  const from = location !== undefined ? (location.state || {} as any).from : '';
  const next = from ? from : '/';

  const onSuccess = (response: any) => {
    if (response.token && response.token != '' && navigate) {
      navigate(next);
    }
  };

  return {
    onSubmit: (values: FormValues) => {
      const valuesToSend = {
        challengeName: challengeName,
        challengeResponses: {
          'USERNAME': challengeParameters.USER_ID_FOR_SRP,
          'SOFTWARE_TOKEN_MFA_CODE': values.softwareTokenMfaCode,
        },
        session: session,
      };
      return dispatch(loginAction(valuesToSend, {onSuccess}));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(SoftwareTokenMfaForm);
