
export enum BucketOwnershipControls {
  OBJECT_WRITER = 'ObjectWriter',
  BUCKET_OWNER_PREFERRED = 'BucketOwnerPreferred',
  BUCKET_OWNER_ENFORCED = 'BucketOwnerEnforced',
}

export enum DefaultRetentionModes {
  COMPLIANCE = 'COMPLIANCE',
  GOVERNANCE = 'GOVERNANCE',
}

export enum DefaultRetentionTimeUnits {
  DAYS = 'DAYS',
  YEARS = 'YEARS',
}

export enum TargetType {
    S3 = 's3',
    S3OTHER = 's3other',
    AZURE = 'azure',
    GOOGLE = 'google',
    BLACKPEARL = 'bp',
    BPLINK = 'bplink',
    BPPOLICY = 'bppolicy',
    VOLUME = 'volume',
}

export enum FormActionType {
  CREATE = 'create',
  EDIT = 'edit',
}

// These must match what is in the worker/severity_type.go
export enum MessageSeverity {
  INFO = 'Info',
  OK = 'Ok',
  WARNING = 'Warning',
  ERROR = 'Error',
}

export enum NodeStatus {
  ok = 'ok',
  unavailable = 'unavailable',
  updating = 'updating',
  degraded = 'degraded',
}

export enum OS {
  BP = 'bp',
  COMMON = 'ubuntu',
  DARWIN = 'darwin',
  VAIL = 'vail',
}

export enum EndpointType {
  Sphere = 'sphere',
  BP = 'bp',
  VM = 'vm',
}

export const EndpointTypes: {[type: string]: string} = {
  [EndpointType.Sphere]: 'Sphere',
  [EndpointType.BP]: 'BlackPearl',
  [EndpointType.VM]: 'Virtual Machine',
};

export enum SphereStatus {
  ok = 'ok',
  updating = 'updating',
  deleting = 'deleting',
}

export enum StorageClass {
  STANDARD = 'STANDARD',
  TIERING = 'INTELLIGENT_TIERING',
  INFREQUENT = 'STANDARD_IA',
  ONEZONE = 'ONEZONE_IA',
  REDUCED = 'REDUCED_REDUNDANCY',
  GLACIERIR = 'GLACIER_IR',
  GLACIER = 'GLACIER',
  DEEPARCHIVE = 'DEEP_ARCHIVE'
}

export const storageClassString = (storageClass: StorageClass | string | undefined) => {
  let sc = '';
  if (storageClass && storageClass != 'ANY') {
    sc = storageClass;
  }
  return sc;
};

export enum ProxyType {
  GLOBAL = 'global',
}

export enum UpdateStatusUpdate {
  ERROR = 'error',
  PROGRESS = 'progress',
  ACTION = 'action',
}
