import React from 'react';
import {Tab, Tabs} from '@mui/material';

import {ApiStatus, StatusIcon} from 'spectra-logic-ui/components';

import {Location, Pod, Pool, StorageUsed} from '@/types';
import {System} from '@/system/types';
import CloudDetails from '@/dashboard/details/cloud';
import OnSiteDetails from '@/dashboard/details/onsite';

type Props = {
  selectedLocationId: string;
  setSelectedLocationId: (...args: any) => void;
  endpoints: Pod[];
  entities: StorageUsed[];
  locations: Location[];
  pools: Pool[];
  system: System;
  fetching: boolean;
}

const LocationDetails = (
  {selectedLocationId, locations, setSelectedLocationId, fetching, pools, endpoints, entities, system}: Props) => {
  const onSiteLocation = locations.find((l: Location) => l.id === selectedLocationId) || {} as Location;
  const cloudPools = pools.filter((p: Pool) => p.id.split('.')[0] === '0');

  return (
    <ApiStatus
      isLoading={fetching || (locations.length > 0 && !selectedLocationId)}
      isEmpty={locations.length === 0}
    >
      <Tabs
        value={selectedLocationId}
        onChange={(event, newValue) => setSelectedLocationId(newValue)}
        scrollButtons='auto'
        variant='scrollable'
      >
        {locations.map((l) => (
          <Tab
            key={l.id} label={l.name} value={l.id}
            icon={<StatusIcon statuses={[l.status === undefined ? '' : l.status]} />}
            iconPosition='start'
          />
        ))}
      </Tabs>
      {selectedLocationId !== '0' &&
        <OnSiteDetails
          endpoints={endpoints} entities={entities} location={onSiteLocation} pools={pools} system={system}
        />}
      {selectedLocationId === '0' && <CloudDetails pools={cloudPools} />}
    </ApiStatus>
  );
};

export default LocationDetails;
