import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {Dispatch} from 'spectra-logic-ui';
import {deleteResource} from 'spectra-logic-ui/actions';
import {Color} from 'spectra-logic-ui/colors';
import {FormDialog} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';

import {LogSet} from '@/types';

type Props = {
  log: LogSet;
  disabled?: boolean;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  description: {
    color: Color.ERROR,
  },
});

const title = 'Delete Log Set';
const description = 'WARNING: Cannot be undone.';

const DeleteLogSet = ({log, disabled = false, onClose, onSubmit, ...others}: Props) => {
  const classes = useStyles();

  if (disabled) {
    return null;
  }

  return (
    <FormDialog
      title={title} submitLabel='Delete' disableSubmit={disabled} onClose={onClose} onSubmit={onSubmit} closeOnSuccess
      {...others}
    >
      {() => (
        <>
          <Typography>
            <strong>Delete {log.type} log set created on {dateTimeLong(log.created)}?</strong>
          </Typography>
          <Typography className={classes.description}>{description}</Typography>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {log}: Props) => ({
  onSubmit: (values: any) => dispatch(deleteResource('logs', log.id, values)),
});

export default connect(undefined, mapDispatchToProps)(DeleteLogSet);
