import {Bucket} from '@/types';
import {ValidationError} from '@/validate';
import {FormValues} from '@/buckets/form/types';
import {DefaultRetentionTimeUnits} from '@/enum';

const maxDays = 36500;
const maxYears = 100;

export const createParametersValidator = (buckets: Bucket[]) => {
  return ({name, owner}: FormValues) => {
    const errors = {} as any;

    // Bucket name error messages are designed to match the AWS console
    if (name) {
      if (/[ ]/.test(name)) {
        errors.name = 'Bucket name contains invalid characters \' \'';
      } else if (name.includes('..')) {
        errors.name = 'Bucket name must not contain two adjacent periods';
      } else if (/[A-Z]/.test(name)) {
        errors.name = 'Bucket name must not contain uppercase characters';
      } else if (name.includes('_')) {
        errors.name = 'Bucket name contains invalid characters \'_\'';
      } else if (!/^[a-z0-9]/.test(name)) {
        errors.name = 'Bucket name must start with a lowercase letter or number';
      } else if (/[\-\.]$/.test(name)) {
        errors.name = 'Bucket name must not end with dash or period';
      } else if (name.length < 3 || name.length > 63) {
        errors.name = 'Bucket name must be between 3 and 63 characters long';
      } else if (/^\d+\.\d+\.\d+\.\d+$/.test(name)) {
        errors.name = 'Bucket name cannot be formatted like an IP address';
      } else if (name.startsWith('xn--')) {
        errors.name = 'Bucket name cannot begin with \'xn--\'';
      } else if (name.endsWith('-s3alias')) {
        errors.name = 'Bucket name cannot end with \'-s3alias\'';
      }

      if (!errors.name) {
        const labels = name.split('.');
        for (let i = 0; i < labels.length; i++) {
          const label = labels[i];
          if (!/^[a-z0-9\-]+$/.test(label)) {
            errors.name = 'Bucket name can only consist of lowercase letters, numbers, periods, and hyphens';
            break;
          } else if (label[0] === '-' || label.slice(-1) === '-') {
            errors.name = 'Bucket name must not contain dash next to period';
          } else if (!/^[a-z0-9]/.test(label)) {
            errors.name = 'Each label must start with a lowercase letter or number';
          } else if (!/[a-z0-9]$/.test(label)) {
            errors.name = 'Each label must end with a lowercase letter or number';
          }
        }
      }
    } else {
      errors.name = 'Bucket name must not be empty';
    }

    if (!errors.name && buckets.find((b) => name === b.name)) {
      errors.name = ValidationError.MUST_BE_UNIQUE;
    }

    if (!owner) {
      errors.owner = 'Bucket owner must not be empty';
    }

    return errors;
  };
};

export const validateACLs = ({acls}: FormValues) => {
  const errors = {acls: []} as any;

  if (acls) {
    errors.acls = acls.map(() => ({}));
    for (let i = 0; i < acls.length; i++) {
      if (!acls[i].id) {
        errors.acls[i].id = ValidationError.CANNOT_BE_BLANK;
      }
    }
  }

  return errors;
};

export const validateRetention = ({useDefaultRetention, mode, timeUnits, time}: FormValues) => {
  const errors = {} as any;

  if (!!useDefaultRetention) {
    if (mode === undefined) {
      errors.mode = 'Must select a retention mode when using default retention';
    } else if (timeUnits === undefined) {
      errors.timeUnits = 'Days or years must be selected';
    } else if (time === undefined || isNaN(Number(time)) || Number(time) <= 0) {
      const msgPrefix = 'Must be a number greater than 0 for how many ';
      const msgSuffix = ' to lock objects';
      if (timeUnits === DefaultRetentionTimeUnits.DAYS) {
        errors.time = msgPrefix + 'days' + msgSuffix;
      } else if (timeUnits === DefaultRetentionTimeUnits.YEARS) {
        errors.time = msgPrefix + 'years' + msgSuffix;
      }
    } else if (timeUnits === DefaultRetentionTimeUnits.DAYS && time > maxDays) {
      errors.time = 'Days cannot be greater than ' + maxDays.toString();
    } else if (timeUnits === DefaultRetentionTimeUnits.YEARS && time > maxYears) {
      errors.time = 'Years cannot be greater than ' + maxYears.toString();
    }
  }
  return errors;
};
