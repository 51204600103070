import React from 'react';
import {Card, Grid, InputAdornment} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import TextField from '@/components/form/text_field';

const useStyles = makeStyles({
  root: {
    padding: 15,
    marginTop: 10,
  },
  grid: {
    '& > *': {
      marginBottom: 10,
    },
  },
});

const ThresholdsCard = () => {
  const classes = useStyles();

  const ThresholdsToolTip = 'Set the threshold ' +
  'percentage to the amount of capacity used ' +
  'from the storage to send a notification that this value has been met. ' +
  '  Changes made to the thresholds for storage that is common ' +
  'in a given endpoint will also be applied to the relevant shared storage.';
  return (
    <Card className={classes.root}>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={6}>
          <TextField
            parse={(value) => {
              const number = Number(value);
              if (!value && value !== '0') {
                return null;
              } else if (isNaN(number)) {
                return value;
              } else {
                return number;
              }
            }}
            name='cautionThreshold'
            label='Caution Threshold'
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            parse={(value) => {
              const number = Number(value);
              if (!value && value !== '0') {
                return null;
              } else if (isNaN(number)) {
                return value;
              } else {
                return number;
              }
            }}
            name='warningThreshold'
            label='Warning Threshold'
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
            tooltip={ThresholdsToolTip} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ThresholdsCard;
