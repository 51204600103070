import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Card from 'spectra-logic-ui/components/card';
import AccountTreeIcon from 'spectra-logic-ui/icons/AccountTree';
import LocationIcon from 'spectra-logic-ui/icons/LocationOn';
import StorageIcon from 'spectra-logic-ui/icons/Storage';

import {Location, Pod, Pool, StorageUsed} from '@/types';
import {isSingle} from '@/single';
import CardHeader from '@/components/card_header';
import SystemComponent from '@/system';
import {System} from '@/system/types';
import LocationDetails from '@/dashboard/details';
import Status from '@/dashboard/status';
import SystemView from '@/dashboard/system_view';

type Props = {
  endpoints?: Pod[];
  entities?: StorageUsed[];
  locations?: Location[];
  pools?: Pool[];
  system?: System;
  fetchLocations?: () => Promise<any>;
  fetchPools?: () => Promise<any>;
  fetchEndpoints?: () => Promise<any>;
  fetchEntities?: () => Promise<any>;
  fetchSystem?: () => Promise<any>;
  fetching?: boolean;
  error?: boolean;
}

const useStyles = makeStyles({
  card: {
    height: 'calc(100% - 30px)',
    marginBottom: 0,
  },
  cardBody: {
    overflowX: 'hidden',
  },
  sphereStatus: {
    flexGrow: 1,
  },
  endpoint: {
    flexGrow: 1,
  },
});

const Dashboard = (props: Props) => {
  const {endpoints = [], entities = [], locations = [], pools = [], system = {} as System,
    fetchLocations, fetchPools, fetchEndpoints, fetchEntities, fetchSystem,
    fetching = false, error = false} = props;

  let sortedLocations = locations;
  if (locations.length > 1) {
    sortedLocations = locations.slice(1);
    sortedLocations.push(locations[0]);
  }
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');

  const classes = useStyles();

  useEffect(() => {
    if (fetchEndpoints !== undefined) {
      fetchEndpoints();
    }
    if (fetchEntities !== undefined) {
      fetchEntities();
    }
    if (fetchLocations !== undefined) {
      fetchLocations();
    }
    if (fetchPools !== undefined) {
      fetchPools();
    }
    if (fetchSystem !== undefined) {
      fetchSystem();
    }
  }, []);

  useEffect(() => {
    // Default to selecting this node's location.
    if (endpoints.length > 0 && sortedLocations.length > 0 && selectedLocationId === '' && system.id) {
      const myEndpointId = system.id ? system.id.split('.')[0] : '-1';
      const myEndpoint = endpoints.find((ep) => ep.id == myEndpointId);
      if (myEndpoint) {
        setSelectedLocationId(myEndpoint.location);
      } else {
        const firstLocationId = (sortedLocations.length > 0) ? sortedLocations[0].id : '';
        setSelectedLocationId(firstLocationId);
      }
    }
  }, [endpoints, locations, system]);

  if (isSingle()) {
    return (
      <Grid container spacing={3}>
        <Grid className={classes.sphereStatus} item xs={6}>
          <Card className={classes.card}>
            <CardHeader icon={StorageIcon} isShort>Sphere Status</CardHeader>
            <Card.Body className={classes.cardBody}>
              <Status />
            </Card.Body>
          </Card>
        </Grid>
        <Grid className={classes.endpoint} item xs={6}>
          <Card className={classes.card}>
            <CardHeader icon={AccountTreeIcon} isShort>Endpoint</CardHeader>
            <Card.Body className={classes.cardBody}>
              <SystemComponent endpoints={endpoints} system={system} fetching={fetching} error={error} />
            </Card.Body>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardHeader icon={LocationIcon} isShort>Location Details</CardHeader>
            <Card.Body>
              <LocationDetails
                selectedLocationId={selectedLocationId} fetching={fetching}
                setSelectedLocationId={setSelectedLocationId} endpoints={endpoints} entities={entities}
                locations={sortedLocations} pools={pools} system={system}
              />
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid container item direction='column' spacing={3} xs={12} lg={3}>
        <Grid className={classes.sphereStatus} item>
          <Card className={classes.card}>
            <CardHeader icon={StorageIcon} isShort>Sphere Status</CardHeader>
            <Card.Body className={classes.cardBody}>
              <Status />
            </Card.Body>
          </Card>
        </Grid>
        <Grid className={classes.endpoint} item>
          <Card className={classes.card}>
            <CardHeader icon={AccountTreeIcon} isShort>Endpoint</CardHeader>
            <Card.Body className={classes.cardBody}>
              <SystemComponent endpoints={endpoints} system={system} fetching={fetching} error={error} />
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={9}>
        <SystemView
          locations={sortedLocations} error={error}
          selectedLocationId={selectedLocationId}
          onLocationSelect={setSelectedLocationId}
        />
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardHeader icon={LocationIcon} isShort>Location Details</CardHeader>
          <Card.Body>
            <LocationDetails
              selectedLocationId={selectedLocationId} fetching={fetching}
              setSelectedLocationId={setSelectedLocationId} endpoints={endpoints} entities={entities}
              locations={sortedLocations} pools={pools} system={system}
            />
          </Card.Body>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: Store) => {
  const endpoints = state.resources.endpoints || {};
  const entities = state.resources['usage/entities'] || {};
  const locations = state.resources.locations || {};
  const pools = state.resources.storage || {};
  const system = state.resources.system || {};

  return {
    endpoints: endpoints.data,
    entities: entities.data,
    locations: locations.data,
    pools: pools.data,
    system: system.data,
    fetching: endpoints.fetching || locations.fetching || pools.fetching,
    error: endpoints.error || locations.error || pools.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLocations: () => dispatch(fetchResource('locations')),
  fetchEndpoints: () => dispatch(fetchResource('endpoints')),
  fetchEntities: () => (dispatch(fetchResource('usage/entities'))),
  fetchPools: () => dispatch(fetchResource('storage')),
  fetchSystem: () => dispatch(fetchResource('system')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
