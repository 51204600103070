import React from 'react';

import {Grid} from '@mui/material';

import {WizardPage, TextField} from 'spectra-logic-ui/components';

const ResetPasswordPage = ({alreadyReset, ...props}: any) => {
  // The user came back to this page after already resetting the password,
  // in which case we can't reset it again.
  if (alreadyReset) {
    return (
      <WizardPage {...props}>
        <p>The Administrator password was already reset</p>
      </WizardPage>
    );
  }

  return (
    <WizardPage {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>Please enter a new Administrator password</p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='newPassword'
            label='New Administrator Password'
            type='password'
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='confirmNewPassword'
            label='Confirm New Administrator Password'
            type='password'
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </WizardPage>
  );
};

export default ResetPasswordPage;
