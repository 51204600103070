import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import Table from 'spectra-logic-ui/components/table';

import {Location, Pod, Pool} from '@/types';
import {StorageRowId} from '@/storage/types';
import OnSiteStorageTableLocation from '@/storage/on_site_storage_table_location';

type Props = {
  selectedRowId: StorageRowId;
  selectRowId: Function;
  setOpenDetails: Function;
  closeDetails: Function;
  locations: Location[];
  pools: Pool[];
  endpoints: Pod[];
  fetchLocations: Function;
}

const OnSiteStorageTable = (props: Props) => {
  const {
    selectedRowId, selectRowId, setOpenDetails, closeDetails, locations, pools, endpoints,
    fetchLocations,
  } = props;

  useEffect(() => {
    fetchLocations();
  }, []);

  const onSiteLocations = locations.filter((l) => l.id !== '0');
  const onSiteEndpoints = endpoints.filter((e) => e.id !== '0');
  if (onSiteEndpoints.length === 0) {
    // So 'No items To display' is shown
    return <Table><Table.Body/></Table>;
  }

  const podsByLocation = {} as any;
  onSiteEndpoints.forEach((pod) => {
    // It's possible for a pod to not have a location (i.e. cloudless vail)
    const location = pod.location || '';
    if (!podsByLocation[location]) {
      podsByLocation[location] = [];
    }
    podsByLocation[location].push({type: pod.type === 'bp' ? 'BlackPearl' : 'VM', pod: pod});
  });

  return (
    <Table>
      {onSiteLocations.map((l, i) => (
        <OnSiteStorageTableLocation key={l.id} index={i} location={l} pools={pools} selectRowId={selectRowId}
          selectedRowId={selectedRowId} setOpenDetails={setOpenDetails} closeDetails={closeDetails}
          pods={podsByLocation[l.id] || []} />
      ))}
      {podsByLocation[''] &&
        <OnSiteStorageTableLocation index={onSiteLocations.length} pools={pools} selectRowId={selectRowId}
          selectedRowId={selectedRowId} setOpenDetails={setOpenDetails} closeDetails={closeDetails}
          pods={podsByLocation[''] || []} />}
    </Table>
  );
};

const mapStateToProps = (state: Store) => {
  const locations = state.resources.locations || {};
  return {
    locations: locations.data || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLocations: () => {
    dispatch(fetchResource('locations'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OnSiteStorageTable);
