import React from 'react';

import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {SpectraLogoColor} from 'spectra-logic-ui/colors';
import {numberToHumanSize} from 'spectra-logic-ui/helpers/number';

type Props = {
  heading: string;
  used: number;
  optional: number;
  total: number;
}

const StorageChart = ({heading, optional, used, total}: Props) => {
  let percent = Math.floor(((used-optional) / total) * 100);
  let percentDisplay = percent + '%';

  const color = SpectraLogoColor.BLUE;
  if (isNaN(percent)) {
    percent = 0;
    percentDisplay = 'N/A';
  }

  return (
    <div>
      <div style={{
        fontSize: '0.8em',
        margin: '0 auto',
        width: '100%',
        textAlign: 'center',
      }}
      >
        {heading}
      </div>
      <div
        style={{
          position: 'relative',
          width: '100px',
          height: '100px',
          margin: '10px auto',
        }}
      >
        <div style={{position: 'absolute'}}>
          <CircularProgressbar
            value={percent}
            styles={{
              path: {
                stroke: color,
              },
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <h2 style={{color: color}}>{percentDisplay}</h2>
          </div>
        </div>
      </div>
      <div style={{
        fontSize: '0.8em',
        textAlign: 'center',
      }}>
        {numberToHumanSize(used-optional)} / {numberToHumanSize(total)}
      </div>
    </div>
  );
};

export default StorageChart;
