import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {Toolbar} from 'spectra-logic-ui/components';

import Paginator, {PaginatorProps} from '@/components/paginator';

const useStyles = makeStyles({
  filler: {
    flexGrow: 1,
  },
});

// PaginatorFooter wraps Paginator in a dense toolbar.  This is designed
// to be rendered at the bottom of a table.
const PaginatorFooter = ({from, showNext, ...others}: PaginatorProps) => {
  const classes = useStyles();

  if (from === 1 && !showNext) {
    return null;
  }

  return (
    <Toolbar variant='dense'>
      <span className={classes.filler} />
      <Paginator from={from} showNext={showNext} {...others} />
    </Toolbar>
  );
};

export default PaginatorFooter;
