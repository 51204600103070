import React from 'react';
import isEmpty from 'is-empty';

import {SlidePanel} from 'spectra-logic-ui/components';

import {Object} from '@/buckets/objects/types';
import PropertiesDetails from '@/buckets/objects/details/properties';
import StorageDetails from '@/buckets/objects/details/storage';

type Props = {
  object: Object;
  bucketName: string;
  clearObject: () => any;
  fetchObjects?: Function;
}

const Details = ({object, bucketName, clearObject, fetchObjects}: Props) => {
  return (
    <SlidePanel
      title={object.key} options={['Properties', 'Storage']}
      open={!isEmpty(object)} onClose={clearObject}
    >
      <PropertiesDetails object={object} />
      <StorageDetails bucketName={bucketName} object={object} fetchObjects={fetchObjects} />
    </SlidePanel>
  );
};

export default Details;
