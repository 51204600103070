import React from 'react';
import {connect} from 'react-redux';

import {Divider} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import AddressLookup from '@/locations/form/create/address_lookup';
import Confirmation from '@/locations/form/create/confirmation';
import {FormValues} from '@/locations/form/create/types';
import validate from '@/locations/form/create/validate';

type Props = {
  onSubmit: (values: FormValues) => Promise<any>;
}

const useStyles = makeStyles({
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const AddLocationDialog = (props: Props) => {
  const form = 'Add Location';

  return (
    <FormDialog title={form} validate={validate} {...props}>
      {() => <AddLocationFormContent />}
    </FormDialog>
  );
};

export const AddLocationFormContent = () => {
  const classes = useStyles();

  return (
    <>
      <AddressLookup />
      <Divider className={classes.divider} />
      <Confirmation />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: FormValues) => {
    const body = {
      name: values.locationName,
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
    };
    return dispatch(createResource('locations', body));
  },
});

export default connect(undefined, mapDispatchToProps)(AddLocationDialog);
