import React from 'react';
import {connect} from 'react-redux';
import {FieldArray} from 'react-final-form-arrays';

import {Button, Card, Grid, IconButton, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';

import {fetchResource} from 'spectra-logic-ui/actions';
import {Loading} from 'spectra-logic-ui/components';
import {WizardPage} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {Account} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import Checkbox from '@/components/form/checkbox';

type PageProps = {
  title: string;
  accounts?: Account[];
  fetching?: boolean;
  fetchAccounts?: () => Promise<any>;
  validate: (formValues: any) => object;
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    padding: 10,
    marginBottom: 8,
  },
  subtitle: {
    lineHeight: 'normal',
    display: 'block',
    fontStyle: 'italic',
    marginBottom: 10,
  },
  inputFields: {
    display: 'flex',
    flex: '1 1 auto',
  },
  select: {
    '& .MuiInputBase-input': {
      padding: '14px 10px 12px 10px',
    },
  },
});

const blockPublicAclsTip = 'Setting this will block public access permissions applied to newly ' +
  'added buckets or objects, and prevent the creation of new public access ACLs for existing buckets ' +
  'and objects. This setting doesn’t change any existing permissions that allow public access to S3 ' +
  'resources using ACLs.';
const ignorePublicAclsTip = 'Setting this will ignore all ACLs that grant public access to buckets and objects.';

const Acls = ({accounts = [], fetching = false, fields}: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Checkbox name='blockPublicAcls' label='Block Public ACLs' tooltip={blockPublicAclsTip} />
      <Checkbox name='ignorePublicAcls' label='Ignore Public ACLs' tooltip={ignorePublicAclsTip} />
      <Typography variant='caption' className={classes.subtitle}>
        Note: Maximum number of ACLs is 100.
      </Typography>
      {fields.map((acl: string, index: number) => (
        <Card key={index} className={classes.card}>
          <div className={classes.inputFields}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {fetching ?
                  <Loading /> :
                  <FormSingleSelect
                    className={classes.select}
                    name={`${acl}.id`} label='AWS Canonical ID'
                    options={accounts.map((account: Account) => (
                      {key: account.canonicalId, text: account.canonicalId}),
                    )}
                  />
                }
              </Grid>
              <Grid item xs={11}>
                <Checkbox name={`${acl}.read`} label='Read' />
                <Checkbox name={`${acl}.readACP`} label='Read ACP' />
                <Checkbox name={`${acl}.write`} label='Write' />
                <Checkbox name={`${acl}.writeACP`} label='Write ACP' />
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => fields.remove(index)} size='large'>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </Card>
      ))}
      <div>
        {fields.length < 100 &&
          <Button color='primary' onClick={() => fields.push({})}>
            Add ACL
          </Button>
        }
      </div>
    </React.Fragment>
  );
};

const AclsPage = ({title, accounts, fetching = false, fetchAccounts, ...otherProps}: PageProps) => {
  React.useEffect(() => {
    if (fetchAccounts !== undefined) {
      fetchAccounts();
    }
  }, []);

  return (
    <WizardPage title={title} {...otherProps}>
      <FieldArray name='acls' component={Acls} accounts={accounts} fetching={fetching} />
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const accountsResources = state.resources.accounts || {};
  return {
    accounts: accountsResources.data || [],
    fetching: accountsResources.fetching || false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccounts: () => dispatch(fetchResource('accounts')),
});


export default connect(mapStateToProps, mapDispatchToProps)(AclsPage);
