import React from 'react';
import {connect} from 'react-redux';
import {Dispatch, Store} from 'spectra-logic-ui';
import {createPatch, fetchResource, patchResource} from 'spectra-logic-ui/actions';
import {FormDialog, FormRenderProps} from 'spectra-logic-ui/components';
import {EndpointType} from '@/enum';
import {Location, Pod, PodPatchFields} from '@/types';
import FormSingleSelect from '@/components/form/single_select';
import {EndpointFormValues} from '@/storage/form/types';
import TextField from '@/components/form/text_field';

import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@/components/form/checkbox';

type Props = {
  locations?: Location[];
  fetching?: boolean;
  endpoint: Pod;
  fetchLocations?: () => Promise<any>;
  onSubmit: (values: EndpointFormValues) => Promise<any>;
}

const useStyles = makeStyles({
  textField: {
    marginTop: 10,
  },
});

const debugTooltip = 'This initiates additional detailed logging which will be '+
    'included in future logsets. A level of 9 gives the most detail.';
const EditEndpointDialog = ({endpoint, locations=[], fetchLocations, fetching, ...others}: Props) => {
  const hostsStr = (endpoint.hosts || []).join('\n');
  const initialValues = {location: endpoint.location, hosts: hostsStr, debug: endpoint.debug ?
    endpoint.debug : '0', url: endpoint.url, discover: !endpoint.staticURL} as EndpointFormValues;
  const title = 'Edit ' + (endpoint.type === EndpointType.BP ? 'BlackPearl' : 'Virtual Machine');
  const urlTooltip = 'The URL is used when other systems communicate with this endpoint. '+
      'In most cases the correct value can be discovered using name resolution services.';
  const hostsTooltip = 'Enter hostnames in addition to "'+endpoint.name+'" that may be used to access this endpoint.';

  React.useEffect(() => {
    if (fetchLocations) {
      fetchLocations();
    }
  }, []);
  const classes = useStyles();
  const options = locations
    .filter((location) => location.id !== '0')
    .map((location) => ({key: location.id, text: location.name})) as any;
  const debugOptions = [{key: '0', text: 'Disabled'}];
  for (let i = 1; i < 10; i++) {
    debugOptions.push({key: i.toString(), text: i.toString()});
  }

  return (
    <FormDialog title={title} initialValues={initialValues} {...others}>
      {({form, values}: FormRenderProps) => (
        <>
          <FormSingleSelect label='Location' name='location' options={options} disabled={fetching} />
          <FormSingleSelect name='debug' label='Debug Logging' options={debugOptions} tooltip={debugTooltip}/>
          <Checkbox name='discover' label='Discover Endpoint URL' onChange={() => {
            if (!values.discover) {
              form.mutators.setFormValue('url', initialValues.url);
            }
          }} tooltip={urlTooltip} />
          <TextField
            name='url'
            className={classes.textField}
            label='URL'
            disabled={values.discover}
            variant='outlined'/>
          <TextField
            name='hosts'
            className={classes.textField}
            label='Additional Hosts'
            multiline
            rows={4}
            variant='outlined'
            tooltip={hostsTooltip}
          />

        </>
      )}
    </FormDialog>
  );
};

const mapStateToProps = (state: Store) => {
  const locations = state.resources['locations'] || {};
  return {
    locations: locations.data,
    fetching: locations.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {endpoint}: Props) => ({
  fetchLocations: () => {
    return dispatch(fetchResource('locations'));
  },
  onSubmit: (values: EndpointFormValues) => {
    const initial = {
      debug: endpoint.debug ? Number(endpoint.debug) : 0,
      location: endpoint.location,
      hosts: endpoint.hosts,
      url: endpoint.url,
    } as Pod;
    let newHosts = [] as string[];
    if (values.hosts) {
      newHosts = values.hosts.replace(/\n+$/, '').split('\n');
    }
    const body = {
      debug: Number(values.debug),
      location: values.location,
      url: values.url,
      hosts: newHosts,
    };
    if (values.discover) {
      if (values.discover !== !endpoint.staticURL) {
        body.url = '';
      } else {
        body.url = endpoint.url;
      }
    } else {
      if (values.discover !== !endpoint.staticURL) {
        initial.url = '';
      }
      body.url = values.url.trim();
    }
    const upd = createPatch(PodPatchFields, initial, body);
    return dispatch(patchResource('endpoints', endpoint.id, upd));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEndpointDialog);
