import {FormData} from '@/iam/groups/form/types';

// Validate that when creating a new IAM group, name cannot
// be empty. This is done client-side because the name
// is part of the URL's path. Thus, having it be empty would
// result in a completely different request being sent to
// the server, which complicates server-side validation.
export default ({name}: FormData) => {
  const errors = {} as any;

  if (!name) {
    errors.name = 'Name must not be empty';
  }

  return errors;
};
