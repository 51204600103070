import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import SettingsIcon from 'spectra-logic-ui/icons/Build';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {getToken} from 'spectra-logic-ui/app/auth';
import {getBasePath} from 'spectra-logic-ui/app/base_path';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import {timeShorter} from 'spectra-logic-ui/helpers/date';
import DownloadIcon from 'spectra-logic-ui/icons/GetApp';

import {isSingle} from '@/single';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import {System} from '@/system/types';
import EditSettingsDialog from '@/settings/form/edit';

type Props = {
  system: System;
  error: boolean;
  fetching: boolean;
  fetchSystem?: () => Promise<any>;
}

const startVmdkDownload = () => {
  const options = {
    method: 'POST',
    headers: new Headers({'Authorization': `Bearer ${getToken()}`}),
  };
  const basePath = getBasePath();
  fetch(basePath+'/api/updates/vmdk-url', options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`Failed to fetch VMDK download URL. ${response.status} (${response.statusText})`);
      }
    })
    .then((json) => window.open(json.url, '_blank'))
    .catch((e) => console.error(e));
};

const Settings = ({system, error, fetching, fetchSystem}: Props) => {
  useEffect(() => {
    if (fetchSystem) {
      fetchSystem();
    }
  }, []);

  system.nightly = system.nightly ? system.nightly : '00:00';
  let nightly = `${system.nightly ? system.nightly : '00:00'} UTC`;
  const nightlyUTCParts = system.nightly.split(':');
  if (nightlyUTCParts.length === 2) {
    const localNightly = new Date();
    localNightly.setUTCHours(parseInt(nightlyUTCParts[0], 10));
    localNightly.setUTCMinutes(parseInt(nightlyUTCParts[1], 10));
    nightly += ` (${timeShorter(localNightly)})`;
  }

  return (
    <Card>
      <CardHeader icon={SettingsIcon}>Global Settings</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.EditDialogButton
            dialog={(props: any) => <EditSettingsDialog system={system} {...props} />}
            disabled={error || fetching}
          />
          <ButtonToolbar.Button title='Download VM Image' icon={DownloadIcon} onClick={startVmdkDownload} />
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Setting</Table.Cell>
              <Table.Cell>Value</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            <Table.Row>
              <Table.Cell>Diagnostic Monitor</Table.Cell>
              <Table.Cell>{system.monitor ? 'Enabled' : 'Disabled'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Nightly Processing</Table.Cell>
              <Table.Cell>{nightly}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Namespace</Table.Cell>
              <Table.Cell>{system.namespace}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>AWS Account ID</Table.Cell>
              <Table.Cell>{system.awsAccountId}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>AWS Region</Table.Cell>
              <Table.Cell>{system.awsRegion}</Table.Cell>
            </Table.Row>
            {isSingle() &&
              <Table.Row>
                <Table.Cell>AWS Access Key ID</Table.Cell>
                <Table.Cell>{system.awsKey}</Table.Cell>
              </Table.Row>
            }
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const system = state.resources.system || {};
  return {
    system: system.data || {},
    error: system.error,
    fetching: system.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSystem: () => dispatch(fetchResource('system')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
