import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {MessageSeverity} from '@/enum';
import {CreateSphereAdministrator} from '@/help';
import Checkbox from '@/components/form/checkbox';
import TextField from '@/components/form/text_field';
import {MessageForm} from '@/administrators/form/enum';
import {FormData} from '@/administrators/form/create/types';

const CreateAdministratorDialog = (props: any) => (
  <FormDialog title='Create Sphere Administrator' getHelpLocation={() => CreateSphereAdministrator}
    {...props}>
    {() => (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField name='username' label='Username' />
          </Grid>
          <Grid item xs={6}>
            <TextField name='email' label='Email Address'
              helperText='A temporary password will be sent to this address' />
          </Grid>
        </Grid>
        <br />{MessageForm.SELECT}<br />
        <div className='checkboxes'>
          <Checkbox name='info' label={MessageSeverity.INFO} />
          <Checkbox name='ok' label={MessageSeverity.OK} />
          <Checkbox name='warning' label={MessageSeverity.WARNING} />
          <Checkbox name='error' label={MessageSeverity.ERROR} />
        </div>
      </>
    )}
  </FormDialog>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: FormData) => dispatch(createResource('users', values)),
});

export default connect(undefined, mapDispatchToProps)(CreateAdministratorDialog);
