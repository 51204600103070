import React from 'react';
import {useFormState} from 'react-final-form';
import makeStyles from '@mui/styles/makeStyles';

import {WizardPage} from 'spectra-logic-ui/components';

import {FormActionType, StorageClass, TargetType} from '@/enum';
import NoteCard from '@/components/note_card';
import DialogDescription from '@/components/form/dialog_description';
import {isStorageClassArchival} from '@/storage/archive';
import {EditOption} from '@/storage/form/enum';

type Props = {
  action: FormActionType;
  title: string;
  name?: string;
  item?: string;
  storageClass?: StorageClass;
}

const useStyles = makeStyles({
  errorCard: {
    marginTop: 10,
  },
  content: {
    '& > *': {
      marginTop: 15,
    },
  },
});

const CloudConfirmPage = ({action, title, ...otherProps}: Props) => {
  const classes = useStyles();

  const formState = useFormState();
  const name = formState.values.name;
  const item = formState.values.item;
  const storageClass = formState.values.storageClass;
  const url = formState.values.url;
  const region = formState.values.region;
  const accessKey = formState.values.accessKey;
  const secretKey = formState.values.secretKey;
  const account = formState.values.account;
  const secret = formState.values.secret;
  const arn = formState.values.arn;
  const externalid = formState.values.externalid;
  const target = formState.values.target;
  const link = formState.values.link;
  const pauseNotifications = formState.values.pauseNotifications;
  const cloneRestore = formState.values.cloneRestore;
  const hasRecoverable = formState.values.recoverable !== undefined;
  const recoverable = formState.values.recoverable;
  const editOption = formState.values.editOption;

  const showAuth = target !== TargetType.GOOGLE &&
    (action === FormActionType.CREATE || (action === FormActionType.EDIT && editOption === EditOption.CHANGE_AUTH));
  const showParams = action === FormActionType.CREATE ||
    (action === FormActionType.EDIT && editOption === EditOption.CHANGE_PARAMS);

  return (
    <WizardPage
      title={title} {...otherProps}
      description={<DialogDescription>Verify your configuration is correct.</DialogDescription>}
    >
      <div className={classes.content}>
        {showAuth &&
          <NoteCard title='Authorization'>
            <NoteCard.Table>
              <React.Fragment>
                {target === TargetType.S3OTHER &&
                    <React.Fragment>
                      <NoteCard.TableRow name='Data Path Endpoint' value={url} />
                      <NoteCard.TableRow name='Access Key' value={accessKey} />
                      <NoteCard.TableRow name='Secret Key' value={secretKey} />
                    </React.Fragment>
                }
                {target === TargetType.AZURE &&
                    <React.Fragment>
                      {action === FormActionType.CREATE && <NoteCard.TableRow name='Storage Account' value={account} />}
                      <NoteCard.TableRow name='Shared Secret' value={secret} />
                    </React.Fragment>
                }
                {target === TargetType.S3 &&
                  <React.Fragment>
                    <NoteCard.TableRow name='Region' value={region} />
                    <NoteCard.TableRow name='AWS Access Key ID' value={accessKey} />
                    <NoteCard.TableRow name='AWS Secret Access Key' value={secretKey} />
                    <NoteCard.TableRow name='AWS IAM Role ARN' value={arn} />
                    <NoteCard.TableRow name='AWS IAM Role External ID' value={externalid} />
                  </React.Fragment>
                }
              </React.Fragment>
            </NoteCard.Table>
          </NoteCard>
        }
        {showParams &&
          <NoteCard title='Parameters'>
            <NoteCard.Table>
              <React.Fragment>
                <NoteCard.TableRow name='Storage Name' value={name} />
                <NoteCard.TableRow name={target === TargetType.AZURE ?
                  'Container' : 'Cloud Bucket'} value={item} />
                {(target === TargetType.S3 || target === TargetType.S3OTHER) &&
                  <>
                    <NoteCard.TableRow name='Link to Bucket' value={!link ? 'Do Not Link' : link} />
                    {!!link &&
                      <NoteCard.TableRow name='Notifications' value={pauseNotifications ? 'Paused' : 'Enabled'} />
                    }
                  </>
                }
                <NoteCard.TableRow name='Storage Class' value={storageClass} />
                {hasRecoverable &&
                    <NoteCard.TableRow name='Third-party Recovery' value={recoverable ? 'Enabled' : 'Disabled'} />}
                {isStorageClassArchival(storageClass) &&
                  <NoteCard.TableRow name='Restore To New Clone' value={cloneRestore ? 'Enabled' : 'Disabled'} />}
              </React.Fragment>
            </NoteCard.Table>
          </NoteCard>
        }
      </div>
    </WizardPage>
  );
};

export default CloudConfirmPage;
