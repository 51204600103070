export interface System {
  id?: string;
  name: string;
  os: string;
  type: string;
  sphere?: string;
  key?: string;
  monitor?: boolean;
  nightly?: string;
  namespace?: string;
  awsAccountId?: string;
  awsRegion?: string;
  awsKey?: string;
}

export interface Address {
  address: string;
  prefixLength: Number;
}

export const SystemPatchFields = [
  'name',
  'key',
  'monitor',
  'nightly',
  'namespace',
  'awsRegion',
  'awsKey',
  'awsSecret',
];
