import React from 'react';
import {connect} from 'react-redux';

import {Dispatch} from 'spectra-logic-ui';
import {deleteResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';

import {IAMGroup, IAMUser} from '@/iam/types';
import {Typography} from '@mui/material';

type Props = {
  user: IAMUser;
  group: IAMGroup;
  onSubmit: (values: any) => Promise<any>;
}

const RemoveUserFromGroupDialog = ({user, group, ...others}: Props) => {
  return (
    <FormDialog title='Remove IAM User From Group' submitLabel='Remove' closeOnSuccess {...others}>
      {() => (
        <Typography>
          <strong>Remove '{user.username}' from '{group.name}'?</strong>
        </Typography>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {user, group}: Props) => {
  const resource = `iam/users/${user.accountid}/${user.username}/groups`;
  return {
    onSubmit: () => dispatch(deleteResource(resource, group.name, {})),
  };
};

export default connect(null, mapDispatchToProps)(RemoveUserFromGroupDialog);
