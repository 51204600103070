import React from 'react';
import {Form} from 'react-final-form';

import {Button, DialogActions, DialogContent, Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {VerificationResult} from '@/buckets/objects/types';
import isEmpty from 'is-empty';
import {Color} from 'spectra-logic-ui/colors';

type Props = {
  disabled?: boolean;
  verificationResult: VerificationResult;
  onClose: () => void;
  submitting?: boolean;
  onSubmit: (values: any) => Promise<any>;
  error?: string;
}

const useStyles = makeStyles({
  description: {
    marginBottom: 10,
  },
  errorText: {
    color: Color.ERROR,
    padding: 10,
  },
});

const VerifyCloneForm = ({disabled, verificationResult, onClose, onSubmit}: Props) => {
  const classes = useStyles();
  if (disabled) {
    return null;
  }
  return (
    <Form onSubmit={onSubmit}>
      {({error, handleSubmit, submitError, submitting}) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {(error || submitError) && !submitting && <div className='error'>{error || submitError}</div>}
            {isEmpty(verificationResult) &&
              <Typography>Are you sure you want to verify the clone?</Typography>
            }
            {!isEmpty(verificationResult) && !isEmpty(verificationResult.errorMessage) &&
              <Typography className={classes.errorText}>
                Verification failed: {verificationResult.errorMessage}
              </Typography>
            }
            {!isEmpty(verificationResult) && isEmpty(verificationResult.errorMessage) &&
              <Typography>Clone OK</Typography>
            }
          </DialogContent>
          <DialogActions>
            {isEmpty(verificationResult) && <Button color='primary' disabled={submitting}
              type='submit'>Submit</Button>}
            {!isEmpty(verificationResult) && <Button color='primary' onClick={onClose}>Close</Button>}
          </DialogActions>
        </form>
      )}
    </Form>
  );
};

export default VerifyCloneForm;
