import React from 'react';
import {connect} from 'react-redux';

import {Dispatch} from 'spectra-logic-ui';
import {FormDialog} from 'spectra-logic-ui/components';
import {updateResource} from 'spectra-logic-ui/actions';

import TextField from '@/components/form/text_field';
import {FormData} from '@/iam/groups/form/types';
import validate from '@/iam/groups/form/validate';

type Props = {
  account: string;
  onSubmit: (values: any) => Promise<any>;
}

const CreateIamGroupDialog = (props: Props) => {
  return (
    <FormDialog title='Create IAM Group' validate={validate} {...props}>
      {() => (<TextField name='name' label='Name' />)}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {account}: Props) => {
  const resource = `iam/groups/${account}`;
  return {
    onSubmit: ({name}: FormData) => dispatch(updateResource(resource, name, {})),
  };
};

export default connect(undefined, mapDispatchToProps)(CreateIamGroupDialog);
