import React from 'react';
import {connect} from 'react-redux';
import {Form, FormRenderProps} from 'react-final-form';

import {Button} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {createResource} from 'spectra-logic-ui/actions';
import {loginStyles} from 'spectra-logic-ui/components/login';
import {Dispatch, RequestOptions} from 'spectra-logic-ui';

import TextField from '@/components/form/text_field';
import {FormValues} from '@/login/forgot_password/form/types';
import {validate} from '@/login/forgot_password/form/validate';

type Props = {
  setUsername?: (username: string) => void;
  onSubmit?: (...args: any) => Promise<any> | void;
}

const useStyles = makeStyles(loginStyles);

const Index = ({onSubmit}: Props) => {
  const usernameRef = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();

  React.useEffect(() => {
    if (usernameRef.current !== null) {
      usernameRef.current.focus();
    }
  }, []);

  return (
    <Form
      onSubmit={(values) => {
        if (onSubmit) return onSubmit(values);
      }}
      validate={validate}
    >
      {({error, submitting, handleSubmit, submitError}: FormRenderProps) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          {(error || submitError) && !submitting && <div className='error'>{error || submitError}</div>}
          <TextField name='username' label='Username' className={classes.field} inputRef={usernameRef} />
          <Button type='submit' className={classes.button} fullWidth disabled={submitting}>
            Request Password Reset Code
          </Button>
        </form>
      )}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {setUsername}: Props) => ({
  onSubmit: ({username}: FormValues) => {
    const onSuccess = () => setUsername !== undefined && setUsername(username);
    return dispatch(createResource(`users/${username}/forgot_password`, undefined, {onSuccess} as RequestOptions));
  },
});

export default connect(undefined, mapDispatchToProps)(Index);
