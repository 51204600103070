import React from 'react';
import {connect} from 'react-redux';

import {Dispatch} from 'spectra-logic-ui';
import {FormDialog} from 'spectra-logic-ui/components';
import {createResource} from 'spectra-logic-ui/actions';

import {Typography} from '@mui/material';

type Props = {
  bucket: string;
  description: string;
  onSubmit: (values: any) => Promise<any>;
}

const ScanBucketDialog = (props: Props) => {
  const {description, onSubmit, ...others} = props;

  return (
    <FormDialog title='Scan Bucket' submitLabel='Scan' onSubmit={onSubmit} {...others}>
      {() => (
        <Typography>{description}</Typography>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {bucket}: Props) => {
  const resource = `buckets/${bucket}/scan`;
  return {
    onSubmit: () => dispatch(createResource(resource)),
  };
};

export default connect(undefined, mapDispatchToProps)(ScanBucketDialog);
