import React from 'react';
import {connect} from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {isAuthEnabled} from 'spectra-logic-ui/app/auth';
import Card from 'spectra-logic-ui/components/card';
import Table from 'spectra-logic-ui/components/table';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';
import LockIcon from 'spectra-logic-ui/icons/Lock';
import KeyIcon from 'spectra-logic-ui/icons/VpnKey';

import {Entitlement} from '@/types';
import ButtonToolbar from '@/components/button_toolbar';
import CardHeader from '@/components/card_header';
import {System} from '@/system/types';
import ActivationKeyDialog from '@/updates/form/activation_dialog';
import EntitlementsDialog from '@/entitlements/edit';

type Props = {
  entitlements?: Entitlement[];
  system: System;
  fetching?: boolean;
  error?: boolean;
  fetchEntitlements?: () => Promise<any>;
  fetchSystem?: () => Promise<any>;
}

const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
});

const headers = ['Dimension', 'Value', 'Expiration'];

const Entitlements = ({entitlements = [], system, error = false, fetching = false, fetchEntitlements,
  fetchSystem}: Props) => {
  const classes = useStyles();

  React.useEffect(() => {
    if (fetchEntitlements !== undefined) {
      fetchEntitlements();
    }
    if (fetchSystem !== undefined) {
      fetchSystem();
    }
  }, []);

  const hasKey = system === undefined ? false : !!system.key;

  return (
    <Card>
      <CardHeader icon={LockIcon}>Entitlements</CardHeader>
      <Card.Body>
        <ButtonToolbar>
          <ButtonToolbar.EditDialogButton
            dialog={(props: any) => <EntitlementsDialog entitlement={entitlements} {...props} />}
          />
          {isAuthEnabled() ? <ButtonToolbar.DialogButton icon={KeyIcon}
            title={hasKey ? 'Get Activation Key' : 'Set Activation Key'} dialog={(props: any) =>
              <ActivationKeyDialog system={system} {...props} />}
          /> : <></>}
        </ButtonToolbar>
        <Table>
          <Table.Header>
            <Table.Row>
              {headers.map((hdr) => <Table.Cell className={classes.boldText} key={hdr}>{hdr}</Table.Cell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body isLoading={fetching} hasError={error}>
            {entitlements.map((entitlement) => (
              <Table.Row
                key={entitlement.dimension}
              >
                <Table.Cell>{entitlement.dimension}</Table.Cell>
                <Table.Cell>{entitlement.value === true ? 'Enabled' : entitlement.value}</Table.Cell>
                <Table.Cell>{entitlement.expires ? dateTimeLong(entitlement.expires) : '--'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};


const mapStateToProps = (state: Store) => {
  const entitlementsResource = state.resources.entitlements || {};
  const system = state.resources.system || {};
  return {
    entitlements: entitlementsResource.data,
    entitlementsError: entitlementsResource.error,
    entitlementsFetching: entitlementsResource.fetching,
    system: system.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchEntitlements: () => dispatch(fetchResource('entitlements')),
  fetchSystem: () => dispatch(fetchResource('system')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Entitlements);
