import React from 'react';
import {connect} from 'react-redux';

import {Store} from 'spectra-logic-ui';

type Props = {
  bootstrappedName: string;
  single: boolean;
  system: any;
};

// Title renders the main page title which includes the hostname of the system.
// It supports getting the hostname two different ways:
//  1) The passed in `bootstrappedName` which is intended to be supplied by
//     the server when rendering `index.html`. This is used as a fallback so
//     the hostname is available when the application is not authenticated
//     (i.e. the login page) and thus it can't get a "GET system" call.
//  2) The `system` resource in Redux. This is used so the current hostname
//     can be obtained in case it was updated after the page was rendered.
//
// Title generation should be kept in sync with how mgmt/server/server.go does it.
const Title = ({bootstrappedName, single, system}: Props) => {
  const name = system.name ? system.name : bootstrappedName;

  return <>{single ? 'BlackPearl S3' : 'Spectra Vail'}{!single && <sup style={{marginLeft: 2}}>&reg;</sup>} {name}</>;
};

const mapStateToProps = (state: Store) => {
  return {system: (state.resources.system || {}).data || {}};
};

export default connect(mapStateToProps)(Title);
