import React from 'react';
import {Link} from 'react-router-dom';

import {Typography, Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type Props = {
  bucketName?: string;
  prefix?: string;
  showVersions?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '25px 20px 5px 20px',
  },
  header: {
    display: 'inline',
  },
  pathDelimiter: {
    margin: '0 10px',
  },
  link: {
    color: theme.palette.primary.light,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Breadcrumbs = ({bucketName, prefix, showVersions}: Props) => {
  const classes = useStyles();
  const links = [{to: '/buckets', label: 'Buckets'}];

  if (bucketName) {
    let link = `/buckets/${bucketName}/objects`;
    if (showVersions) {
      link += '?showVersions=true';
    }
    links.push({to: link, label: bucketName});

    if (prefix) {
      const prefixes = prefix.split('/');
      let prevPs = '';
      for (let i = 0; i < prefixes.length; i++) {
        const p = prefixes[i];
        if (p === '') {
          break;
        }
        const encodedP = encodeURIComponent(prevPs + p + '/');
        link = `/buckets/${bucketName}/objects?prefix=${encodedP}`;
        if (showVersions) {
          link += '&showVersions=true';
        }
        links.push({to: link, label: p});
        prevPs += p + '/';
      }
    }
  }

  return (
    <div className={classes.root}>
      {links.map((link, index) => (
        <Typography key={index} variant='subtitle1' className={classes.header}>
          {link.to && index < links.length-1 ?
            <Link to={link.to} className={classes.link}>{link.label}</Link> :
            <span>{link.label}</span>
          }
          {index < links.length-1 && <span className={classes.pathDelimiter}>&gt;</span>}
        </Typography>
      ))}
    </div>
  );
};

export default Breadcrumbs;
