import React from 'react';

import {Grid} from '@mui/material';

import {WizardPage} from 'spectra-logic-ui/components';

import {MessageSeverity} from '@/enum';
import Checkbox from '@/components/form/checkbox';
import TextField from '@/components/form/text_field';
import {MessageForm} from '@/administrators/form/enum';

type Props = {
  title: string;
}

const ChangeAttributesPage = ({title, ...props}: Props) => (
  <WizardPage title={title} {...props}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField name='username' label='Username' disabled />
      </Grid>
      <Grid item xs={6}>
        <TextField name='email' label='Email Address' />
      </Grid>
    </Grid>
    <br />{MessageForm.SELECT}<br />
    <div className='checkboxes'>
      <Checkbox name='info' label={MessageSeverity.INFO} />
      <Checkbox name='ok' label={MessageSeverity.OK} />
      <Checkbox name='warning' label={MessageSeverity.WARNING} />
      <Checkbox name='error' label={MessageSeverity.ERROR} />
    </div>
  </WizardPage>
);

export default ChangeAttributesPage;
