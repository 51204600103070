import React from 'react';
import {connect} from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';

import {updateCollection} from 'spectra-logic-ui/actions';
import {FormDialog, TextField as OutlinedTextField, Tooltip} from 'spectra-logic-ui/components';
import {Dispatch, RequestOptions} from 'spectra-logic-ui';

import DialogDescription from '@/components/form/dialog_description';
import {Entitlement} from '@/types';

type Props = {
  id: string;
  resource: string;
  title?: string;
  description?: string;
  disabled?: boolean;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
  form?: string;
}

const useStyles = makeStyles({
  textField: {
    marginTop: 10,
  },
});

const EntitlementsDialog = (props: Props) => {
  const {
    title = 'Edit Entitlements', disabled = false, onClose, onSubmit, ...others
  } = props;
  const classes = useStyles();

  const entitlementsTooltip = 'Copy and paste the contents of your entitlements.';
  return (
    <FormDialog
      title={title} submitLabel='Save' disableSubmit={disabled}
      onClose={onClose} onSubmit={onSubmit} closeOnSuccess
      {...others}
    >
      {() => (
        <>
          <DialogDescription>Apply your entitlements<Tooltip>{entitlementsTooltip}</Tooltip></DialogDescription>
          <br/>
          <OutlinedTextField
            name='jsondata'
            className={classes.textField}
            label='Entitlements'
            fullWidth
            multiline
            rows={4}
            variant='outlined'
          />
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const onSubmit = (values: any) => {
    let entitlements: Entitlement[] = [];
    const options: RequestOptions = {};
    if (values.jsondata) {
      try {
        const indata = JSON.parse(values.jsondata);
        if ('entitlements' in indata) {
          entitlements = indata.entitlements;
        }
        if ('signature' in indata && indata.signature) {
          options.query = {'signature': indata.signature};
        }
      } catch (e) {
        entitlements = [];
      }
    }
    return dispatch(updateCollection('entitlements', entitlements, options));
  };
  return {onSubmit};
};

export default connect(null, mapDispatchToProps)(EntitlementsDialog);
