import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {divide, toNumber} from 'lodash';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Table, Tooltip} from 'spectra-logic-ui/components';
import {commafy, numberToHumanSize} from 'spectra-logic-ui/helpers/number';

import {Metrics} from '@/types';

type Props = {
  id: string;
  metrics?: Metrics;
  error?: boolean;
  fetching?: boolean;
  fetchMetrics?: () => Promise<any>;
}

const storedTip = 'The stored size of all clones is the amount of data that is actually stored on the ' +
  'storage. This could be different from the original content size, since the clones are compressed before ' +
  'being written to the storage.';
const optionalTip = 'Optional data is stored to improve performance when there is sufficient '+
  'space available. Optional data is deleted automatically when space is needed.';

const MetricsDetails = ({id, fetchMetrics, metrics={}, error=false, fetching=false}: Props) => {
  useEffect(() => {
    if (fetchMetrics !== undefined) {
      fetchMetrics();
    }
  }, [id]);

  return (
    <Table>
      <Table.Body isLoading={fetching} hasError={error}>
        <Table.Row>
          <Table.Cell>Number of clones</Table.Cell>
          <Table.Cell>{commafy(metrics.count)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Total size of clone content</Table.Cell>
          <Table.Cell>{numberToHumanSize(metrics.size)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Total stored size of all clones</Table.Cell>
          <Table.Cell>{numberToHumanSize(metrics.stored)}<Tooltip>{storedTip}</Tooltip></Table.Cell>
        </Table.Row>
        {metrics.optional !== undefined &&
          <Table.Row>
            <Table.Cell>Total size of optional data</Table.Cell>
            <Table.Cell>{numberToHumanSize(metrics.optional)}<Tooltip>{optionalTip}</Tooltip></Table.Cell>
          </Table.Row>
        }
        <Table.Row>
          <Table.Cell>Average clone size</Table.Cell>
          <Table.Cell>{numberToHumanSize(divide(toNumber(metrics.stored), toNumber(metrics.count)))}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const mapStateToProps = (state: Store, {id}: Props) => {
  const metricsResource = state.resources[`storage/${id}/metrics`] || {};
  return {
    metrics: metricsResource.data,
    error: metricsResource.error,
    fetching: metricsResource.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {id}: Props) => ({
  fetchMetrics: () => dispatch(fetchResource(`storage/${id}/metrics`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MetricsDetails);
