// A custom login page that supports forced password resets (after logging
// in for the first time and forget password requests.
import React, {useState} from 'react';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import {LoginForm, loginStyles, Copyright} from 'spectra-logic-ui/components/login';
import {isAuthEnabled} from 'spectra-logic-ui/app/auth';

import {isSingle} from '@/single';
import ResetPasswordForm from '@/login/reset_password';
import SoftwareTokenMfaForm from '@/login/software_token_mfa';

type Props = {
  session: string;
  challengeName: string;
  challengeParameters: {
    [name: string]: string;
  };
  copyrightLink?: string;
}

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
    textAlign: 'center',
  },
  link: {
    fontSize: '0.8em',
    fontWeight: 'normal',
  },
  ...loginStyles,
});

const challengeNewPasswordRequired = 'NEW_PASSWORD_REQUIRED';
const challengeSoftwareTokenMfa = 'SOFTWARE_TOKEN_MFA';

const LoginPage = ({copyrightLink}: Props) => {
  const [session, setSession] = useState();
  const [challengeName, setChallengeName] = useState();
  const [challengeParameters, setChallengeParameters] = useState();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const onSuccess = (response: any) => {
    if (response.challengeName !== '') {
      setSession(response.session);
      setChallengeName(response.challengeName);
      setChallengeParameters(response.challengeParameters);
    }
  };

  if (!isAuthEnabled()) {
    return <Navigate to={'/'}/>;
  }
  return (
    <div className={classes.page}>
      {challengeName === challengeNewPasswordRequired &&
        <ResetPasswordForm
          location={location} navigate={navigate} session={session}
          challengeName={challengeName} challengeParameters={challengeParameters}
        />
      }
      {challengeName === challengeSoftwareTokenMfa &&
        <SoftwareTokenMfaForm
          location={location} navigate={navigate} session={session}
          challengeName={challengeName} challengeParameters={challengeParameters}
        />
      }
      {challengeName !== challengeNewPasswordRequired &&
        challengeName !== challengeSoftwareTokenMfa &&
        <LoginForm location={location} navigate={navigate} onSuccess={onSuccess} />
      }
      <div className={classes.root}>
        {!isSingle() && <Link to='/login/forgot_password' className={classes.link}>
          Forgot password?
        </Link>}
        <Copyright link={copyrightLink} />
      </div>
    </div>
  );
};

export default LoginPage;
