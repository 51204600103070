import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

const ClearIamUserCacheDialog = (props: any) => (
  <FormDialog title='Clear Cache' submitLabel='Clear Cache' {...props}>
    {() => (<Typography>Clear the current cache for all of the listed IAM users?</Typography>)}
  </FormDialog>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: () => dispatch(createResource('iam/users/clear_cache')),
});

export default connect(undefined, mapDispatchToProps)(ClearIamUserCacheDialog);
