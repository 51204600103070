import React from 'react';
import {useForm} from 'react-final-form';
import {stringify} from 'query-string';

import {Card, TextField} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';

import {getToken} from 'spectra-logic-ui/app/auth';
import {getBasePath} from 'spectra-logic-ui/app/base_path';
import {Color} from 'spectra-logic-ui/colors';
import {Loading} from 'spectra-logic-ui/components';

import DialogDescription from '@/components/form/dialog_description';
import {Geocode} from '@/locations/form/create/types';

const useStyles = makeStyles({
  descriptionNote: {
    display: 'block',
    fontStyle: 'italic',
    marginBottom: 10,
  },
  searchCard: {
    marginTop: 10,
    padding: 15,
  },
  errorText: {
    color: Color.ERROR,
    paddingTop: 10,
  },
  loading: {
    paddingTop: 10,
  },
});

const AddressLookup = () => {
  const [typingTimeout, setTypingTimeout] = React.useState<number>();
  const [hasError, setError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();
  const form = useForm();

  const setSearchText = (searchText: string) => form.mutators.setFormValue('searchText', searchText);

  const [geocodes, setGeocodes] = React.useState([]);

  const processSearchText = (event: React.ChangeEvent<any>, name: string) => {
    const searchText = event.target.value || name;
    if (searchText && searchText.length >= 2) {
      const query = searchText ? searchText.trim() : searchText;
      clearTimeout(typingTimeout);
      setTypingTimeout(window.setTimeout(() => fetchGeocodes(query), 500));
    }
    setSearchText(searchText);
  };

  const fetchGeocodes = (searchText: string) => {
    setLoading(true);
    if (hasError) setError(false);
    const options = {headers: new Headers({'Authorization': `Bearer ${getToken()}`})};
    const basePath = getBasePath();
    fetch(basePath + `/api/geocodes?${stringify({'q': searchText})}`, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Failed to fetch geocodes. ${response.status} (${response.statusText})`);
        }
      })
      .then(setGeocodes)
      .catch((error) => {
        setError(true);
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const updateLocation = (geocode: Geocode) => {
    form.mutators.setFormValue('locationName', geocode.name);
    form.mutators.setFormValue('latitude', geocode.latitude);
    form.mutators.setFormValue('longitude', geocode.longitude);
  };

  return (
    <div>
      <DialogDescription
        footnote={
          <React.Fragment>
            <strong>Note:</strong> You may skip this step if you want to enter your location data manually.
          </React.Fragment>
        }
      >
        Search and choose an address to use for your new location.
      </DialogDescription>
      <Card className={classes.searchCard}>
        <Autocomplete
          options={geocodes}
          // remove autoComplete filtering since we want all locations from the response (including translations).
          filterOptions={(x) => x}
          getOptionLabel={(option: Geocode) => option.displayName}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newGeocode) => {
            if (newGeocode) {
              processSearchText(event, newGeocode.displayName);
            };
            updateLocation(newGeocode ? newGeocode : {} as Geocode);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label='Address Search'
              fullWidth
              onChange={processSearchText}
              variant='standard'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <Loading /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Card>
    </div>
  );
};

export default AddressLookup;
