import React from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';

import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from 'spectra-logic-ui/icons/Check';
import NotInterestedIcon from 'spectra-logic-ui/icons/NotInterested';

import {fetchResource} from 'spectra-logic-ui/actions';
import Table from 'spectra-logic-ui/components/table';
import {Color} from 'spectra-logic-ui/colors';
import {Dispatch, Store} from 'spectra-logic-ui';

import ButtonToolbar from '@/components/button_toolbar';
import DeleteDialog from '@/components/delete_dialog';
import {AccessKey, IAMUser} from '@/iam/types';
import CreateKeyDialog from '@/iam/users/details/form/create';
import EnableKeyDialog from '@/iam/users/details/form/enable';
import DisableKeyDialog from '@/iam/users/details/form/disable';

type Props = {
  user: IAMUser;
  accessKeys?: AccessKey[];
  error?: boolean;
  fetching?: boolean;
  fetchAccessKeys?: () => Promise<any>;
}

const useStyles = makeStyles({
  check: {
    float: 'right',
    color: Color.OK,
  },
});

const AccessKeys = ({user, accessKeys = [], fetchAccessKeys, error, fetching}: Props) => {
  const classes = useStyles();
  const [selectedAccessKey, setSelectedAccessKey] = React.useState({} as AccessKey);

  const clearAccessKey = () => {
    setSelectedAccessKey({} as AccessKey);
  };

  React.useEffect(() => {
    if (fetchAccessKeys !== undefined) {
      fetchAccessKeys();
    }
    clearAccessKey();
  }, [user]);
  React.useEffect(() => {
    if (selectedAccessKey && selectedAccessKey.id) {
      for (let i = 0; i < accessKeys.length; i++) {
        if (accessKeys[i].id === selectedAccessKey.id) {
          setSelectedAccessKey(accessKeys[i]);
          return;
        }
      }
      clearAccessKey();
    }
  }, [accessKeys]);

  const selectAccessKey = (id: string) => {
    const key = accessKeys && accessKeys.find((key: AccessKey) => key.id == id) || {} as AccessKey;
    setSelectedAccessKey(key);
  };

  const deleteResource = `iam/users/${user.accountid}/${user.username}/keys`;
  return (
    <div>
      <ButtonToolbar>
        <ButtonToolbar.CreateDialogButton
          dialog={(props: any) => <CreateKeyDialog user={user} form='Create Access Key' {...props}/>}
          disabled={fetching || accessKeys.length >= 2}
        />
        <ButtonToolbar.DialogButton
          title='Enable' icon={CheckIcon} dialog={(props: any) => <EnableKeyDialog
            user={user} accessKey={selectedAccessKey} {...props} />}
          disabled={isEmpty(selectedAccessKey) || (selectedAccessKey.initialized && !selectedAccessKey.inactive)}
        />
        <ButtonToolbar.DialogButton
          title='Disable' icon={NotInterestedIcon} dialog={(props: any) => <DisableKeyDialog
            user={user} accessKey={selectedAccessKey} {...props} />}
          disabled={isEmpty(selectedAccessKey) || selectedAccessKey.inactive || !selectedAccessKey.initialized}
        />
        <ButtonToolbar.DeleteDialogButton
          disabled={isEmpty(selectedAccessKey)} dialog={(props: any) => (
            <DeleteDialog
              id={selectedAccessKey.id} name={selectedAccessKey.id}
              resource={deleteResource} title='Delete Access Key' {...props}
            />
          )}
        />
      </ButtonToolbar>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Cell>Access Key ID</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body isLoading={fetching} hasError={error}>
          {accessKeys && accessKeys.map((key: AccessKey) => (
            <Table.Row
              key={key.id} selected={!isEmpty(selectedAccessKey) && key.id === selectedAccessKey.id}
              onClick={() => selectAccessKey(key.id)}
            >
              <Table.Cell>{key.id} {key.initialized && !key.inactive &&
                <CheckIcon className={classes.check}></CheckIcon>}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const mapStateToProps = (state: Store, {user}: Props) => {
  const resource = `iam/users/${user.accountid}/${user.username}/keys`;
  const keysResource = state.resources[resource] || {};
  const accessKeys = keysResource.data || [];
  return {
    accessKeys: accessKeys,
    error: keysResource.error,
    fetching: keysResource.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {user}: Props) => {
  const resource = `iam/users/${user.accountid}/${user.username}/keys`;
  return {
    fetchAccessKeys: () => dispatch(fetchResource(resource)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessKeys);
