import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {FormApi} from 'final-form';

import {createPatch, fetchResource, patchResource, resetResource} from 'spectra-logic-ui/actions';
import {WizardFormDialog} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {FormActionType, TargetType} from '@/enum';
import {CloudProperties, Pool, PoolPatchFields, TargetItemDetails} from '@/types';
import {EditOption} from '@/storage/form/enum';
import {FormValues} from '@/storage/form/types';
import OptionsPage from '@/storage/form/edit/options';
import {validateEditAuthorization, validateEditParameters} from '@/storage/form/pages/validate';
import BlackPearlConfirmPage from '@/storage/form/pages/blackpearl/confirm';
import BlackPearlParametersPage from '@/storage/form/pages/blackpearl/parameters';
import VirtualMachineConfirmPage from '@/storage/form/pages/vm/confirm';
import VirtualMachineParametersPage from '@/storage/form/pages/vm/parameters';
import CloudAuthorizationPage from '@/storage/form/pages/cloud/authorization';
import CloudConfirmPage from '@/storage/form/pages/cloud/confirm';
import CloudParametersPage from '@/storage/form/pages/cloud/parameters';
import {isCloud} from '@/storage/cloud';
import {isBP} from '@/storage/on_site_storage';
import {itemDetails} from '@/storage/form/store';

type Props = {
  pool: Pool;
  itemDetails: TargetItemDetails;
  fetchItemDetails: Function;
  resetItemDetails: Function;
  onSubmit: (values: FormValues) => Promise<any>;
}

const EditStorageWizard = ({pool, itemDetails, fetchItemDetails, resetItemDetails, onSubmit, ...others}: Props) => {
  const initialValues: FormValues = {
    editOption: EditOption.CHANGE_PARAMS, ...pool,
  } as any;

  const title = pool.endpoint === '0' ? 'Edit Cloud Storage' : 'Edit Endpoint Storage';

  const [values, setValues] = useState({} as any);
  const endpoint = values.endpoint;
  const selectedOption = values['editOption'];

  // We need to fetch item details for all storage types so we can show the storage
  // class options. We also need it specifically for S3OTHER so we can pre-populate
  // the endpoint field.
  useEffect(() => {
    fetchItemDetails();
    return () => resetItemDetails();
  }, []);

  const formRef: React.MutableRefObject<FormApi> = useRef({} as any);
  if (pool.target === TargetType.S3OTHER) {
    useEffect(() => {
      const cloudProperties = itemDetails.properties as CloudProperties;
      if (formRef && formRef.current && formRef.current.mutators && cloudProperties && cloudProperties.endpoint) {
        formRef.current.mutators.setFormValue('url', cloudProperties.endpoint);
      }
    }, [itemDetails]);
  }

  return (
    <WizardFormDialog
      title={title} initialValues={initialValues} onSubmit={onSubmit} onValuesChange={setValues}
      formRef={formRef} {...others}
    >
      {isBP(pool) &&
        <React.Fragment>
          <BlackPearlParametersPage title='Parameters' action={FormActionType.EDIT}
            pool={pool} endpoint={endpoint} validate={validateEditParameters} />
          <BlackPearlConfirmPage title='Confirm' />
        </React.Fragment>
      }
      {pool.target === TargetType.VOLUME &&
        <React.Fragment>
          <VirtualMachineParametersPage title='Parameters' action={FormActionType.EDIT} pool={pool}
            validate={validateEditParameters} />
          <VirtualMachineConfirmPage title='Confirm' action={FormActionType.EDIT} />
        </React.Fragment>
      }
      {isCloud(pool) &&
        <React.Fragment>
          <OptionsPage title='Options' pool={pool} />
          {selectedOption === EditOption.CHANGE_PARAMS &&
            <CloudParametersPage title='Parameters' action={FormActionType.EDIT} pool={pool}
              validate={validateEditParameters} />}
          {selectedOption === EditOption.CHANGE_AUTH &&
            <CloudAuthorizationPage title='Authorization' action={FormActionType.EDIT}
              validate={validateEditAuthorization} />}
          <CloudConfirmPage title='Confirm' action={FormActionType.EDIT} />
        </React.Fragment>
      }
    </WizardFormDialog>
  );
};

const mapStateToProps = (state: Store) => {
  const itemState = state.resources[itemDetails] || {};
  return {itemDetails: itemState.data || {}};
};

const mapDispatchToProps = (dispatch: Dispatch, {pool}: Props) => ({
  fetchItemDetails: () => {
    const opts = {storeName: itemDetails};
    return dispatch(fetchResource('storage/' + pool.id + '/item', '', opts));
  },
  resetItemDetails: () => dispatch(resetResource(itemDetails)),
  onSubmit: (values: any) => {
    const {...props} = values;
    let body: any = {};
    if (props.editOption === EditOption.CHANGE_PARAMS) {
      delete props.accessKey;
      delete props.secretKey;
      delete props.arn;
      delete props.externalid;
      delete props.url;
      delete props.region;
      delete props.credentials;
      delete props.account;
      delete props.secret;
      body = createPatch(PoolPatchFields, pool, props);
    } else {
      // Changing authorization basically means forcing the user to re-enter all
      // the authorization parameters as opposed to displaying the existing ones
      // and only changing certain ones. Thus, we just always send down the applicable
      // params instead of using `createPatch`.
      //
      // Region and endpoint cannot be changed since that implies we're getting the data
      // from some other place.
      const params: any = {};
      if (props.target === TargetType.AZURE) {
        params.secret = props.secret;
      } else if (props.target === TargetType.GOOGLE) {
        params.credentials = props.credentials;
      } else {
        if (props.target === TargetType.S3OTHER) {
          params.endpoint = props.url;
        }
        params.accessKey = props.accessKey;
        params.secretKey = props.secretKey;
        params.arn = props.arn;
        params.externalid = props.externalid;
      }
      body.parameters = params;
    }
    delete body.id;
    delete body.editOption;
    if (!pool.link) {
      delete body.pauseNotifications;
    }
    return dispatch(patchResource('storage', values.id, body));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStorageWizard);
