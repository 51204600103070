import React from 'react';
import {connect} from 'react-redux';

import {Dispatch} from 'spectra-logic-ui';
import {FormDialog} from 'spectra-logic-ui/components';
import {updateResource} from 'spectra-logic-ui/actions';

import TextField from '@/components/form/text_field';
import {FormData} from '@/iam/users/form/types';
import validate from '@/iam/users/form/validate';

type Props = {
  account: string;
  onSubmit: (values: any) => Promise<any>;
}

const CreateIamUserDialog = (props: Props) => {
  return (
    <FormDialog title='Create IAM User' validate={validate} {...props}>
      {() => (<TextField name='username' label='Username' />)}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {account}: Props) => {
  const resource = `iam/users/${account}`;
  return {
    onSubmit: ({username}: FormData) => dispatch(updateResource(resource, username, {})),
  };
};

export default connect(undefined, mapDispatchToProps)(CreateIamUserDialog);
