import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';
import {Card, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, SelectOption, Store, StoreResourceData} from 'spectra-logic-ui';
import {createCollection, fetchResource} from 'spectra-logic-ui/actions';
import {WizardPage} from 'spectra-logic-ui/components';

import {FormActionType, StorageClass, TargetType} from '@/enum';
import {Bucket, Pool, TargetItem, TargetItemDetails} from '@/types';
import Checkbox from '@/components/form/checkbox';
import DialogDescription from '@/components/form/dialog_description';
import FormForwardSelect from '@/components/form/forward_select';
import FormSingleSelect from '@/components/form/single_select';
import TextField from '@/components/form/text_field';
import {isArchivalSupported, isStorageClassArchival} from '@/storage/archive';
import {cloneRestoreTooltip, recoverableTooltip} from '@/storage/details/properties';
import {items, itemDetails} from '@/storage/form/store';
import {AwsLinkTooltip} from '@/storage/form/pages/cloud/aws/tooltips';

type ParametersCardProps = {
  action: FormActionType;
  target?: TargetType;
  pool?: Pool;
  buckets: Bucket[];
  bucketsFetching: boolean;
  targetItems?: TargetItem[];
  poolLink?: string;
  storageClass: StorageClass;
  itemDetails?: TargetItemDetails;
  itemFetching: boolean;
  error?: string;
  link?: string;
}

type PageProps = {
  title: string;
  action: FormActionType;
  buckets?: Bucket[];
  bucketsFetching?: boolean;
  targetItems?: TargetItem[];
  fetchBuckets?: Function;
  fetchCreateStorageItemDetails?: Function;
  pool?: Pool;
  itemDetails?: TargetItemDetails;
  itemFetching?: boolean;
  itemErrorMessage?: string;
  validate?: (values: any) => object;
}

const useParametersCardStyles = makeStyles({
  root: {
    padding: 15,
    marginTop: 10,
  },
  grid: {
    '& > *': {
      marginBottom: 10,
    },
  },
});

const ParametersCard = ({buckets = [], targetItems = [], action, bucketsFetching, target,
  pool, poolLink, storageClass, itemDetails, itemFetching, error='', link=''}: ParametersCardProps) => {
  const classes = useParametersCardStyles();
  const cloudOtherBucketTip = 'The cloud bucket can be used as a storage target when creating a lifecycle.';
  const cloudAWSBucketTip = cloudOtherBucketTip +
    ' Additionally, when linking to a Vail bucket (see field below), ' +
    'existing and new data will be discovered in the cloud bucket and copied to the Vail bucket.';
  const cloudBucketTooltip = target === TargetType.S3 ? cloudAWSBucketTip : cloudOtherBucketTip;
  const storageClassTooltip = 'The selected storage class is used when clones are created on the cloud bucket.'+
    'The charges associated with the different storage classes are controlled by the cloud provider.';
  const bucketDesc = target === TargetType.AZURE ? 'Container' : 'Cloud Bucket';
  const cloudBucketSelectorLabel = action === FormActionType.EDIT ? bucketDesc : 'Select '+bucketDesc;

  let bucketOptions;
  if (action === FormActionType.EDIT && pool && pool.link) {
    bucketOptions = [{key: pool.link, text: pool.link}] as SelectOption[];
  } else {
    bucketOptions = buckets.filter((b) => !b.linkedStorage).map((b) => {
      return {key: b.name, text: b.name};
    }) as SelectOption[];
  }

  let cloudBucketOptions;
  if (action === FormActionType.EDIT && pool && pool.item) {
    cloudBucketOptions = [{key: pool.item, text: pool.item}] as SelectOption[];
  } else {
    cloudBucketOptions = targetItems.map((b) => ({key: b.id, text: b.name ? b.name : b.id})) as SelectOption[];
  }

  const form = useForm();
  const disableCloneRestoreForNonArchival = (e: any) => {
    if (e && e.target && !isStorageClassArchival(e.target.value)) {
      form.mutators.setFormValue('cloneRestore', false);
    }
  };
  const recoverableEnabled = !poolLink;

  const updatePauseNotifications = (e: any) => {
    if (e && e.target && (target === TargetType.S3 || target === TargetType.S3OTHER)) {
      const value = e.target.value;
      if (value && target === TargetType.S3OTHER) {
        // S3 Other doesn't support notifications. Thus they must always be paused
        // when creating linked storage.
        form.mutators.setFormValue('pauseNotifications', true);
      }
      if (!value) {
        // Notification pausing doesn't apply to unlinked storage.
        form.mutators.setFormValue('pauseNotifications', false);
      }
    }
  };

  const clearStorageClass = () => {
    form.mutators.setFormValue('storageClass', undefined);
  };
  const stgClasses = itemDetails && itemDetails.classes ? itemDetails.classes : [];

  return (
    <Card className={classes.root}>
      {error && <p className='error'>{error}</p>}
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={6}>
          <FormForwardSelect
            name='item' target='name' label={cloudBucketSelectorLabel} tooltip={cloudBucketTooltip}
            options={cloudBucketOptions} disabled={action === FormActionType.EDIT}
            onChange={clearStorageClass}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField name='name' label='Storage Name' />
        </Grid>
        {(target === TargetType.S3 || target === TargetType.S3OTHER) &&
          <Grid item xs={6}>
            <FormSingleSelect
              name='link' label='Link to Bucket' tooltip={<AwsLinkTooltip />}
              options={bucketOptions} disabled={bucketsFetching || action === FormActionType.EDIT}
              noneOption='Do Not Link' onChange={updatePauseNotifications}
            />
            <Checkbox
              name='pauseNotifications'
              label='Pause Notifications'
              disabled={!link || target !== TargetType.S3}
            />
          </Grid>
        }
        <Grid item xs={6}>
          <FormSingleSelect
            name='storageClass' label='Select Storage Class' tooltip={storageClassTooltip}
            options={stgClasses} disabled={stgClasses.length === 0 || itemFetching}
            onChange={disableCloneRestoreForNonArchival}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            name='recoverable' label='Third-party Recovery' tooltip={recoverableTooltip}
            disabled={!recoverableEnabled}
          />
        </Grid>
        {isArchivalSupported(stgClasses) &&
          <Grid item xs={6}>
            <Checkbox
              name='cloneRestore' label='Restore To New Clone' tooltip={cloneRestoreTooltip}
              disabled={!isStorageClassArchival(storageClass)}
            />
          </Grid>
        }
      </Grid>
    </Card>
  );
};


const CloudParametersPage = (props: PageProps) => {
  const {
    title, buckets = [], targetItems, fetchBuckets, bucketsFetching = false,
    action, pool, fetchCreateStorageItemDetails,
    itemDetails, itemFetching=false, itemErrorMessage, ...otherProps
  } = props;

  const form = useForm();
  const formState = useFormState();
  const item = formState.values.item;
  const target = formState.values.target;
  const storageClass = formState.values.storageClass;
  const link = formState.values.link;
  const stgClasses = itemDetails && itemDetails.classes ? itemDetails.classes : [];

  useEffect(() => {
    if (action === FormActionType.CREATE && fetchBuckets !== undefined) {
      fetchBuckets();
    }
  }, []);

  if (action === FormActionType.CREATE && fetchCreateStorageItemDetails) {
    useEffect(() => {
      if (item) {
        // The top-level edit storage page takes care of fetching item details
        // when editing storage. We only need this when creating storage.
        fetchCreateStorageItemDetails(formState.values);
      }
    }, [item]);

    useEffect(() => {
      if (storageClass && !stgClasses.includes(storageClass)) {
        form.mutators.setFormValue('storageClass', undefined);
      }
    }, [itemDetails]);
  }

  useEffect(() => {
    if (link) {
      form.mutators.setFormValue('recoverable', true);
    }
  }, [link]);

  return (
    <WizardPage
      title={title} {...otherProps}
      description={<DialogDescription>Provide additional information for this cloud storage below.</DialogDescription>}
    >
      <ParametersCard
        buckets={buckets}
        bucketsFetching={bucketsFetching}
        targetItems={targetItems}
        target={target}
        action={action}
        pool={pool}
        poolLink={link}
        storageClass={storageClass}
        itemDetails={itemDetails}
        itemFetching={itemFetching}
        error={itemErrorMessage}
        link={link}
      />
    </WizardPage>
  );
};

const mapStateToProps = (state: Store) => {
  const bucketsResource = state.resources['buckets'] || {} as StoreResourceData<string[]>;
  const targetItemsResource = state.resources[items] || {};
  const itemState = state.resources[itemDetails] || {};
  return {
    buckets: bucketsResource.data,
    bucketsFetching: bucketsResource.fetching,
    targetItems: targetItemsResource.data,
    itemDetails: itemState.data,
    itemFetching: itemState.fetching,
    itemErrorMessage: itemState.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchBuckets: () => dispatch(fetchResource('buckets')),
  fetchCreateStorageItemDetails: (values: any) => {
    const opts = {storeName: itemDetails};
    const body = getCloudBucketsBody(values);
    return dispatch(createCollection(`targets/0/${values.target}/${values.item}`, body, opts));
  },
});

export const getCloudBucketsBody = (values: any) => {
  const target = values.target;
  const body = {} as any;
  if (target === TargetType.S3) {
    if (!!values.region) {
      body.region = values.region;
    }
    if (!!values.arn) {
      body.arn = values.arn;
    }
    if (!!values.externalid) {
      body.externalid = values.externalid;
    }
  } else if (target === TargetType.S3OTHER) {
    body.endpoint = values.url;
  }
  if (target === TargetType.S3 || target == TargetType.S3OTHER) {
    if (!!values.accessKey) {
      body.accessKey = values.accessKey;
    }
    if (!!values.secretKey) {
      body.secretKey = values.secretKey;
    }
  } else if (target === TargetType.GOOGLE) {
    body.credentials = values.credentials;
  } else if (target === TargetType.AZURE) {
    body.account = values.account;
    body.secret = values.secret;
  }
  return body;
};

export default connect(mapStateToProps, mapDispatchToProps)(CloudParametersPage);
