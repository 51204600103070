import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {StatusIcon, Table} from 'spectra-logic-ui/components';
import {dateTimeLong} from 'spectra-logic-ui/helpers/date';

import {Message} from '@/messages/types';

type Props = {
  message: Message;
}

const useStyles = makeStyles({
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
  status: {
    paddingRight: '5px',
  },
  tableRow: {
    '& > *': {
      overflowWrap: 'break-word',
    },
  },
});

const Properties = ({message}: Props) => {
  const classes = useStyles();
  const rowProps = {className: classes.tableRow};
  return (
    <Table size='small'>
      <Table.Body>
        <Table.Row {...rowProps}><Table.Cell>ID</Table.Cell><Table.Cell>{message.id}</Table.Cell></Table.Row>
        <Table.Row {...rowProps}>
          <Table.Cell>Severity</Table.Cell>
          <Table.Cell>
            <StatusIcon status={message.severity} className={classes.status} />{message.severity}
          </Table.Cell>
        </Table.Row>
        <Table.Row {...rowProps}><Table.Cell>Description</Table.Cell><Table.Cell>{message.text}</Table.Cell></Table.Row>
        <Table.Row {...rowProps}><Table.Cell>Key</Table.Cell><Table.Cell>{message.key}</Table.Cell></Table.Row>
        <Table.Row {...rowProps}>
          <Table.Cell>Time</Table.Cell><Table.Cell>{dateTimeLong(message.time)}</Table.Cell>
        </Table.Row>
      </Table.Body >
    </Table>
  );
};

export default Properties;
