import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {updateCollection} from 'spectra-logic-ui/actions';
import {FormDialog, TextField as OutlinedTextField, Tooltip} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import DialogDescription from '@/components/form/dialog_description';
import TextField from '@/components/form/text_field';
import {SSLCertValues, SSLCertificate} from '@/types';

type Props = {
  id: string;
  resource: string;
  title?: string;
  description?: string;
  disabled?: boolean;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
  form?: string;
  certificate?: SSLCertificate;
}

const useStyles = makeStyles({
  radioButton: {
    marginBottom: 5,
  },
  textField: {
    marginTop: 10,
  },
});

const SSLCertificateDialog = (props: Props) => {
  const {
    title = 'Edit SSL Certificate', disabled = false, onClose, onSubmit, certificate, ...others
  } = props;
  const classes = useStyles();

  const certificateTooltip = 'Copy and paste the contents of your certificate and private key. '+
  'Both the certificate and private key must be in PEM format.';
  const passphraseTooltip = 'The passphrase used to encrypt the private key.';
  const initialValues = {...certificate} as SSLCertValues;
  return (
    <FormDialog
      title={title} submitLabel='Save' disableSubmit={disabled}
      onClose={onClose} onSubmit={onSubmit} closeOnSuccess initialValues={initialValues}
      {...others}
    >
      {() => (
        <>
          <DialogDescription>Configure your SSL Certificate<Tooltip>{certificateTooltip}</Tooltip></DialogDescription>
          <br/>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <OutlinedTextField
                name='certPEM'
                className={classes.textField}
                label='Certificate'
                fullWidth
                multiline
                rows={4}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedTextField
                name='privateKeyPEM'
                className={classes.textField}
                label='Private Key'
                fullWidth
                multiline
                rows={4}
                variant='outlined'
              />
            </Grid>
          </Grid>
          <TextField
            name='passphrase'
            label='Passphrase'
            tooltip={passphraseTooltip}
            type='password'
          />
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const onSubmit = (values: SSLCertValues) => {
    return dispatch(updateCollection('certificate', values));
  };
  return {onSubmit};
};

export default connect(null, mapDispatchToProps)(SSLCertificateDialog);
