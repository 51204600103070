import React from 'react';
import {connect} from 'react-redux';

import {Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {FormDialog} from 'spectra-logic-ui/components';
import {deleteResource} from 'spectra-logic-ui/actions';
import {Dispatch} from 'spectra-logic-ui';

import {Pod} from '@/types';
import {EndpointType} from '@/enum';

type Props = {
  endpoint: Pod;
  onClose?: () => any;
  onSubmit: (values: any) => Promise<any>;
}

const useStyles = makeStyles({
  text: {
    marginBottom: 10,
  },
});

const DeleteEndpointDialog = ({endpoint, onClose, onSubmit, ...others}: Props) => {
  const classes = useStyles();
  const title = 'Delete ' + (endpoint.type === EndpointType.BP ? 'BlackPearl' : 'Virtual Machine');
  const podURL = endpoint.url;
  return (
    <FormDialog title={title} submitLabel='Delete' closeOnSuccess onClose={onClose} onSubmit={onSubmit} {...others}>
      {() => (
        <>
          <Typography className={classes.text} variant='subtitle1'>
            <b>Are you sure you want to remove '{podURL}' from the Sphere?</b>
          </Typography>
          <Typography className={classes.text} variant='subtitle1'>
            The Vail Sphere will no longer recognize this endpoint if it is deleted.
          </Typography>
        </>
      )}
    </FormDialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => {
  const id = ownProps.endpoint.id;
  return {
    onSubmit: (values: any) => dispatch(deleteResource('endpoints', id, values)),
  };
};

export default connect(undefined, mapDispatchToProps)(DeleteEndpointDialog);
