import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import isEmpty from 'is-empty';
import {Grid, Typography, Paper, Toolbar} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchResource} from 'spectra-logic-ui/actions';
import {Color, SpectraLogoColor} from 'spectra-logic-ui/colors';
import {Loading, StatusIcon, Tooltip} from 'spectra-logic-ui/components';
import UpdateIcon from 'spectra-logic-ui/icons/Sync';
import UpdateAltIcon from 'spectra-logic-ui/icons/SystemUpdateAlt';
import RegisterIcon from 'spectra-logic-ui/icons/ArrowUpward';
import ErrorIcon from 'spectra-logic-ui/icons/Error';
import InfoIcon from 'spectra-logic-ui/icons/Info';

import {EndpointType, UpdateStatusUpdate} from '@/enum';
import {Pod, UpdateStatus} from '@/types';
import BpIcon from '@/assets/images/icons/bp.png';
import VmIcon from '@/assets/images/icons/vm.png';
import ButtonToolbar from '@/components/button_toolbar';
import {updateStatusDetails} from '@/updates';
import UploadDialog from '@/updates/form/upload_dialog';
import ActivateDialog from '@/system/form/activate';
import RegisterWizard from '@/system/form/register';
import {System} from '@/system/types';

type Props = {
  endpoints: Pod[];
  system: System;
  fetching: boolean;
  error: boolean;
  updateStatus: UpdateStatus;
  fetchUpdateStatus: () => void;
}

const useStyles = makeStyles({
  banner: {
    marginTop: '15px',
    marginBottom: '10px',
  },
  bannerInfo: {
    backgroundColor: SpectraLogoColor.PURPLE,
  },
  bannerError: {
    backgroundColor: Color.ERROR,
  },
  toolbar: {
    // The margin comes from our card body's padding so the toolbar looks the
    // same as if it were rendered inside the card body.
    margin: '0.9375rem 20px',
  },
  bannerIcon: {
    color: Color.WHITE,
  },
  title: {
    display: 'inline',
    marginLeft: '5px',
    fontWeight: 'bold',
    color: Color.WHITE,
  },
  activateButton: {
    marginLeft: 14,
  },
  registerButton: {
    marginLeft: 14,
  },
  headerContent: {
    display: 'inline',
    margin: '7px 0px 0px 20px',
  },
  link: {'&:hover': {textDecoration: 'underline'}},
  grid: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  gridHeader: {
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    textAlign: 'right',
    marginRight: 10,
  },
  gridData: {
    wordBreak: 'break-all',
  },
  podIcon: {
    height: 20,
    width: 20,
    marginRight: 2,
    verticalAlign: 'bottom',
  },
});

const SystemPage = ({endpoints, system, fetching, error, updateStatus, fetchUpdateStatus}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    fetchUpdateStatus();
  }, []);

  if (error) {
    return <Typography>There was an error loading the system information.</Typography>;
  } else if (fetching) {
    return <Loading />;
  }

  if (system.id) {
    let name: string|React.ReactElement = system.name;
    let tooltip;
    // endpoint.id is a pod ID whereas system.id is a node ID.
    const systemEndpointID = system.id.split('.')[0];
    const endpoint = endpoints && endpoints.find((e: Pod) => e.id === systemEndpointID);
    let status;
    let type;
    if (endpoint) {
      status = endpoint.status;
      type = endpoint.type;
      if (endpoint.managementURL) {
        name = <a href={endpoint.managementURL} target='_blank'>{name}</a>;
        tooltip = <Tooltip>BlackPearl Management Interface link.</Tooltip>;
      }
    }
    return (
      <React.Fragment>
        <Typography className={classes.headerContent} variant='h5'>{name}</Typography>{tooltip}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={12}>
            <Grid container className={classes.grid}>
              {status && <>
                <Grid item xs={6} className={classes.gridHeader}>Status:</Grid>
                <Grid item xs={5}>
                  <div className={classes.gridData}><StatusIcon status={status} /></div>
                </Grid>
              </>}
              {type && <>
                <Grid item xs={6} className={classes.gridHeader}>Type:</Grid>
                <Grid item xs={5}>
                  <img src={type === EndpointType.BP ? BpIcon : VmIcon} className={classes.podIcon} />
                  {type === EndpointType.BP ? 'BlackPearl' : 'Virtual Machine'}
                </Grid>
              </>}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  let BannerIcon = InfoIcon;
  if (updateStatus && updateStatus.update === UpdateStatusUpdate.ERROR) {
    BannerIcon = ErrorIcon;
  }

  const nextAction = isEmpty(system.sphere) ? 'Activate' : 'Register with Sphere';
  let bannerMessage = `Before clicking '${nextAction}' make sure all your settings are correct.`;
  const updateDetails = updateStatusDetails(updateStatus);
  if (!isEmpty(updateDetails)) {
    if (updateStatus && updateStatus.update === UpdateStatusUpdate.ERROR) {
      bannerMessage = 'Update Error';
    } else {
      bannerMessage = 'Updating';
    }
    bannerMessage += updateDetails;
  }

  let bannerClasses = classes.banner;
  if (updateStatus && updateStatus.update === UpdateStatusUpdate.ERROR) {
    bannerClasses += ' ' + classes.bannerError;
  } else {
    bannerClasses += ' ' + classes.bannerInfo;
  }

  const canActivate = isEmpty(updateDetails) || (updateDetails && updateStatus.update === UpdateStatusUpdate.ERROR);

  return (
    <div>
      <Paper className={bannerClasses} square>
        <Toolbar variant='dense'>
          <BannerIcon className={classes.bannerIcon}/>
          <Typography className={classes.title}>{bannerMessage}</Typography>
        </Toolbar>
      </Paper>
      <ButtonToolbar className={classes.toolbar}>
        <ButtonToolbar.DialogButton
          icon={UpdateIcon} title='Upload'
          dialog={(props: any) => <UploadDialog resource='endpoints/local/update/upload' {...props} />}
          disabled={!canActivate}
        />
        <ButtonToolbar.DialogButton
          icon={UpdateAltIcon} title='Activate' dialog={ActivateDialog}
          disabled={!canActivate}
        />
        <ButtonToolbar.DialogButton icon={RegisterIcon} title='Register with Sphere'
          dialog={(props: any) => <RegisterWizard system={system} {...props} />}
          disabled={isEmpty(system.sphere) || !isEmpty(updateDetails)} />
      </ButtonToolbar>
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  return {updateStatus: (state.resources['endpoints/local/update'] || {}).data || {}};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUpdateStatus: () => dispatch(fetchResource('endpoints/local/update')),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemPage);
